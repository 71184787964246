<template>
  <div>
    <!-- {{filters}} -->
     <el-row class="container">
      <PPMFiltersReports v-model="filters"></PPMFiltersReports>
      <br>
      <el-button @click="exportReports()" type="success" v-if="!loading">
        <strong class="white">Export</strong>
      </el-button>
    </el-row>
    <br>
    <!-- <el-row class="container">
      <el-table
        :data="PPMList"
        :row-class-name="PPMStatus"
        v-loading="loading"
        @sort-change="sortDates"
        border
        :header-cell-style="{background:'rgb(170, 238, 238)',color:'black' ,'text-align':'center'}"
      >
        <el-table-column prop="hall" align="center" label="Hall"></el-table-column>
        <el-table-column prop="periodicity" align="center" label="Periodicity"></el-table-column>
        <el-table-column label="System" prop="system"></el-table-column>
        <el-table-column label="Group" prop="group"></el-table-column>
        <el-table-column label="Component ID" :formatter="componentIDFormatter" show-overflow-tooltip></el-table-column>
        <el-table-column prop="status" label="Status" align="center"></el-table-column>
        
        <el-table-column
          sortable
          label="Date Performed"
          prop="date_performed"
          align="center"
          width="250"
          :formatter="Completiondate" 
        ></el-table-column>
        
        <el-table-column fixed="right" label="Actions" width="120">
          <template slot-scope="scope">
            <el-button
              circle
              type="primary"
              icon="el-icon-edit"
              @click="handleClick(scope.$index, scope.row)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="deletePPM(scope.$index, scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row> -->
  </div>
</template>
<script lang="ts">
import Moment from "moment";
import { Component, Vue, Prop } from "vue-property-decorator";
import { component } from "../../api/component/component";
import { personnel } from "../../api/personnel/personnel";
import { workorder } from "../../api/workorder/workorder";
import PPMFiltersReports from "../Filters/PPMFiltersReports.vue";
import { ppm } from "../../api/ppm/ppm";
import { status } from "../../api/status/status";
@Component({
  name: "PPMReports",
  components: {
    PPMFiltersReports
  }
})
export default class PPMReports extends Vue {
  public PPMList: any[] = [];
  public componentList: any[] = [];
  public componentLiterals: any[] = [];
  public personnelList: any[] = [];
  public workOrderList: any[] = [];
  public statusList: any[] = [];
  public periodicityList: any[] = [];
  public loading: boolean = false;
  public filters: any = {};

  public componentIDFormatter(row, column, cellValue, index){
    return Array.from(row.component_table, (x: any) => x['id_component']).join(', ');
  }

 public async created() {
   
    this.loading = true;
    await this.getPersonnelList();
    this.fillTable();
    console.log('calling getPPMList2')
    //this.getPPMList2();

  }
  

    public exportReports() {
      console.log('testforrepooooooooooooooooooooooooooooooooooooooooooooorts')
      console.log(this.filters.fromDate)
      console.log(this.filters.toDate)
      console.log(this.filters.periodicity)
      console.log(this.filters.reports)
      console.log('testforrepooooooooooooooooooooooooooooooooooooooooooooorts2')
        

    this.$store.dispatch("updateCurrentObject", { fromDate: this.filters.fromDate , toDate: this.filters.toDate , periodicity: this.filters.periodicity , reports: this.filters.reports});
    this.$store.dispatch("updateCurrentComponent", "downloadPPMReports");
    this.$router.replace({
      name: "load"
    });
  }
  // na to valw gia na fenonte otan vazo to pontiki apo pano select subpanel !!!!!
  //  <el-table-column
  //     property="address"
  //     label="Address"
  //     show-overflow-tooltip>
  
////?

// public getPPMList2(){

// // let r = await ppm.getALLPpmsList2(this.$store.getters.getToken, this.$store.getters.getHost)
    
// //     if (r.status == 400) {
// //       this.$message.error(r.error);
// //     } else {
// //       console.log("THIS IS THE POST RESPONSE")
// //       console.log(JSON.stringify(r))

// //       let ppm_id = r.ppm_id; 

// // }

// }
public Completiondate(row: any) {
    // if (row.status.enddate === undefined) {
    //   return "";
    // }
    console.log(JSON.stringify(row))
    
   return Moment(row.date_performed).format("DD/MM/YYYY");
  }



   public async getPPMList2() {

    // var filters = {
    //   fromDate: JSON.stringify(new Date(0)),
    //   toDate: JSON.stringify(new Date(86400000000000)),
    //   system: 3,
    //   status: 2,
    //   maintenance_period: "2019-2020",
    //   component: 146,
    // }
    
    //   .getSomePpmsList2(this.$store.getters.getToken, filters, this.$store.getters.getHost)

    await ppm
      .getALLPpmsList2(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        
        response.json().then(async resp => {

          this.PPMList = JSON.parse(JSON.stringify(resp));

          console.log('ppmlist1')
          console.log(JSON.stringify(this.PPMList))
          console.log('ppmlist2')
          
          // await this.getComponentList();
        });
        

      });
  }

  public getDate(row: any, column: any) {
    let date_started = new Date(8640000000000000);
    let date_ended = new Date(0);
    for (const p of row.personnel) {
      if (new Date(p.date_started) < new Date(date_started)) {
        date_started = p.date_started;
      }
      if (new Date(p.date_ended) > new Date(date_ended)) {
        date_ended = p.date_ended;
      }
    }
    return (
      Moment(date_started).format("DD/MM/YYYY HH:mm") +
      " - " +
      Moment(date_ended).format("HH:mm")
    );
  }
  public sortDates() {
    return this.PPMList.sort(function(a, b) {
      let aDate_started = new Date(8640000000000000);
      let bDate_started = new Date(8640000000000000);
      for (const p of a.personnel) {
        if (
          new Date(p.date_started).getTime() < new Date(aDate_started).getTime()
        ) {
          aDate_started = p.date_started;
        }
      }
      for (const p of b.personnel) {
        if (
          new Date(p.date_started).getTime() < new Date(bDate_started).getTime()
        ) {
          bDate_started = p.date_started;
        }
      }
      console.log(a);
      console.log(b);
      if (
        new Date(aDate_started).getTime() < new Date(bDate_started).getTime()
      ) {
        return 1;
      }
      return -1;
    });
  }
  public filterHandler(value: any, row: any, column: any) {
    const property = column["property"];
    return row[property] === value;
  }
  public getFilters() {
    let a: any = [];
    {
      for (const s of this.statusList) {
        a.push({ value: s.id, text: s.literal });
      }
    }
    return a;
  }

  public getPeriodicityFilters() {
    let a: any = [];
    let commonPeriodicities: any = [];
    let commonIds = [1, 2, 4, 6, 7, 8];
    let rarePeriodicities: any = [];
    for (const s of this.periodicityList) {
      if (commonIds.indexOf(s.id) != -1) {
        commonPeriodicities.push({ value: s.id, text: s.literal });
      } else {
        rarePeriodicities.push({ value: s.id, text: s.literal });
      }
    }
    for (const s of commonPeriodicities) {
      a.push(s);
    }
    for (const s of rarePeriodicities) {
      a.push(s);
    }
    return a;
  }
  public getComponentName(row: any, column: any): String {
    let content = "";
    for (const k of row.components) {
      if (content != "") {
        content = content + ", ";
      }
      let component = this.componentList.find((obj: any) => obj.id === k.id);
      if (component != undefined) {
        content = content + component.literal;
      }
    }
    return content;
  }
  public getHall(row: any, column: any): String {
    console.log(row);
    let component = this.componentList.find(
      (obj: any) => obj.id === row.components[0]
    );
    if (component != undefined) {
      return component.group1;
    }
    return "";
  }
  public getSystem(row: any, column: any): String {
    return row.system;
  }
  public async getStatusList() {
    await status
      .getAllStatuses(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then((resp: any) => {
          this.statusList = JSON.parse(JSON.stringify(resp));
          this.getComponentList();
        });
      });
  }
  public async getPeriodicities() {
    await ppm
      .getMaintenancePeriodicities(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then((resp: any) => {
          this.periodicityList = JSON.parse(JSON.stringify(resp));
          this.loading = false;
        });
      });
  }
  public filterDateTime(date: any) {
    console.log(date);
    if (date != undefined) {
      return Moment(date).format("DD/MM/YYYY HH:mm");
    }
    return "";
  }
  public getStatus(row: any, column: any): String {
    let status = this.statusList.find((obj: any) => obj.id === row.status);
    if (status != undefined) {
      return status.literal;
    }
    return "";
  }
  public getPeriodicity(row: any, column: any): String {
    let periodicity = this.periodicityList.find(
      (obj: any) => obj.id === row.periodicity
    );
    if (periodicity != undefined) {
      return periodicity.literal;
    }
    return "";
  }
  public PPMStatus({
    row,
    rowIndex
  }: {
    row: number;
    rowIndex: number;
  }): String {
    return this.getStatus(row, 1);
  }
  public async deletePPM(rowIndex: number, row: any) {
    await ppm
      .deletePPM2(
        this.$store.getters.getToken,
        row.id,
        this.$store.getters.getHost
      )
      .then(async () => {
          this.$message.success("PPM Deleted");
          this.$router.replace({
            name: "load"
          });
      });
  }
  public async search() {
    this.loading = true;
    console.log('JSON.stringify(this.filters) BEFORE DELETES');
    console.log(JSON.stringify(this.filters));

    var fromDateOld = null
    if (this.filters.fromDate == null) {
      delete this.filters.fromDate;
    }
    if(this.filters.fromDate){
      fromDateOld = this.filters.fromDate
      this.filters.fromDate = JSON.stringify(this.filters.fromDate)
    }

    var toDateOld = null
    if (this.filters.toDate == null) {
      delete this.filters.toDate;
    }
    if(this.filters.toDate){
      toDateOld = this.filters.toDate
      this.filters.toDate = JSON.stringify(this.filters.toDate)
    }


    if(this.filters.system == ''){
      delete this.filters.system;
    }

    if(this.filters.component == ''){
      delete this.filters.component;
    }

    if(this.filters.status == ''){
      delete this.filters.status;
    }

    if(this.filters.maintenance_period == ''){
      delete this.filters.maintenance_period;
    }

    
    console.log('JSON.stringify(this.filters) AFTER DELETES');
    console.log(JSON.stringify(this.filters));
    
    // var filters = {
    //   fromDate: JSON.stringify(new Date(0)),
    //   toDate: JSON.stringify(new Date(86400000000000)),
    //   system: 3,
    
    //   status: 2,
    //   maintenance_period: "2019-2020",
    //   component: 146,
    // }

    await ppm
      .getSomePpmsList2(this.$store.getters.getToken, this.filters, this.$store.getters.getHost)
      .then(response => {
        
        response.json().then(async resp => {

          this.PPMList = JSON.parse(JSON.stringify(resp));

          console.log('ppmlist1')
          console.log(JSON.stringify(this.PPMList))
          console.log('ppmlist2')
        });
      });
    
      if(fromDateOld){
        this.filters.fromDate = fromDateOld
      }
    
      if(toDateOld){
        this.filters.toDate = toDateOld
      }

    this.loading = false;
  }
  public fillTable() {
    for (const p of this.PPMList) {
      //p.personnel = JSON.parse(p.personnel);
    }
    this.loading = false;
  }
 
  public filterDate(date: any) {
    if (date != undefined) {
      return Moment(date).format("DD-MM-YYYY");
    }
    return "";
  }
  public handleClick(rowIndex: number, row: any) {
    console.log("ROW");
    console.log(row);
    console.log("ROW")
    this.$store.dispatch("updateCurrentObject", row);
    this.$store.dispatch("updateCurrentComponent", "AddPPMyearly");
    this.$router.replace({ name: "load" });
  }
 

  public async getComponentList() {
    await component
      .getAllComponents(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(async resp => {
          this.componentList = JSON.parse(JSON.stringify(resp));
          await this.getPeriodicities();
        });
      });
  }
  public async getPersonnelList() {
    await personnel
      .getAllPersonnel(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(async resp => {
          this.personnelList = JSON.parse(JSON.stringify(resp));
          await this.getStatusList();
        });
      });
  }
}
</script>
<style>
.el-table .Pending {
  background: rgb(236, 255, 149);
}

.el-table .Completed {
  background: #ffffff;
}

.el-table .Cancelled {
  background: rgb(255, 186, 186);
}
</style>
<style scoped>
.container {
  border: 2px solid rgb(170, 238, 238);
  border-radius: 10px;
  background-color: rgb(248, 247, 247);
  padding: 5px 10px 5px 10px;
}
.personnel {
  background-color: rgb(230, 242, 255);
  font-weight: normal !important;
  padding-left: 10px;
  padding-right: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}
.personnelHead {
  background-color: rgb(255, 242, 240);
  font-weight: normal !important;
  padding-left: 10px;
  padding-right: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}
</style>