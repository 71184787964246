<template>
  <div>
    <el-row class="container">
      <AlarmFilters v-model="filters"></AlarmFilters>
      <br />
      <el-button @click="search" type="success" v-if="!loading">
        <strong class="white">Search</strong>
      </el-button>
    </el-row>
    <br />
    <el-row class="container">
      <el-table
        :data="alarmList"
        :row-class-name="alarmStatus"
        v-loading="loading"
        :default-sort="{prop: 'date_started', order: 'descending'}"
        :header-cell-style="{background:'rgb(170, 238, 238)',color:'black', 'text-align':'center'}"
        border
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="personnel">
              <p>
                <u>
                  <strong>Description:</strong>
                </u>
                {{props.row.name}}
              </p>
              <p>
                <u>
                  <strong>Start Date:</strong>
                </u>
                {{filterDateTime(props.row.date_started)}}
              </p>
              <p>
                <u>
                  <strong>End Date:</strong>
                </u>
                {{filterDateTime(props.row.date_ended)}}
              </p>
            </div>
            <div class="personnel">
              <p>
                <strong>Involved Personnel:</strong>
              </p>
            </div>
            <table style="font-weight:normal;">
              <tr>
                <th class="personnelHead">
                  <strong>Last Name</strong>
                </th>
                <th class="personnelHead">
                  <strong>First Name</strong>
                </th>
                <th class="personnelHead">
                  <strong>Date Started</strong>
                </th>
                <th class="personnelHead">
                  <strong>Date Ended</strong>
                </th>
              </tr>
              <tr v-for="p in JSON.parse(props.row.personnel)">
                <th class="personnel">{{personnelList.find(obj=>obj.id==p.id).lastname}}</th>
                <th class="personnel">{{personnelList.find(obj=>obj.id==p.id).firstname}}</th>
                <th class="personnel">{{filterDateTime(p.date_started)}}</th>
                <th class="personnel">{{filterDateTime(p.date_ended)}}</th>
              </tr>
            </table>
          </template>
        </el-table-column>
        <el-table-column label="Shift" sortable :formatter="getShift" width="150px"></el-table-column>
        <el-table-column
          prop="date_started"
          width="100px"
          label="Date"
          sortable
          :formatter="getDateTime"
        ></el-table-column>
        <el-table-column
          sortable
          prop="componentIds[0]"
          label="Hall"
          :formatter="getHall"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="componentIds[0]"
          label="Code"
          sortable
          :formatter="getComponentName"
          align="center"
        ></el-table-column>
        <el-table-column sortable prop="name" label="Description" width="250px"></el-table-column>
        <el-table-column
          prop="status"
          :formatter="getStatus"
          label="Status"
          :filters="getFilters()"
          :filter-method="filterHandler"
          align="center"
        ></el-table-column>
        <el-table-column sortable fixed="right" label="Actions" width="120">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="handleClick(scope.$index, scope.row)"
              icon="el-icon-edit"
              circle
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="deleteAlarm(scope.$index, scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { component } from "../../api/component/component";
import { personnel } from "../../api/personnel/personnel";
import { alarm } from "../../api/alarm/alarm";
import { status } from "../../api/status/status";
import { shifts } from "../../api/shifts/shifts";
import AlarmFilters from "../Filters/AlarmFilters.vue";
import Moment from "moment";
import { StringHelpers } from "../../api/helpers/StringHelpers";
@Component({
  name: "AlarmList",
  components: {
    AlarmFilters
  }
})
export default class AlarmList extends Vue {
  public alarmList: any[] = [];
  public statusList: any[] = [];
  public personnelList: any[] = [];
  public componentList: any[] = [];
  public loading: boolean = false;
  public filters: any = {};
  public typeList: any[] = [];
  public tableData: any[] = [];
  public listEnd: boolean = false;
  public index: number = 0;
  public more() {
    let i = 1;
    while (i < 10) {
      this.tableData.push(
        this.alarmList[this.alarmList.length - 1 - this.index - i]
      );
      this.index++;
      i++;
      if (
        this.alarmList[this.alarmList.length - 1 - this.index - i] == undefined
      ) {
        this.index--;
        this.listEnd = true;
        return;
      }
    }
  }
  public getDateTime(row: any) {
    if (row.date_started === undefined) {
      return "";
    }
    return Moment(row.date_started).format("DD/MM/YYYY HH:mm");
  }
  public getShift(row: any) {
    if (row.poster[0].level < 2) {
      return row.poster[0].name.split(" ")[1];
    }
    if (
      new Date(row.date_started).getHours() >= 14 &&
      new Date(row.date_started).getHours() < 22
    ) {
      return "Evening " + row.poster[0].name.split(" ")[1];
    }
    if (
      new Date(row.date_started).getHours() >= 6 &&
      new Date(row.date_started).getHours() < 14
    ) {
      return "Morning " + row.poster[0].name.split(" ")[1];
    }
    return "Night " + row.poster[0].name.split(" ")[1];
  }
  public async getPersonnelList() {
    await personnel
      .getAllPersonnel(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.personnelList = JSON.parse(JSON.stringify(resp));
          console.log(this.personnelList);
          this.getStatusList();
        });
      });
  }
  public getComponentName(row: any, column: any): String {
    console.log(row);
    let component = this.componentList.find(
      (obj: any) => obj.id === row.componentIds[0]
    );
    if (component != undefined) {
      return component.literal;
    }
    return "";
  }
  public getHall(row: any, column: any): String {
    console.log(row);
    let component = this.componentList.find(
      (obj: any) => obj.id === row.componentIds[0]
    );
    if (component != undefined) {
      return component.group1;
    }
    return "";
  }
  public getStatus(row: any, column: any): String {
    console.log(row);
    let status = this.statusList.find((obj: any) => obj.id === row.status);
    if (status != undefined) {
      return status.literal;
    }
    return "";
  }
  public chopDescription(row: any, column: any): String {
    return StringHelpers.shorten(row.name);
  }
  public getFilters() {
    let a: any = [];
    {
      for (const s of this.statusList) {
        a.push({ value: s.id, text: s.literal });
      }
    }
    return a;
  }
  public async search() {
    this.loading = true;
    this.listEnd = false;
    console.log(this.filters);
    if (this.filters.dateFrom === null) {
      delete this.filters.dateFrom;
    }
    if (this.filters.dateTo === null) {
      delete this.filters.dateTo;
    }
    await alarm
      .getSomeAlarms(
        this.$store.getters.getToken,
        this.filters,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then((resp: any) => {
          this.alarmList = JSON.parse(JSON.stringify(resp));
          this.tableData = this.alarmList.slice(
            this.alarmList.length - 10,
            this.alarmList.length
          );
          this.loading = false;
          console.log(JSON.parse(JSON.stringify(resp)));
        });
      });
  }
  public fillTable() {
    for (const w of this.alarmList) {
      //w.personnel = JSON.parse(w.personnel);
    }
    console.log(this.alarmList);
  }
  public alarmStatus({
    row,
    rowIndex
  }: {
    row: number;
    rowIndex: number;
  }): String {
    return this.getStatus(row, 1);
  }
  public async created() {
    this.loading = true;
    await this.getPersonnelList();
  }
  public filterHandler(value: any, row: any, column: any) {
    const property = column["property"];
    return row[property] === value;
  }
  public handleClick(rowIndex: number, row: any) {
    row.personnel = JSON.stringify(row.personnel);
    this.$store.dispatch("updateCurrentObject", row);
    this.$store.dispatch("setCurrentId", row.id);
    this.$store.dispatch("updateCurrentComponent", "AddAlarm");
    this.$router.replace({
      name: "load"
    });
  }
  public filterDate(date: any) {
    if (date === undefined) {
      return "";
    }
    return Moment(date).format("DD/MM/YYYY");
  }
  public filterDateTime(date: any) {
    if (date === undefined) {
      return "";
    }
    return Moment(date).format("DD/MM/YYYY HH:mm");
  }
  public async getComponentList() {
    await component
      .getAllComponents(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.componentList = JSON.parse(JSON.stringify(resp));
          this.getShiftTypeList();
        });
      });
    this.fillTable();
  }
  public async getShiftTypeList() {
    await shifts
      .getShiftTypes(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then((resp: any) => {
          this.typeList = JSON.parse(JSON.stringify(resp));
          this.loading = false;
        });
      });
  }
  public async getAlarmList() {
    await alarm
      .getAllAlarms(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then((resp: any) => {
          this.alarmList = JSON.parse(JSON.stringify(resp));
        });
      });
  }
  public async getStatusList() {
    await status
      .getAllStatuses(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then((resp: any) => {
          this.statusList = JSON.parse(JSON.stringify(resp));
          this.getComponentList();
        });
      });
  }
  public async deleteAlarm(rowIndex: number, row: any) {
    await alarm
      .recycleAlarm(
        this.$store.getters.getToken,
        row.id,
        this.$store.getters.getHost
      )
      .then(async r => {
        if (r.status == 400) {
          this.$message.error(r.error);
        } else {
          this.$message("Alarm Recycled");
          this.search();
        }
      });
  }
}
</script>
<style>
.el-table .Pending {
  background: rgb(236, 255, 149);
}

.el-table .Completed {
  background: #ffffff;
}

.el-table .Cancelled {
  background: rgb(255, 186, 186);
}
</style>
<style scoped>
.container {
  border: 2px solid rgb(170, 238, 238);
  border-radius: 10px;
  background-color: rgb(248, 247, 247);
  padding: 5px 10px 5px 10px;
}
.personnel {
  background-color: rgb(230, 242, 255);
  font-weight: normal !important;
  padding-left: 10px;
  padding-right: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}
.personnelHead {
  background-color: rgb(255, 242, 240);
  font-weight: normal !important;
  padding-left: 10px;
  padding-right: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}
u {
  text-decoration: underline;
}
</style>