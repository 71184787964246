<template>
  <div class="container">
    <el-row>
      <el-col :span="8">
        <el-select
          v-model="hall"
          filterable
          remote
          clearable
          placeholder="Hall"
          :remote-method="hallMethod"
          :loading="loading"
          size="mini"
          :disabled="newHall"
        >
          <el-option
            v-for="item in visibleHalls"
            :key="item.id"
            :label="item.literal"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <el-switch v-model="newHall" active-text="New Hall" inactive-text="Existing Hall"></el-switch>
      </el-col>
      <el-col :span="4">
        <el-input placeholder="Hall" v-model="hallText" size="mini" :disabled="!newHall"></el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-select
          v-model="system"
          filterable
          remote
          clearable
          placeholder="System"
          :remote-method="systemMethod"
          :loading="loading"
          size="mini"
          :disabled="newSystem"
        >
          <el-option
            v-for="item in visibleSystems"
            :key="item.id"
            :label="item.literal"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <el-switch v-model="newSystem" active-text="New System" inactive-text="Existing System"></el-switch>
      </el-col>
      <el-col :span="4">
        <el-input placeholder="System" v-model="systemText" size="mini" :disabled="!newSystem"></el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-select
          v-model="group"
          filterable
          remote
          clearable
          placeholder="Group"
          :remote-method="groupMethod"
          :loading="loading"
          size="mini"
          :disabled="newGroup"
        >
          <el-option
            v-for="item in visibleGroups"
            :key="item.id"
            :label="item.literal"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <el-switch v-model="newGroup" active-text="New Group" inactive-text="Existing Group"></el-switch>
      </el-col>
      <el-col :span="4">
        <el-input placeholder="Group" v-model="groupText" size="mini" :disabled="!newGroup"></el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-select
          v-model="code"
          filterable
          remote
          clearable
          placeholder="Code"
          :remote-method="codeMethod"
          :loading="loading"
          size="mini"
          :disabled="newCode"
        >
          <el-option
            v-for="item in visibleCodes"
            :key="item.id"
            :label="item.literal"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <el-switch v-model="newCode" active-text="New Code" inactive-text="Existing Code"></el-switch>
      </el-col>
      <el-col :span="4">
        <el-input placeholder="Code" v-model="codeText" size="mini" :disabled="!newCode"></el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-select
          v-model="description"
          filterable
          remote
          clearable
          placeholder="Description"
          :remote-method="descriptionMethod"
          :loading="loading"
          size="mini"
          :disabled="newDescription"
        >
          <el-option
            v-for="item in visibleDescriptions"
            :key="item.id"
            :label="item.literal"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <el-switch
          v-model="newDescription"
          active-text="New Description"
          inactive-text="Existing Description"
        ></el-switch>
      </el-col>
      <el-col :span="4">
        <el-input
          placeholder="Description"
          v-model="descriptionText"
          size="mini"
          :disabled="!newDescription"
        ></el-input>
      </el-col>
    </el-row>
    <el-input placeholder="Name" v-model="name" clearable></el-input>
    <br>
    <el-button type="success" round @click.native="addToDB">Save</el-button>
    <el-button type="danger" round @click.native="back">Back</el-button>
    <el-button
      type="danger"
      round
      @click.native="recycle"
      v-if="item!=undefined&&!item.recycled"
    >Recycle</el-button>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { group } from "../../api/group/group";
import { component } from "../../api/component/component";
@Component({ name: "AddComponent" })
export default class ppmDetails extends Vue {
  public groupList: any[] = [];
  public visibleHalls: any[] = [];
  public visibleSystems: any[] = [];
  public visibleGroups: any[] = [];
  public visibleCodes: any[] = [];
  public visibleDescriptions: any[] = [];
  public halls: any[] = [];
  public systems: any[] = [];
  public groups: any[] = [];
  public codes: any[] = [];
  public descriptions: any[] = [];
  public hall: string = "";
  public system: string = "";
  public group: string = "";
  public code: string = "";
  public description: string = "";
  public hallText: string = "";
  public systemText: string = "";
  public groupText: string = "";
  public codeText: string = "";
  public descriptionText: string = "";
  public loading: boolean = false;
  public name: string = "";
  public newHall: boolean = false;
  public newSystem: boolean = false;
  public newGroup: boolean = false;
  public newCode: boolean = false;
  public newDescription: boolean = false;
  public maxHalls: number = -1;
  public maxSystems: number = -1;
  public maxGroups: number = -1;
  public maxCodes: number = -1;
  public maxDescriptions: number = -1;
  public maxId: number = -1;
  public item: any = {};
  public inHall: string = "";
  public inSystem: string = "";
  public inGroup: string = "";
  public inCode: string = "";
  public inDescription: string = "";
  public async recycle() {
    if (this.item.id != undefined) {
      component.recycleComponent(
        this.$store.getters.getToken,
        this.item.id,
        this.$store.getters.getHost
      );
      this.$store.dispatch("updateCurrentObject", undefined);
      this.$store.dispatch("updateCurrentComponent", "ComponentList");
      this.$store.dispatch("setCurrentId", -1);
      this.$router.replace({ name: "load" });
    }
  }
  public async created() {
    this.item = this.$store.getters.getCurrentObject;
    if (this.item == undefined) {
      this.item = {};
    }
    await this.getGroupList();
  }
  public beforeDestroy() {
    this.$store.dispatch("updateCurrentObject", {});
    this.$store.dispatch("setCurrentId", -1);
  }
  public back() {
    this.$store.dispatch("updateCurrentObject", {});
    this.$store.dispatch("updateCurrentComponent", "ComponentList");
    this.$store.dispatch("setCurrentId", -1);
    this.$router.replace({ name: "load" });
  }
  public CheckEdit(component: any) {
    if (component.id != undefined) {
      console.log(component);
      this.hall = component.group1;
      this.system = component.group2;
      this.group = component.group3;
      this.code = component.group4;
      this.description = component.group5;
      this.inHall = this.groupList.find(r => r.literal == component.group1).id;
      this.inSystem = this.groupList.find(
        r => r.literal == component.group2
      ).id;
      this.inGroup = this.groupList.find(
        r => r.literal == component.group3
      ).id;
      this.inCode = this.groupList.find(r => r.literal == component.group4).id;
      this.inDescription = this.groupList.find(
        r => r.literal == component.group5
      ).id;
      this.name = component.literal;
    }
  }
  public async addToDB() {
    let newgroups: number = 1;
    if (this.inHall != "") {
      this.hall = this.inHall;
    }
    if (this.inSystem != "") {
      this.system = this.inSystem;
    }
    if (this.inGroup != "") {
      this.group = this.inGroup;
    }
    if (this.inCode != "") {
      this.code = this.inCode;
    }
    if (this.inDescription != "") {
      this.description = this.inDescription;
    }
    let valid = true;
    if (this.newHall) {
      if (
        this.groupList.filter(
          r =>
            r.literal.toLowerCase() == this.hallText.toLowerCase() &&
            r.group_number == 1
        ).length > 0
      ) {
        valid = false;
        this.$message.error("Hall already exists");
      }
    }
    if (this.newSystem) {
      if (
        this.groupList.filter(
          r =>
            r.literal.toLowerCase() == this.systemText.toLowerCase() &&
            r.group_number == 2
        ).length > 0
      ) {
        valid = false;
        this.$message.error("System already exists");
      }
    }
    if (this.newGroup) {
      if (
        this.groupList.filter(
          r =>
            r.literal.toLowerCase() == this.groupText.toLowerCase() &&
            r.group_number == 3
        ).length > 0
      ) {
        valid = false;
        this.$message.error("Group already exists");
      }
    }
    if (this.newCode) {
      if (
        this.groupList.filter(
          r =>
            r.literal.toLowerCase() == this.codeText.toLowerCase() &&
            r.group_number == 4
        ).length > 0
      ) {
        valid = false;
        this.$message.error("Code already exists");
      }
    }
    if (this.newDescription) {
      if (
        this.groupList.filter(
          r =>
            r.literal.toLowerCase() == this.descriptionText.toLowerCase() &&
            r.group_number == 5
        ).length > 0
      ) {
        valid = false;
        this.$message.error("Description already exists");
      }
    }
    if (this.newHall && valid) {
      let hallItem = {
        id: this.maxId + newgroups,
        internal_id: this.maxHalls + 1,
        literal: this.hallText,
        group_number: 1
      };
      await group.addGroup(
        this.$store.getters.getToken,
        hallItem,
        this.$store.getters.getHost
      );
      this.hall = hallItem.id + '';
      newgroups++;
    }

    if (this.newSystem && valid) {
      let systemItem = {
        id: this.maxId + newgroups,
        internal_id: this.maxSystems + 1,
        literal: this.codeText,
        group_number: 2
      };
      await group.addGroup(
        this.$store.getters.getToken,
        systemItem,
        this.$store.getters.getHost
      );
      this.system = systemItem.id + '';
      newgroups++;
    }

    if (this.newGroup && valid) {
      let groupItem = {
        id: this.maxId + newgroups,
        internal_id: this.maxGroups + 1,
        literal: this.groupText,
        group_number: 3
      };
      await group.addGroup(
        this.$store.getters.getToken,
        groupItem,
        this.$store.getters.getHost
      );
      this.group = groupItem.id + '';
      newgroups++;
    }

    if (this.newCode && valid) {
      let codeItem = {
        id: this.maxId + newgroups,
        internal_id: this.maxCodes + 1,
        literal: this.codeText,
        group_number: 4
      };
      await group.addGroup(
        this.$store.getters.getToken,
        codeItem,
        this.$store.getters.getHost
      );
      this.code = codeItem.id + '';
      newgroups++;
    }

    if (this.newDescription && valid) {
      let descriptionItem = {
        id: this.maxId + newgroups,
        internal_id: this.maxDescriptions + 1,
        literal: this.descriptionText,
        group_number: 5
      };
      await group.addGroup(
        this.$store.getters.getToken,
        descriptionItem,
        this.$store.getters.getHost
      );
      this.description = descriptionItem.id + '';
      newgroups++;
    }
    if (valid) {
      if (this.item.id != undefined) {
        await component.editComponent(
          this.$store.getters.getToken,
          {
            group1_id: this.hall,
            group2_id: this.system,
            group3_id: this.group,
            group4_id: this.code,
            group5_id: this.description,
            literal: this.name,
            id: this.item.id
          },
          this.$store.getters.getHost
        );
      } else {
        await component.addComponent(
          this.$store.getters.getToken,
          {
            group1_id: this.hall,
            group2_id: this.system,
            group3_id: this.group,
            group4_id: this.code,
            group5_id: this.description,
            literal: this.name
          },
          this.$store.getters.getHost
        );
      }

      this.$store.dispatch("updateCurrentObject", {});
      this.$store.dispatch("updateCurrentComponent", "ComponentList");
      this.$router.replace({
        name: "load"
      });
    }
  }
  public async getGroupList() {
    await group
      .getAllGroups(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then(resp => {
          this.groupList = JSON.parse(JSON.stringify(resp));
          for (const g of this.groupList) {
            switch (g.group_number) {
              case 1:
                this.halls.push(g);
                if (g.id > this.maxId) {
                  this.maxId = g.id;
                }
                if (g.internal_id > this.maxHalls) {
                  this.maxHalls = g.internal_id;
                }
                break;
              case 2:
                this.systems.push(g);
                if (g.id > this.maxId) {
                  this.maxId = g.id;
                }
                if (g.internal_id > this.maxSystems) {
                  this.maxSystems = g.internal_id;
                }
                break;
              case 3:
                this.groups.push(g);
                if (g.id > this.maxId) {
                  this.maxId = g.id;
                }
                if (g.internal_id > this.maxGroups) {
                  this.maxGroups = g.internal_id;
                }
                break;
              case 4:
                this.codes.push(g);
                if (g.id > this.maxId) {
                  this.maxId = g.id;
                }
                if (g.internal_id > this.maxCodes) {
                  this.maxCodes = g.internal_id;
                }
                break;
              case 5:
                this.descriptions.push(g);
                if (g.id > this.maxId) {
                  this.maxId = g.id;
                }
                if (g.internal_id > this.maxDescriptions) {
                  this.maxDescriptions = g.internal_id;
                }
                break;
            }
          }
          this.CheckEdit(this.item);
        });
      });
  }
  public hallMethod(query: string) {
    this.inHall = "";
    if (query !== "") {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.visibleHalls = this.halls.filter(item =>
          item.literal.toLowerCase().includes(query.toLowerCase())
        );
      }, 200);
    } else {
      this.visibleHalls = [];
    }
  }
  public codeMethod(query: string) {
    this.inCode = "";
    if (query !== "") {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.visibleCodes = this.codes.filter(item =>
          item.literal.toLowerCase().includes(query.toLowerCase())
        );
      }, 200);
    } else {
      this.visibleCodes = [];
    }
  }
  public descriptionMethod(query: string) {
    this.inDescription = "";
    if (query !== "") {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.visibleDescriptions = this.descriptions.filter(item =>
          item.literal.toLowerCase().includes(query.toLowerCase())
        );
      }, 200);
    } else {
      this.visibleDescriptions = [];
    }
  }
  public systemMethod(query: string) {
    this.inSystem = "";
    if (query !== "") {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.visibleSystems = this.systems.filter(item =>
          item.literal.toLowerCase().includes(query.toLowerCase())
        );
      }, 200);
    } else {
      this.visibleSystems = [];
    }
  }
  public groupMethod(query: string) {
    this.inGroup = "";
    if (query !== "") {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.visibleGroups = this.groups.filter(item =>
          item.literal.toLowerCase().includes(query.toLowerCase())
        );
      }, 200);
    } else {
      this.visibleGroups = [];
    }
  }
}
</script>
<style>
</style>