export namespace vacation {
  //addWorkOrder
  export async function addVacation(
    token: string,
    VacationItem: any,
    host: any
  ): Promise<any> {
    if (VacationItem[0].comments != undefined) {
      VacationItem[0].comments = VacationItem[0].comments.replace("&", "and");
    }
    let result: Response;
    VacationItem.token = token;
    // createServer.ts
    result = (await fetch(host + "/api/vacation/Add", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body:
        "token=" +
        token +
        "&idpersonel=" +
        VacationItem[0].idper +
        "&total_days_req=" +
        VacationItem[0].total_days_req +
        "&leave_on=" +
        VacationItem[0].leaveOn +
        "&return_on=" +
        VacationItem[0].ReturnOn +
        "&type_of_vacation=" +
        VacationItem[0].type +
        "&remarks=" +
        VacationItem[0].remarks +
        "&date_of_request=" +
        VacationItem[0].DateOfRequest +
        "&leave_end=" +
        VacationItem[0].leaveEnd+
        "&json_leaveon_leaveend=" +
        VacationItem[0].json_leaveon_leaveend+
        "&year_vacation=" +
        VacationItem[0].year_vacation
    })) as any;

    return await result.json();
  }



  export async function addVacation2update(
    token: string,
    VacationItem: any,
    host: any
  ): Promise<any> {
    if (VacationItem[0].comments != undefined) {
      VacationItem[0].comments = VacationItem[0].comments.replace("&", "and");
    }
    let result: Response;
    VacationItem.token = token;
    // createServer.ts
    result = (await fetch(host + "/api/vacation/Add2", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body:
        "token=" +
        token +
        "&id=" +
        VacationItem[0].id +
        "&idpersonel=" +
        VacationItem[0].idper +
        "&total_days_req=" +
        VacationItem[0].total_days_req +
        "&leave_on=" +
        VacationItem[0].leaveOn +        
        "&return_on=" +
        VacationItem[0].ReturnOn +
        "&type_of_vacation=" +
        VacationItem[0].type +
        "&remarks=" +
        VacationItem[0].remarks +
        "&date_of_request=" +
        VacationItem[0].DateOfRequest +
        "&leave_end=" +
        VacationItem[0].leaveEnd +
        "&json_leaveon_leaveend=" +
        VacationItem[0].json_leaveon_leaveend+
        "&year_vacation=" +
        VacationItem[0].year_vacation 
    })) as any;

    return await result.json();
  }

  // export async function Vacation1(
  //   token: string,
  //   host: any
  // ): Promise<any> {
  //   let result: Response;    
  //   // createServer.ts
  //   result = (await fetch(host + "/api/vacation/vacation1", {
  //     method: "POST",
  //     headers: {
  //       "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
  //     },
  //     body:
  //       "token=" +
  //       token

  //   })) as any;    
    
  //   return await result.json();
  // }

  export async function Vacation1(token: string, id: number, host: any) {

    return await fetch(host + "/api/vacation/vacation1?token=" + token + "&id=" + id);
  }


  export async function getAllType(token: string, host: any) {

    return await fetch(host + "/api/vacation/getAllType?token=" + token);
  }
  export async function getAllStatusofvacation(token: string, host: any) {

    return fetch(host + "/api/vacation/getAllstatusofvacation?token=" + token);
  }

  export async function getstatusofvacation(token: string, host: any) {

    return fetch(host + "/api/vacation/getstatusofvacation?token=" + token);
  }

  export async function getAllVacations(token: string, host: any) {

    return fetch(host + "/api/vacation/getAllVacations?token=" + token);
  }
  //getSomeShifts(
  export async function getSomeVacation(token: string, filters: any, host: any) {
    return fetch(
      host +
      "/api/vacation/getSomevacations?token=" +
      token +
      "&dateFrom=" +
      filters.dateFrom +
      "&dateTo=" +
      filters.dateTo
    );
  }

  //downloadDetails(

  export async function downloadVacation(
    token: string,
    id: number,
    host: any
  ): Promise<any> {
    return await fetch(
      host + "/api/vacation/downloadVacation?token=" + token + "&id=" + id
    );
  }

  export async function downloadVacation2(
    token: string,
    id1: number,
    host: any,
    year1: any,
  ): Promise<any> {
    return await fetch(
      host + "/api/vacation/downloadVacation2?token=" + token + "&id1=" + id1 + "&year1=" + year1
    );

  }

  export async function deleteVacation(
    token: string,
    id: number,
    host: any
  ): Promise<any> {
    console.log('token:' + token);
    return await fetch(
      host + "/api/vacation/deleteVavation?token=" + token + "&id=" + id
    );
  }

  export async function recycleVacation(
    token: string,
    id: number,
    host: any
  ): Promise<any> {
    return await fetch(
      host + "/api/vacation/recycle?token=" + token + "&id=" + id
    );
  }

  export async function approvedVacation(
    token: string,
    id: number,
    host: any
  ): Promise<any> {
    return await fetch(
      host + "/api/vacation/approvedva?token=" + token + "&id=" + id
    );
  }

  export async function rejectedVacation(
    token: string,
    id: number,
    host: any
  ): Promise<any> {
    return await fetch(
      host + "/api/vacation/rejectedva?token=" + token + "&id=" + id
    );
  }


}