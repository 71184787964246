<template>
  <div v-loading="loading">
    <el-row>
      <el-col :span="16">
        <el-row>
          <el-col :span="6">
            <div class="tag">
              <strong>Select Hall</strong>
            </div>
          </el-col>
          <el-col :span="12">
            <el-select
              v-model="hall"
              placeholder="Select Hall"
              :items="halls"
              @change="filterHalls()"
              clearable
              size="mini"
            >
              <el-option v-for="item in halls" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div class="tag">
              <strong>Select System</strong>
            </div>
          </el-col>
          <el-col :span="12">
            <el-select
              v-model="system"
              placeholder="Select System"
              :items="availableSystems"
              @change="filterSystems()"
              clearable
              size="mini"
            >
              <el-option v-for="item in availableSystems" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div class="tag">
              <strong>Select Group</strong>
            </div>
          </el-col>
          <el-col :span="12">
            <el-select
              v-model="group"
              placeholder="Select Group"
              :items="availableGroups"
              @change="filterGroups()"
              clearable
              size="mini"
            >
              <el-option v-for="item in availableGroups" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div class="tag">
              <strong>Select Code</strong>
            </div>
          </el-col>
          <el-col :span="12">
            <el-select
              v-model="code"
              placeholder="Select Code"
              :items="availableCodes"
              @change="filterCodes()"
              clearable
              size="mini"
            >
              <el-option v-for="item in availableCodes" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div class="tag">
              <strong>Select Description</strong>
            </div>
          </el-col>
          <el-col :span="12">
            <el-select
              v-model="description"
              placeholder="Select Description"
              :items="availableDescriptions"
              @change="filterDescriptions()"
              clearable
              size="mini"
            >
              <el-option
                v-for="item in availableDescriptions"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row>
      <el-input v-model="search" placeholder="Type to search" v-on:input="manageData"></el-input>
      <div id="compos" style="height: 300px; border: solid 1px #ddd;" align="left" class="compos"></div>
    </el-row>
  </div>
</template>
<script lang="ts">
const cheetahGrid = require("cheetah-grid");
import { Component, Vue, Prop } from "vue-property-decorator";
import { component } from "../../api/component/component";
import { group } from "../../api/group/group";
let grid: any = {};
const { CLICK_CELL } = cheetahGrid.ListGrid.EVENT_TYPE;
const buttonAction = new cheetahGrid.columns.action.ButtonAction({
  action(rec) {
    alert(JSON.stringify(rec, null, "  "));
  }
});
@Component({
  name: "ComponentList"
})
export default class ComponentSelector extends Vue {
  public dataview: any[] = [];
  public search: string = "";
  public componentList: any[] = [];
  public loading: boolean = true;
  public halls: any[] = [];
  public systems: any[] = [];
  public groups: any[] = [];
  public codes: any[] = [];
  public descriptions: any[] = [];
  public availableSystems: any[] = [];
  public availableGroups: any[] = [];
  public availableCodes: any[] = [];
  public availableDescriptions: any[] = [];
  public hall: string = "";
  public system: string = "";
  public group: string = "";
  public code: string = "";
  public description: string = "";
  public fillTable() {
    let records: any = [];
    grid = new cheetahGrid.ListGrid({
      // Parent element on which to place the grid
      parentElement: document.querySelector("#compos"),
      // Header definition
      header: [
        {
          field: "group2",
          caption: "System",
          width: "100px",
          style: { font: "13px sans-serif" },
          sort(order, col, grid) {
            const compare =
              order === "desc"
                ? (v1, v2) => (v1 === v2 ? 0 : v1 > v2 ? 1 : -1)
                : (v1, v2) => (v1 === v2 ? 0 : v1 < v2 ? 1 : -1);
            records.sort((r1, r2) => compare(r1.group2, r2.group2));
            grid.records = records;
          }
        },
        {
          field: "group1",
          caption: "Hall",
          style: { font: "13px sans-serif" },
          sort(order, col, grid) {
            const compare =
              order === "desc"
                ? (v1, v2) => (v1 === v2 ? 0 : v1 > v2 ? 1 : -1)
                : (v1, v2) => (v1 === v2 ? 0 : v1 < v2 ? 1 : -1);
            records.sort((r1, r2) => compare(r1.group1, r2.group1));
            grid.records = records;
          }
        },
        {
          field: "group4",
          caption: "Code",
          width: "130px",
          style: { font: "13px sans-serif" },
          sort(order, col, grid) {
            const compare =
              order === "desc"
                ? (v1, v2) => (v1 === v2 ? 0 : v1 > v2 ? 1 : -1)
                : (v1, v2) => (v1 === v2 ? 0 : v1 < v2 ? 1 : -1);
            records.sort((r1, r2) => compare(r1.group4, r2.group4));
            grid.records = records;
          }
        },
        {
          field: "literal",
          columnType: "button",
          caption: "Name",
          width: "110px",
          style: { font: "13px sans-serif" },
          action: buttonAction,
          sort(order, col, grid) {
            const compare =
              order === "desc"
                ? (v1, v2) => (v1 === v2 ? 0 : v1 > v2 ? 1 : -1)
                : (v1, v2) => (v1 === v2 ? 0 : v1 < v2 ? 1 : -1);
            records.sort((r1, r2) => compare(r1.literal, r2.literal));
            grid.records = records;
          }
        },
        {
          field: "group5",
          caption: "Description",
          width: "160px",
          style: { font: "13px sans-serif" },
          sort(order, col, grid) {
            const compare =
              order === "desc"
                ? (v1, v2) => (v1 === v2 ? 0 : v1 > v2 ? 1 : -1)
                : (v1, v2) => (v1 === v2 ? 0 : v1 < v2 ? 1 : -1);
            records.sort((r1, r2) => compare(r1.group5, r2.group5));
            grid.records = records;
          }
        },
        {
          field: "group3",
          caption: "Group",
          width: "200px",
          style: { font: "13px sans-serif" },
          sort(order, col, grid) {
            const compare =
              order === "desc"
                ? (v1, v2) => (v1 === v2 ? 0 : v1 > v2 ? 1 : -1)
                : (v1, v2) => (v1 === v2 ? 0 : v1 < v2 ? 1 : -1);
            records.sort((r1, r2) => compare(r1.group3, r2.group3));
            grid.records = records;
          }
        }
      ],
      defaultRowHeight: 30
      // Array data to be displayed on the grid
      // Column fixed position
    });
    this.dataview = this.componentList;
    grid.records = this.dataview;
    grid.theme = "BASIC";
    for (const i of this.componentList) {
      if (!this.halls.includes(i.group1)) {
        this.halls.push(i.group1);
      }
      if (!this.systems.includes(i.group2)) {
        this.systems.push(i.group2);
      }
      if (!this.groups.includes(i.group3)) {
        this.groups.push(i.group3);
      }
      if (!this.codes.includes(i.group4)) {
        this.codes.push(i.group4);
      }
      if (!this.descriptions.includes(i.group5)) {
        this.descriptions.push(i.group5);
      }
    }
    this.loading = false;
    this.halls.sort(function(a, b): number {
      if (a >= b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
  }
  public created() {
    buttonAction.action = rec => {
      this.handleEdit(rec);
    };
    this.loading = true;
    console.log(this.$props);
    this.getComponentList();
  }
  public mounted() {
    console.log('Mounted componentlist_new')
  }
  public manageData() {
    this.dataview = [];
    console.log('searching for ' + this.search.toLowerCase())
    for (const v of this.componentList) {
      if (
        v.literal.toLowerCase().includes(this.search.toLowerCase()) ||
        v.group1.toLowerCase().includes(this.search.toLowerCase()) ||
        v.group2.toLowerCase().includes(this.search.toLowerCase()) ||
        v.group3.toLowerCase().includes(this.search.toLowerCase()) ||
        v.group4.toLowerCase().includes(this.search.toLowerCase()) ||
        v.group5.toLowerCase().includes(this.search.toLowerCase())
      ) {
        this.dataview.push(v);
      }
    }
    grid.records = this.dataview;
  }
  public handleEdit(row: any) {
    row.recycled = false;
    this.$store.dispatch("updateCurrentObject", row);
    this.$store.dispatch("updateCurrentComponent", "AddComponent");
    this.$router.replace({ name: "load" });
  }
  public async getComponentList() {
    await component
      .getAllComponents(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response
          .json()
          .then(resp => {
            this.componentList = JSON.parse(JSON.stringify(resp));
            this.componentList = this.componentList.filter(r => !r.recycled);
          })
          .then(r => {
            this.fillTable();
          });
      });
  }
  public filterHalls() {
    this.dataview = [];
    this.availableSystems = [];
    this.availableGroups = [];
    this.availableCodes = [];
    this.availableDescriptions = [];
    if (this.hall === "") {
      this.dataview = this.componentList;
      this.system = "";
      this.group = "";
      this.description = "";
      this.code = "";
    } else {
      for (const c of this.componentList) {
        if (c.group1 === this.hall) {
          this.dataview.push(c);
          if (!this.availableSystems.includes(c.group2)) {
            this.availableSystems.push(c.group2);
          }
        }
      }
      this.availableSystems.sort(function(a, b): number {
        if (a >= b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      });
      if (!this.availableSystems.includes(this.system)) {
        this.system = "";
        this.group = "";
        this.description = "";
        this.code = "";
      }
    }
    grid.records = this.dataview;
  }
  public filterSystems() {
    this.availableGroups = [];
    this.availableCodes = [];
    this.availableDescriptions = [];
    if (this.system != "") {
      this.dataview = [];
      for (const c of this.componentList) {
        if (c.group2 === this.system && c.group1 === this.hall) {
          this.dataview.push(c);
          if (!this.availableGroups.includes(c.group3)) {
            this.availableGroups.push(c.group3);
          }
        }
      }
    } else {
      this.dataview = [];
      for (const c of this.componentList) {
        if (c.group1 === this.hall) {
          this.dataview.push(c);
        }
      }
    }
    this.availableGroups.sort(function(a, b): number {
      if (a >= b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
    if (!this.availableGroups.includes(this.group)) {
      this.group = "";
      this.description = "";
      this.code = "";
    }
    grid.records = this.dataview;
  }
  public filterGroups() {
    this.availableCodes = [];
    this.availableDescriptions = [];
    if (this.group != "") {
      this.dataview = [];
      for (const c of this.componentList) {
        if (
          c.group2 === this.system &&
          c.group1 === this.hall &&
          c.group3 === this.group
        ) {
          this.dataview.push(c);
          if (!this.availableCodes.includes(c.group4)) {
            this.availableCodes.push(c.group4);
          }
        }
      }
      this.availableCodes.sort(function(a, b): number {
        if (a >= b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      });
    } else {
      this.dataview = [];
      for (const c of this.componentList) {
        if (c.group2 === this.system && c.group1 === this.hall) {
          this.dataview.push(c);
        }
      }
    }
    if (!this.availableCodes.includes(this.code)) {
      this.code = "";
      this.description = "";
    }
    grid.records = this.dataview;
  }

  public filterCodes() {
    this.availableDescriptions = [];
    if (this.code != "") {
      this.dataview = [];
      for (const c of this.componentList) {
        if (
          c.group2 === this.system &&
          c.group1 === this.hall &&
          c.group3 === this.group &&
          c.group4 === this.code
        ) {
          this.dataview.push(c);
          if (!this.availableDescriptions.includes(c.group5)) {
            this.availableDescriptions.push(c.group5);
          }
        }
      }
      this.availableDescriptions.sort(function(a, b): number {
        if (a >= b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      });
    } else {
      this.dataview = [];
      for (const c of this.componentList) {
        if (
          c.group2 === this.system &&
          c.group1 === this.hall &&
          c.group3 === this.group
        ) {
          this.dataview.push(c);
        }
      }
    }
    if (!this.availableDescriptions.includes(this.description)) {
      this.description = "";
    }
    grid.records = this.dataview;
  }
  public filterDescriptions() {
    if (this.description != "") {
      this.dataview = [];
      for (const c of this.componentList) {
        if (
          c.group2 === this.system &&
          c.group1 === this.hall &&
          c.group3 === this.group &&
          c.group4 === this.code &&
          c.group5 === this.description
        ) {
          this.dataview.push(c);
        }
      }
      grid.records = this.dataview;
    } else {
      this.dataview = [];
      for (const c of this.componentList) {
        if (
          c.group2 === this.system &&
          c.group1 === this.hall &&
          c.group3 === this.group &&
          c.group4 === this.code
        ) {
          this.dataview.push(c);
        }
      }
      grid.records = this.dataview;
    }
  }
}
</script>
<style>
</style>
