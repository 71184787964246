<template>
  <div class="container">
    <h2>Vacation</h2>
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Name:</strong>
        </div>
      </el-col>
      <el-col :span="6" align="left">
        <el-select
          v-model="name"
          filterable
          :style="{width:'230px'}"
          remote
          size="mini"
          clearable
          placeholder="Name"
          value-key="id"
          :remote-method="nameMethod"
          :loading="loading"
          @change="testme()"
          @focus="testme()"
        >
        <!-- :label="item.firstname+' '+item.lastname+(!item.active?' (INACTIVE) ':'')" -->
          <el-option
            v-for="item in availableName"
            :key="item.id"
            :label="item.firstname+' '+item.lastname+(!item.active?' (INACTIVE) ':'')"
            :value="item"
          ></el-option>
        </el-select>
      </el-col>
          <el-col :span="6" align="left">
        <div class="tag">
          <strong>Entitled Year:</strong>
        </div>
      </el-col>
      <el-col :span="6" align="left">
        <el-input v-model="year_vacation" size="mini" :style="{width:'230px'}"></el-input>
      </el-col>
    </el-row>
    <br />
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Total Vacation Days:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-input v-model="total_days_req" size="mini" :style="{width:'230px'}"></el-input>
      </el-col>
    </el-row>
    <br />
   
    <el-row v-for="(index, k) in leave_on_end" :key="k"> 
      <!-- {{index}} -->
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Leave on:</strong>
        </div>
      </el-col>
      <el-col :span="6" align="left">
        <el-date-picker
          @change="vacationUpdate(k)"
          
          v-model="index.leave_on"
          type="date"
          placeholder="Date"
          format="dd/MM/yyyy"
          size="mini"
          :style="{width:'230px'}"
        ></el-date-picker>
      </el-col>
    
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Leave end:</strong>
        </div>
      </el-col>
      <el-col :span="6" align="left">
        <el-date-picker
         @change="vacationUpdate(k)"
          v-model="index.leave_end"
          type="date"
          placeholder="Date"
          format="dd/MM/yyyy"
          size="mini"
          :style="{width:'230px'}"
        ></el-date-picker>
      </el-col>
    </el-row>
     <!-- new -->
    <el-row> 
      <!-- {{index}} -->
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Leave on:</strong>
        </div>
      </el-col>
      <el-col :span="6" align="left">
        <el-date-picker
          @change="pushVacation"
          
          v-model="leaveOn"
          type="date"
          placeholder="Date"
          format="dd/MM/yyyy"
          size="mini"
          :style="{width:'230px'}"
        ></el-date-picker>
      </el-col>
    
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Leave end:</strong>
        </div>
      </el-col>
      <el-col :span="6" align="left">
        <el-date-picker
          @change="pushVacation"
          v-model="leaveEnd"
          type="date"
          placeholder="Date"
          format="dd/MM/yyyy"
          size="mini"
          :style="{width:'230px'}"
        ></el-date-picker>
      </el-col>
    </el-row>
    <br />
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Return on:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-date-picker
          :style="{width:'230px'}"
          @change="fixReturnOn"
          v-model="ReturnOn"
          type="date"
          placeholder="Date"
          format="dd/MM/yyyy"
          size="mini"
        ></el-date-picker>
      </el-col>
    </el-row>
    <br />
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Type:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-select value-key="id" :style="{width:'230px'}" v-model="type" placeholder size="mini">
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.type_of_vacation"
            :value="item"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <br />
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Remarks:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-input type="textarea" :rows="2" placeholder="Vacation Comments" v-model="remarks"></el-input>
      </el-col>
    </el-row>
    <br />
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Date of Request:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-date-picker
          :style="{width:'230px'}"
          @change="fixDateofRequest"
          v-model="DateOfRequest"
          type="date"
          placeholder="Date"
          format="dd/MM/yyyy"
          size="mini"
        ></el-date-picker>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" align="senter">
        <el-button @click="addToDBVacationDecision" style="margin-top: 12px;" type="success">
          <strong class="white">Submit</strong>
        </el-button>
      </el-col>
      <el-col :span="12" align="senter">
        <!--exportVA(id)   id einai to row pou exei epilexthei-->
        <el-button @click="exportVA(id)" style="margin-top: 12px;" type="success">
          <strong class="white">Export form</strong>
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Emit from "vue-property-decorator";
import { StringHelpers } from "../../api/helpers/StringHelpers";
import { status } from "../../api/status/status";
import { workorder } from "../../api/workorder/workorder";
import { personnel } from "../../api/personnel/personnel";
import { vacation } from "../../api/vacation";
import { postgrest } from "../../api/postgrest";

import Moment from "moment";

import { DateTime } from "luxon";

@Component({
  name: "AddNewVacation",
})
export default class AddNewVacation extends Vue {
  public id: any = "";
  public name: any = "";
  public total: any[] = [];
  public leaveOn: any = "";
  public ReturnOn: any = "";
  public leaveEnd: any = "";
  public json_leaveon_leaveend: any = "";
  public year_vacation: any = "";
  public type: any = "";
  public types: any[] = [];
  public remarks: string = "";
  public DateOfRequest: any = "";
  public availableName: any[] = [];
  public availableType: any[] = [];
  public personnelList: any[] = [];
  public typeList: any[] = [];
  public loading: boolean = false;
  public total_days_req: any = "";
  public row: any = "";
  public leave_on_end: any[] = [];

  // public leaveon: any = "";

  public pushVacation() {
    if (this.leaveOn != "" && this.leaveEnd != "") {

      let x = Moment(this.leaveOn).format("YYYY-MM-DD");
      let y = Moment(this.leaveEnd).format("YYYY-MM-DD");
      console.log("x is:" + x);
      console.log("y is:" + y);

      this.leave_on_end.push({ leave_on: x, leave_end: y });
      console.log("this.leave_on_end--> " + JSON.stringify(this.leave_on_end));
      this.leaveOn = "";
      this.leaveEnd = "";
    }
  }

  public vacationUpdate(k: number) {
    
    if (this.leave_on_end[k].leave_on == null && this.leave_on_end[k].leave_end == null) {
      this.leave_on_end.splice(k, 1);
    }
    console.log("this.leave_on_end -> " + this.leave_on_end);
  }
  public testme() {
    //console.log(JSON.stringify(this.name));
  }

  public fixReturnOn(a){
    console.log("-----")
    console.log(a)
    this.ReturnOn = Moment(a).add(5, 'hours').format("YYYY-MM-DD");
    console.log(this.ReturnOn)
  }

  public fixDateofRequest(a){
    console.log("=====")
    console.log(a)
    this.DateOfRequest = Moment(a).add(5, 'hours').format("YYYY-MM-DD");
    console.log(this.DateOfRequest)
  }

  public addToDBVacationDecision() {
  console.log("1")
  console.log("+++ " + this.ReturnOn)
    if (this.row.id == undefined) {
      this.addToDBVacation();
    } else {
      this.addToDBVacation2();
    }
  }

  public addToDBVacation() {
    console.log("entered addToDBVacation")
    //dimiourgia item. aristera idia met me api front, deksia idia met m v-model tou idiou
    let a = Moment(this.leave_on_end[0].leave_on).format("YYYY/MM/DD");
    a = a.replace("/", "-");
    a = a.replace("/", "-");
      console.log('replaced a-> '+ a)
    let b = Moment(this.leave_on_end[0].leave_end).format("YYYY/MM/DD");
    b = b.replace("/", "-");
    b = b.replace("/", "-");
      console.log('replaced b-> '+ b)
    let c = Moment(this.ReturnOn).format("YYYY/MM/DD");
    c = c.replace("/", "-");
    c = c.replace("/", "-");
     console.log('replaced c-> '+ c)
    if (a <= b && a < c && b < c) {
      console.log("bhka edw")
      let vacationItem: any[] = [
        {
          idper: this.name.id,
          total_days_req: this.total_days_req,
          // leaveOn: this.leaveOn,
          leaveOn: this.leave_on_end[0].leave_on,
          ReturnOn: this.ReturnOn,
          type: this.type.id,
          remarks: this.remarks,
          DateOfRequest: this.DateOfRequest,
          leaveEnd: this.leave_on_end[0].leave_end,
          json_leaveon_leaveend: JSON.stringify(this.leave_on_end),
          year_vacation: this.year_vacation,
        },
      ];

      //api front addVacation
      vacation.addVacation(this.$store.getters.getToken, vacationItem, this.$store.getters.getHost).then((r) => {
        if (r.status == 400) {
          console.log("EERROORRR")
          this.$message.error(r.error);
        } else {
          console.log("+++++++++")
          /* this.$store.dispatch("updateCurrentObject", {});
            this.$store.dispatch("updateCurrentComponent", "ShiftList"); */
          this.$message.success("Vacation Added");
          this.$router.replace({
            name: "load",
          });
          //this.$store.dispatch("updateCurrentObject", {});
        }
      });
    } else {
      this.$notify.error({
        title: "Error",
        message: "Incorrect dates",
      });
    }
  }
  public addToDBVacation2() {
    let vacationItem: any[] = [
      {
        id: this.row.id,
        idper: this.name.id,
        total_days_req: this.total_days_req,
        leaveOn: this.leave_on_end[0].leave_on,
        ReturnOn: this.ReturnOn,
        type: this.type.id,
        remarks: this.remarks,
        DateOfRequest: this.DateOfRequest,
        leaveEnd: this.leave_on_end[0].leave_end,
        json_leaveon_leaveend: JSON.stringify(this.leave_on_end),
        year_vacation: this.year_vacation,
      },
    ];

    //api front addVacation
    //console.log("Frontend token:" + this.$store.getters.getToken);
    vacation.addVacation2update(this.$store.getters.getToken, vacationItem, this.$store.getters.getHost).then((r) => {
      //console.log("r =====> " + JSON.stringify(r));
      if (r.status == 400) {
        this.$message.error(r.error);
      } else {
        /* this.$store.dispatch("updateCurrentObject", {});
            this.$store.dispatch("updateCurrentComponent", "ShiftList"); */
        this.$message.success("Vacation Added");
        this.$router.replace({
          name: "load",
        });
      }
    });
  }
  //apla gemizi tin forma tou add
  //gia to edit
  public async checkEdit() {
    this.row = this.$store.getters.getCurrentObject;
    console.log(JSON.stringify(this.row));
    console.log(JSON.stringify("this.rowvacation"));
    if (this.row.id != undefined) {
      let met = this.row.idpersonel;
      let xxx: any = {
        key: this.row.idpersonel,
        label:
          this.personnelList.find(function(element) {
            return met == element.id;
          }).firstname +
          " " +
          this.personnelList.find(function(element) {
            return met == element.id;
          }).lastname,
        value: this.row.idpersonel,
        id: this.row.idpersonel,
      };
      this.name = {};
      Vue.set(this.name, "key", xxx.key);
      Vue.set(this.name, "value", xxx.value);
      Vue.set(this.name, "label", xxx.label);
      Vue.set(this.name, "id", xxx.id);

      this.year_vacation = this.row.year_vacation;
      this.total_days_req = this.row.total_days_req;

      let x = JSON.parse(this.row.json_leaveon_leaveend);
      this.leave_on_end = x;

      this.ReturnOn = new Date(this.row.return_on);
      this.remarks = this.row.remarks;
      this.DateOfRequest = new Date(this.row.date_of_request);

      let met2 = this.row.type_of_vacation;
      let yyy: any = null;
      //console.log("ROOOOOW:" + JSON.stringify(this.row));
      try {
        //console.log("typeList ====> " + JSON.stringify(this.typeList));
        let result: any = this.typeList.find(function(element) {
          return met2 == element.id;
        });
        //console.log("result => " + JSON.stringify(result));
        result = result.type_of_vacation;
        yyy = {
          key: this.row.type_of_vacation,
          label: result,
          value: this.row.type_of_vacation,
          id: this.row.type_of_vacation,
        };
      } catch (ex) {
        // console.log("Exception:" + ex);
      }

      this.type = {};
      Vue.set(this.type, "key", yyy.key);
      Vue.set(this.type, "label", yyy.label);
      Vue.set(this.type, "value", yyy.value);
      Vue.set(this.type, "id", yyy.id);
    }
    this.$store.dispatch("updateCurrentObject", {});
  }
  //rows atofia
  public nameMethod(query: string) {
    this.loading = false;
    this.availableName = this.personnelList.filter((item) => {
      return (
        (item.firstname.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          item.lastname.toLowerCase().indexOf(query.toLowerCase()) > -1) &&
        (item.active || this.$store.getters.getUserLevel < 1)
      );
    });
  }
  public async getPersonnelList() {
    this.personnelList = await postgrest.get(
      "personnel?select=*,companies(*),wo_specialties(*),specialties(*)",
      this.$store.getters.getToken
    );
  }

  public exportVA(id: any) {
    this.$store.dispatch("setCurrentId", id);
    this.$store.dispatch("updateCurrentComponent", "DownloadVacation");
    this.$router.replace({
      name: "load",
    });
  }

  public async getTypeList() {
    //to arxio tou api einai to vacation
    let resp = await postgrest.get("type_vacation", this.$store.getters.getToken);
    this.typeList = JSON.parse(JSON.stringify(resp));

  }
  //energop automata
  public async created() {
    await this.getPersonnelList();
    await this.getTypeList();
    this.nameMethod("");
    this.checkEdit();
  }
}
</script>

<style scoped>
strong {
  color: black;
}

.tag {
  background-color: rgb(201, 228, 238);
  color: black;
  width: 140px;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  padding: 2px 10px 2px 10px;
  font-size: 9pt;
  line-height: 20px;
}

.container {
  border: 2px solid rgb(170, 238, 238);
  border-radius: 10px;
  background-color: rgb(248, 247, 247);
  padding: 5px 10px 5px 10px;
}
</style>
