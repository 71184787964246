import { StringHelpers } from "../helpers/StringHelpers";

export namespace project {
  export async function addproject(token: string, projectItem: any,host:any) {
    let result: Response;
    projectItem.token = token;
    result = (await fetch(host+"/api/project/Add", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body:
        "token=" +
        token +
        "&description=" +
        projectItem[0].description +
        "&status_id=" +
        projectItem[0].status_id +
        "&start_date=" +
        projectItem[0].start_date +
        "&end_date=" +
        projectItem[0].end_date +
        "&personnelObj=" +
        JSON.stringify(projectItem[1])
    })) as any;

    return await result.json();
  }
  export async function editproject(token: string, projectItem: any,host:any) {
    let result: Response;
    result = (await fetch(host+"/api/project/Add", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body:
        "id=" +
        projectItem[0].id +
        "&token=" +
        token +
        "&description=" +
        projectItem[0].description +
        "&status_id=" +
        projectItem[0].status_id +
        "&start_date=" +
        projectItem[0].start_date +
        "&end_date=" +
        projectItem[0].end_date +
        "&personnelObj=" +
        JSON.stringify(projectItem[1])
    })) as any;

    return await result.json();
  }
  export async function getAllprojects(token: string,host:any) {
    return fetch(host+"/api/project/getAll?token=" + token);
  }
}
