export namespace StringHelpers {
  export function capitalize(x: string) {
    return x.substring(0, 1).toUpperCase() + x.substring(1).toLowerCase();
  }
  export function shorten(x: string) {
    let a = x.split(" ");
    let b = "";
    let lines = 1;
    for (const w of a) {
      if (b.length > lines * 40 && b.length != 0) {
        lines++;
        b = b + "       ";
      }
      b = b + " " + w;
    }
    console.log(b);
    return b;
  }
}
