import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Element from "element-ui";
import i18n from "vue-i18n";
import locale from "element-ui/lib/locale/lang/en";
import vuescroll from "vue-scroll";

Vue.use(vuescroll);
Vue.config.productionTip = false;

Vue.use(Element, { locale });
Vue.use(i18n);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
