<template>
  <div>
    <el-collapse accordion v-model="activeName">
      <el-collapse-item title="Work Order Filters" name="1" :style="{'text-align':'center'}">
        <div class="container">
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>From Date:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-date-picker
                v-model="date"
                v-on:change="update"
                type="date"
                placeholder="From Date"
                format="dd/MM/yyyy"
                size="mini"
                :style="{width:'200px'}"
                clearable
              ></el-date-picker>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>To Date:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-date-picker
                v-model="$props.filters.dateTo"
                type="date"
                placeholder="To Date"
                format="dd/MM/yyyy"
                size="mini"
                :style="{width:'200px'}"
                clearable
              ></el-date-picker>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>Status:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-select
                size="mini"
                :style="{width:'200px'}"
                v-model="$props.filters.status"
                placeholder="Status"
                clearable
              >
                <el-option
                  v-for="item in statuses"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>Type:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-select
                v-model="$props.filters.type"
                size="mini"
                :style="{width:'200px'}"
                placeholder="Type"
                clearable
              >
                <el-option
                  v-for="item in types"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>KeyWord:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-input
                placeholder="KeyWord"
                :style="{width:'200px'}"
                v-model="$props.filters.keyword"
                size="mini"
                clearable
              ></el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>From ID:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-input
                placeholder="From ID"
                :style="{width:'200px'}"
                v-model="$props.filters.idFrom"
                size="mini"
                clearable
              ></el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>To ID:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-input
                placeholder="To ID"
                :style="{width:'200px'}"
                v-model="$props.filters.idTo"
                size="mini"
                clearable
              ></el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>Component Name:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-select
                v-model="$props.filters.search"
                filterable
                remote
                clearable
                :style="{width:'200px'}"
                reserve-keyword
                placeholder="Component Name"
                :remote-method="remoteMethod"
                :loading="loading"
                size="mini"
              >
                <el-option
                  v-for="item in visibleComponents"
                  :key="item.id"
                  :label="item.literal"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script lang="ts">
import moment from "moment";
import ComponentSelector from "../Selectors/ComponentSelector.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { component } from "../../api/component/component";
import { workorder } from "../../api/workorder/workorder";
import { status } from "../../api/status/status";
@Component({
  name: "WorkOrderFilters",
  // components: { ComponentSelector },
  props: {
    filters: Object
    // filters: {
    //   idFrom: "",
    //   idTo: "",
    //   dateFrom: "",
    //   dateTo: "",
    //   search: "",
    //   keyword: "",
    //   type: "",
    //   status: ""
    // }
  },
  model: {
    prop: "filters",
    event: "change"
  },
  watch: {
    date: function(val) {
      this.$props.filters.dateFrom = val;
    }
  }
})
export default class WorkOrderFilters extends Vue {
  public loading: boolean = false;
  public activeName: any = "1";
  public componentList: any[] = [];
  public date: Date = new Date();
  public visibleComponents: any[] = [];
  public types: any[] = [];
  public statuses: any[] = [];
  public update() {
    this.$forceUpdate();
  }
  public async getComponentList() {
    await component
      .getAllComponents(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then((resp: any) => {
          this.componentList = JSON.parse(JSON.stringify(resp));
        });
      });
  }
  public async mounted() {
    await this.getComponentList();
    await this.getWOTypeList();
    await this.getStatusList();
    this.$props.filters.dateFrom = new Date(
      new Date().setDate(new Date().getDate() - 10)
    );
    this.date = new Date(new Date().setDate(new Date().getDate() - 10));
  }
  public remoteMethod(query: string) {
    console.log(this.componentList);
    if (query !== "") {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.visibleComponents = this.componentList.filter(item =>
          item.literal.toLowerCase().includes(query.toLowerCase())
        );
      }, 200);
    } else {
      this.visibleComponents = [];
    }
  }
  public async getWOTypeList() {
    await workorder
      .getAllTypes(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then((resp: any) => {
          let i = 0;
          while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
            this.types.push({
              value: JSON.parse(JSON.stringify(resp))[i].id,
              label: JSON.parse(JSON.stringify(resp))[i].literal
            });
            i++;
          }
        });
      });
  }
  public async getStatusList() {
    await status
      .getAllStatuses(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then(resp => {
          let i = 0;
          while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
            this.statuses.push({
              value: JSON.parse(JSON.stringify(resp))[i].id,
              label: JSON.parse(JSON.stringify(resp))[i].literal
            });
            i++;
          }
        });
      });
  }
}
</script>
<style scoped>
.tag {
  background-color: rgb(201, 228, 238);
  color: black;
  width: 110px;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  padding: 2px 10px 2px 10px;
  font-size: 9pt;
  line-height: 20px;
  text-align: left;
}
strong {
  color: black;
}
.container {
  border: 5px solid rgb(152, 226, 226);
  border-radius: 10px;
  background-color: rgb(221, 218, 218);
  padding: 5px 5px 5px 5px;
}
.vdp-datepicker input {
  width: 75px;
  height: 20px;
}
</style>