<template>
  <div class="container" v-loading="loading">
    <el-input v-model="search" placeholder="Type to search"></el-input>
    <el-table
      :data="personnelList.filter(data => !search || data.lastname.toLowerCase().includes(search.toLowerCase()) || data.firstname.toLowerCase().includes(search.toLowerCase()))"
      style="width: 100%"
      border
      :row-class-name="personnelStatus"
      :default-sort="{prop: 'lastname', order: 'descending'}"
      :header-cell-style="{background:'rgb(170, 238, 238)',color:'black' ,'text-align':'center'}"
      height="500"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <p>{{props.row.specialty}}</p>
          <p
            v-if="!(props.row.mobile==undefined||props.row.mobile=='null'||props.row.mobile=='')"
          >Mobile Phone: {{props.row.mobile}}</p>
          <p
            v-if="!(props.row.landline==undefined||props.row.landline=='null'||props.row.landline=='')"
          >Landline: {{ props.row.landline }}</p>
          <p
            v-if="!(props.row.email==undefined||props.row.email=='null'||props.row.email=='')"
          >Email: {{ props.row.email }}</p>
          <p>ID: {{props.row.id}}</p>
        </template>
      </el-table-column>
      <el-table-column prop="lastname" label="Last Name" sortable width="300px"></el-table-column>
      <el-table-column prop="firstname" label="First Name" width="200px"></el-table-column>
      <el-table-column
        prop="company"
        width="150px"
        label="Company"
        :filters="companies"
        :filter-method="filterHandler"
      ></el-table-column>
      <el-table-column
        prop="WOSpecialty"
        label="Specialty"
        width="100px"
        :filters="WOSpecialties"
        :filter-method="filterHandler"
      ></el-table-column>
      <el-table-column align="right" label="Actions">
        <template slot-scope="scope">
          <el-button
            size="small"
            @click="handleClick(scope.$index, scope.row)"
            icon="el-icon-edit"
            circle
            type="primary"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { personnel } from "../../api/personnel/personnel";
import { StringHelpers } from "../../api/helpers/StringHelpers";
import { Company } from "../../api/personnel/company";
import { Specialty } from "../../api/personnel/specialty";
import { WOSpecialty } from "../../api/personnel/wospecialty";
@Component({
  name: "PersonnelList"
})
export default class PersonnelList extends Vue {
  public personnelList: any[] = [];
  public search: string = "";
  public companies: any[] = [];
  public specialties: any[] = [];
  public WOSpecialties: any[] = [];
  public companyList: any[] = [];
  public specialtyList: any[] = [];
  public woSpecialtyList: any[] = [];
  public loading: boolean = true;
  public fillTable() {
    let i = 0;
    for (const tempPerson of this.personnelList) {
      console.log(tempPerson);
      tempPerson.company = this.companyList.find(
        obj => obj.id === tempPerson.company_id
      ).name;
      tempPerson.specialty = this.specialtyList.find(
        obj => obj.id === tempPerson.specialty_id
      ).literal;
      tempPerson.WOSpecialty = this.woSpecialtyList.find(
        obj => obj.id === tempPerson.wo_specialty
      ).literal;
      if (!this.companies.some(e => e.text === tempPerson.company)) {
        this.companies.push({
          text: tempPerson.company,
          value: tempPerson.company
        });
      }
      if (!this.specialties.some(e => e.text === tempPerson.specialty)) {
        this.specialties.push({
          text: tempPerson.specialty,
          value: tempPerson.specialty
        });
      }
      if (!this.WOSpecialties.some(e => e.text === tempPerson.WOSpecialty)) {
        this.WOSpecialties.push({
          text: tempPerson.WOSpecialty,
          value: tempPerson.WOSpecialty
        });
      }
    }
    this.loading = false;
  }
  public personnelStatus({
    row,
    rowIndex
  }: {
    row: any;
    rowIndex: number;
  }): string {
    if (row.active === false) {
      return "warning-row";
    } else {
      if (this.personnelList[rowIndex].active === true) {
        return "success-row";
      } else {
        return "";
      }
    }
  }
  public handleClick(rowIndex: number, row: any) {
    this.$store.dispatch("updateCurrentObject", row);
    this.$store.dispatch("updateCurrentComponent", "AddPersonnel");
    this.$router.replace({ name: "load" });
  }
  public async created() {
    this.loading = true;
    await this.getSpecialtyList();
  }
  public filterHandler(value: string, row: any, column: any) {
    const property = column["property"];
    return row[property] === value;
  }
  public async getSpecialtyList() {
    Specialty.getAllSpecialties(
      this.$store.getters.getToken,
      this.$store.getters.getHost
    ).then(response => {
      response.json().then(resp => {
        this.specialtyList = JSON.parse(JSON.stringify(resp));
        this.getCompanyList();
      });
    });
  }
  public async getCompanyList() {
    Company.getAllCompanies(
      this.$store.getters.getToken,
      this.$store.getters.getHost
    ).then(response => {
      response.json().then(resp => {
        this.companyList = JSON.parse(JSON.stringify(resp));
        this.getWoSpecialtyList();
      });
    });
  }
  public async getWoSpecialtyList() {
    WOSpecialty.getAllWOSpecialties(
      this.$store.getters.getToken,
      this.$store.getters.getHost
    ).then(response => {
      response.json().then(resp => {
        this.woSpecialtyList = JSON.parse(JSON.stringify(resp));
        this.getPersonnelList();
      });
    });
  }
  public async getPersonnelList() {
    personnel
      .getAllPersonnel(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          console.log('hellololo', JSON.stringify(resp))
          this.personnelList = JSON.parse(JSON.stringify(resp));
          this.fillTable();
        });
      });
  }
}
// oldlace
</script>
<style>
.el-table .warning-row {
  background: #ffcec7;
}
.el-table .success-row {
  background: #ffffff;
}
</style>
<style scoped>
.container {
  border: 2px solid rgb(170, 238, 238);
  border-radius: 10px;
  background-color: rgb(248, 247, 247);
  padding: 5px 10px 5px 10px;
}
</style>