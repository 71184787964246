<template>
  <div class="container">
    <br>
    <br>
    <br>
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Select Employee(s):</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-select
          v-model="name"
          filterable
          :style="{width:'230px'}"
          remote
          size="mini"
          clearable
          placeholder="Name"
          value-key="id"
          :remote-method="nameMethod"
          :loading="loading"
          @change="testme()"
          @focus="testme()"
        >
          <el-option
            v-for="item in availableName"
            :key="item.id"
            :label="item.firstname+' '+item.lastname "
            :value="item"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <br>
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Year:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-input v-model="year" size="mini" :style="{width:'230px'}"></el-input>
      </el-col>
    </el-row>
    <br>
    <br>
    <el-row>
      <el-col :span="12" align="center">
        <el-button @click="View(year,name)" style="margin-top: 12px;" type="success">
          <strong class="white">View Employees Leaves</strong>
        </el-button>
      </el-col>
      <el-col :span="12" align="center">
        <el-button @click="exportVA2(year,name)" style="margin-top: 12px;" type="success">
          <strong class="white">Export Employees Leaves</strong>
        </el-button>
      </el-col>
    </el-row>
    <br>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { personnel } from "../../api/personnel/personnel";
import { vacation } from "../../api/vacation";
import Moment from "moment";

@Component({
  name: "Overview"
})
export default class Overview extends Vue {
  public id: any = "";
  public name: any = "";
  public total: any[] = [];
  public leaveOn: any = "";
  public ReturnOn: any = "";
  public leaveEnd: any = "";
  public type: any = "";
  public types: any[] = [];
  public remarks: string = "";
  public DateOfRequest: any = "";
  public availableName: any[] = [];
  public availableType: any[] = [];
  public personnelList: any[] = [];
  public typeList: any[] = [];
  public loading: boolean = false;
  public total_days_req: any = "";
  public row: any = "";
  public year: any = "";

  public testme() {
    console.log("ttt");
    console.log(JSON.stringify(this.name));
  }

  public addToDBVacation() {
    //dimiourgia item. aristera idia met me api front, deksia idia met m v-model tou idiou
    let vacationItem: any[] = [
      {
        id: this.name.id,
        total_days_req: this.total_days_req,
        leaveOn: this.leaveOn,
        ReturnOn: this.ReturnOn,
        type: this.type,
        remarks: this.remarks,
        DateOfRequest: this.DateOfRequest,
        leaveend: this.leaveEnd
      }
    ];
   
    //api front addVacation
    vacation
      .addVacation(
        this.$store.getters.getToken,
        vacationItem,
        this.$store.getters.getHost
      )
      .then(r => {
        if (r.status == 400) {
          this.$message.error(r.error);
        } else {
          this.$message.success("Vacation Added");
          this.$router.replace({
            name: "load"
          });
        }
      });
  }

  //rows atofia
  public nameMethod(query: string) {
    this.loading = false;
    this.availableName = this.personnelList.filter(item => {
      return (
        item.firstname.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
        item.lastname.toLowerCase().indexOf(query.toLowerCase()) > -1  &&
        (item.active || this.$store.getters.getUserLevel < 1)
      );
    });
  }
  public async getPersonnelList() {
    await personnel
      .getAllPersonnel(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.personnelList = JSON.parse(JSON.stringify(resp));

          this.personnelList.push({ firstname: "All", lastname: " ", lastnamegr: "All", id: -1 });
          //new
          this.personnelList.push({ firstname: "Vacation", lastname: " ", lastnamegr: "Vacation", id: -2 });
          console.log("testforpersonnelList1")
          console.log(this.personnelList);
          console.log("testforpersonnelList2");
        });
      });
  }

  public exportVA2(x: any, y: any) {
    this.$store.dispatch("updateCurrentObject", { year: x, id: y });
    this.$store.dispatch("updateCurrentComponent", "downloadVacation2");
    this.$router.replace({
      name: "load"
    });
  }
  //new
  // public exportVA3(x: any, y: any){
  //   this.$store.dispatch("updateCurrentObject", { year: x, id: y});
  //   this.$store.dispatch("updateCurrentComponent", "downloadVacation3");
  //   this.$store.replace({name: "load"});
  // }

  public View(x: any, y: any) {
    console.log("testviewvacation111")
    console.log(x)
    console.log(y)
    console.log("testviewvacation222")
    this.$store.dispatch("updateCurrentObject", { year: x, id: y });
    this.$store.dispatch("updateCurrentComponent", "ViewVacationSubmitted");
    this.$router.replace({ name: "load" });
   
  }

  public async created() {
    await this.getPersonnelList();

    this.nameMethod("");
    this.$store.dispatch("updateCurrentObject", {  });
  }
}
</script>

<style scoped>
strong {
  color: black;
}

.tag {
  background-color: rgb(201, 228, 238);
  color: black;
  width: 140px;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  padding: 2px 10px 2px 10px;
  font-size: 9pt;
  line-height: 20px;
}

.container {
  border: 2px solid rgb(170, 238, 238);
  border-radius: 10px;
  background-color: rgb(248, 247, 247);
  padding: 5px 10px 5px 10px;
}
</style>
