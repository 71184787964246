export namespace shifts {
  export async function addShift(token: string, shiftItem: any, host: any) {
    let result: Response;
    shiftItem.token = token;
    result = (await fetch(host + "/api/shifts/Add", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body:
        "token=" +
        token +
        "&shift_date=" +
        shiftItem[0].shift_date +
        "&foreman=" +
        shiftItem[0].foreman +
        "&senTech=" +
        shiftItem[0].senTech +
        "&type=" +
        shiftItem[0].type +
        "&personnelObj=" +
        JSON.stringify(shiftItem[1]) +
        "&recycled=false"
    })) as any;
    return await result.json();
  }
  export async function editShift(token: string, shiftItem: any, host: any) {
    let result: Response;
    shiftItem.token = token;
    result = (await fetch(host + "/api/shifts/Add", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body:
        "id=" +
        shiftItem[0].id +
        "&token=" +
        token +
        "&shift_date=" +
        new Date(shiftItem[0].shift_date) +
        "&foreman=" +
        shiftItem[0].foreman +
        "&senTech=" +
        shiftItem[0].senTech +
        "&type=" +
        shiftItem[0].type +
        "&personnelObj=" +
        JSON.stringify(shiftItem[1]) +
        "&recycled=false"
    })) as any;
    return await result.json();
  }
  export async function getSomeShifts(token: string, filters: any, host: any) {
    return fetch(
      host +
        "/api/shifts/getSome?token=" +
        token +
        "&dateFrom=" +
        filters.dateFrom +
        "&dateTo=" +
        filters.dateTo
    );
  }
  export async function getAllShifts(token: string, host: any) {
    return fetch(host + "/api/shifts/getAll?token=" + token);
  }
  export async function getShiftTypes(token: string, host: any) {
    return fetch(host + "/api/shifts/getTypes?token=" + token);
  }

  export async function recycleShift(token: string, id: any, host: any) {
    return await fetch(
      host + "/api/shifts/recycle?token=" + token + "&id=" + id
    );
  }

  export async function deleteShift(token: string, id: any, host: any) {
    return await fetch(
      host + "/api/shifts/delete?token=" + token + "&id=" + id
    );
  }

  export async function getSomeRecycledShifts(
    token: string,
    filters: any,
    host: any
  ) {
    return fetch(
      host +
        "/api/shifts/getSomeRecycled?token=" +
        token +
        "&dateFrom=" +
        filters.dateFrom +
        "&dateTo=" +
        filters.dateTo
    );
  }
}
