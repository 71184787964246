  <template>
  <div>
    <el-collapse accordion v-model="activeName">
      <el-collapse-item title="Internal Information Filters" name="1">
        <div class="container">
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>From Date:</strong>
              </div>
            </el-col>
            <el-col :span="18" align="left">
              <el-date-picker
                v-model="date"
                :style="{width:'200px'}"
                type="date"
                v-on:change="update"
                placeholder="From Date"
                format="dd/MM/yyyy"
                size="mini"
                clearable
              ></el-date-picker>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>To Date:</strong>
              </div>
            </el-col>
            <el-col :span="18" align="left">
              <el-date-picker
                v-model="$props.filters.dateTo"
                :style="{width:'200px'}"
                type="date"
                placeholder="To Date"
                format="dd/MM/yyyy"
                size="mini"
                clearable
              ></el-date-picker>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>Witten By:</strong>
              </div>
            </el-col>
            <el-col :span="18" align="left">
              <el-select
                v-model="$props.filters.user"
                clearable
                placeholder="Witten By"
                size="mini"
                :style="{width:'200px'}"
              >
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { users } from "../../api/users/users";
@Component({
  name: "InternalInformationFilters",
  props: {
    filters: Object
    // {
    //   dateFrom: "",
    //   dateTo: "",
    //   user: ""
    // }
  },
  model: {
    prop: "filters",
    event: "change"
  },
  watch: {
    date: function(val) {
      this.$props.filters.dateFrom = val;
    }
  }
})
export default class InternalInformationFilters extends Vue {
  public loading: boolean = false;
  public activeName: string = "1";
  public userList: any[] = [];
  public date: Date = new Date();

  public async mounted() {
    this.getUserList();
    this.$props.filters.dateFrom = new Date(
      new Date().setDate(new Date().getDate() - 10)
    );
    this.date = new Date(new Date().setDate(new Date().getDate() - 10));
  }
  public async getUserList() {
    await users
      .getAllUsers(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then((resp: any) => {
          this.userList = JSON.parse(JSON.stringify(resp));
        });
      });
  }
  public update() {
    this.$forceUpdate();
  }
}
</script>
<style scoped>
.tag {
  background-color: rgb(201, 228, 238);
  color: black;
  width: 130px;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  padding: 2px 10px 2px 10px;
  font-size: 9pt;
  line-height: 20px;
}
strong {
  color: black;
}
.container {
  border: 5px solid rgb(152, 226, 226);
  border-radius: 10px;
  background-color: rgb(221, 218, 218);
  padding: 5px 5px 5px 5px;
}
</style>