class Alarm {
  public async addAlarm(
    token: string,
    AlarmItem: any,
    host: any
  ): Promise<any> {
    AlarmItem[0].name = AlarmItem[0].name.replace("&", "and");
    let result: Response;
    AlarmItem.token = token;
    result = (await fetch(host + "/api/alarm/Add", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body:
        "token=" +
        token +
        "&name=" +
        AlarmItem[0].name +
        "&status=" +
        AlarmItem[2].selectedStatus +
        "&date_completed=" +
        AlarmItem[2].enddate +
        "&recycled=false" +
        "&component_ids=[" +
        AlarmItem[1] +
        "]" +
        "&personnelObj=" +
        JSON.stringify(AlarmItem[3])
    })) as any;

    return await result.json();
  }

  public async editAlarm(
    token: string,
    AlarmItem: any,
    host: any
  ): Promise<any> {
    let result: Response;
    AlarmItem[0].name = AlarmItem[0].name.replace("&", "and");
    console.log(AlarmItem[0].name);
    result = (await fetch(host + "/api/alarm/Add", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body:
        "id=" +
        AlarmItem[0].id +
        "&token=" +
        token +
        "&name=" +
        AlarmItem[0].name +
        "&status=" +
        AlarmItem[2].selectedStatus +
        "&date_completed=" +
        AlarmItem[2].enddate +
        "&recycled=false" +
        "&component_ids=[" +
        AlarmItem[1] +
        "]" +
        "&personnelObj=" +
        JSON.stringify(AlarmItem[3])
    })) as any;

    return await result.json();
  }

  public async getAllAlarms(token: string, host: any): Promise<any> {
    return await fetch(host + "/api/alarm/getAll?token=" + token);
  }

  public async deleteAlarm(token: string, id: number, host: any): Promise<any> {
    return await fetch(host + "/api/alarm/delete?token=" + token + "&id=" + id);
  }

  public async recycleAlarm(
    token: string,
    id: number,
    host: any
  ): Promise<any> {
    return await fetch(
      host + "/api/alarm/recycle?token=" + token + "&id=" + id
    );
  }

  public async getSomeAlarms(
    token: string,
    filters: any,
    host: any
  ): Promise<any> {
    return await fetch(
      host +
        "/api/alarm/getSome?token=" +
        token +
        "&dateFrom=" +
        filters.dateFrom +
        "&dateTo=" +
        filters.dateTo +
        "&search=" +
        filters.search +
        "&hall=" +
        filters.hall +
        "&system=" +
        filters.system +
        "&group=" +
        filters.group
    );
  }

  public async getSomeRecycledAlarms(
    token: string,
    filters: any,
    host: any
  ): Promise<any> {
    return await fetch(
      host +
        "/api/alarm/getSomeRecycled?token=" +
        token +
        "&dateFrom=" +
        filters.dateFrom +
        "&dateTo=" +
        filters.dateTo +
        "&search=" +
        filters.search +
        "&hall=" +
        filters.hall +
        "&system=" +
        filters.system +
        "&group=" +
        filters.group
    );
  }
}

export const alarm = new Alarm();
