export namespace group {
  export async function getAllGroups(token: string, host: any) {
    return fetch(host + "/api/groups/getAll?token=" + token);
  }

  export async function addGroup(token: string, groupItem: any, host: any) {
    let result: Response;
    groupItem.token = token;
    result = (await fetch(host + "/api/groups/Add", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body:
        "token=" +
        token +
        "&id=" +
        groupItem.id +
        "&internal_id=" +
        groupItem.internal_id +
        "&literal=" +
        groupItem.literal +
        "&group_number=" +
        groupItem.group_number
    })) as any;

    return await result.json();
  }
}
