<template>
  <div>
    <h2>Alarm Details</h2>
    <el-row>
      <el-col :span="4" align="left">
        <div class="tag">
          <strong>Alarm Description:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-input type="textarea" :rows="2" placeholder="Alarm Description" v-model="$props.details.name"></el-input>
      </el-col>
    </el-row>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Emit from "vue-property-decorator";
import { StringHelpers } from "../../api/helpers/StringHelpers";
import { status } from "../../api/status/status";
import { workorder } from "../../api/workorder/workorder";
@Component({
  name: "AlarmDetails",
  props: {
    details: Object
    // {
    //   name: "",
    //   issuedate: "",
    //   type: "",
    //   id: "",
    //   aia: "",
    //   comments: ""
    // }
  },
  model: {
    prop: "details",
    event: "change"
  }
})
export default class AlarmDetails extends Vue {
  public types: any[] = [];
  public row: any;
  public async fillboxes() {}
  public mounted() {
    this.row = this.$store.getters.getCurrentId;
    this.fillboxes();
    this.$emit("on-loaded");
  }
}
</script>
<style scoped>
strong {
  color: black;
}
.tag {
  background-color: rgb(201, 228, 238);
  color: black;
  width: 110px;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  padding: 2px 10px 2px 10px;
  font-size: 9pt;
  line-height: 20px;
}
</style>