<template>
  <div v-bind:class="menuClass">
    <div class="las-logo" v-if="menuClass == 'menuExtended'">
      <el-row>
        <strong>{{ $store.getters.getUserName }}</strong>
      </el-row>
      <el-row>
        <el-col :span="10">
          <img src="logo_las.png" />
        </el-col>
        <el-col :span="14">
          <div class="about">LASS BHS CMMS version: {{ $store.getters.getVersion }}</div>
        </el-col>
      </el-row>
      <el-col :span="19">
        <el-button type="danger" @click="handleselection('Logout', '')"
          >Logout ({{ Math.floor(currentTime / (60 * 1000)) }})</el-button
        >
        <el-button type="success" @click="resetTime()">Reset Time </el-button>
        <br />
      </el-col>
      <el-col :span="5">
        <el-button
          type="primary"
          icon="el-icon-arrow-left"
          @click="minimize()"
        ></el-button>
        <br />
      </el-col>
    </div>
    <div class="sidebar" v-if="menuClass == 'menuExtended'">
      <el-menu :unique-opened="true" @select="handleselection">
          <el-submenu index="16" class="menuTitle">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>
                <strong>Athens BHS</strong>
              </span>
            </template>
            <el-submenu index="10" class="menuTitle">
              <template slot="title">
                <span>
                  Shift Records
                </span>
              </template>
              <el-menu-item index="AddShift">Add Shift</el-menu-item>
              <el-menu-item index="ShiftList">View Shift Records</el-menu-item>
              <el-menu-item index="ShiftBin" v-if="userlevel < 2"
                >View Shift Recycle Bin</el-menu-item
              >
            </el-submenu>
            <el-submenu index="3" class="menuTitle">
              <template slot="title">
                <span>
                  Work Order
                </span>
              </template>
              <el-menu-item index="AddWorkOrder"
                >Add new Work Order</el-menu-item
              >
              <el-menu-item index="WorkOrderList"
                >View Work Order List</el-menu-item
              >
              <el-menu-item index="WorkOrderBin" v-if="userlevel < 2"
                >View Work Order Recycle Bin</el-menu-item
              >
              <el-menu-item index="DownloadWorkOrders" v-if="userlevel < 2"
                >Download Work Orders</el-menu-item
              >
            </el-submenu>
            <el-submenu index="17" class="menuTitle" v-if="userlevel < 3">
              <template slot="title">
                <span>
                  PPM
                </span>
              </template>
              <el-menu-item index="AddPPMnew" v-if="userlevel < 1"
                >Add new PPM</el-menu-item
              >
              <el-menu-item index="AddPPMyearly" v-if="userlevel < 3"
                >Add yearly PPM</el-menu-item
              >
              <el-menu-item index="PPMList2" v-if="userlevel < 3"
                >View PPM List
              </el-menu-item>
              <el-menu-item index="PPMList" v-if="userlevel < 1"
                >View PPM List old</el-menu-item
              >
              <el-menu-item index="PPMReports" v-if="userlevel < 2"
                >Reports</el-menu-item
              >
              <el-menu-item index="PPMBin" v-if="userlevel < 1"
                >View PPM Recycle Bin</el-menu-item
              >
              <el-menu-item index="ppmexport" v-if="userlevel < 2"
                >PPM Reports</el-menu-item
              >
            </el-submenu>
            <!-- <el-submenu index="1" class="menuTitle">
              <template slot="title">
                <span>
                  Alarms
                </span>
              </template>
              <el-menu-item index="AddAlarm">Add new Alarm</el-menu-item>
              <el-menu-item index="AlarmList">View Alarm List</el-menu-item>
              <el-menu-item index="AlarmBin" v-if="userlevel < 2"
                >View Alarm Bin</el-menu-item
              >
            </el-submenu> -->
            <el-submenu index="11" class="menuTitle">
              <template slot="title">
                <span>
                  System Information
                </span>
              </template>
              <el-menu-item index="AddSystemInformation"
                >Add System Information</el-menu-item
              >
              <el-menu-item index="SystemInformationList"
                >View System Information</el-menu-item
              >
              <el-menu-item index="SystemInformationBin" v-if="userlevel < 2"
                >View System Information Bin</el-menu-item
              >
            </el-submenu>
            <el-submenu index="12" class="menuTitle">
              <template slot="title">
                <span>
                  Internal Information
                </span>
              </template>
              <el-menu-item index="AddInternalInformation"
                >Add Internal Information</el-menu-item
              >
              <el-menu-item index="InternalInformationList"
                >View Internal Information</el-menu-item
              >
              <el-menu-item index="InternalInformationBin" v-if="userlevel < 2"
                >View Internal Information Bin</el-menu-item
              >
            </el-submenu>
            <el-submenu index="8" v-if="userlevel < 2" class="menuTitle">
              <template slot="title">
                <span>
                  Equipment
                </span>
              </template>
              <el-menu-item index="AddComponent"
                >Add new Component</el-menu-item
              >
              <el-menu-item index="ComponentList_new"
                >View Component List</el-menu-item
              >
              <el-menu-item index="ComponentBin"
                >View Recycled Components</el-menu-item
              >
            </el-submenu>
          </el-submenu>
  

        <el-submenu index="14" class="menuTitle" v-if="userlevel < 2">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>
              <strong>Project</strong>
            </span>
          </template>
          <el-menu-item index="AddNewProject" v-if="userlevel < 2"
            >Add new Project</el-menu-item
          >
          <el-menu-item index="AddNewDuration" v-if="userlevel < 2"
            >Add new Record</el-menu-item
          >
          <el-menu-item index="ViewEmployeesDurations" v-if="userlevel < 2"
            >View Employees Records</el-menu-item
          >
          <el-menu-item index="ViewProjectsSubmitted" v-if="userlevel < 2"
            >View Projects Submitted</el-menu-item
          >
          <el-menu-item index="newProjectReports" v-if="userlevel < 2"
            >Reports</el-menu-item
          >
        </el-submenu>
        <el-submenu index="20" class="menuTitle">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>
              <strong>Vacation</strong>
            </span>
          </template>
          <el-submenu index="21">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>
                <strong>AKTOR Vacation</strong>
              </span>
            </template>

            <el-menu-item index="AddNewVacation">Add new Vacation</el-menu-item>
            <el-menu-item index="ViewVacationSubmitted"
              >View Vacation Submitted</el-menu-item
            >
            <el-menu-item index="Decisionofvacation" v-if="userlevel < 2"
              >Decision</el-menu-item
            >
            <el-menu-item index="Overview" v-if="userlevel < 2"
              >Overview</el-menu-item
            >
            <!-- <el-menu-item index="RecycleBin" v-if="userlevel<2" >Recycle bin</el-menu-item> -->
            <!-- </el-submenu> -->
          </el-submenu>
          <el-submenu index="22" v-if="userlevel < 2">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>
                <strong>LASS (TBA)</strong>
              </span>
            </template>
          </el-submenu>
        </el-submenu>
        <el-submenu index="2" v-if="userlevel < 2" class="menuTitle">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>
              <strong>Personnel</strong>
            </span>
          </template>
          <el-menu-item index="AddPersonnel">Add new Employee</el-menu-item>
          <el-menu-item index="PersonnelList">View Personnel List</el-menu-item>
          <el-menu-item index="employeeOfTheMonth">Employee Chart</el-menu-item>
        </el-submenu>
        <el-submenu index="6" v-if="userlevel < 2" class="menuTitle">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>
              <strong>Users Activity</strong>
            </span>
          </template>
          <el-menu-item index="ActivityList"
            >View User Activity List</el-menu-item
          >
          <el-menu-item index="dashBoard" v-if="userlevel < 1"
            >Open DashBoard</el-menu-item
          >
        </el-submenu>
        <el-submenu index="7" class="menuTitle">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>
              <strong>User Options</strong>
            </span>
          </template>
          <el-menu-item index="ChangePassword">Change Password</el-menu-item>
        </el-submenu>

        <el-submenu index="9" class="menuTitle" v-if="userlevel == 0">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>
              <strong>Maintenance</strong>
            </span>
          </template>
          <el-menu-item index="cleanActivities">Clean Activities</el-menu-item>
          <el-menu-item index="cleanComponents">Clean Components</el-menu-item>
        </el-submenu>
        <el-submenu index="4" v-if="userlevel < 1" class="menuTitle">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>
              <strong>Project</strong>
            </span>
          </template>
          <el-menu-item index="AddProject">Add new Project</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <div class="las-logo" v-else>
      <el-button
        type="primary"
        icon="el-icon-arrow-right"
        @click="maximize()"
        size="mini"
      ></el-button>
    </div>
    <div class="main-app">
      <component
        v-bind:is="componentname"
        :obj="details"
        class="heightAdjust"
      ></component>
    </div>
  </div>
</template>
<script lang="ts">
import Overview from "../parts/Lists/Overview.vue";
import Decisionofvacation from "../parts/Lists/Decisionofvacation.vue";
import ViewVacationSubmitted from "../parts/Lists/ViewVacationSubmitted.vue";
import AddNewVacation from "../parts/Adders/AddNewVacation.vue";
import { Component, Vue } from "vue-property-decorator";
import Login from "../views/Login.vue";
import PersonnelList from "../parts/Lists/PersonnelList.vue";
import AlarmList from "../parts/Lists/AlarmList.vue";
import Logout from "../components/Logout.vue";
import Welcome from "../parts/Welcome.vue";
import AddAlarm from "../parts/Adders/AddAlarm.vue";
import AddPersonnel from "../parts/Adders/AddPersonnel.vue";
import AddWorkOrder from "../parts/Adders/AddWorkOrder.vue";
import WorkOrderList from "../parts/Lists/WorkOrderList.vue";
import AddPPMnew from "../parts/Adders/AddPPMnew.vue";
import AddPPMyearly from "../parts/Adders/AddPPMyearly.vue";
import PPMList from "../parts/Lists/PPMList.vue";
import PPMList2 from "../parts/Lists/PPMList2.vue";
import ppmexport from "../parts/Lists/ppmexport.vue";
import PPMReports from "../parts/Lists/PPMReports.vue";
import DownloadWorkOrders from "../parts/Downloaders/DownloadWorkOrders.vue";
import DownloadWorkOrderDetails from "../parts/Downloaders/DownloadWorkOrderDetails.vue";
import AddProject from "../parts/Adders/AddProject.vue";
import WorkOrderBin from "../parts/Bins/WorkOrderBin.vue";
import ActivityList from "../parts/Lists/ActivityList.vue";
import ChangePassword from "../components/ChangePassword.vue";
import AddComponent from "../parts/Adders/AddComponent.vue";
import ComponentList from "../parts/Lists/ComponentList.vue";
import ComponentList_new from "../parts/Lists/ComponentList_new.vue";
import ComponentBin from "../parts/Bins/ComponentBin.vue";
import ShiftList from "../parts/Lists/ShiftList.vue";
import dashBoard from "../parts/Stats/dashBoard.vue";
import PPMBin from "../parts/Bins/PPMBin.vue";
import employeeOfTheMonth from "../parts/Stats/employeeOfTheMonth.vue";
import AddShift from "../parts/Adders/AddShift.vue";
import ShiftBin from "../parts/Bins/ShiftBin.vue";
import { alarm } from "../api/alarm/alarm";
import { personnel } from "../api/personnel/personnel";
import { activities } from "../api/activity/activities";
import { component } from "../api/component/component";
import AddSystemInformation from "../parts/Adders/AddSystemInformation.vue";
import SystemInformationList from "../parts/Lists/SystemInformationList.vue";
import AddInternalInformation from "../parts/Adders/AddInternalInformation.vue";
import InternalInformationList from "../parts/Lists/InternalInformationList.vue";
import SystemInformationBin from "../parts/Bins/SystemInformationBin.vue";
import InternalInformationBin from "../parts/Bins/InternalInformationBin.vue";
import AlarmBin from "../parts/Bins/AlarmBin.vue";
import DownloadVacation from "../parts/Downloaders/DownloadVacation.vue";
import DownloadVacation2 from "../parts/Downloaders/DownloadVacation2.vue";
import DownloadPPMReports from "../parts/Downloaders/DownloadPPMReports.vue";
import AddNewProject from "../parts/Adders/AddNewProject.vue";
import AddNewDuration from "../parts/Adders/AddNewDuration.vue";
import ViewEmployeesDurations from "../parts/Lists/ViewEmployeesDurations.vue";
import ViewProjectsSubmitted from "../parts/Lists/ViewProjectsSubmitted.vue";
import newProjectReports from "../parts/Lists/newProjectReports.vue";
import DownloadNPReports from "../parts/Downloaders/DownloadNPReports.vue";


@Component({
  components: {
    DownloadPPMReports,
    DownloadNPReports,
    DownloadVacation2,
    Overview,
    Decisionofvacation,
    DownloadVacation,
    ViewVacationSubmitted,
    AddNewVacation,
    Login,
    Welcome,
    PersonnelList,
    AlarmList,
    Logout,
    AddAlarm,
    AddPersonnel,
    AddWorkOrder,
    WorkOrderList,
    AddPPMnew,
    AddPPMyearly,
    AddNewProject,
    AddNewDuration,
    ViewEmployeesDurations,
    ViewProjectsSubmitted,
    newProjectReports,
    PPMList,
    PPMList2,
    ppmexport,
    PPMReports,
    DownloadWorkOrders,
    DownloadWorkOrderDetails,
    AddProject,
    WorkOrderBin,
    ActivityList,
    ChangePassword,
    AddComponent,
    ComponentList,
    ComponentList_new,
    ComponentBin,
    PPMBin,
    employeeOfTheMonth,
    dashBoard,
    AddShift,
    ShiftList,
    ShiftBin,
    AddSystemInformation,
    SystemInformationList,
    AddInternalInformation,
    InternalInformationList,
    InternalInformationBin,
    SystemInformationBin,
    AlarmBin,
  } as any,
})
export default class MainMenu extends Vue {
  public componentname: string = "Welcome";
  public details: any = {};
  public userlevel: number = -1;
  public menuClass: string = "menuExtended";
  public currentTime: number = 60;
  public resetTime() {
    this.$store.dispatch("resetTime");
  }
  public updateTime() {
    //console.log("time");
    this.$store.dispatch("timerTick");
    this.currentTime = this.$store.getters.getRemainingTime;

    if (this.currentTime <= 10 * 60 * 1000 && this.currentTime > 0) {
      if (this.currentTime % (60 * 1000) == 0) {
        //popup 5leptou me warning message
        this.$notify({
          title: "Logout",
          message:
            Math.floor(this.currentTime / (1000 * 60)) -
            1 +
            " minutes remain, please relog.",
          type: "warning",
          duration: 60 * 1000,
        });
      }
    }
    if (this.currentTime <= 0 && !this.$store.getters.getDialogSeen) {
      this.$store.dispatch("dialogSeen");
      //psofos me messagebox element
      this.$alert("Press OK to Relog", "Login Expired", {
        confirmButtonText: "OK",
        callback: (action) => {
          this.$store.dispatch("resetTime");
          this.details = {};
          this.$store.dispatch("updateCurrentComponent", "Logout");
          this.componentname = "Logout";
        },
      });
    }
  }
  public async handleselection(key: string, key2: string) {
    switch (key) {
      case "cleanActivities":
        this.$message("Cleaning Activities");
        await activities.cleanActivities(
          this.$store.getters.getToken,
          this.$store.getters.getHost
        );
        this.$message.success("Activities Cleaned");
        break;
      case "cleanComponents":
        this.$message("Cleaning Components");
        await component.cleanComponents(
          this.$store.getters.getToken,
          this.$store.getters.getHost
        );
        this.$message.success("Components Cleaned");
        break;
      default:
        this.details = {};
        this.$store.dispatch("updateCurrentComponent", key);
        this.componentname = key;
        break;
    }
  }
  public mounted() {
    this.currentTime = this.$store.getters.getRemainingTime;
    this.details = this.$store.getters.getCurrentObject;
    this.componentname = this.$store.getters.getCurrentComponent;
    this.userlevel = this.$store.getters.getUserLevel;
    let timer = setInterval(this.updateTime, 1000);
  }
  public minimize() {
    this.menuClass = "menuMin";
  }
  public maximize() {
    this.menuClass = "menuExtended";
  }
}
</script>
<style scoped>
.menuTitle {
  text-align: left;
  margin: 5px 5px 5px 5px;
}
.logout {
  text-align: left;
}
</style>
<style>
.las-logo {
  grid-area: header;
  text-align: left;
  overflow: hidden;
}
.sidebar {
  grid-area: sidebar;
  overflow: auto;
}
.main-app {
  grid-area: main;
  overflow: auto;
  width: 95wh;
}

.menuExtended {
  display: grid;
  grid-template-columns: 300px auto;
  grid-template-rows: 150px auto;
  grid-template-areas:
    "header main"
    "sidebar main";
  height: 88vh;
  overflow: hidden;
  border: 2px solid rgb(170, 238, 238);
  border-radius: 10px;
  background-color: rgb(248, 247, 247);
  padding: 5px 5px 5px 5px;
}

.menuMin {
  display: grid;
  grid-template-columns: 30px auto;
  grid-template-rows: 150px auto;
  grid-template-areas:
    "header main"
    "sidebar main";
  height: 88vh;
  overflow: hidden;
  border: 2px solid rgb(170, 238, 238);
  border-radius: 10px;
  background-color: rgb(248, 247, 247);
  padding: 5px 5px 5px 5px;
}
.about {
  padding: 8px 10px 8px 10px;
  border-style: inset;
  height: 52px;
}
</style>
