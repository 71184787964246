

<template>
<div>
    Logging out 
    </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
@Component({
    name :'Logout'
})
export default class Logout extends Vue {
  public mounted() {
    this.$store.dispatch('reset');
    this.$router.replace({ name: 'login' });
  }
}
</script>