<template>
<div>
 <el-row class="container">
<br>
<!-- {{titleselected}} -->
  <el-row>
    <el-col :span="4" align="left">
      <div class="tag">
        <strong>Project Title:</strong>
      </div>
    </el-col>
    <el-col :span="20" align="left">
      <el-select
        v-model="titleselected"
        placeholder="Project Titles"
                 
        size="mini"
        :style="{width:'250px'}"                 
      >
      <el-option
        v-for="item in titlesList"
        :key="item.value"
        :label="item.label"
        :value="item.value"
       ></el-option>
     </el-select>
    </el-col>        
  </el-row>

<br>
<br>
<br>
 <el-row>

      <!-- {{techs}} -->
      <!-- {{ techs[index].start_date}}  -->      
      <table>
        <tr>
          <th>
            <div class="tableCell">
              <strong class="tagText">Tech Name</strong>
            </div>
          </th>
          <th>
            <div class="dateTableCell">
              <strong class="tagText">Start Date</strong>
            </div>
          </th>
          <th>
            <div class="dateTableCell3">
              <strong class="tagText">Start Time</strong>
            </div>
          </th>
          <th>
            <div class="dateTableCell">
              <strong class="tagText">End Date</strong>
            </div>
          </th>
          <th>
            <div class="dateTableCell3">
              <strong class="tagText">End Time</strong>
            </div>
          </th>
          <th>
            <div class="dateTableCell2">
              <strong class="tagText">Mech</strong>
            </div>
          </th>
          <th>
            <div class="dateTableCell2">
              <strong class="tagText">Elec</strong>
            </div>
          </th>
          <th>
            <div class="dateTableCell2">
              <strong class="tagText">General</strong>
            </div>
          </th>
          <th>
            <div class="dateTableCell2">
              <strong class="tagText">Control</strong>
            </div>
          </th>
          <th>
            <div class="dateTableCell2">
              <strong class="tagText">IT</strong>
            </div>
          </th>
          <th>
            <div class="dateTableCell2">
              <strong class="tagText">PM</strong>
            </div>
          </th>
          <th>
            <div class="dateTableCell2">
              <strong class="tagText">PS</strong>
            </div>
          </th>
          <th>
            <div class="dateTableCell2">
              <strong class="tagText">Adm</strong>
            </div>
          </th>
          <th>
            <!-- &&system!='Electrical Panels' -->            
            <div
              class="shortTableCell"
              v-if="!(periodicity==4||periodicity==6||(periodicity==7&&system=='IT and Automation')||(periodicity==8)||system=='Detectors'||system=='Fire Panels')"
            >
              <strong class="tagText">Hall</strong>
            </div>
          </th>
          <th v-if="false">
            <div class="tableCell">
              <strong class="tagText">Executed Parts</strong>
            </div>
          </th>
        </tr>
       
        <tr v-for="(p,index) in techs" v-if="techs[index].id!=''">
          <th>
            <div>
              <el-select
                v-model="techs[index].id"
                filterable
                :style="{width:'220px'}"
                remote
                size="mini"
                @change="techUpdate(techs[index].id,index)"
                clearable
                placeholder="Add Technician"
                :remote-method="foremanMethod"
                :loading="loading"
               
              >
                <el-option
                  v-for="item in availableForemen"
                  :key="item.value"
                  :label="item.firstname + ' ' + item.lastname"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </th>
          <th>
            <div>
              <el-date-picker
                v-model="techs[index].start_date"
                
                type="date"
                placeholder="Start Date"
                size="mini"
                format="dd/MM/yyyy"
                :style="{width:'125px'}"
                 :disabled="index > 0"
              ></el-date-picker>
            </div>
          </th>
          <th>
            <div>
              <!-- :formater="Completiondate" -->
                <!-- v-on:input="startTimeChange(index)"  @selection-change="handleSelectionChange"-->
              <el-input
                placeholder="00:00"
                v-on:input="startTimeChange(index);handleSelectionChange(index)"
                v-model="techs[index].start"
                :style="{width:'90px'}"
                size="mini"
                maxlength="5"
                 :disabled="index > 0"
                
              ></el-input>
            </div>
          </th>
          <th>
            <div>
            <el-date-picker
            v-model="techs[index].end_date"
            
            type="date"
            placeholder="End Date"
            size="mini"
            format="dd/MM/yyyy"
            :style="{width:'125px'}"
             :disabled="index > 0"
            >
            </el-date-picker>
            </div>
            </th>
          <th>
            <div>
              <el-input
                placeholder="00:00"
                v-on:input="endTimeChange(index)"
                v-model="techs[index].end"
                :style="{width:'90px'}"
                size="mini"
                maxlength="5"
                 :disabled="index > 0"
              ></el-input>
            </div>
          </th>
            <th>
            <div>
               <!-- :disabled="index > 0" -->
               <!-- :remote-method="testMECH_HOURS" -->
              <el-input
                placeholder="00.00"               
                v-model="techs[index].mech_hours"
                v-on:input="mech_hoursTimeChange(index)"
                :style="{width:'67px'}"
                :default-value="0"
                size="mini"
                maxlength="5"
                
              ></el-input>
            </div>
          </th>
          <th>
            <div>
               <!-- :disabled="index > 0" -->
              <el-input
                placeholder="00.00"
                v-on:input="elec_hoursTimeChange(index)"
                v-model="techs[index].elec_hours"
                :style="{width:'67px'}"
                size="mini"
                maxlength="5"
                
              ></el-input>
            </div>
          </th>
          <th>
            <div>
               <!-- :disabled="index > 0" -->
              <el-input
                placeholder="00.00"
                v-on:input="general_hoursTimeChange(index)"
                v-model="techs[index].general_hours"
                :style="{width:'67px'}"
                size="mini"
                maxlength="5"
                
              ></el-input>
            </div>
          </th>
          <th>
            <div>
              <!-- :disabled="index > 0" -->
              <el-input
                placeholder="00.00"
                v-on:input="control_hoursTimeChange(index)"
                v-model="techs[index].control_hours"
                :style="{width:'67px'}"
                size="mini"
                maxlength="5"
                 
              ></el-input>
            </div>
          </th>
          <th>
             <!-- :disabled="index > 0" -->
            <div>
              <el-input
                placeholder="00.00"
                v-on:input="it_hoursTimeChange(index)"
                v-model="techs[index].it_hours"
                :style="{width:'67px'}"
                size="mini"
                maxlength="5"
                
              ></el-input>
            </div>
          </th>
          <th>
            <div>
              <!-- :disabled="index > 0" -->
              <el-input
                placeholder="00.00"
                v-on:input="pm_hoursTimeChange(index)"
                v-model="techs[index].pm_hours"
                :style="{width:'67px'}"
                size="mini"
                maxlength="5"
                 
              ></el-input>
            </div>
          </th>
          <th>
            <div>
               <!-- v-on:input="endTimeChange(index)" -->
               <!-- :disabled="index > 0" -->
              <el-input
                placeholder="00.00"
                v-on:input="ps_hoursTimeChange(index)"
                v-model="techs[index].ps_hours"
                :style="{width:'67px'}"
                size="mini"
                maxlength="5"
                 
              ></el-input>
            </div>
          </th>
          <th>
            <div>
              <!-- :disabled="index > 0" -->
              <el-input
                placeholder="00.00"
                v-on:input="admin_hoursTimeChange(index)"
                v-model="techs[index].admin_hours"
                :style="{width:'67px'}"
                size="mini"
                maxlength="5"
                 
              ></el-input>
            </div>
          </th>
          <th>
            <!-- &&system!='Electrical Panels' -->
            <div
              v-if="!(periodicity==4||periodicity==6||(periodicity==7&&system=='IT and Automation')||(periodicity==8)||system=='Detectors'||system=='Fire Panels')"
            >
              <el-select
                v-model="techs[index].hall"
                :style="{width:'100px'}"
                placeholder="Hall"
                :items="halls"
                clearable
                size="mini"
              >
                <el-option v-for="item in halls" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
          </th>
          <th v-if="false">
            <div>
              <el-input
                placeholder="Parts"
                type="textarea"
                autosize
                v-model="techs[index].parts"
                size="mini"
              ></el-input>
            </div>
          </th>
        </tr>
        <tr>
          <th>
            <div>
              <el-select
                v-model="selectedTech"
                filterable
                @change="pushPersonnel"
                :style="{width:'220px'}"
                remote
                size="mini"
                clearable
                placeholder="Add Technician"
                :remote-method="foremanMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in availableForemen"
                  :key="item.value"
                  :label="item.firstname + ' ' + item.lastname"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </th>
          <th>
            <div></div>
          </th>
          <th>
            <div></div>
          </th>
          <th>
            <div></div>
          </th>
          <th v-if="false">
            <div></div>
          </th>
        </tr>
      </table>
    </el-row>
    <br>
<el-row>

      <el-button style="margin-top: 12px;" @click="addToDBDuration" type="success">
        <strong class="white">Submit</strong>
      </el-button>
    </el-row>
        
    </el-row>
</div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { new_project } from "../../api/new_project/new_project"
import { ppm } from "../../api/ppm/ppm";
import { personnel } from "../../api/personnel/personnel";
import { aiaPersonnel } from "../../api/personnel/aiaPersonnel";
import { component } from "../../api/component/component";
import { workorder } from "../../api/workorder/workorder";
import StatusSelector from "../Selectors/StatusSelector.vue";
import { postgrest } from "../../api/postgrest";
import Moment from "moment";
// import moment from "moment";
// import "moment-timezone";

import moment from "moment";
import "moment-timezone";
//import toHHMMSS from 'time-input-js'

@Component({
  name: "AddNewDuration",
  components: {
    StatusSelector
  },
  props: {
    obj: {}
  }
  ,
   watch: {
    techs: {
      deep: true,
      handler() {
        console.log(JSON.stringify(this.$data.techs))
        console.log('JSON.stringify(this.techs)')
        for(let x of this.$data.techs){
          console.log('xxxxxxxx1')
          console.log(x)
          console.log('xxxxxxxx2')
          x.start_date=this.$data.techs[0].start_date
          x.end_date=this.$data.techs[0].end_date
          x.start=this.$data.techs[0].start
          x.end=this.$data.techs[0].end
          //??
          // x.mech_hours=this.techs[0].mech_hours
          // x.elec_hours=this.techs[0].elec_hours
          // x.general_hours=this.techs[0].general_hours
          // x.control_hours=this.techs[0].control_hours
          // x.it_hours=this.techs[0].it_hours
          // x.pm_hours=this.techs[0].pm_hours
          // x.ps_hours=this.techs[0].ps_hours
          // x.admin_hours=this.techs[0].admin_hours
        }
      }
    }

  }
})
export default class AddNewDuration extends Vue {
public titleselected: any = "";
public titlesList: any[] = [];
public techs: any[] = [];
public unique_id: any = 0;
public MAXEmployeesDurationsList: any[] =[];
public temp: any= 0;
public temp2: any= '';
public temp3: any= 0;
public tempsum: number =0;

 //  public update() {
  //   this.$forceUpdate();
  // }

public status: any = {};
public periodicity: any = 8;
public availableSystems: any[] = [];
public systemPeriodicities: any[] = [];
public existing: boolean = false;
public system: string = "";
public currentYear: any = "";
public currentMonth: any = "";
public maintenancePeriodOptions: any[] = [];
public maintenancePeriodOptions2: any[] = [];
public maintenancePeriod: any = '';
//public techs: any[] = [];
public personnelList: any[] = [];
public availableForemen: any[] = [];
public loading: boolean = false;
public selectedTech: any = "";
public availableSenTechs: any[] = [];
public workOrders: any[] = [];
public workOrderList: any[] = [];
public availableWorkOrders: any[] = [];
public yearlyComponent: any=""; 
public availableComponents: any [] = [];
public availableComponents2: any [] = [];
public componentList: any[] = [];
public senTechs: any[] = [];
public senTechList: any[] = [];
public atrGroups: any[] = [];
public southUps: any[] = [];
public northUps: any[] = [];
public upsGroups: any[] = [];
public northSorter: any[] = [];
public southSorter: any[] = [];
public itGroups: any[] = [];
public atrComponents: any[] = [];
public northInductions: any[] = [];
public southInductions: any[] = [];
public halls: any[] = [];
public systems: any[] = [];
public groups: any[] = [];
public codes: any[] = [];
public descriptions: any[] = [];
public conveyorBelts: any[] = [];
public southPanels: any[] = [];
public northPanels: any[] = [];
public selectedHall = "";
public belt: any = "";
public components: any[] = [];
public selectedWeek: any = "";
public lines: any[] = [];
public beltLine: any[] = [];
public workOrdersTable: any[] = [];
public selectedWorkOrder: any = "";
public selectedWorkOrderNew: any = "";
public selectedComponent = "";
public jsonRemarks: any[] = [];
public yearlyRemarks: any = "";
public yearlyActions: any = "";
public activities: any[] = [];
public foreman: any = "";
public senTech: any = "";
public row: any = "";
public selectedSubpanels: any[] = [];
public selectedScales: any[] = [];
public selectedSubsortername: any[] = [];

public PpmsList2subpanel: any[] = [];
public Select: boolean = false;
public select_all: boolean = false;
public oneWO: any = "";
public valid: boolean = true;
async  created(){
  this.testforunigue();
  await this.getEmployeesDurationsList2()
  await this.getPersonnelList();
  await this.getProjectTitlesList();
   
 

  
//await this.getWorkOrderList();
  await this.getSenTechList();
  await this.getActivities();
   
  this.checkEdit();
 

  }

 public handleSelectionChange(val) {
    console.log("handle");
    console.log(val)
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!123')
    // this.multipleSelection = val;
    // console.log(this.multipleSelection);
  }

public async getProjectTitlesList(){
    await new_project
    .getProjectTitles(
    this.$store.getters.getToken,
    this.$store.getters.getHost
    )
    .then(response => {
    response.json().then(resp => {
    let i = 0;
    while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
      this.titlesList.push({
        value: JSON.parse(JSON.stringify(resp))[i].id,
        label: JSON.parse(JSON.stringify(resp))[i].literal
        });
         i++;
       }
       console.log('testforprojectplaces-------->'+JSON.stringify(resp))
      });
    });   
  }





public async clear(){
this.techs = [];

 
}





  public pushPersonnel() {
    //auto gemizi tin proti timi kai meta i for ta emfanizi
    if(this.techs.length<1){
    this.techs.push({
      id:
        this.personnelList.find(r => r.id == this.selectedTech).firstname +
        " " +
        this.personnelList.find(r => r.id == this.selectedTech).lastname,
      value: this.selectedTech,
      start: "",
      end: "",
      start_date: "",
      end_date: "",
      mech_hours:"",
      elec_hours:"",
      general_hours:"",
      control_hours:"",
      it_hours:"",
      pm_hours:"",
      ps_hours:"",
      admin_hours:""

    });
    
    }else{
     this.techs.push({
      id:
        this.personnelList.find(r => r.id == this.selectedTech).firstname +
        " " +
        this.personnelList.find(r => r.id == this.selectedTech).lastname,
      value: this.selectedTech,
      start: this.techs[0].start,
      end: this.techs[0].end,
      start_date: this.techs[0].start_date,
      end_date: this.techs[0].end_date,
      mech_hours:this.techs[this.techs.length-1].mech_hours,
      elec_hours:this.techs[this.techs.length-1].elec_hours,
      general_hours:this.techs[this.techs.length-1].general_hours,
      control_hours:this.techs[this.techs.length-1].control_hours,
      it_hours:this.techs[this.techs.length-1].it_hours,
      pm_hours:this.techs[this.techs.length-1].pm_hours,
      ps_hours:this.techs[this.techs.length-1].ps_hours,
      admin_hours:this.techs[this.techs.length-1].admin_hours

    });
    }
    this.selectedTech = "";
    this.availableForemen = [];
    console.log("xxxxxxxxx")
    console.log(JSON.stringify(this.techs));
    console.log("xxxxxxxxx")
  }
public time(index : number){
console.log('test');
console.log('test2');
}



// var moment = require ('moment')
// moment().format();

// var time1  

// if(time1 == 0.15 ){

// time1=0
// time1=00:15 

// }

// var time2
// if( time2 == 0.30){

// time2 =0
// time2=00:30

// }

// }

// }

public endTimeChange(index: number) {
    if (this.techs[index].end.length === 2) {
      this.techs[index].end = this.techs[index].end + ":";
      console.log("This is a example for end time change" )
    }
  }
  public startTimeChange(index: number) {
    if (this.techs[index].start.length === 2) {
      this.techs[index].start = this.techs[index].start + ":";
      console.log( this.techs[index].start )
      console.log("This is a example for start time change")
    }
  }
 
  public mech_hoursTimeChange(index: number) {
  if (this.techs[index].mech_hours.length === 2) {
      this.techs[index].mech_hours = this.techs[index].mech_hours + ".";
      console.log( this.techs[index].mech_hours )
      console.log("This is a example for mech_hours time change")
    }

  }
  public elec_hoursTimeChange(index: number) {
  if (this.techs[index].elec_hours.length === 2) {
      this.techs[index].elec_hours = this.techs[index].elec_hours + ".";
      console.log( this.techs[index].elec_hours )
      console.log("This is a example for elec_hours time change")
    }

  }
public general_hoursTimeChange(index: number) {
  if (this.techs[index].general_hours.length === 2) {
      this.techs[index].general_hours = this.techs[index].general_hours + ".";
      console.log( this.techs[index].general_hours )
      console.log("This is a example for general_hours time change")
    }

  }
public control_hoursTimeChange(index: number) {
  if (this.techs[index].control_hours.length === 2) {
      this.techs[index].control_hours = this.techs[index].control_hours + ".";
      console.log( this.techs[index].control_hours )
      console.log("This is a example for control_hours time change")
    }

  }
  public it_hoursTimeChange(index: number) {
  if (this.techs[index].it_hours.length === 2) {
      this.techs[index].it_hours = this.techs[index].it_hours + ".";
      console.log( this.techs[index].it_hours )
      console.log("This is a example for it_hours time change")
    }

  }
  public pm_hoursTimeChange(index: number) {
  if (this.techs[index].pm_hours.length === 2) {
      this.techs[index].pm_hours = this.techs[index].pm_hours + ".";
      console.log( this.techs[index].pm_hours )
      console.log("This is a example for pm_hours time change")
    }

  }
 public ps_hoursTimeChange(index: number) {
  if (this.techs[index].ps_hours.length === 2) {
      this.techs[index].ps_hours = this.techs[index].ps_hours + ".";
      console.log( this.techs[index].ps_hours)
      console.log("This is a example for ps_hours time change")
    }

  }
   public admin_hoursTimeChange(index: number) {
  if (this.techs[index].admin_hours.length === 2) {
      this.techs[index].admin_hours = this.techs[index].admin_hours + ".";
      console.log( this.techs[index].admin_hours)
      console.log("This is a example for admin_hours time change")
    }

  }


 public techUpdate(id: any, index: number) {
    if (id == "") {
      this.techs.splice(index, 1);
    }
    console.log("this.techs1")
    console.log(this.techs);
    console.log("this.techs2")
  }

  public foremanMethod(query: string) {
    if (query != "" && query != undefined) {
      this.loading = true;
      this.loading = false;
      this.availableForemen = this.personnelList.filter(item => {
        return (
          (item.firstname.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
            item.team == query ||
            item.lastname.toLowerCase().indexOf(query.toLowerCase()) > -1) &&
          this.techs.find(r => r.id == item.firstname + " " + item.lastname) ==
            undefined && item.active != false
        );
      });
      console.log("this.availableForemen1")
      console.log(this.availableForemen);
      console.log("this.availableForemen2")
    } else {
      this.availableForemen = [];
    }
  }



  public async getPersonnelList() {
    await personnel
      .getAllPersonnel(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.personnelList = JSON.parse(JSON.stringify(resp));
        });
      });   
  }

  public async testforunigue() {
  //  let x = new Date().toLocaleString()
  //  console.log(x)
  //  console.log("test32")
  }

  public async getEmployeesDurationsList2() {
     //AllNewProject
    await new_project
      .getAllEmployeesDurations2(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(async resp => {
          console.log(JSON.stringify(resp))
          console.log('resp')
          this.MAXEmployeesDurationsList = JSON.parse(JSON.stringify(resp));
           console.log(JSON.stringify(this.MAXEmployeesDurationsList))
           console.log('this.newProjectEmployeesDurationsList111')
          
        });
      });
  }

public async addToDBDuration(){
  console.log(this.temp2)
  console.log('this.temp2')
  await this.deletenpdurations(this.temp2)
  console.log(this.MAXEmployeesDurationsList.length)
   console.log('this.MAXEmployeesDurationsList.length')
if (this.MAXEmployeesDurationsList[0].max == null){
  this.unique_id=10
  }else{
   let temp1 = parseInt(this.MAXEmployeesDurationsList[0].max) 
this.unique_id= temp1+1

}
 
  this.temp = 0
  this.temp3 = 0
  this.valid = true;
for( const i in this.techs ) {
//

let starthour = this.techs[i].start.split(":")[0];
let startminute = this.techs[i].start.split(":")[1];
let endhour = this.techs[i].end.split(":")[0];
let endminute = this.techs[i].end.split(":")[1];

let startmech_hours = this.techs[i].mech_hours.toString().split(".")[0];
let endmech_hours = this.techs[i].mech_hours.toString().split(".")[1];
let startelec_hours= this.techs[i].elec_hours.toString().split(".")[0];
let endelec_hours= this.techs[i].elec_hours.toString().split(".")[1];
let startgeneral_hours = this.techs[i].general_hours.toString().split(".")[0];
let endgeneral_hours = this.techs[i].general_hours.toString().split(".")[1];
let startcontrol_hours = this.techs[i].control_hours.toString().split(".")[0];
let endcontrol_hours = this.techs[i].control_hours.toString().split(".")[1];
let startit_hours = this.techs[i].it_hours.toString().split(".")[0]
let endit_hours = this.techs[i].it_hours.toString().split(".")[1];
let startpm_hours = this.techs[i].pm_hours.toString().split(".")[0]
let endpm_hours = this.techs[i].pm_hours.toString().split(".")[1];
let startps_hours = this.techs[i].ps_hours.toString().split(".")[0]
let endps_hours = this.techs[i].ps_hours.toString().split(".")[1];
let startadmin_hours = this.techs[i].admin_hours.toString().split(".")[0]
let endadmin_hours = this.techs[i].admin_hours.toString().split(".")[1];
 if (starthour < 0 || starthour > 23) {
            this.valid = false;
            this.$message.error("Invalid Start Hours");
          }
          if (this.valid && (endhour < 0 || endhour > 23)) {
            this.valid = false;
            this.$message.error("Invalid End Hours");
          }
          if (this.valid && (startminute < 0 || startminute > 59)) {
            this.valid = false;
            this.$message.error("Invalid Start Minutes");
          }
          if (this.valid && (endminute < 0 || endminute > 59)) {
            this.valid = false;
            this.$message.error("Invalid End Minutes");
          }
          if (this.valid && startminute % 15 != 0) {
              this.valid = false;             
              this.$message.error("Valid Start Minutes are: 0,15,30,45");
          }
          if (this.valid && endminute % 15 != 0) {
              this.valid = false;              
              this.$message.error("Valid End Minutes are: 0,15,30,45");
          }
        console.log(startmech_hours)
        console.log(typeof(startmech_hours))
        console.log(endmech_hours)
        console.log(typeof(endmech_hours))
        console.log("endmech_hours")
          if ((this.valid && startmech_hours != 0  && endmech_hours != undefined && this.valid  && endmech_hours != 0 && endmech_hours != 25 && endmech_hours != 50 && endmech_hours != 5 && endmech_hours != 75 )) {
              this.valid = false;              
              this.$message.error("Valid End Minutes are: 0,25,50,75");
          }
          if ((this.valid && startelec_hours != 0  && endelec_hours != undefined && this.valid  && endelec_hours != 0 && endelec_hours != 25 && endelec_hours != 50 && endmech_hours != 5 && endelec_hours != 75 )) {
              this.valid = false;              
              this.$message.error("Valid End Minutes are: 0,25,50,75");
          }
           if ((this.valid && startgeneral_hours != 0  && endgeneral_hours != undefined && this.valid  && endgeneral_hours != 0 && endgeneral_hours != 25 && endgeneral_hours != 50 && endmech_hours != 5 &&  endgeneral_hours != 75 )) {
              this.valid = false;              
              this.$message.error("Valid End Minutes are: 0,25,50,75");
          }
          if ((this.valid && startcontrol_hours != 0  && endcontrol_hours != undefined && this.valid  && endcontrol_hours != 0 && endcontrol_hours != 25 && endcontrol_hours != 50 && endmech_hours != 5 &&  endcontrol_hours != 75 )) {
              this.valid = false;              
              this.$message.error("Valid End Minutes are: 0,25,50,75");
          }
           if ((this.valid && startit_hours != 0  && endit_hours != undefined && this.valid  && endit_hours != 0 && endit_hours != 25 && endit_hours != 50 && endmech_hours != 5 &&  endit_hours != 75 )) {
              this.valid = false;              
              this.$message.error("Valid End Minutes are: 0,25,50,75");
          }
          if ((this.valid && startpm_hours != 0  && endpm_hours != undefined && this.valid  && endpm_hours != 0 && endpm_hours != 25 && endpm_hours != 50 && endmech_hours != 5 && endpm_hours != 75 )) {
              this.valid = false;              
              this.$message.error("Valid End Minutes are: 0,25,50,75");
          }
          if ((this.valid && startps_hours != 0  && endps_hours != undefined && this.valid  && endps_hours != 0 && endps_hours != 25 && endps_hours != 50 && endmech_hours != 5 && endps_hours != 75 )) {
              this.valid = false;              
              this.$message.error("Valid End Minutes are: 0,25,50,75");
          }
           if ((this.valid && startadmin_hours != 0  && endadmin_hours!= undefined && this.valid  && endadmin_hours != 0 && endadmin_hours != 25 && endadmin_hours != 50 && endmech_hours != 5 && endadmin_hours != 75 )) {
              this.valid = false;              
              this.$message.error("Valid End Minutes are: 0,25,50,75");
          }

//
let dateMills1 = this.techs[i].start_date;
let x = moment(dateMills1).format("YYYY-MM-DD")
let timeMills1 = this.techs[i].start;
var target1 = new Date( x+' '+timeMills1+":00");
console.log(target1)
console.log('target1')
let dateMills2 = this.techs[i].end_date;
let y = moment(dateMills2).format("YYYY-MM-DD")
let timeMills2 = this.techs[i].end;
var target2 = new Date( y+' '+timeMills2+":00");


let a = Moment(target1).format("YYYY/MM/DD")
a=a.replace("/","-")
a=a.replace("/","-")
let b = Moment(target2).format("YYYY/MM/DD")
b=b.replace("/","-")
b=b.replace("/","-")

console.log('aaaaaa--->'+a)

console.log('bbbbbb--->'+b)
moment(moment(a).diff(b)).format()
var diffTime = moment(moment(1390310146.791877).diff( 1390309386.271075)).format('H m s')
console.log('diffTime----->'+diffTime)

if(a <= b){
this.temp = 1
}else{
this.temp = 2
}
//this.tempsum = (isNaN(parseFloat(this.techs[i].mech_hours))?parseFloat(this.techs[i].mech_hours)*60:Number(this.techs[i].mech_hours))+(isNaN(parseFloat(this.techs[i].elec_hours))?parseFloat(this.techs[i].elec_hours)*60:Number(this.techs[i].elec_hours))+(isNaN(parseFloat(this.techs[i].general_hours))?parseFloat(this.techs[i].general_hours)*60:Number(this.techs[i].general_hours))+(isNaN(parseFloat(this.techs[i].control_hours))?parseFloat(this.techs[i].control_hours)*60:Number(this.techs[i].control_hours))+(isNaN(parseFloat(this.techs[i].it_hours))?parseFloat(this.techs[i].it_hours)*60:Number(this.techs[i].it_hours))+(isNaN(parseFloat(this.techs[i].pm_hours))?parseFloat(this.techs[i].pm_hours)*60:Number(this.techs[i].pm_hours))+(isNaN(parseFloat(this.techs[i].ps_hours))?parseFloat(this.techs[i].ps_hours)*60:Number(this.techs[i].ps_hours))+(isNaN(parseFloat(this.techs[i].admin_hours))?parseFloat(this.techs[i].admin_hours)*60:Number(this.techs[i].admin_hours));

this.tempsum = (isNaN(parseFloat(this.techs[i].mech_hours))?Number(this.techs[i].mech_hours):parseFloat(this.techs[i].mech_hours)*60)+(isNaN(parseFloat(this.techs[i].elec_hours))?Number(this.techs[i].elec_hours):parseFloat(this.techs[i].elec_hours)*60)+(isNaN(parseFloat(this.techs[i].general_hours))?Number(this.techs[i].general_hours):parseFloat(this.techs[i].general_hours)*60)+(isNaN(parseFloat(this.techs[i].control_hours))?Number(this.techs[i].control_hours):parseFloat(this.techs[i].control_hours)*60)+(isNaN(parseFloat(this.techs[i].it_hours))?Number(this.techs[i].it_hours):parseFloat(this.techs[i].it_hours)*60)+(isNaN(parseFloat(this.techs[i].pm_hours))?Number(this.techs[i].pm_hours):parseFloat(this.techs[i].pm_hours)*60)+(isNaN(parseFloat(this.techs[i].ps_hours))?Number(this.techs[i].ps_hours):parseFloat(this.techs[i].ps_hours)*60)+(isNaN(parseFloat(this.techs[i].admin_hours))?Number(this.techs[i].admin_hours):parseFloat(this.techs[i].admin_hours)*60)
console.log('AYTO EINAI TO ATHRISMA TWN WRWN-------->'+this.tempsum)

var now  = target2;
var then = target1;
// var ms = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(then,"DD/MM/YYYY HH:mm:ss"));
// var d = moment.duration(ms);
// var s = Math.floor(d.asHours());
// // + moment.utc(ms).format(":mm:ss")

var startTime = new Date(then); 
var endTime = new Date(now);
var difference = endTime.getTime() - startTime.getTime();  
var s = Math.round(difference / 60000);
console.log("resultInMinutes---->"+s)

console.log(typeof s)
console.log('AYTO EINAI H DIAFORA TWN DYO IMEROMINIWN-------->'+s)

if (this.tempsum == s){ 
this.temp3 = 1
}else{
this.temp3 = 2
}
}

if(this.temp == 1 && this.temp3 == 1 && this.valid != false){
   for( const i in this.techs ) {
     
let dateMills1 = this.techs[i].start_date;
let x = moment(dateMills1).format("YYYY-MM-DD")
let timeMills1 = this.techs[i].start;
var target1 = new Date( x+' '+timeMills1+":00");
console.log('target1111------>'+target1)

let dateMills2 = this.techs[i].end_date;
let y = moment(dateMills2).format("YYYY-MM-DD")
let timeMills2 = this.techs[i].end;
var target2 = new Date( y+' '+timeMills2+":00");
console.log('target222------>'+target2)




// let a = Moment(target1).format("YYYY/MM/DD")
// a=a.replace("/","-")
// a=a.replace("/","-")
// let b = Moment(target2).format("YYYY/MM/DD")
// b=b.replace("/","-")
// b=b.replace("/","-")

// if(a <= b){

let yy = this.techs[i].xx
//moment(p.start_date).tz("Europe/Athens").format("DD/MM/YYYY"),
    let projectdurationItem: any[] = [
        {
          id: undefined ,
          project_id: this.titleselected,
          personnel_id: isNaN(this.techs[i].value)
            ? this.personnelList.find(
                r =>
                  r.firstname == this.techs[i].value.split(" ")[0] &&
                  r.lastname == this.techs[i].value.split(" ")[1]
              ).id
            : this.techs[i].value ,  
          start_date: target1, 
          end_date: target2,
          unique_id: this.unique_id,
          mech_hours:isNaN(this.techs[i].mech_hours)?parseFloat(this.techs[i].mech_hours):this.techs[i].mech_hours,
          elec_hours:isNaN(this.techs[i].elec_hours)?parseFloat(this.techs[i].elec_hours):this.techs[i].elec_hours,
          general_hours:isNaN(this.techs[i].general_hours)?parseFloat(this.techs[i].general_hours):this.techs[i].general_hours,
          control_hours:isNaN(this.techs[i].control_hours)?parseFloat(this.techs[i].control_hours):this.techs[i].control_hours,
          it_hours:isNaN(this.techs[i].it_hours)?parseFloat(this.techs[i].it_hours):this.techs[i].it_hours,
          pm_hours:isNaN(this.techs[i].pm_hours)?parseFloat(this.techs[i].pm_hours):this.techs[i].pm_hours,
          ps_hours:isNaN(this.techs[i].ps_hours)?parseFloat(this.techs[i].ps_hours):this.techs[i].ps_hours,
          admin_hours:isNaN(this.techs[i].admin_hours)?parseFloat(this.techs[i].admin_hours):this.techs[i].admin_hours

        }
    ];
 
    console.log("Thisis what iwant to push to ppm_personnel2");
    console.log(JSON.stringify(projectdurationItem));

    let re = await new_project.addnewProjectPersonnel2(this.$store.getters.getToken, projectdurationItem, this.$store.getters.getHost)
    //addPPMPersonnel2
    if (re.status == 400) {
      this.$message.error(re.error);
    } else {
      console.log("THIS IS THE POST RESPONSE")
      console.log(JSON.stringify(re))
    }
     this.$router.replace({
          name: "load"
        });

        
  }
this.unique_id= ""
this.tempsum=0
  }else{
 this.$notify.error({
          title: 'Error',
          message: 'Incorrect Duration'
        });

}


//getEmployeesDurationsList2() 
}


//  old public async addToDBDuration(){
//   console.log(this.temp2)
//   console.log('this.temp2')
//   await this.deletenpdurations(this.temp2)
//   console.log(this.MAXEmployeesDurationsList.length)
//    console.log('this.MAXEmployeesDurationsList.length')
// if (this.MAXEmployeesDurationsList[0].max == null){
  
// this.unique_id=10
//   console.log('test1')
//   console.log(this.unique_id)
//   console.log('test1')
// }else{
//     console.log('test2')
//     console.log(this.MAXEmployeesDurationsList[0].max)
//     console.log('test2')
// let temp1 = parseInt(this.MAXEmployeesDurationsList[0].max) 
// console.log('test3')
// console.log(temp1)
// console.log('test3')
// this.unique_id= temp1+1
// console.log('test4')
// console.log(this.unique_id)
// console.log('test4')
 
// }

// //  let a = Moment(this.leave_on_end[0].leave_on).format("YYYY/MM/DD")
// //     a=a.replace("/","-")
// //     a=a.replace("/","-")
// //     //  console.log('replaced a'+a)
// //     let b = Moment(this.leave_on_end[0].leave_end).format("YYYY/MM/DD")
// //     b=b.replace("/","-")
// //     b=b.replace("/","-")
// //     //  console.log('replaced b'+b)
// //     let c = Moment(this.ReturnOn).format("YYYY/MM/DD")
// //     c=c.replace("/","-")
// //     c=c.replace("/","-")
// //     // console.log('replaced c'+c)
// //     if((a <= b)&&(a < c )&& (b<c)){
//   this.temp = 0
//   this.temp3 = 0
// for( const i in this.techs ) {
// let dateMills1 = this.techs[i].start_date;
// let x = moment(dateMills1).format("YYYY-MM-DD")
// let timeMills1 = this.techs[i].start;
// var target1 = new Date( x+' '+timeMills1+":00");
// console.log(target1)
// console.log('target1')
// let dateMills2 = this.techs[i].end_date;
// let y = moment(dateMills2).format("YYYY-MM-DD")
// let timeMills2 = this.techs[i].end;
// var target2 = new Date( y+' '+timeMills2+":00");


// let a = Moment(target1).format("YYYY/MM/DD")
// a=a.replace("/","-")
// a=a.replace("/","-")
// let b = Moment(target2).format("YYYY/MM/DD")
// b=b.replace("/","-")
// b=b.replace("/","-")

// console.log('aaaaaa--->'+a)

// console.log('bbbbbb--->'+b)
// moment(moment(a).diff(b)).format()
// var diffTime = moment(moment(1390310146.791877).diff( 1390309386.271075)).format('H m s')
// console.log(diffTime)
 


// if(a <= b){
// this.temp = 1
// }else{
// this.temp = 2

// }

// console.log( typeof this.techs[i].mech_hours )
// console.log( typeof  Number( this.techs[i].mech_hours) )
// console.log( typeof this.techs[i].mech_hours )
// console.log( JSON.stringify(this.techs[i].mech_hours) )
// console.log('test')
// this.tempsum =  Number( this.techs[i].mech_hours ) +Number(  this.techs[i].elec_hours )+ Number( this.techs[i].general_hours )+Number(  this.techs[i].control_hours )+ Number( this.techs[i].it_hours )+Number(  this.techs[i].pm_hours )+Number(  this.techs[i].ps_hours )+Number(  this.techs[i].admin_hours) ;

// console.log('AYTO EINAI TO ATHRISMA TWN WRWN-------->'+this.tempsum)

// var now  = target2;
// var then = target1;
// var ms = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(then,"DD/MM/YYYY HH:mm:ss"));
// var d = moment.duration(ms);
// var s = Math.floor(d.asHours());
// // + moment.utc(ms).format(":mm:ss")

// console.log(typeof s)
// console.log('AYTO EINAI H DIAFORA TWN DYO IMEROMINIWN-------->'+s)

// if (this.tempsum == s){ 
// this.temp3 = 1
// }else{
// this.temp3 = 2
// }
// }
// /// mexri edw h siggrish na
// // if(this.temp == 1 && this.temp3 == 1 ){
//    for( const i in this.techs ) {
// console.log('iiiiiiiiiiiiiii1')
// console.log(i)
// console.log(this.techs[i])
// console.log('iiiiiiiiiiiiiii2')

     
// let dateMills1 = this.techs[i].start_date;
// let x = moment(dateMills1).format("YYYY-MM-DD")
// let timeMills1 = this.techs[i].start;
// var target1 = new Date( x+' '+timeMills1+":00");
// console.log('target1111------>'+target1)

// let dateMills2 = this.techs[i].end_date;
// let y = moment(dateMills2).format("YYYY-MM-DD")
// let timeMills2 = this.techs[i].end;
// var target2 = new Date( y+' '+timeMills2+":00");
// console.log('target222------>'+target2)




// // let a = Moment(target1).format("YYYY/MM/DD")
// // a=a.replace("/","-")
// // a=a.replace("/","-")
// // let b = Moment(target2).format("YYYY/MM/DD")
// // b=b.replace("/","-")
// // b=b.replace("/","-")

// // if(a <= b){

// let yy = this.techs[i].xx
// console.log(yy)

// //moment(p.start_date).tz("Europe/Athens").format("DD/MM/YYYY"),
//     let projectdurationItem: any[] = [
//         {
//           id: undefined ,
//           project_id: this.titleselected,
//           personnel_id: isNaN(this.techs[i].value)
//             ? this.personnelList.find(
//                 r =>
//                   r.firstname == this.techs[i].value.split(" ")[0] &&
//                   r.lastname == this.techs[i].value.split(" ")[1]
//               ).id
//             : this.techs[i].value ,  
//           start_date: target1, 
//           end_date: target2,
//           unique_id: this.unique_id,
//           mech_hours:this.techs[i].mech_hours,
//           elec_hours:this.techs[i].elec_hours,
//           general_hours:this.techs[i].general_hours,
//           control_hours:this.techs[i].control_hours,
//           it_hours:this.techs[i].it_hours,
//           pm_hours:this.techs[i].pm_hours,
//           ps_hours:this.techs[i].ps_hours,
//           admin_hours:this.techs[i].admin_hours 

//         }
//     ];
 
//     console.log("Thisis what iwant to push to ppm_personnel2");
//     console.log(JSON.stringify(projectdurationItem));

//     let re = await new_project.addnewProjectPersonnel2(this.$store.getters.getToken, projectdurationItem, this.$store.getters.getHost)
//     //addPPMPersonnel2
//     if (re.status == 400) {
//       this.$message.error(re.error);
//     } else {
//       console.log("THIS IS THE POST RESPONSE")
//       console.log(JSON.stringify(re))
//     }
//      this.$router.replace({
//           name: "load"
//         });

        
//   }
// this.unique_id= ""
// this.tempsum=0
// //   }else{
// //  this.$notify.error({
// //           title: 'Error',
// //           message: 'Incorrect Duration'
// //         });

// // }


// //getEmployeesDurationsList2() 
// }




public async deletenpdurations( row: any) {
    await new_project
      .deletenpdurations(
        this.$store.getters.getHost,
        this.$store.getters.getToken,
        row.unique_id,
        
      )
      .then(async () => {
          this.$message.success("Project Added");
          this.$router.replace({
            name: "load"
          });
      });

console.log( row.unique_id)
console.log( 'row.unique_id11111')

  }

public async clear2(){
  this.techs = [];
  this.yearlyComponent = "";
  this.yearlyActions = "";
  this.selectedHall = "";
  this.belt = "";
  this.beltLine = [];
  this.senTech = "";
  this.foreman = "";
  this.maintenancePeriod=""
  this.selectedTech=""
  this.workOrders = []
  this.selectedWorkOrder =""
  this.selectedWorkOrderNew = ''
  this.jsonRemarks=[]
  this.yearlyRemarks="" 
  this.status={ enddate: "", selectedStatus: "" } 
  this.selectedSubpanels = [];
  console.log("clear")

}

public async clearBelowHall(){
  this.belt = "";
  this.selectedSubpanels = [];
}

public async clearBelowElectricalPanels(){
  this.selectedSubpanels = [];
}


    



 public async checkEdit() {
 
    
    this.row = this.$store.getters.getCurrentObject; 
    console.log(JSON.stringify(this.row))
    console.log('this.row123')
      if(this.row &&this.row.data && this.row.data.length){
     this.temp2 =this.row.data[0]
   }
    console.log(this.row.data)
    console.log(JSON.stringify(this.row))
    console.log('this.row')
     if(this.row.data){
     

    for(let x of this.row.data){
      console.log(JSON.stringify(this.row.data))
      console.log('this.row.data')
    this.titleselected = x.project_id
  
    
    //this.row.id = x.id    
    x.datestart =  moment.utc(x.start_date).format("YYYY-MM-DD") 
    x.timestart = moment.utc(x.start_date).format("HH:mm")  
    x.dateend = moment.utc(x.end_date).format("YYYY-MM-DD")  
    x.timeend = moment.utc(x.end_date).format("HH:mm") 
 
 
    
    this.techs.push({xx:x.id ,id:x.personnel_id2 , value:x.personnel_id , start_date:x.datestart, end_date:x.dateend, start:x.timestart,end:x.timeend, mech_hours: x.mech_hours, elec_hours: x.elec_hours, general_hours: x.general_hours ,control_hours: x.control_hours , it_hours : x.it_hours , pm_hours : x.pm_hours , ps_hours: x.ps_hours, admin_hours :x.admin_hours})
     

    }


     }
  }




public destroyed(){
  this.$store.dispatch("updateCurrentObject", {});
}


 public async getActivities() {
    await ppm
      .getMaintenanceActivities(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then((resp: any) => {
          console.log(JSON.stringify(resp))
          console.log('testforyearlyppm1')
          let i = 0;
          while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
            this.activities.push({
              value: JSON.parse(JSON.stringify(resp))[i].id,
              label: JSON.parse(JSON.stringify(resp))[i].literal
            });
            i++;
          }
        });
      });
      console.log('testforyearlyppm')
  }



   

 public async getSenTechList() {
   console.log(new Date().toLocaleTimeString()+'1')
    await aiaPersonnel
      .getAllPersonnel(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        console.log(new Date().toLocaleTimeString()+'2')
        response.json().then(resp => {
          console.log(new Date().toLocaleTimeString()+'3')
          this.senTechList = JSON.parse(JSON.stringify(resp));
          for (const p of this.senTechList) {
            this.senTechs.push({
              label: p.firstname + " " + p.lastname,
              value: p.id,
              team: p.team
            });
          }
          console.log(new Date().toLocaleTimeString()+'4')
          console.log(this.senTechs);
         
        });
      });
  }


 public clearSearch() {
    this.availableSenTechs = [];
  }
public clearSearch2() {
this.availableComponents = [];
}
 

    public senTechMethod(query: string) {
    if (query != "" && query != undefined) {
      this.loading = true;
      this.loading = false;
      this.availableSenTechs = this.senTechs.filter(item => {
        return (
          item.label.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          item.team == query
        );
      });
    } else {
      this.availableSenTechs = [];
    }
  }

}

</script>

<style scoped>
.tableCell {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 220px;
  position: relative;
  height: 30px;
}
.longTableCell {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 400px;
  position: relative;
  height: 30px;
}
.superLongTableCell {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 100%;
  position: relative;
  height: 30px;
}
.superLongTableCellFixed {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 550px;
  position: relative;
  height: 30px;
}
.shortTableCell {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 100px;
  position: relative;
  height: 30px;
}
.midTableCell {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 120px;
  position: relative;
  height: 30px;
}
.tableTag {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 100px;
  height: 30px;
}
.yellowTableTag {
  background-color: rgb(253, 255, 136);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 100px;
  height: 30px;
}
.dateTableCell {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 125px;
  position: relative;
  height: 30px;
}
.dateTableCell2 {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 67px;
  position: relative;
  height: 30px;
}
.dateTableCell3 {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 90px;
  position: relative;
  height: 30px;
}
.tagText {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.notATextbox {
  width: 100%;
  padding-left: 5px;
  background-color: rgb(226, 226, 226);
  border: 1px solid rgb(0, 0, 0);
  font-size: 9pt !important;
  overflow-wrap: break-word;
}
.white {
  color: white;
}
</style>