<template>
  <div>
    <h1>Change Password</h1>
    <br>
    <el-input v-model="newpass" placeholder="new password"></el-input>
    <br>
    <el-button @click="change">Change</el-button>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { users } from "../api/users/users";
@Component({
  name: "ChangePassword"
})
export default class ChangePassword extends Vue {
  public newpass: string = "";
  public async change() {
    await users.changePassword(
      this.$store.getters.getToken,
      this.newpass,
      this.$store.getters.getHost
    );
    this.$store.dispatch("updateCurrentComponent", "Logout");
    this.$router.replace({
      name: "load"
    });
  }
}
</script>
<style>
</style>