<template>
  <div>Loading</div>
</template>
<script lang="ts">
import { personnel } from "../api/personnel/personnel";
import { Company } from "../api/personnel/company";
import { Specialty } from "../api/personnel/specialty";
import { component } from "../api/component/component";
import { group } from "../api/group/group";
import { WOSpecialty } from "../api/personnel/wospecialty";
import { status } from "../api/status/status";
import { workorder } from "../api/workorder/workorder";
import { ppm } from "../api/ppm/ppm";
import Vue from "vue";
import Component from "vue-class-component";
@Component({
  name: "Load"
})
export default class Load extends Vue {
  public async getPersonnelList() {
    await personnel
      .getAllPersonnel(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.$store.dispatch(
            "updatePersonnelList",
            JSON.parse(JSON.stringify(resp))
          );
        });
      });
  }
  public async getCompanyList() {
    await Company.getAllCompanies(
      this.$store.getters.getToken,
      this.$store.getters.getHost
    ).then(response => {
      response.json().then(resp => {
        this.$store.dispatch(
          "updateCompanyList",
          JSON.parse(JSON.stringify(resp))
        );
      });
    });
  }
  public async getWorkOrderList() {
    await workorder
      .getAllWorkOrders(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.$store.dispatch(
            "updateWorkOrderList",
            JSON.parse(JSON.stringify(resp))
          );
        });
      });
  }
  public async getSpecialtyList() {
    await Specialty.getAllSpecialties(
      this.$store.getters.getToken,
      this.$store.getters.getHost
    ).then(response => {
      response.json().then(resp => {
        this.$store.dispatch(
          "updateSpecialtyList",
          JSON.parse(JSON.stringify(resp))
        );
      });
    });
  }
  public async getWOSpecialtyList() {
    await WOSpecialty.getAllWOSpecialties(
      this.$store.getters.getToken,
      this.$store.getters.getHost
    ).then(response => {
      response.json().then(resp => {
        this.$store.dispatch(
          "updateWOSpecialtyList",
          JSON.parse(JSON.stringify(resp))
        );
      });
    });
  }
  public async getComponentList() {
    await component
      .getAllComponents(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.$store.dispatch(
            "updateComponentList",
            JSON.parse(JSON.stringify(resp))
          );
        });
      });
  }
  public async getGroupList() {
    await group
      .getAllGroups(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then(resp => {
          this.$store.dispatch(
            "updateGroupList",
            JSON.parse(JSON.stringify(resp))
          );
        });
      });
  }
  public async getStatusList() {
    await status
      .getAllStatuses(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then(resp => {
          this.$store.dispatch(
            "updateStatusList",
            JSON.parse(JSON.stringify(resp))
          );
        });
      });
  }
  public async getWOTypeList() {
    await workorder
      .getAllTypes(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then(resp => {
          this.$store.dispatch(
            "updateWorkOrderTypeList",
            JSON.parse(JSON.stringify(resp))
          );
        });
      });
  }
  public async getMaintenancePeriodicities() {
    await ppm
      .getMaintenancePeriodicities(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.$store.dispatch(
            "updateMaintenancePeriodicities",
            JSON.parse(JSON.stringify(resp))
          );
        });
      });
  }
  public async getPPMList() {
    await ppm
      .getAllPPMs(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then(resp => {
          this.$store.dispatch(
            "updatePPMList",
            JSON.parse(JSON.stringify(resp))
          );
        });
      });
  }
  public async load() {
    console.log(this.$store.getters.getUserName);
    await this.getCompanyList().then(r =>
      this.getPersonnelList().then(r =>
        this.getSpecialtyList().then(r =>
          this.getComponentList().then(r =>
            this.getGroupList().then(r =>
              this.getWOSpecialtyList().then(r =>
                this.getStatusList().then(r =>
                  this.getWOTypeList().then(r =>
                    this.getWorkOrderList().then(r =>
                      this.getMaintenancePeriodicities().then(r =>
                        this.getPPMList()
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    );
  }
  public async mounted(): Promise<void> {
    this.$router.replace({ name: "menu" });
    var highestTimeoutId = setTimeout(";");
    for (var i = 0; i < highestTimeoutId; i++) {
      clearTimeout(i);
    }
  }

  public print() {
    console.log(this.$store.getters.getCompanyList);
    console.log(this.$store.getters.getPersonnelList);
    console.log(this.$store.getters.getSpecialtyList);
    console.log(this.$store.getters.getComponentList);
    console.log(this.$store.getters.getGroupList);
    console.log(this.$store.getters.getWOSpecialtyList);
  }
}
</script>