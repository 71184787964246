<template>
  <div>
    <el-collapse accordion v-model="activeName">
      <el-collapse-item title="Project Filters" name="1">
        <div class="container">
              <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>Sector:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-select
                size="mini"
                :style="{width:'200px'}"
                v-model="$props.filters.sector"
                placeholder="Sector"
                clearable
              >
                <el-option
                  v-for="item in sectorsList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
            <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>Place:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-select
                size="mini"
                :style="{width:'200px'}"
                v-model="$props.filters.place"
                placeholder="Place"
                clearable
              >
                <el-option
                  v-for="item in placesList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
            <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>Project Title:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-select
                size="mini"
                :style="{width:'200px'}"
                v-model="$props.filters.project_title"
                placeholder="Project Title"
                clearable
              >
                <el-option
                  v-for="item in titlesList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>From Date:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-date-picker
                v-model="date"
                v-on:change="update"
                type="date"
                placeholder="From Date"
                format="dd/MM/yyyy"
                size="mini"
                :style="{width:'200px'}"
                clearable
              ></el-date-picker>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>To Date:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-date-picker
                v-model="$props.filters.toDate"
                type="date"
                placeholder="To Date"
                format="dd/MM/yyyy"
                size="mini"
                :style="{width:'200px'}"
                clearable
              ></el-date-picker>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>Status:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-select
                size="mini"
                :style="{width:'200px'}"
                v-model="$props.filters.status"
                placeholder="Status"
                clearable
              >
                <el-option
                  v-for="item in statuses"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
           
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script lang="ts">
import ComponentSelector from "../Selectors/ComponentSelector.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { ppm } from "../../api/ppm/ppm";
import { group } from "../../api/group/group";
import { component } from "../../api/component/component";
import { status } from "../../api/status/status";
import { new_project } from "../../api/new_project/new_project"
import Moment from "moment";

@Component({
  name: "PPMFilters2",
  props: {
    filters: Object
    // {
    //   sector: "",
    //   place: "",
    //   project_title: "",
    //   fromDate: "",
    //   toDate: "",     
    //   status: "",
    // }
  },
  model: {
    prop: "filters",
    event: "change"
  },
   watch: {
    date: function(val) {
      this.$props.filters.fromDate = val;
    }
  }
})
export default class WorkOrderFilters extends Vue {
  public sectorsList: any[] = [];
  public placesList: any[] = [];
  public titlesList: any[] = [];

  public loading: boolean = false;
  public activeName: any = "1";
  public systems: any[] = [];
  public componentList: any[] = [];
  public visibleComponents: any[] = [];
  public statuses: any[] = [];
  public maintenancePeriodOptions: any[] = [];
  public maintenancePeriod: any = ''; 
  public date: Date = new Date();
   public update() {
    this.$forceUpdate();
  }
  public async mounted() {
    this.loading = true;

    await this.getSectorsList();
    await this.getProjectPlacesList();
    await this.getProjectTitlesList();

    //this.maintenancePeriodYearOptions();
    //await this.getGroupList();
    //await this.getComponentList();
     await this.getStatusList();
    this.$props.filters.fromDate = new Date(
      new Date().setDate(new Date().getDate() - 10)
    );
    this.date = new Date(new Date().setDate(new Date().getDate() - 10));
    this.loading = false;
  }
 public async getSectorsList(){
    await new_project
    .getSectors(
    this.$store.getters.getToken,
    this.$store.getters.getHost
    )
    .then(response => {
    response.json().then(resp => {
    let i = 0;
    while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
      this.sectorsList.push({
        value: JSON.parse(JSON.stringify(resp))[i].id,
        label: JSON.parse(JSON.stringify(resp))[i].literal
        });
         i++;
       }
       console.log('testforresponse--------->'+resp)
      });
    });   
  }
  public async getProjectPlacesList(){
    await new_project
    .getPlaces(
    this.$store.getters.getToken,
    this.$store.getters.getHost
    )
    .then(response => {
    response.json().then(resp => {
    let i = 0;
    while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
      this.placesList.push({
        value: JSON.parse(JSON.stringify(resp))[i].id,
        label: JSON.parse(JSON.stringify(resp))[i].literal
        });
         i++;
       }
       console.log('testforprojectplaces-------->'+JSON.stringify(resp))
      });
    });   
  }
public async getProjectTitlesList(){
    await new_project
    .getProjectTitles(
    this.$store.getters.getToken,
    this.$store.getters.getHost
    )
    .then(response => {
    response.json().then(resp => {
    let i = 0;
    while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
      this.titlesList.push({
        value: JSON.parse(JSON.stringify(resp))[i].id,
        label: JSON.parse(JSON.stringify(resp))[i].literal
        });
         i++;
       }
       console.log('testforprojectplaces-------->'+JSON.stringify(resp))
      });
    });   
  }
   public async getStatusList() {
    await status
      .getAllStatuses(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then(resp => {
          let i = 0;
          while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
            this.statuses.push({
              value: JSON.parse(JSON.stringify(resp))[i].id,
              label: JSON.parse(JSON.stringify(resp))[i].literal
            });
            i++;
          }
        });
      });
  }  




//   public async maintenancePeriodYearOptions(){
//     // ftiaxnw epiloges gia to maintenance period pedio
//     var currentYear = Moment(new Date()).format("YYYY");
//     var year = parseInt(currentYear);
//     var currentMonth = Moment(new Date()).format("MM")
//     //  this.currentMonth = Moment(new Date(2019,9,2)).format("MM")
//     var month = parseInt(currentMonth)
//     if(month <= 9){

//       year=year-1

//     }

//     do {
//       // year= year +"-"+year+1
//       console.log(typeof year)
//       console.log("typeofyear1")
//       console.log(year+1)
//       console.log("typeofyear2")
//       var yeartest2=year+1
//       let yeartest= year+"-"+yeartest2
//       this.maintenancePeriodOptions.push(yeartest);
//       year -= 1;
//     } while (2019 <= year);
//   }

//   public async getGroupList() {
//     await group
//       .getAllGroups(this.$store.getters.getToken, this.$store.getters.getHost)
//       .then(response => {
//         response.json().then(resp => {
//           let groupList = JSON.parse(JSON.stringify(resp));
//           for (const g of groupList) {
//             switch (g.group_number) {
//               case 2:
//                 this.systems.push({ value: g.id, label: g.literal });
//                 break;
//             }
//           }
//         });
//       });
//   }
  
  
//   public async getComponentList() {
//     await component
//       .getAllComponents(
//         this.$store.getters.getToken,
//         this.$store.getters.getHost
//       )
//       .then(response => {
//         response.json().then((resp: any) => {
//           this.componentList = JSON.parse(JSON.stringify(resp));
//         });
//       });
//   }

  
//   public remoteMethod(query: string) {
//     console.log(this.componentList);
//     if (query !== "") {
//       this.loading = true;
//       setTimeout(() => {
//         this.loading = false;
//         this.visibleComponents = this.componentList.filter(item =>
//           item.literal.toLowerCase().includes(query.toLowerCase())
//         );
//       }, 200);
//     } else {
//       this.visibleComponents = [];
//     }
//   }

}
</script>
<style>
.tag {
  background-color: rgb(201, 228, 238);
  color: black;
  width: 130px;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  padding: 2px 10px 2px 10px;
  font-size: 9pt;
  line-height: 20px;
}
strong {
  color: black;
}
.container {
  border: 5px solid rgb(152, 226, 226);
  border-radius: 10px;
  background-color: rgb(221, 218, 218);
  padding: 5px 5px 5px 5px;
}
.vdp-datepicker input {
  width: 75px;
  height: 20px;
}
</style>