<template>
  <div>
    <!-- nai -->
    <el-row class="container">
      <el-row>
        <h2>PPM Details</h2>
      </el-row>
      <el-row>
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>PPM Periodicity:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            v-model="periodicity"
            placeholder="PPM Periodicity"
            :items="periodicities"
            size="mini"
            @change="periodicityChange()"
            :style="{width:'250px'}"
            :disabled="existing"
          >
            <el-option-group v-for="group in periodicities" :key="group.label" :label="group.label">
              <el-option
                v-for="item in group.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-option-group>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Select System:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            v-Fdel="system"
            placeholder="Select System"
            :items="availableSystems"
            @change="receiveRemarks()"
            size="mini"
            :style="{width:'250px'}"
            :disabled="existing"
          >
            <el-option v-for="item in availableSystems" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row
        v-if="((periodicity==8&&(system=='Inductions'||system=='Sorter'|| system=='UPS'|| system=='IT and Automation' ||system=='Fire Shutters' ||system=='Retainer' ||system=='Air-Condition' ||system=='Electrical Panels'))||(periodicity==4&&system=='Electrical Panels')||(periodicity==4&&(system=='Fire Shutter and Ret'||system=='Reclaims')||(periodicity==7&&system!=''&&system!=undefined)))"
      >
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Select Hall:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            v-model="selectedHall"
            :style="{width:'250px'}"
            placeholder="Hall"
            :items="halls"
            clearable
            size="mini"
            @change="componentMethod('')"
          >
            <el-option v-for="item in halls" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row v-if="periodicity==8&&system=='Electrical Panels'">
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Select Electrical Panel:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            filterable
            v-model="belt"
            placeholder="Select Electrical Panel"
            :items="southPanels"
            size="mini"
            :style="{width:'250px'}"
            @change="componentMethod('')"
            v-if="selectedHall=='South'"
          >
            <el-option v-for="item in southPanels" :key="item" :label="item" :value="item"></el-option>
          </el-select>
          <el-select
            filterable
            v-model="belt"
            placeholder="Select Electrical Panel"
            :items="northPanels"
            size="mini"
            :style="{width:'250px'}"
            @change="componentMethod('')"
            v-if="selectedHall=='North'"
          >
            <el-option v-for="item in northPanels" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row v-if="system=='IT and Automation'&&periodicity!=7">
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Select Group:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            v-model="belt"
            :style="{width:'250px'}"
            placeholder="Group"
            :items="itGroups"
            clearable
            size="mini"
            @change="componentMethod('')"
          >
            <el-option v-for="item in itGroups" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row v-if="system=='IT and Automation'&&periodicity==7">
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Select Group:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            v-model="belt"
            :style="{width:'250px'}"
            placeholder="Group"
            :items="atrGroups"
            clearable
            size="mini"
            @change="componentMethod('')"
          >
            <el-option v-for="item in atrGroups" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row
        v-if="(system=='Inductions'||system=='Sorter'||system=='UPS')&&periodicity==8&&selectedHall!=''&&selectedHall!=undefined"
      >
        <el-col :span="4">
          <div class="tag">
            <strong v-if="system!='UPS'">Select Group:</strong>
            <strong v-if="system=='UPS'">Select Equipment:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <div v-if="system=='Sorter'">
            <div v-if="selectedHall=='North'">
              <el-select :style="{width:'250px'}" size="mini" v-model="belt">
                <el-option v-for="item in northSorter" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
            <div v-if="selectedHall=='South'">
              <el-select :style="{width:'250px'}" size="mini" v-model="belt">
                <el-option v-for="item in southSorter" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
          </div>
          <div v-if="system=='Inductions'">
            <div v-if="selectedHall=='North'">
              <el-select :style="{width:'250px'}" size="mini" v-model="belt">
                <el-option v-for="item in northInductions" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
            <div v-if="selectedHall=='South'">
              <el-select :style="{width:'250px'}" size="mini" v-model="belt">
                <el-option v-for="item in southInductions" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
          </div>
          <div v-if="system=='UPS'">
            <div v-if="selectedHall=='North'">
              <el-select :style="{width:'250px'}" size="mini" v-model="belt">
                <el-option v-for="item in northUps" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
            <div v-if="selectedHall=='South'">
              <el-select :style="{width:'250px'}" size="mini" v-model="belt">
                <el-option v-for="item in southUps" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row
        v-if="(periodicity==8||periodicity==6)&&system!='Scales'&&system!=''&&system!=undefined&&system!='Electrical Panels'&&system!='Sorter'&&system!='UPS'&&system!='Inductions'"
      >
        <el-col :span="4">
          <div class="tag">
            <strong>Select Component:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            v-model="yearlyComponent"
            filterable
            :style="{width:'250px'}"
            remote
            size="mini"
            clearable
            placeholder="Add Component"
            :remote-method="componentMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in availableComponents"
              :key="item.id"
              :label="item.literal"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row v-if="periodicity==4&&system=='Conveyors'">
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Select Conveyor Line:</strong>
          </div>
        </el-col>
        <el-col :span="7" align="left">
          <el-select
            filterable
            v-model="belt"
            placeholder="Select Conveyor Belt"
            :items="conveyorBelts"
            @change="componentMethod('')"
            size="mini"
            :style="{width:'250px'}"
          >
            <el-option v-for="item in conveyorBelts" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-col>
        <el-col :span="11" align="left">
          <el-select
            v-if="belt=='EA'||belt=='EK'"
            size="mini"
            v-model="beltLine"
            multiple
          >
            <el-option v-for="item in conveyorNumbers" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''">
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>AIA Sen. Tech:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            v-model="senTech"
            filterable
            :style="{width:'250px'}"
            remote
            size="mini"
            @change="clearSearch()"
            clearable
            placeholder="AIA Sen. Tech"
            :remote-method="senTechMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in availableSenTechs"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''">
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Foreman:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            v-model="foreman"
            filterable
            :style="{width:'250px'}"
            remote
            size="mini"
            clearable
            placeholder="Add Foreman"
            :remote-method="foremanMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in availableForemen"
              :key="item.value"
              :label="item.firstname + ' ' + item.lastname"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
    </el-row>
    <br>
    <!-- nai -->
    <el-row
      class="container"
      v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''"
    >
      <el-row>
        <h2>Personnel</h2>
      </el-row>
      <table>
        <tr>
          <th>
            <div class="tableCell">
              <strong class="tagText">Tech Name</strong>
            </div>
          </th>
          <th>
            <div class="dateTableCell">
              <strong class="tagText">Date</strong>
            </div>
          </th>
          <th>
            <div class="shortTableCell">
              <strong class="tagText">Time Started</strong>
            </div>
          </th>
          <th>
            <div class="shortTableCell">
              <strong class="tagText">Time Ended</strong>
            </div>
          </th>
          <th>
            <div
              class="shortTableCell"
              v-if="!(periodicity==4||periodicity==6||(periodicity==7&&system=='IT and Automation')||(periodicity==8&&system!='Electrical Panels')||system=='Detectors'||system=='Fire Panels')"
            >
              <strong class="tagText">Hall</strong>
            </div>
          </th>
          <th v-if="false">
            <div class="tableCell">
              <strong class="tagText">Executed Parts</strong>
            </div>
          </th>
        </tr>
        <!-- o pinakas tech name mazi me ta date -->
        <!-- 2 -->
        <tr v-for="(p,index) in techs" v-if="techs[index].id!=''">
          <th>
            <div>
              <el-select
                v-model="techs[index].id"
                filterable
                :style="{width:'220px'}"
                remote
                size="mini"
                @change="techUpdate(techs[index].id,index)"
                clearable
                placeholder="Add Technician"
                :remote-method="foremanMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in availableForemen"
                  :key="item.value"
                  :label="item.firstname + ' ' + item.lastname"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </th>
          <th>
            <div>
              <el-date-picker
                v-model="techs[index].date"
                type="date"
                placeholder="Date"
                size="mini"
                format="dd/MM/yyyy"
                :style="{width:'150px'}"
              ></el-date-picker>
            </div>
          </th>
          <th>
            <div>
              <el-input
                placeholder="00:00"
                v-on:input="startTimeChange(index)"
                v-model="techs[index].start"
                :style="{width:'100px'}"
                size="mini"
                maxlength="5"
                style="width:110px;"
              ></el-input>
            </div>
          </th>
          <th>
            <div>
              <el-input
                placeholder="00:00"
                v-on:input="endTimeChange(index)"
                v-model="techs[index].end"
                :style="{width:'100px'}"
                size="mini"
                maxlength="5"
                style="width:110px;"
              ></el-input>
            </div>
          </th>
          <th>
            <div
              v-if="!(periodicity==4||periodicity==6||(periodicity==7&&system=='IT and Automation')||(periodicity==8&&system!='Electrical Panels')||system=='Detectors'||system=='Fire Panels')"
            >
              <el-select
                v-model="techs[index].hall"
                :style="{width:'100px'}"
                placeholder="Hall"
                :items="halls"
                clearable
                size="mini"
              >
                <el-option v-for="item in halls" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
          </th>
          <th v-if="false">
            <div>
              <el-input
                placeholder="Parts"
                type="textarea"
                autosize
                v-model="techs[index].parts"
                size="mini"
              ></el-input>
            </div>
          </th>
        </tr>
        <!-- first -->
        <tr>
          <th>
            <div>
              <el-select
                v-model="selectedTech"
                filterable
                @change="pushPersonnel"
                :style="{width:'220px'}"
                remote
                size="mini"
                clearable
                placeholder="Add Technician"
                :remote-method="foremanMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in availableForemen"
                  :key="item.value"
                  :label="item.firstname + ' ' + item.lastname"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </th>
          <th>
            <div></div>
          </th>
          <th>
            <div></div>
          </th>
          <th>
            <div></div>
          </th>
          <th v-if="false">
            <div></div>
          </th>
        </tr>
      </table>
    </el-row>
    <br>
    <!-- nai -->
    <el-row
      class="container"
      v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''"
    >
      <el-row>
        <h2>Work Orders</h2>
      </el-row>
      <table>
        <tr>
          <th>
            <div class="tableCell">
              <strong class="tagText">Work Order ID</strong>
            </div>
          </th>
          <th v-if="workOrders.length>0">
            <div class="superLongTableCell">
              <strong class="tagText">Description</strong>
            </div>
          </th>
        </tr>
        <tr v-for="(p,index) in workOrders" v-if="workOrders[index].id!=''">
          <th>
            <div>
              <el-select
                v-model="workOrders[index].id"
                filterable
                :style="{width:'220px'}"
                remote
                size="mini"
                @change="WOUpdate(workOrders[index].id,index)"
                clearable
                placeholder="Add Work Order"
                :remote-method="workOrderMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in availableWorkOrders"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </th>
          <th class="notATextbox">
            <p style="text-align:left">{{p.description}}</p>
          </th>
        </tr>
        <tr>
          <th>
            <div>
              <el-select
                v-model="selectedWorkOrder"
                filterable
                @change="pushWorkOrder"
                :style="{width:'220px'}"
                remote
                size="mini"
                clearable
                placeholder="Add Work Order"
                :remote-method="workOrderMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in availableWorkOrders"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </th>
          <th>
            <div></div>
          </th>
        </tr>
      </table>
    </el-row>
    <br>
    <el-row class="container" v-if="(system=='Desks'||system=='Chutes')&&periodicity==2">
      <el-row>
        <h2>Current Maintenance Remarks</h2>
      </el-row>
      <el-row>
        <table>
          <tr>
            <th>
              <div class="tableCell">
                <strong class="tagText">{{system}}</strong>
              </div>
            </th>
            <th>
              <div class="superLongTableCellFixed">
                <strong class="tagText">Remarks</strong>
              </div>
            </th>
            <th>
              <div class="midTableCell">
                <strong class="tagText">Actions</strong>
              </div>
            </th>
          </tr>
          <tr v-for="(p,index) in components">
            <td>
              <el-select
                :style="{width:'220px'}"
                size="mini"
                filterable
                remote
                multiple
                :remote-method="componentMethod"
                v-model="p.id"
              >
                <el-option
                  v-for="item in availableComponents"
                  :key="item.id"
                  :label="item.literal"
                  :value="item.id"
                ></el-option>
              </el-select>
            </td>
            <td>
              <div>
                <el-input
                  placeholder="Remarks"
                  type="textarea"
                  autosize
                  v-model="components[index].remarks"
                  size="mini"
                ></el-input>
              </div>
            </td>
            <td>
              <div>
                <el-select
                  v-model="components[index].actions"
                  placeholder="Activity"
                  :items="activities"
                  size="mini"
                  :style="{width:'80px'}"
                >
                  <el-option
                    v-for="item in activities"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  :style="{margin: 'auto'}"
                  @click="removeGroup(p,'groupTable')"
                ></el-button>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <el-select
                :style="{width:'220px'}"
                size="mini"
                filterable
                remote
                @change="pushComponent"
                :remote-method="componentMethod"
                v-model="selectedComponent"
              >
                <el-option
                  v-for="item in availableComponents"
                  :key="item.id"
                  :label="item.literal"
                  :value="item.id"
                ></el-option>
              </el-select>
            </td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </el-row>
    </el-row>
    <!-- nai -->
    <el-row
      class="container"
      v-if="system=='Scales'||(system=='IT and Automation'&&periodicity==7)||(system=='Conveyors'&&periodicity==4)"
    >
      <el-row>
        <h2 v-if="system!='Conveyors'">Maintenance Remarks During PPM</h2>
        <h2 v-if="system=='Conveyors'">Current Maintenance Remarks</h2>
      </el-row>
      <el-row v-if="system!='Conveyors'">
        <table>
          <tr>
            <th>
              <div class="tableCell">
                <strong class="tagText">{{system}}</strong>
              </div>
            </th>
            <th>
              <div class="superLongTableCellFixed">
                <strong class="tagText">Remarks</strong>
              </div>
            </th>
            <th>
              <div class="midTableCell">
                <strong class="tagText">Actions</strong>
              </div>
            </th>
          </tr>
          <tr v-for="(p,index) in components">
            <th>
              <div>
                <el-select
                  v-model="components[index].id"
                  filterable
                  :style="{width:'220px'}"
                  remote
                  size="mini"
                  @change="componentUpdate(components[index].id,index)"
                  clearable
                  placeholder="Add Component"
                  :remote-method="componentMethod"
                  :loading="loading"
                >
                  <el-option
                    v-for="item in availableComponents"
                    :key="item.id"
                    :label="item.literal"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
            </th>
            <th>
              <div>
                <el-input
                  placeholder="Remarks"
                  type="textarea"
                  autosize
                  v-model="components[index].remarks"
                  size="mini"
                ></el-input>
              </div>
            </th>
            <th>
              <div>
                <el-select
                  v-model="components[index].actions"
                  placeholder="Activity"
                  :items="activities"
                  size="mini"
                  :style="{width:'80px'}"
                >
                  <el-option
                    v-for="item in activities"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  :style="{margin: 'auto'}"
                  @click="remove(p,'compos')"
                ></el-button>
              </div>
            </th>
          </tr>
          <tr>
            <th v-if="periodicity!=8||components.length<1||system=='Scales'">
              <div>
                <el-select
                  v-model="selectedComponent"
                  filterable
                  :style="{width:'220px'}"
                  remote
                  @change="pushComponent"
                  size="mini"
                  clearable
                  placeholder="Add Component"
                  :remote-method="componentMethod"
                  :loading="loading"
                >
                  <el-option
                    v-for="item in availableComponents"
                    :key="item.id"
                    :label="item.literal"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
            </th>
            <th>
              <div></div>
            </th>
            <th>
              <div></div>
            </th>
          </tr>
        </table>
      </el-row>
      <el-row v-if="system=='Conveyors'">
        <table>
          <tr>
            <th>
              <div class="tableCell">
                <strong class="tagText">{{system}}</strong>
              </div>
            </th>
            <th>
              <div class="superLongTableCellFixed">
                <strong class="tagText">Remarks</strong>
              </div>
            </th>
            <th>
              <div class="midTableCell">
                <strong class="tagText">Actions</strong>
              </div>
            </th>
          </tr>
          <tr v-for="(p,index) in components" v-if="checkConveyor(p)">
            <td>
              <el-select
                :style="{width:'220px'}"
                size="mini"
                filterable
                remote
                multiple
                :remote-method="componentMethod"
                v-model="p.id"
              >
                <el-option
                  v-for="item in availableComponents"
                  :key="item.id"
                  :label="item.literal"
                  :value="item.id"
                ></el-option>
              </el-select>
            </td>
            <td>
              <div>
                <el-input
                  placeholder="Remarks"
                  type="textarea"
                  autosize
                  v-model="components[index].remarks"
                  size="mini"
                ></el-input>
              </div>
            </td>
            <td>
              <div>
                <el-select
                  v-model="components[index].actions"
                  placeholder="Activity"
                  :items="activities"
                  size="mini"
                  :style="{width:'80px'}"
                >
                  <el-option
                    v-for="item in activities"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  :style="{margin: 'auto'}"
                  @click="removeGroup(p,'groupTable')"
                ></el-button>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <el-select
                :style="{width:'220px'}"
                size="mini"
                filterable
                remote
                @change="pushComponent"
                :remote-method="componentMethod"
                v-model="selectedComponent"
              >
                <el-option
                  v-for="item in availableComponents"
                  :key="item.id"
                  :label="item.literal"
                  :value="item.id"
                ></el-option>
              </el-select>
            </td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </el-row>
    </el-row>
    <el-row class="container" v-if="system=='Detectors'">
      <el-row>
        <h2>Maintenance Remarks During PPM</h2>
      </el-row>
      <el-row>
        <table>
          <tr>
            <th>
              <div class="tableCell">
                <strong class="tagText">{{system}}</strong>
              </div>
            </th>
            <th>
              <div class="superLongTableCellFixed">
                <strong class="tagText">Remarks</strong>
              </div>
            </th>
            <th>
              <div class="midTableCell">
                <strong class="tagText">Actions</strong>
              </div>
            </th>
          </tr>
          <tr v-for="(p,index) in selectedGroups" v-if="selectedHall==selectedGroups[index].hall">
            <th>
              <div v-if="selectedGroups[index].hall=='North'">
                <el-select
                  :style="{width:'220px'}"
                  size="mini"
                  v-model="selectedGroups[index].value"
                >
                  <el-option
                    v-for="item in northZones"
                    :key="item.zone"
                    :label="item.zone"
                    :value="item.zone"
                  ></el-option>
                </el-select>
              </div>
              <div v-if="selectedGroups[index].hall=='South'">
                <el-select
                  :style="{width:'220px'}"
                  size="mini"
                  v-model="selectedGroups[index].value"
                >
                  <el-option
                    v-for="item in southZones"
                    :key="item.zone"
                    :label="item.zone"
                    :value="item.zone"
                  ></el-option>
                </el-select>
              </div>
            </th>

            <th>
              <div>
                <el-input
                  placeholder="Remarks"
                  type="textarea"
                  autosize
                  v-model="selectedGroups[index].remarks"
                  size="mini"
                ></el-input>
              </div>
            </th>
            <th>
              <div>
                <el-select
                  v-model="selectedGroups[index].actions"
                  placeholder="Activity"
                  :items="activities"
                  size="mini"
                  :style="{width:'80px'}"
                >
                  <el-option
                    v-for="item in activities"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  :style="{margin: 'auto'}"
                  @click="removeGroup(p,'groupTable')"
                ></el-button>
              </div>
            </th>
          </tr>
          <tr>
            <th>
              <div v-if="selectedHall=='North'">
                <el-select :style="{width:'220px'}" size="mini" v-model="group" @change="pushGroup">
                  <el-option
                    v-for="item in northZones"
                    :key="item.zone"
                    :label="item.zone"
                    :value="item.zone"
                  ></el-option>
                </el-select>
              </div>
              <div v-if="selectedHall=='South'">
                <el-select :style="{width:'220px'}" size="mini" v-model="group" @change="pushGroup">
                  <el-option
                    v-for="item in southZones"
                    :key="item.zone"
                    :label="item.zone"
                    :value="item.zone"
                  ></el-option>
                </el-select>
              </div>
            </th>
            <th></th>
            <th></th>
          </tr>
        </table>
      </el-row>
    </el-row>
    <el-row
      class="container"
      v-if="(periodicity==8&&(system=='Electrical Panels'||system=='Sorter'||system=='Inductions'))"
    >
      <el-row>
        <h2>Maintenance Remarks During PPM</h2>
      </el-row>
      <el-row>
        <table id="compos">
          <tr>
            <th>
              <div class="superLongTableCell">
                <strong class="tagText">Remarks</strong>
              </div>
            </th>
            <th>
              <div class="midTableCell">
                <strong class="tagText">Actions</strong>
              </div>
            </th>
          </tr>
          <tr v-for="(p,index) in jsonRemarks">
            <th>
              <div>
                <el-input
                  placeholder="Remarks"
                  type="textarea"
                  autosize
                  v-model="p.remarks"
                  size="mini"
                  :style="{width:'800px'}"
                ></el-input>
              </div>
            </th>
            <th>
              <div>
                <el-select
                  v-model="p.actions"
                  placeholder="Activity"
                  :items="activities"
                  size="mini"
                  :style="{width:'80px'}"
                >
                  <el-option
                    v-for="item in activities"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  :style="{margin: 'auto'}"
                  @click="removeRemark(p,'compos')"
                ></el-button>
              </div>
            </th>
          </tr>
          <tr>
            <th>
              <div>
                <el-input
                  placeholder="Remarks"
                  type="textarea"
                  autosize
                  v-model="yearlyRemarks"
                  size="mini"
                  :style="{width:'800px'}"
                ></el-input>
              </div>
            </th>
            <th>
              <div>
                <el-select
                  v-model="yearlyActions"
                  placeholder="Activity"
                  :items="activities"
                  size="mini"
                  :style="{width:'120px'}"
                  @change="pushRemark"
                >
                  <el-option
                    v-for="item in activities"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </th>
          </tr>
        </table>
      </el-row>
    </el-row>
    <el-row
      class="container"
      v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''&&(((system=='Sorter'||system=='Inductions')&&periodicity!=8))"
    >
      <el-row>
        <h2 v-if="periodicity!=8">Current Maintenance Remarks</h2>
        <h2 v-if="periodicity==8">Maintenance Remarks During PPM</h2>
      </el-row>
      <table id="groupTable">
        <tr>
          <th>
            <div class="shortTableCell">
              <strong class="tagText">Hall</strong>
            </div>
          </th>
          <th>
            <div class="tableCell">
              <strong class="tagText">{{system}}</strong>
            </div>
          </th>
          <th>
            <div class="longTableCell">
              <strong class="tagText">Remarks</strong>
            </div>
          </th>
          <th>
            <div class="midTableCell">
              <strong class="tagText">Actions</strong>
            </div>
          </th>
        </tr>
        <tr
          v-for="(p,index) in selectedGroups"
          v-if="system=='Sorter'||system=='Inductions'||selectedHall==selectedGroups[index].hall"
        >
          <th>
            <div class="shortTableCell">
              <strong class="tagText">{{selectedGroups[index].hall}}</strong>
            </div>
          </th>

          <th>
            <div v-if="system=='UPS'">
              <el-select :style="{width:'220px'}" size="mini" v-model="selectedGroups[index].value">
                <el-option v-for="item in upsGroups" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
            <div v-if="system=='Sorter'">
              <div v-if="selectedGroups[index].hall=='North'">
                <el-select
                  :style="{width:'220px'}"
                  size="mini"
                  v-model="selectedGroups[index].value"
                >
                  <el-option v-for="item in northSorter" :key="item" :label="item" :value="item"></el-option>
                </el-select>
              </div>
              <div v-if="selectedGroups[index].hall=='South'">
                <el-select
                  :style="{width:'220px'}"
                  size="mini"
                  v-model="selectedGroups[index].value"
                >
                  <el-option v-for="item in southSorter" :key="item" :label="item" :value="item"></el-option>
                </el-select>
              </div>
            </div>
            <div v-if="system=='IT and Automation'">
              <el-select :style="{width:'220px'}" size="mini" v-model="selectedGroups[index].value">
                <el-option v-for="item in itGroups" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
            <div v-if="system=='Inductions'">
              <div v-if="selectedGroups[index].hall=='North'">
                <el-select
                  :style="{width:'220px'}"
                  size="mini"
                  multiple
                  v-model="selectedGroups[index].value"
                >
                  <el-option
                    v-for="item in northInductions"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </div>
              <div v-if="selectedGroups[index].hall=='South'">
                <el-select
                  :style="{width:'220px'}"
                  size="mini"
                  multiple
                  v-model="selectedGroups[index].value"
                >
                  <el-option
                    v-for="item in southInductions"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div v-if="system=='Detectors'">
              <div v-if="selectedGroups[index].hall=='North'">
                <el-select
                  :style="{width:'220px'}"
                  size="mini"
                  v-model="selectedGroups[index].value"
                >
                  <el-option
                    v-for="item in northZones"
                    :key="item.zone"
                    :label="item.zone"
                    :value="item.zone"
                  ></el-option>
                </el-select>
              </div>
              <div v-if="selectedGroups[index].hall=='South'">
                <el-select
                  :style="{width:'220px'}"
                  size="mini"
                  v-model="selectedGroups[index].value"
                >
                  <el-option
                    v-for="item in southZones"
                    :key="item.zone"
                    :label="item.zone"
                    :value="item.zone"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </th>

          <th>
            <div>
              <el-input
                placeholder="Remarks"
                type="textarea"
                autosize
                v-model="selectedGroups[index].remarks"
                size="mini"
              ></el-input>
            </div>
          </th>
          <th>
            <div>
              <el-select
                v-model="selectedGroups[index].actions"
                placeholder="Activity"
                :items="activities"
                size="mini"
                :style="{width:'80px'}"
              >
                <el-option
                  v-for="item in activities"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                :style="{margin: 'auto'}"
                @click="removeGroup(p,'groupTable')"
              ></el-button>
            </div>
          </th>
        </tr>
        <tr>
          <th>
            <div v-if="periodicity!=4&&system!='Conveyors'">
              <el-select
                v-model="selectedHall"
                :style="{width:'100px'}"
                placeholder="Hall"
                :items="halls"
                clearable
                size="mini"
              >
                <el-option v-for="item in halls" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
          </th>
          <th>
            <div v-if="system=='UPS'">
              <el-select :style="{width:'220px'}" size="mini" v-model="group" @change="pushGroup">
                <el-option v-for="item in upsGroups" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>

            <div v-if="system=='IT and Automation'">
              <el-select :style="{width:'220px'}" size="mini" v-model="group" @change="pushGroup">
                <el-option v-for="item in itGroups" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
            <div v-if="system=='Detectors'">
              <div v-if="selectedHall=='North'">
                <el-select :style="{width:'220px'}" size="mini" v-model="group" @change="pushGroup">
                  <el-option
                    v-for="item in northZones"
                    :key="item.zone"
                    :label="item.zone"
                    :value="item.zone"
                  ></el-option>
                </el-select>
              </div>
              <div v-if="selectedHall=='South'">
                <el-select :style="{width:'220px'}" size="mini" v-model="group" @change="pushGroup">
                  <el-option
                    v-for="item in southZones"
                    :key="item.zone"
                    :label="item.zone"
                    :value="item.zone"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div v-if="system=='Sorter'">
              <div v-if="selectedHall=='North'">
                <el-select :style="{width:'220px'}" size="mini" v-model="group" @change="pushGroup">
                  <el-option v-for="item in northSorter" :key="item" :label="item" :value="item"></el-option>
                </el-select>
              </div>
              <div v-if="selectedHall=='South'">
                <el-select :style="{width:'220px'}" size="mini" v-model="group" @change="pushGroup">
                  <el-option v-for="item in southSorter" :key="item" :label="item" :value="item"></el-option>
                </el-select>
              </div>
            </div>
            <div v-if="system=='Inductions'">
              <div v-if="selectedHall=='North'">
                <el-select :style="{width:'220px'}" size="mini" v-model="group" @change="pushGroup">
                  <el-option
                    v-for="item in northInductions"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </div>
              <div v-if="selectedHall=='South'">
                <el-select :style="{width:'220px'}" size="mini" v-model="group" @change="pushGroup">
                  <el-option
                    v-for="item in southInductions"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </th>
          <th></th>
          <th></th>
        </tr>
      </table>
    </el-row>
    <el-row
      class="container"
      v-if="(periodicity!=7&&system!='IT and Automation')&&system!='Fire Panels'&&periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''&&(periodicity!=8)&&!((system=='IT and Automation'&&periodicity==6)||system=='Detectors')&&(system!='Sorter'&&system!='Inductions')&&system!='Conveyors'&&system!='Desks'&&system!='Chutes'"
    >
      <el-row>
        <h2>Current Maintenance Remarks</h2>
      </el-row>
      <table
        id="compos"
        v-if="(periodicity==4&&(system=='Fire Shutters'||system=='Reclaims'||system=='Retainer'||system=='Electrical Panels'))"
      >
        <tr>
          <th>
            <div class="tableCell">
              <strong class="tagText">{{system}}</strong>
            </div>
          </th>
          <th>
            <div class="superLongTableCellFixed">
              <strong class="tagText">Remarks</strong>
            </div>
          </th>
          <th>
            <div class="midTableCell">
              <strong class="tagText">Actions</strong>
            </div>
          </th>
        </tr>
        <tr
          v-for="(p,index) in components"
          v-if="selectedHall==componentList.find(r=>r.id == components[index].value).group1"
        >
          <th>
            <div>
              <el-select
                v-model="components[index].id"
                filterable
                :style="{width:'220px'}"
                remote
                size="mini"
                @change="componentUpdate(components[index].id,index)"
                clearable
                placeholder="Add Component"
                :remote-method="componentMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in availableComponents"
                  :key="item.id"
                  :label="item.literal"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </th>
          <th>
            <div>
              <el-input
                placeholder="Remarks"
                type="textarea"
                autosize
                :style="{width:'550px'}"
                v-model="components[index].remarks"
                size="mini"
              ></el-input>
            </div>
          </th>
          <th>
            <div>
              <el-select
                v-model="components[index].actions"
                placeholder="Activity"
                :items="activities"
                size="mini"
                :style="{width:'80px'}"
              >
                <el-option
                  v-for="item in activities"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                :style="{margin: 'auto'}"
                @click="remove(p,'compos')"
              ></el-button>
            </div>
          </th>
        </tr>

        <tr>
          <th v-if="periodicity!=8||components.length<1||system=='Scales'">
            <div>
              <el-select
                v-model="selectedComponent"
                filterable
                :style="{width:'220px'}"
                remote
                size="mini"
                @change="pushComponent"
                clearable
                placeholder="Add Component"
                :remote-method="componentMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in availableComponents"
                  :key="item.id"
                  :label="item.literal"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </th>
          <th>
            <div></div>
          </th>
          <th>
            <div></div>
          </th>
        </tr>
      </table>
      <table
        id="compos"
        v-if="!(periodicity==4&&(system=='Fire Shutters'||system=='Reclaims'||system=='Retainer'||system=='Electrical Panels'))"
      >
        <tr>
          <th>
            <div class="shortTableCell">
              <strong class="tagText" v-if="system!='Pull Rope Circuits'">Hall</strong>
              <strong class="tagText" v-if="system=='Pull Rope Circuits'">Week</strong>
            </div>
          </th>
          <th>
            <div class="tableCell">
              <strong class="tagText">{{system}}</strong>
            </div>
          </th>
          <th>
            <div class="longTableCell">
              <strong class="tagText">Remarks</strong>
            </div>
          </th>
          <th>
            <div class="midTableCell">
              <strong class="tagText">Actions</strong>
            </div>
          </th>
        </tr>
        <tr
          v-for="(p,index) in components"
          v-if="componentList.find(r=>r.id == components[index].value).group4!='ATR'&&((system=='Conveyors'&&periodicity==4&&belt==componentList.find(r=>r.id == components[index].value).group4)||(system=='IT and Automation'&&periodicity!=8&&belt==componentList.find(r=>r.id == components[index].value).group3))||(system=='Electrical Panels'&&(periodicity==4||periodicity==8)&&(selectedHall==componentList.find(r=>r.id == components[index].value).group1))"
        >
          <th>
            <div
              v-if="componentList.find(r=>r.id == components[index].value)!=undefined"
              class="shortTableCell"
            >
              <div class="tagText">{{componentList.find(r=>r.id == components[index].value).group1}}</div>
            </div>
          </th>
          <th>
            <div>
              <el-select
                v-model="components[index].id"
                filterable
                :style="{width:'220px'}"
                remote
                size="mini"
                @change="componentUpdate(components[index].id,index)"
                clearable
                placeholder="Add Component"
                :remote-method="componentMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in availableComponents"
                  :key="item.id"
                  :label="item.literal"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </th>
          <th>
            <div>
              <el-input
                placeholder="Remarks"
                type="textarea"
                autosize
                v-model="components[index].remarks"
                size="mini"
              ></el-input>
            </div>
          </th>
          <th>
            <div>
              <el-select
                v-model="components[index].actions"
                placeholder="Activity"
                :items="activities"
                size="mini"
                :style="{width:'80px'}"
              >
                <el-option
                  v-for="item in activities"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                :style="{margin: 'auto'}"
                @click="remove(p,'compos')"
              ></el-button>
            </div>
          </th>
        </tr>
        <tr
          v-for="(p,index) in components"
          v-if="(componentList.find(r=>r.id == components[index].value).group4!='ATR')&&(system!='Conveyors'&&periodicity!=4)&&!(system=='Electrical Panels'&&(periodicity==4||periodicity==8))"
        >
          <th>
            <div
              v-if="componentList.find(r=>r.id == components[index].value)!=undefined"
              class="shortTableCell"
            >
              <div class="tagText">{{componentList.find(r=>r.id == components[index].value).group1}}</div>
            </div>
          </th>
          <th>
            <div>
              <el-select
                v-model="components[index].id"
                filterable
                :style="{width:'220px'}"
                remote
                size="mini"
                @change="componentUpdate(components[index].id,index)"
                clearable
                placeholder="Add Component"
                :remote-method="componentMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in availableComponents"
                  :key="item.id"
                  :label="item.literal"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </th>
          <th>
            <div>
              <el-input
                placeholder="Remarks"
                type="textarea"
                autosize
                v-model="components[index].remarks"
                size="mini"
              ></el-input>
            </div>
          </th>
          <th>
            <div>
              <el-select
                v-model="components[index].actions"
                placeholder="Activity"
                :items="activities"
                size="mini"
                :style="{width:'80px'}"
              >
                <el-option
                  v-for="item in activities"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                :style="{margin: 'auto'}"
                @click="remove(p,'compos')"
              ></el-button>
            </div>
          </th>
        </tr>
        <tr
          v-for="(p,index) in components"
          v-if="(componentList.find(r=>r.id == components[index].value).group4!='ATR')&&(system!='Conveyors'&&periodicity!=4)&&(system=='Electrical Panels'&&(periodicity==4||periodicity==8)&&(selectedHall==componentList.find(r=>r.id == components[index].value).group1))"
        >
          <th>
            <div
              v-if="componentList.find(r=>r.id == components[index].value)!=undefined"
              class="shortTableCell"
            >
              <div class="tagText">{{componentList.find(r=>r.id == components[index].value).group1}}</div>
            </div>
          </th>
          <th>
            <div>
              <el-select
                v-model="components[index].id"
                filterable
                :style="{width:'220px'}"
                remote
                size="mini"
                @change="componentUpdate(components[index].id,index)"
                clearable
                placeholder="Add Component"
                :remote-method="componentMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in availableComponents"
                  :key="item.id"
                  :label="item.literal"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </th>
          <th>
            <div>
              <el-input
                placeholder="Remarks"
                type="textarea"
                autosize
                v-model="components[index].remarks"
                size="mini"
              ></el-input>
            </div>
          </th>
          <th>
            <div>
              <el-select
                v-model="components[index].actions"
                placeholder="Activity"
                :items="activities"
                size="mini"
                :style="{width:'80px'}"
              >
                <el-option
                  v-for="item in activities"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                :style="{margin: 'auto'}"
                @click="remove(p,'compos')"
              ></el-button>
            </div>
          </th>
        </tr>
        <tr
          v-for="(p,index) in components"
          v-if="(componentList.find(r=>r.id == components[index].value).group4!='ATR')&&((((system=='Fire Shutter and Ret'||system=='Reclaims')&&selectedHall==componentList.find(r=>r.id == components[index].value).group1)||system=='Pull Rope Circuits'))"
        >
          <th>
            <div
              v-if="componentList.find(r=>r.id == components[index].value)!=undefined"
              class="shortTableCell"
            >
              <div
                class="tagText"
                v-if="system!='Pull Rope Circuits'"
              >{{componentList.find(r=>r.id == components[index].value).group1}}</div>
              <div
                class="tagText"
                v-if="system=='Pull Rope Circuits'"
              >Week {{componentList.find(r=>r.id == components[index].value).week}}</div>
            </div>
          </th>
          <th>
            <div>
              <el-select
                v-model="components[index].id"
                filterable
                :style="{width:'220px'}"
                remote
                size="mini"
                @change="componentUpdate(components[index].id,index)"
                clearable
                placeholder="Add Component"
                :remote-method="componentMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in availableComponents"
                  :key="item.id"
                  :label="item.literal"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </th>
          <th>
            <div>
              <el-input
                placeholder="Remarks"
                type="textarea"
                autosize
                v-model="components[index].remarks"
                size="mini"
              ></el-input>
            </div>
          </th>
          <th>
            <div>
              <el-select
                v-model="components[index].actions"
                placeholder="Activity"
                :items="activities"
                size="mini"
                :style="{width:'80px'}"
              >
                <el-option
                  v-for="item in activities"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                :style="{margin: 'auto'}"
                @click="remove(p,'compos')"
              ></el-button>
            </div>
          </th>
        </tr>

        <tr>
          <th>
            <div v-if="periodicity!=4&&system!='Conveyors'">
              <el-select
                v-model="selectedHall"
                :style="{width:'100px'}"
                placeholder="Hall"
                :items="halls"
                clearable
                size="mini"
                @change="componentMethod('')"
              >
                <el-option v-for="item in halls" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
            <div v-if="system=='Pull Rope Circuits'">
              <el-select
                v-model="selectedWeek"
                :style="{width:'100px'}"
                placeholder="Week"
                :items="weeks"
                clearable
                size="mini"
                @change="componentMethod('')"
              >
                <el-option
                  v-for="item in weeks"
                  :key="item.week"
                  :label="item.literal"
                  :value="item.week"
                ></el-option>
              </el-select>
            </div>
          </th>
          <th v-if="periodicity!=8||components.length<1||system=='Scales'">
            <div>
              <el-select
                v-model="selectedComponent"
                filterable
                :style="{width:'220px'}"
                remote
                size="mini"
                @change="pushComponent"
                clearable
                placeholder="Add Component"
                :remote-method="componentMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in availableComponents"
                  :key="item.id"
                  :label="item.literal"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </th>
          <th>
            <div></div>
          </th>
          <th>
            <div></div>
          </th>
        </tr>
      </table>
      <br>
      <table v-if="system=='Sorter'&&periodicity==1" id="atr">
        <tr>
          <th>
            <div class="shortTableCell">
              <strong class="tagText">Hall</strong>
            </div>
          </th>
          <th>
            <div class="tableCell">
              <strong class="tagText">ATR</strong>
            </div>
          </th>
          <th>
            <div class="longTableCell">
              <strong class="tagText">Remarks</strong>
            </div>
          </th>
          <th>
            <div class="midTableCell">
              <strong class="tagText">Actions</strong>
            </div>
          </th>
        </tr>
        <tr
          v-for="(p,index) in components"
          v-if="componentList.find(r=>r.id == components[index].value).group4=='ATR'"
        >
          <th>
            <div
              class="shortTableCell"
              v-if="componentList.find(r=>r.id == components[index].value)!=undefined"
            >
              <div class="tagText">{{componentList.find(r=>r.id == components[index].value).group1}}</div>
            </div>
          </th>
          <th>
            <div>
              <el-select
                v-model="components[index].id"
                filterable
                :style="{width:'220px'}"
                remote
                size="mini"
                @change="componentUpdate(components[index].id,index)"
                clearable
                placeholder="Add Component"
                :remote-method="atrComponentMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in availableATRComponents"
                  :key="item.id"
                  :label="item.literal"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </th>
          <th>
            <div>
              <el-input
                placeholder="Remarks"
                type="textarea"
                autosize
                v-model="components[index].remarks"
                size="mini"
              ></el-input>
            </div>
          </th>
          <th>
            <div>
              <el-select
                v-model="components[index].actions"
                placeholder="Activity"
                :items="activities"
                size="mini"
                :style="{width:'80px'}"
              >
                <el-option
                  v-for="item in activities"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                :style="{margin: 'auto'}"
                @click="remove(p,'atr')"
              ></el-button>
            </div>
          </th>
        </tr>
        <tr>
          <th>
            <div>
              <el-select
                v-model="selectedATRHall"
                :style="{width:'100px'}"
                placeholder="Hall"
                :items="halls"
                clearable
                size="mini"
              >
                <el-option v-for="item in halls" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
          </th>
          <th>
            <div>
              <el-select
                v-model="selectedComponent"
                filterable
                :style="{width:'220px'}"
                remote
                size="mini"
                @change="pushComponent"
                clearable
                placeholder="Add Component"
                :remote-method="atrComponentMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in availableATRComponents"
                  :key="item.id"
                  :label="item.literal"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </th>
          <th></th>
          <th></th>
        </tr>
      </table>
    </el-row>
    <el-row
      v-if="((periodicity==8||periodicity==6)&&system!='Scales'&&system!=''&&system!=undefined&&system!='Sorter'&&system!='Electrical Panels'&&system!='Inductions')||system=='Fire Panels'"
      class="container"
    >
      <el-row>
        <h2>Maintenance Remarks During PPM</h2>
      </el-row>
      <el-row>
        <table id="compos">
          <tr>
            <th>
              <div class="superLongTableCell">
                <strong class="tagText">Remarks</strong>
              </div>
            </th>
            <th>
              <div class="midTableCell">
                <strong class="tagText">Actions</strong>
              </div>
            </th>
          </tr>
          <tr>
            <th>
              <div>
                <el-input
                  placeholder="Remarks"
                  type="textarea"
                  autosize
                  v-model="yearlyRemarks"
                  size="mini"
                  :style="{width:'800px'}"
                ></el-input>
              </div>
            </th>
            <th>
              <div>
                <el-select
                  v-model="yearlyActions"
                  placeholder="Activity"
                  :items="activities"
                  size="mini"
                  :style="{width:'120px'}"
                >
                  <el-option
                    v-for="item in activities"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </th>
          </tr>
        </table>
      </el-row>
    </el-row>
    <div
      v-if="periodicity!=1&&periodicity!=2&&(periodicity!=4||system=='Pull Rope Circuits')&&periodicity!=6&&(periodicity!=7||system=='Detectors')&&!(periodicity==8&&system=='Electrical Panels')"
    >
      <br v-if="system!='Detectors'">
      <el-row
        class="container"
        v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''&&(periodicity!=8||periodicity==8&&system=='Electrical Panels')&&!(periodicity==4&&system=='Pull Rope Circuits')&&system!='Detectors'"
      >
        <h2>Maintenance Completion</h2>
        <br>
        <table>
          <tr>
            <td>
              <div class="tableTag">
                <strong>South Completion</strong>
              </div>
            </td>
            <td rowspan="2" width="800">
              <el-input
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 3}"
                placeholder="Executed Parts"
                v-model="Sparts"
                v-if="Scompletion"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td>
              <el-select
                v-model="Scompletion"
                placeholder="Completion"
                size="mini"
                :style="{width:'100px'}"
              >
                <el-option
                  v-for="item in progressList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <td>
              <div class="tableTag">
                <strong>North Completion</strong>
              </div>
            </td>
            <td rowspan="2" width="800">
              <el-input
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 3}"
                placeholder="Executed Parts"
                v-model="Nparts"
                v-if="Ncompletion"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td>
              <el-select
                v-model="Ncompletion"
                placeholder="Completion"
                size="mini"
                :style="{width:'100px'}"
              >
                <el-option
                  v-for="item in progressList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </td>
          </tr>
        </table>
      </el-row>
      <el-row
        class="container"
        v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''&&periodicity!=8&&(periodicity==4&&system=='Pull Rope Circuits')&&system!='Detectors'"
      >
        <h2>Maintenance Completion</h2>
        <br>
        <table>
          <tr>
            <td>
              <div class="yellowTableTag">
                <strong>Week 1:</strong>
              </div>
            </td>
            <td rowspan="2" width="800">
              <el-input
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 3}"
                placeholder="Executed Parts"
                v-model="W1Parts"
                v-if="W1Completion"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td>
              <el-select
                v-model="W1Completion"
                placeholder="Completion"
                size="mini"
                :style="{width:'100px'}"
              >
                <el-option
                  v-for="item in progressList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <td>
              <div class="yellowTableTag">
                <strong>Week 2:</strong>
              </div>
            </td>
            <td rowspan="2" width="800">
              <el-input
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 3}"
                placeholder="Executed Parts"
                v-model="W2Parts"
                v-if="W2Completion"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td>
              <el-select
                v-model="W2Completion"
                placeholder="Completion"
                size="mini"
                :style="{width:'100px'}"
              >
                <el-option
                  v-for="item in progressList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <td>
              <div class="yellowTableTag">
                <strong>Week 3:</strong>
              </div>
            </td>
            <td rowspan="2" width="800">
              <el-input
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 3}"
                placeholder="Executed Parts"
                v-model="W3Parts"
                v-if="W3Completion"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td>
              <el-select
                v-model="W3Completion"
                placeholder="Completion"
                size="mini"
                :style="{width:'100px'}"
              >
                <el-option
                  v-for="item in progressList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <td>
              <div class="yellowTableTag">
                <strong>Week 4:</strong>
              </div>
            </td>
            <td rowspan="2" width="800">
              <el-input
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 3}"
                placeholder="Executed Parts"
                v-model="W4Parts"
                v-if="W4Completion"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td>
              <el-select
                v-model="W4Completion"
                placeholder="Completion"
                size="mini"
                :style="{width:'100px'}"
              >
                <el-option
                  v-for="item in progressList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </td>
          </tr>
        </table>
      </el-row>
    </div>
    <br>
    <!--nai mln-->
    <el-row
      class="container"
      v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''"
    >
      <StatusSelector v-model="status"></StatusSelector>
    </el-row>
    <br>
    <!--nai-->
    <el-row v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''">
      <el-button style="margin-top: 12px;" @click="validate" type="success">
        <strong class="white">Submit</strong>
      </el-button>
    </el-row>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { ppm } from "../../api/ppm/ppm";
import { personnel } from "../../api/personnel/personnel";
import { aiaPersonnel } from "../../api/personnel/aiaPersonnel";
import { component } from "../../api/component/component";
import { workorder } from "../../api/workorder/workorder";
import StatusSelector from "../Selectors/StatusSelector.vue";
var Moment = require("moment");
@Component({
  name: "Addppmnew",
  components: {
    StatusSelector
  },
  props: {
    obj: {}
  }
})
export default class AddPPMnew extends Vue {
  public periodicities: any[] = [];
  public activities: any[] = [];
  public personnelList: any[] = [];
  public periodicity: any = "";
  public componentList: any[] = [];
  public halls: any[] = [];
  public systems: any[] = [];
  public groups: any[] = [];
  public descriptions: any[] = [];
  public codes: any[] = [];
  public loading: boolean = false;
  public system: any = "";
  public selectedTech: any = "";
  public availableForemen: any[] = [];
  public techs: any[] = [];
  public hall: any = "";
  public components: any[] = [];
  public availableComponents: any[] = [];
  public selectedComponent = "";
  public row: any;
  public status: any = {};
  public selectedHall = "";
  public selectedATRHall = "";
  public systemPeriodicities: any[] = [];
  public availableSystems: any[] = [];
  public atrComponents: any[] = [];
  public availableATRComponents: any[] = [];
  public progressList: any[] = [
    { value: false, label: "All" },
    { value: true, label: "Partial" }
  ];
  public beltLine: any[] = [];
  public conveyorBelts: any[] = [];
  public Scompletion: any = "";
  public Sparts: string = "";
  public Ncompletion: any = "";
  public Nparts: string = "";
  public availableSenTechs: any[] = [];
  public senTechs: any[] = [];
  public senTech: any = "";
  public senTechList: any[] = [];
  public workOrderList: any[] = [];
  public workOrders: any[] = [];
  public availableWorkOrders: any[] = [];
  public selectedWorkOrder: any = "";
  public belt: any = "";
  public foreman: any = "";
  public southPanels: any[] = [];
  public northPanels: any[] = [];
  public yearlyComponent: any = "";
  public yearlyRemarks: any = "";
  public yearlyActions: any = "";
  public W1Parts: any = "";
  public W2Parts: any = "";
  public W3Parts: any = "";
  public W4Parts: any = "";
  public W1Completion: any = "";
  public W2Completion: any = "";
  public W3Completion: any = "";
  public W4Completion: any = "";
  public itGroups: any[] = [];
  public upsGroups: any[] = [];
  public northSorter: any[] = [];
  public southSorter: any[] = [];
  public group: any = "";
  public selectedGroups: any[] = [];
  public northInductions: any[] = [];
  public southInductions: any[] = [];
  public southZones: any[] = [];
  public northZones: any[] = [];
  public existing: boolean = false;
  public southUps: any[] = [];
  public northUps: any[] = [];
  public jsonRemarks: any[] = [];
  public atrGroups: any[] = [];
  public selectedWeek: any = "";
  public weeks: any[] = [
    { week: 1, literal: "Week 1" },
    { week: 2, literal: "Week 2" },
    { week: 3, literal: "Week 3" },
    { week: 4, literal: "Week 4" }
  ];
  public lines: any[] = [];
  public conveyorNumbers: any[] = [];
  public southDesks: any[] = [];
  public northDesks: any[] = [];
  public northChutes: any[] = [];
  public southChutes: any[] = [];
  public async getWorkOrderList() {
    await workorder
      .getSomeWorkOrders(
        this.$store.getters.getToken,
        {},
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then((resp: any) => {
          let i = 0;
          while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
            this.workOrderList.push({
              value: JSON.parse(JSON.stringify(resp))[i].aia_id.toString(),
              label: JSON.parse(JSON.stringify(resp))[i].aia_id.toString(),
              key: JSON.parse(JSON.stringify(resp))[i].aia_id,
              description: JSON.parse(JSON.stringify(resp))[i].name
            });
            i++;
          }
        });
      });
  }
  public clearSearch() {
    this.availableSenTechs = [];
  }
  public senTechMethod(query: string) {
    if (query != "" && query != undefined) {
      this.loading = true;
      this.loading = false;
      this.availableSenTechs = this.senTechs.filter(item => {
        return (
          item.label.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          item.team == query
        );
      });
    } else {
      this.availableSenTechs = [];
    }
  }
  public workOrderMethod(query: string) {
    if (query != "" && query != undefined) {
      this.availableWorkOrders = this.workOrderList.filter(item => {
        return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
      });
    } else {
      this.availableWorkOrders = [];
    }
  }
  public remove(r: any, t: string) {
    this.components = this.components.filter(k => r.value != k.value);
  }
  public removeGroup(r: any, t: string) {
    this.selectedGroups = this.selectedGroups.filter(k => r.value != k.value);
  }
  public removeRemark(r: any, t: string) {
    this.jsonRemarks = this.jsonRemarks.filter(
      k => k.remarks != r.remarks && k.actions != r.actions
    );
  }
  public clear() {
    this.techs = [];
    this.selectedGroups = [];
    this.components = [];
    this.status = [];
    this.workOrders = [];
    this.senTech = "";
    this.foreman = "";
    this.W1Completion = "";
    this.W2Completion = "";
    this.W3Completion = "";
    this.W4Completion = "";
    this.W1Parts = "";
    this.W2Parts = "";
    this.W3Parts = "";
    this.W4Parts = "";
    this.group = "";
    this.yearlyComponent = "";
    this.yearlyRemarks = "";
    this.yearlyActions = "";
    this.Ncompletion = "";
    this.Scompletion = "";
    this.Nparts = "";
    this.Sparts = "";
    this.belt = "";
    this.selectedHall = "";
  }
  public async receiveRemarks() {
    if (!this.existing) {
      this.clear();
    }
    if (
      this.periodicity != undefined &&
      this.system != undefined &&
      this.periodicity != "" &&
      this.system != ""
    ) {
      this.componentMethod("");
      await ppm
        .getSomeRemarks(
          this.$store.getters.getToken,
          {
            periodicity: this.periodicity,
            system:
              this.system == "Fire Panels" ? "Electrical Panels" : this.system
          },
          this.$store.getters.getHost
        )
        .then(response => {
          console.log("SYSTEM: " + this.system);
          console.log("PERIOD: " + this.periodicity);
          console.log(response);
          response.json().then((resp: any) => {
            let i = 0;
            this.components = [];
            while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
              console.log(JSON.parse(JSON.stringify(resp))[i].id);
              console.log(this.system);
              if (
                this.system == "UPS" ||
                this.system == "Sorter" ||
                (this.system == "IT and Automation" && this.periodicity == 6) ||
                this.system == "Inductions"
              ) {
                this.selectedGroups.push({
                  id: JSON.parse(JSON.stringify(resp))[i].group,
                  remarks: JSON.parse(JSON.stringify(resp))[i].remarks,
                  value: JSON.parse(JSON.stringify(resp))[i].group,
                  actions: JSON.parse(JSON.stringify(resp))[i].activity,
                  hall: JSON.parse(JSON.stringify(resp))[i].hall
                });
              } else {
                this.components.push({
                  id: this.componentList.find(
                    r =>
                      r.id == JSON.parse(JSON.stringify(resp))[i].component_id
                  ).literal,
                  remarks: JSON.parse(JSON.stringify(resp))[i].remarks,
                  value: JSON.parse(JSON.stringify(resp))[i].component_id,
                  actions: JSON.parse(JSON.stringify(resp))[i].activity
                });
              }
              i++;
            }
            if (this.system == "Inductions") {
              let remarkGroups: any = [];
              for (const g of this.selectedGroups) {
                if (remarkGroups.length == 0) {
                  remarkGroups.push({
                    id: [g.id],
                    remarks: g.remarks,
                    value: [g.value],
                    actions: g.actions,
                    hall: g.hall
                  });
                } else {
                  let tempg = remarkGroups.find(
                    r =>
                      r.remarks == g.remarks &&
                      r.actions == g.actions &&
                      r.hall == g.hall
                  );
                  if (tempg == undefined) {
                    remarkGroups.push({
                      id: [g.id],
                      remarks: g.remarks,
                      value: [g.value],
                      actions: g.actions,
                      hall: g.hall
                    });
                  } else {
                    tempg.id.push(g.id);
                    tempg.value.push(g.id);
                  }
                }
              }
              this.selectedGroups = remarkGroups;
              console.log(this.selectedGroups);
            }
            console.log("LOOOP");
            console.log(this.components);
            if (
              (this.system == "Conveyors" && this.periodicity == 4) ||
              this.system == "Desks" ||
              this.system == "Chutes"
            ) {
              let remarkGroups: any = [];
              for (const g of this.components) {
                if (remarkGroups.length == 0) {
                  remarkGroups.push({
                    id: [g.id],
                    remarks: g.remarks,
                    value: [g.value],
                    actions: g.actions,
                    hall: g.hall
                  });
                } else {
                  let tempg = remarkGroups.find(
                    r => r.remarks == g.remarks && r.actions == g.actions
                  );
                  if (tempg == undefined) {
                    remarkGroups.push({
                      id: [g.id],
                      remarks: g.remarks,
                      value: [g.value],
                      actions: g.actions,
                      hall: g.hall
                    });
                  } else {
                    tempg.id.push(g.id);
                    tempg.value.push(g.value);
                  }
                }
              }
              this.components = remarkGroups;
              console.log(this.components);
            }
          });
        });
    }
  }

  public checkConveyor(p: any) {
    let response =
      (this.belt != "EA" &&
        this.belt != "EK" &&
        (this.componentList.find(r => r.id == p.value[0]) != undefined &&
          this.componentList.find(r => r.id == p.value[0]).group4 ==
            this.belt)) ||
      ((this.belt == "EA" || this.belt == "EK") &&
        (this.lines.find(r => r.component == p.value[0]) != undefined &&
          this.beltLine.includes(
            this.lines.find(r => r.component == p.value[0]).literal
          )));
    console.log(response);
    return response;
  }

  public async checkEdit() {
    this.row = this.$store.getters.getCurrentObject;
    console.log(this.row);
    if (this.row.id != undefined) {
      this.existing = true;
      this.selectedHall = this.row.hall;
      this.belt = this.row.belt;
      this.status = {
        enddate: this.row.date_completed,
        selectedStatus: this.row.status
      };
      this.system = this.row.system;
      this.periodicity = this.row.periodicity;
      if (this.system == "Conveyors" && this.periodicity == 4) {
        this.beltLine = this.row.multiremarks;
        console.log("this.beltLine")
        console.log(this.beltLine)
        console.log("this.beltLine")
      }
      if (this.row.multiremarks != undefined) {
        if (this.row.system == "Detectors") {
          this.selectedGroups = JSON.parse(this.row.multiremarks);
        }
        if (
          this.row.system == "Scales" ||
          (this.row.system == "IT and Automation" && this.row.periodicity == 7)
        ) {
          this.components = JSON.parse(this.row.multiremarks);
        }
        if (this.system != "Conveyors" && this.periodicity != 4) {
          this.jsonRemarks = JSON.parse(this.row.multiremarks);
        }
      }
      let completion = JSON.parse(this.row.completion);
      if (this.system == "Pull Rope Circuits" && this.periodicity == 4) {
        this.W1Parts = completion.week1.parts;
        this.W2Parts = completion.week2.parts;
        this.W3Parts = completion.week3.parts;
        this.W4Parts = completion.week4.parts;
        this.W1Completion = completion.week1.completion;
        this.W2Completion = completion.week2.completion;
        this.W3Completion = completion.week3.completion;
        this.W4Completion = completion.week4.completion;
      } else {
        this.Sparts = completion.south.parts;
        this.Scompletion = completion.south.completion;
        this.Nparts = completion.north.parts;
        this.Ncompletion = completion.north.completion;
      }
      if (this.row.senTech != undefined) {
        this.senTech =
          this.row.senTech.firstname + " " + this.row.senTech.lastname;
      }
      if (
        (this.periodicity == 8 &&
          this.system != "Scales" &&
          this.system != "Sorter" &&
          this.system != "Electrical Panels") ||
        this.periodicity == 6
      ) {
        let compo = await this.componentList.find(
          r => r.id == this.row.component
        );
        if (compo != undefined) {
          this.yearlyComponent = compo.literal;
          this.selectedHall = compo.group1;
        }
        console.log(this.yearlyComponent);
      }
      this.yearlyRemarks = this.row.remarks;
      this.yearlyActions = this.row.actions;
      if (this.periodicity == 8 && this.system == "IT and Automation") {
        this.selectedHall = await this.componentList.find(
          r => r.id == this.row.component
        ).group1;
        if (this.system == "IT and Automation") {
          this.belt = await this.componentList.find(
            r => r.id == this.row.component
          ).group3;
        }
      }

      if (this.row.foreMan != undefined) {
        this.foreman =
          this.row.foreMan.firstname + " " + this.row.foreMan.lastname;
      }
      for (const w of this.row.work_orders) {
        this.workOrders.push({
          id: w.aia_id,
          value: w.aia_id,
          description: w.name
        });
      }
      for (const p of this.row.personnel) {
        console.log("PERSON");
        console.log(p);
        this.techs.push({
          value: p.id,
          id: p.firstname + " " + p.lastname,
          start:
            (new Date(p.date_started).getHours() < 10
              ? "0" + new Date(p.date_started).getHours()
              : new Date(p.date_started).getHours()) +
            ":" +
            (new Date(p.date_started).getMinutes() < 10
              ? "0" + new Date(p.date_started).getMinutes()
              : new Date(p.date_started).getMinutes()),
          end:
            (new Date(p.date_ended).getHours() < 10
              ? "0" + new Date(p.date_ended).getHours()
              : new Date(p.date_ended).getHours()) +
            ":" +
            (new Date(p.date_ended).getMinutes() < 10
              ? "0" + new Date(p.date_ended).getMinutes()
              : new Date(p.date_ended).getMinutes()),
          date: new Date(p.date_started),
          hall: p.hall != undefined ? p.hall.literal : "",
          parts: p.parts
        });
      }
      console.log(this.techs);
      if (
        this.system != "Inductions" &&
        (this.system != "Conveyors" && this.periodicity == 4)
      ) {
        await ppm
          .getSomeRemarks(
            this.$store.getters.getToken,
            {
              periodicity: this.periodicity,
              system:
                this.system == "Fire Panels" ? "Electrical Panels" : this.system
            },
            this.$store.getters.getHost
          )
          .then(response => {
            console.log(response);
            response.json().then((resp: any) => {
              console.log(resp);
              let i = 0;
              while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
                console.log(JSON.parse(JSON.stringify(resp))[i].id);
                console.log(this.componentList);
                console.log(this.system);
                if (
                  this.system == "Sorter" ||
                  (this.system == "IT and Automation" &&
                    this.periodicity == 6) ||
                  this.system == "Inductions"
                ) {
                  this.selectedGroups.push({
                    id: JSON.parse(JSON.stringify(resp))[i].group,
                    remarks: JSON.parse(JSON.stringify(resp))[i].remarks,
                    value: JSON.parse(JSON.stringify(resp))[i].group,
                    actions: JSON.parse(JSON.stringify(resp))[i].activity,
                    hall: JSON.parse(JSON.stringify(resp))[i].hall
                  });
                } else {
                  this.components.push({
                    id: this.componentList.find(
                      r =>
                        r.id == JSON.parse(JSON.stringify(resp))[i].component_id
                    ).literal,
                    remarks: JSON.parse(JSON.stringify(resp))[i].remarks,
                    value: JSON.parse(JSON.stringify(resp))[i].component_id,
                    actions: JSON.parse(JSON.stringify(resp))[i].activity
                  });
                }
                i++;
              }
            });
          });
      } else {
        this.receiveRemarks();
      }
    }
  }
  public endTimeChange(index: number) {
    if (this.techs[index].end.length === 2) {
      this.techs[index].end = this.techs[index].end + ":";
    }
  }
  public startTimeChange(index: number) {
    if (this.techs[index].start.length === 2) {
      this.techs[index].start = this.techs[index].start + ":";
    }
  }
  public periodicityChange() {
    console.log(this.systemPeriodicities);
    this.availableSystems = [];
    let availablePeriodicities = this.systemPeriodicities.filter(
      r => r.periodicity == this.periodicity
    );
    console.log(this.periodicity);
   console.log("testforyearly88")
    console.log(availablePeriodicities);
    console.log("testforyearly88")
    //edw exw ta lektika apo ta componets kai periodicity 8 pou einai to yearly
    
    for (const p of availablePeriodicities) {
      this.availableSystems.push(p.system);
    }
    if (this.periodicity == 7) {
      this.availableSystems.push("Fire Panels");
    }
    if (this.periodicity == 4) {
      this.availableSystems.push("Fire Shutter and Ret");
      this.availableSystems = this.availableSystems.filter(
        r => r != "Fire Shutters" && r != "Retainer"
      );
    }
    this.receiveRemarks();
  }
  //END
  public techUpdate(id: any, index: number) {
    if (id == "") {
      this.techs.splice(index, 1);
    }
    console.log("this.techs1")
    console.log(this.techs);
     console.log("this.techs2")
  }
  public pushPersonnel() {
    this.techs.push({
      id:
        this.personnelList.find(r => r.id == this.selectedTech).firstname +
        " " +
        this.personnelList.find(r => r.id == this.selectedTech).lastname,
      value: this.selectedTech,
      start: "",
      end: ""
    });
    this.selectedTech = "";
    this.availableForemen = [];
    console.log(this.techs);
  }

  public foremanMethod(query: string) {
    if (query != "" && query != undefined) {
      this.loading = true;
      this.loading = false;
      this.availableForemen = this.personnelList.filter(item => {
        return (
          (item.firstname.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
            item.team == query ||
            item.lastname.toLowerCase().indexOf(query.toLowerCase()) > -1) &&
          this.techs.find(r => r.id == item.firstname + " " + item.lastname) ==
            undefined
        );
      });
      console.log("this.availableForemen1")
      console.log(this.availableForemen);
      console.log("this.availableForemen2")
    } else {
      this.availableForemen = [];
    }
  }
  public pushComponent() {
    console.log(this.selectedComponent);
    if (this.selectedComponent != undefined || this.selectedComponent != "") {
      this.components.push({
        id:
          (this.system == "Conveyors" && this.periodicity == 4) ||
          this.system == "Desks" ||
          this.system == "Chutes"
            ? [this.selectedComponent]
            : this.componentList.find(r => r.id == this.selectedComponent)
                .literal,
        value:
          (this.system == "Conveyors" && this.periodicity == 4) ||
          this.system == "Desks" ||
          this.system == "Chutes"
            ? [this.selectedComponent]
            : this.selectedComponent,
        remarks: "",
        hall: "",
        actions: ""
      });
      this.selectedComponent = "";
      this.componentMethod("");
      console.log(this.components);
    }
  }
  public pushRemark() {
    this.jsonRemarks.push({
      remarks: this.yearlyRemarks,
      actions: this.yearlyActions
    });
    console.log("this.jsonRemarks")
    console.log(this.jsonRemarks)
    console.log("this.jsonRemarks")
    this.yearlyRemarks = "";
    this.yearlyActions = "";
  }
  public pushGroup() {
    if (this.group != undefined || this.group != "") {
      this.selectedGroups.push({
        id: this.system == "Inductions" ? [this.group] : this.group,
        value: this.system == "Inductions" ? [this.group] : this.group,
        remarks: "",
        hall: this.selectedHall,
        actions: ""
      });
      this.group = "";
    }
    console.log(this.selectedGroups);
  }
  public pushWorkOrder() {
    console.log(this.selectedComponent);
    if (this.selectedWorkOrder != undefined || this.selectedWorkOrder != "") {
      this.workOrders.push({
        id: this.selectedWorkOrder,
        value: this.selectedWorkOrder,
        description: this.workOrderList.find(
          r => r.value == this.selectedWorkOrder
        ).description
      });
      this.selectedWorkOrder = "";
      this.availableWorkOrders = [];
    }
  }
  public componentUpdate(id: any, index: number) {
    if (id == "") {
      this.components.splice(index, 1);
    }
  }
  public WOUpdate(id: any, index: number) {
    if (id == "") {
      this.workOrders.splice(index, 1);
    }
  }

  public componentMethod(query: string) {
    console.log(this.system);
    console.log(this.selectedHall);
    console.log(this.belt);
    //if (query != "" && query != undefined) {
    this.loading = true;
    this.loading = false;
    if (this.system == "Chutes" || this.system == "Desks") {
      this.availableComponents = this.componentList.filter(item => {
        return (
          (item.literal.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
            query == "" ||
            query == undefined) &&
          this.components.find(r => r.id == item.literal) == undefined &&
          item.group2 == this.system
        );
      });
    } else {
      if (this.system == "IT and Automation" && this.periodicity == 7) {
        this.availableComponents = this.componentList.filter(item => {
          return (
            (item.literal.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
              query == "" ||
              query == undefined) &&
            this.components.find(r => r.id == item.literal) == undefined &&
            item.group2 == "ATR" &&
            item.group1 == this.selectedHall &&
            item.group4 == this.belt
          );
        });
      } else {
        if (this.system == "Scales") {
          this.availableComponents = this.componentList.filter(item => {
            return (
              (item.literal.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
                query == "" ||
                query == undefined) &&
              this.components.find(r => r.id == item.literal) == undefined &&
              item.group2 == this.system
            );
          });
        } else {
          if (this.system == "IT and Automation" && this.periodicity == 6) {
            this.availableComponents = this.componentList.filter(item => {
              return (
                (item.literal.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
                  query == "" ||
                  query == undefined) &&
                this.components.find(r => r.id == item.literal) == undefined &&
                item.group2 == this.system &&
                item.group3 == this.belt
              );
            });
          } else {
            if (this.system == "Reclaims" && this.periodicity == 4) {
              this.availableComponents = this.componentList.filter(item => {
                return (
                  (item.literal.toLowerCase().indexOf(query.toLowerCase()) >
                    -1 ||
                    query == "" ||
                    query == undefined) &&
                  this.components.find(r => r.id == item.literal) ==
                    undefined &&
                  item.group2 == this.system &&
                  item.group1 == this.selectedHall
                );
              });
            } else {
              if (
                this.system == "Fire Shutter and Ret" &&
                this.periodicity == 4
              ) {
                this.availableComponents = this.componentList.filter(item => {
                  return (
                    (item.literal.toLowerCase().indexOf(query.toLowerCase()) >
                      -1 ||
                      query == "" ||
                      query == undefined) &&
                    this.components.find(r => r.id == item.literal) ==
                      undefined &&
                    (item.group2 == "Retainer" ||
                      item.group2 == "Fire Shutters") &&
                    item.group1 == this.selectedHall
                  );
                });
              } else {
                if (
                  this.system == "Electrical Panels" &&
                  this.periodicity == 4
                ) {
                  this.availableComponents = this.componentList.filter(item => {
                    return (
                      (item.literal.toLowerCase().indexOf(query.toLowerCase()) >
                        -1 ||
                        query == "" ||
                        query == undefined) &&
                      this.components.find(r => r.id == item.literal) ==
                        undefined &&
                      item.group2 == this.system &&
                      item.group1 == this.selectedHall
                    );
                  });
                } else {
                  if (this.system == "Fire Panels") {
                    this.availableComponents = this.componentList.filter(
                      item => {
                        return (
                          (item.literal
                            .toLowerCase()
                            .indexOf(query.toLowerCase()) > -1 ||
                            query == "" ||
                            query == undefined) &&
                          item.group2 == "Electrical Panels" &&
                          item.group1 == this.selectedHall &&
                          item.group3 == "Fire Panel"
                        );
                      }
                    );
                  } else {
                    if (
                      this.system == "IT and Automation" &&
                      this.periodicity != 6
                    ) {
                      this.availableComponents = this.componentList.filter(
                        item => {
                          return (
                            (item.literal
                              .toLowerCase()
                              .indexOf(query.toLowerCase()) > -1 ||
                              query == "" ||
                              query == undefined) &&
                            this.components.find(r => r.id == item.literal) ==
                              undefined &&
                            item.group2 == this.system &&
                            item.group1 == this.selectedHall &&
                            item.group3 == this.belt
                          );
                        }
                      );
                    } else {
                      if (
                        this.periodicity == 8 &&
                        (this.system == "Sorter" ||
                          this.system == "Inductions" ||
                          this.system == "UPS" ||
                          this.system == "Fire Shutters" ||
                          this.system == "Air-Condition" ||
                          this.system == "Retainer")
                      ) {
                        console.log("QUERY:" + query);
                        this.availableComponents = this.componentList.filter(
                          item => {
                            return (
                              (item.literal
                                .toLowerCase()
                                .indexOf(query.toLowerCase()) > -1 ||
                                query == "" ||
                                query == undefined) &&
                              item.group2 == this.system &&
                              item.group1 == this.selectedHall
                            );
                          }
                        );
                      } else {
                        if (
                          this.periodicity == 4 &&
                          this.system == "Pull Rope Circuits"
                        ) {
                          this.availableComponents = this.componentList.filter(
                            item => {
                              return (
                                (item.literal
                                  .toLowerCase()
                                  .indexOf(query.toLowerCase()) > -1 ||
                                  query == "" ||
                                  query == undefined) &&
                                item.group2 == "Conveyors" &&
                                item.week == this.selectedWeek
                              );
                            }
                          );
                        } else {
                          if (
                            this.periodicity == 4 &&
                            this.system == "Conveyors"
                          ) {
                            this.availableComponents = this.componentList.filter(
                              item => {
                                if (
                                  item.group4 != "EK" &&
                                  item.group4 != "EA"
                                ) {
                                  return (
                                    (item.literal
                                      .toLowerCase()
                                      .indexOf(query.toLowerCase()) > -1 ||
                                      query == "" ||
                                      query == undefined) &&
                                    item.group2 == this.system &&
                                    (item.group4 == this.belt ||
                                      (this.lines.find(
                                        r => r.component == item.id
                                      ) != undefined
                                        ? this.lines.find(
                                            r => r.component == item.id
                                          ).literal == this.belt
                                        : false))
                                  );
                                } else {
                                  console.log(item.id);
                                  return (
                                    (item.literal
                                      .toLowerCase()
                                      .indexOf(query.toLowerCase()) > -1 ||
                                      query == "" ||
                                      query == undefined) &&
                                    item.group2 == this.system &&
                                    item.group4 == this.belt &&
                                    (this.lines.find(
                                      r => r.component == item.id
                                    ) != undefined &&
                                      this.beltLine.includes(
                                        this.lines.find(
                                          r => r.component == item.id
                                        ).literal
                                      ))
                                  );
                                }
                              }
                            );
                          } else {
                            if (
                              this.periodicity == 8 &&
                              this.system == "Electrical Panels"
                            ) {
                              this.availableComponents = this.componentList.filter(
                                item => {
                                  return (
                                    (item.literal
                                      .toLowerCase()
                                      .indexOf(query.toLowerCase()) > -1 ||
                                      query == "" ||
                                      query == undefined) &&
                                    item.group2 == this.system &&
                                    item.group4 == this.belt
                                  );
                                }
                              );
                            } else {
                              if (
                                this.periodicity == 8 ||
                                this.periodicity == 4
                              ) {
                                this.availableComponents = this.componentList.filter(
                                  item => {
                                    return (
                                      (item.literal
                                        .toLowerCase()
                                        .indexOf(query.toLowerCase()) > -1 ||
                                        query == "" ||
                                        query == undefined) &&
                                      item.group2 == this.system
                                    );
                                  }
                                );
                              } else {
                                this.availableComponents = this.componentList.filter(
                                  item => {
                                    return (
                                      (item.literal
                                        .toLowerCase()
                                        .indexOf(query.toLowerCase()) > -1 ||
                                        query == "" ||
                                        query == undefined) &&
                                      item.group2 == this.system &&
                                      item.group1 == this.selectedHall
                                    );
                                  }
                                );
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  } //else {
  //this.availableComponents = [];
  //}
  //}
  public atrComponentMethod(query: string) {
    console.log(this.system);
    if (query != "" && query != undefined) {
      this.loading = true;
      this.loading = false;
      this.availableATRComponents = this.atrComponents.filter(item => {
        return (
          item.literal.toLowerCase().indexOf(query.toLowerCase()) > -1 &&
          item.group1 == this.selectedATRHall
        );
      });
    } else {
      this.availableATRComponents = [];
    }
  }
  public async getPeriodicities() {
    await ppm
      .getMaintenancePeriodicities(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then((resp: any) => {
          let i = 0;
          let commonPeriodicities: any = [];
          let commonIds = [1, 2, 4, 6, 7, 8];
          let rarePeriodicities: any = [];
          while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
            if (
              commonIds.indexOf(JSON.parse(JSON.stringify(resp))[i].id) != -1
            ) {
              commonPeriodicities.push({
                value: JSON.parse(JSON.stringify(resp))[i].id,
                label: JSON.parse(JSON.stringify(resp))[i].literal
              });
            } else {
              rarePeriodicities.push({
                value: JSON.parse(JSON.stringify(resp))[i].id,
                label: JSON.parse(JSON.stringify(resp))[i].literal
              });
            }
            i++;
          }
          this.periodicities = [
            { options: commonPeriodicities },
            { options: rarePeriodicities }
          ];
        });
      });
  }

  public async getActivities() {
    await ppm
      .getMaintenanceActivities(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then((resp: any) => {
          let i = 0;
          while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
            this.activities.push({
              value: JSON.parse(JSON.stringify(resp))[i].id,
              label: JSON.parse(JSON.stringify(resp))[i].literal
            });
            i++;
          }
        });
      });
  }
  public async getSystemPeriodicities() {
    await ppm
      .getSystemPeriodicities(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        console.log("response12")
        console.log(response)
        console.log("response12")
        response.json().then((resp: any) => {
          let i = 0;
          while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
            this.systemPeriodicities.push({
              system: JSON.parse(JSON.stringify(resp))[i].system,
              periodicity: JSON.parse(JSON.stringify(resp))[i].periodicity
            });
            i++;
          }
        });
      });
     
      console.log("this.systemPeriodicities1")
      console.log(this.systemPeriodicities)
      //edw einai ola ta periodicities me ola ta components
      console.log("this.systemPeriodicities2")
  }
  public async getSenTechList() {
    await aiaPersonnel
      .getAllPersonnel(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.senTechList = JSON.parse(JSON.stringify(resp));
          for (const p of this.senTechList) {
            this.senTechs.push({
              label: p.firstname + " " + p.lastname,
              value: p.id,
              team: p.team
            });
          }
          console.log(this.senTechs);
        });
      });
  }
  public async getPersonnelList() {
    await personnel
      .getAllPersonnel(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.personnelList = JSON.parse(JSON.stringify(resp));
        });
      });
  }

  public async getRemarks() {
    await ppm.getSomeRemarks(
      this.$store.getters.getToken,
      {
        periodicity: this.periodicity,
        system:
          this.system == "Fire Panels" && this.periodicity == 7
            ? "Electrical Panels"
            : this.system
      },
      this.$store.getters.getHost
    );
  }

  public beforeDestroy() {
    this.$store.dispatch("updateCurrentObject", {});
  }
  public async getComponentList() {
    await component
      .getAllComponents(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.componentList = JSON.parse(JSON.stringify(resp));
          let tempcompo = this.componentList.filter(r=>r.group2==this.system)
          for (const i of this.componentList) {
            if (i.group2 == "ATR") {
              if (!this.atrGroups.includes(i.group4)) {
                this.atrGroups.push(i.group4);
              }
            }
            if (i.group2 == "UPS") {
              if (i.group1 == "South") {
                if (!this.southUps.includes(i.group4)) {
                  this.southUps.push(i.group4);
                }
              } else {
                if (!this.northUps.includes(i.group4)) {
                  this.northUps.push(i.group4);
                }
              }
              if (!this.upsGroups.includes(i.group4)) {
                this.upsGroups.push(i.group4);
              }
            }
            if (i.group2 == "Sorter") {
              if (i.group1 == "North") {
                if (!this.northSorter.includes(i.group4)) {
                  this.northSorter.push(i.group4);
                }
              } else {
                if (!this.southSorter.includes(i.group4)) {
                  this.southSorter.push(i.group4);
                }
              }
            }
            if (i.group2 == "IT and Automation") {
              if (!this.itGroups.includes(i.group3)) {
                this.itGroups.push(i.group3);
              }
            }
            if (i.group4 == "ATR" || i.group2 == "ATR") {
              this.atrComponents.push(i);
            }
            if (i.group2 == "Inductions") {
              if (i.group1 == "North") {
                if (!this.northInductions.includes(i.group4)) {
                  this.northInductions.push(i.group4);
                }
              } else {
                if (!this.southInductions.includes(i.group4)) {
                  this.southInductions.push(i.group4);
                }
              }
            }
            if (!this.halls.includes(i.group1)) {
              this.halls.push(i.group1);
            }
            if (!this.systems.includes(i.group2)) {
              this.systems.push(i.group2);
            }
            if (!this.groups.includes(i.group3)) {
              this.groups.push(i.group3);
            }
            if (!this.codes.includes(i.group4)) {
              this.codes.push(i.group4);
            }
            if (!this.descriptions.includes(i.group5)) {
              this.descriptions.push(i.group5);
            }
            if (i.group2 == "Conveyors") {
              if (!this.conveyorBelts.includes(i.group4)) {
                this.conveyorBelts.push(i.group4);
              }
            }
            if (i.group2 == "Electrical Panels") {
              if (i.group1 == "South") {
                if (!this.southPanels.includes(i.group4)) {
                  this.southPanels.push(i.group4);
                }
              } else {
                if (!this.northPanels.includes(i.group4)) {
                  this.northPanels.push(i.group4);
                }
              }
            }
          }
          console.log(this.atrComponents);
        });
      });
  }
  public async validate() {
    if (this.periodicity == 4 && this.system == "Conveyors") {
      this.jsonRemarks = this.beltLine;
    }
    let valid = true;
    for (const c of this.components) {
      if (c.actions == undefined || c.actions == "") {
        this.$message.error("Set activity on " + c.id);
        valid = false;
      }
    }
    if (this.techs.length == 0) {
      this.$message.error("Please add personnel");
      valid = false;
    }
    if (this.status.selectedStatus != 2) {
      this.status = {
        enddate: new Date(0),
        selectedStatus: this.status.selectedStatus
      };
    }
    console.log(this.status);
    if (this.constructDate() && valid) {
      console.log({
        periodicity: this.periodicity,
        components: this.components,
        personnel: this.techs,
        status: this.status
      });
      if (this.system == "Detectors") {
        this.jsonRemarks = this.selectedGroups;
      }
      if (
        this.system == "Scales" ||
        (this.system == "IT and Automation" && this.periodicity == 7)
      ) {
        this.jsonRemarks = this.components;
      }
      if (this.row.id != undefined) {
        console.log("id");
        console.log(this.row.id);
        await ppm
          .editPPM(
            this.$store.getters.getToken,
            {
              multiremarks:
                this.jsonRemarks.length != 0 ? this.jsonRemarks : [],
              hall:
                (this.system == "IT and Automation" && this.periodicity == 7) ||
                this.system == "Detectors" ||
                this.system == "Fire Panels" ||
                (this.system == "Electrical Panels" &&
                  (this.periodicity == 4 || this.periodicity == 8)) ||
                ((this.system == "Sorter" ||
                  this.system == "UPS" ||
                  this.system == "Inductions") &&
                  this.periodicity == 8) ||
                (this.periodicity == 4 &&
                  (this.system == "Fire Shutter and Ret" ||
                    this.system == "Reclaims"))
                  ? this.selectedHall
                  : undefined,
              belt:
                (this.periodicity == 4 && this.system == "Conveyors") ||
                (this.periodicity == 8 && this.system == "Electrical Panels") ||
                (this.periodicity != 8 && this.system == "IT and Automation") ||
                (this.periodicity == 8 &&
                  (this.system == "Sorter" ||
                    this.system == "UPS" ||
                    this.system == "Inductions"))
                  ? this.belt
                  : undefined,
              periodicity: this.periodicity,
              system:
                this.system == "Fire Panels"
                  ? "Electrical Panels"
                  : this.system,
              personnel: this.techs,
              id: this.row.id,
              status: this.status,
              completion:
                this.periodicity == 4 && this.system == "Pull Rope Circuits"
                  ? JSON.stringify({
                      week1: {
                        parts: this.W1Parts,
                        completion: this.W1Completion
                      },
                      week2: {
                        parts: this.W2Parts,
                        completion: this.W2Completion
                      },
                      week3: {
                        parts: this.W3Parts,
                        completion: this.W3Completion
                      },
                      week4: {
                        parts: this.W4Parts,
                        completion: this.W4Completion
                      }
                    })
                  : JSON.stringify({
                      north: {
                        parts: this.Nparts,
                        completion: this.Ncompletion
                      },
                      south: {
                        parts: this.Sparts,
                        completion: this.Scompletion
                      }
                    }),
              workOrders: this.workOrders,
              senTech: isNaN(this.senTech)
                ? this.senTechList.find(
                    r =>
                      r.firstname == this.senTech.split(" ")[0] &&
                      r.lastname == this.senTech.split(" ")[1]
                  ).id
                : this.senTech,
              foreman: isNaN(this.foreman)
                ? this.personnelList.find(
                    r =>
                      r.firstname == this.foreman.split(" ")[0] &&
                      r.lastname == this.foreman.split(" ")[1]
                  ).id
                : this.foreman,
              component:
                this.system == "Conveyors" &&
                this.periodicity == 4 &&
                (this.belt == "EA" || this.belt == "EK")
                  ? this.beltLine
                  : this.periodicity == 8 ||
                    (this.periodicity == 6 &&
                      this.system == "IT and Automation")
                  ? isNaN(this.yearlyComponent)
                    ? (await this.componentList.find(
                        r => r.literal == this.yearlyComponent
                      )).id
                    : this.yearlyComponent
                  : undefined,
              remarks:
                this.periodicity == 8 ||
                this.system == "Fire Panels" ||
                (this.periodicity == 6 && this.system == "IT and Automation")
                  ? this.yearlyRemarks
                  : undefined,
              activity:
                this.periodicity == 8 ||
                this.system == "Fire Panels" ||
                (this.periodicity == 6 && this.system == "IT and Automation")
                  ? this.yearlyActions
                  : undefined
            },
            this.$store.getters.getHost
          )
          .then(async r => {
            if (
              (this.periodicity != 8 &&
                (this.periodicity != 7 &&
                  this.system != "IT and Automation")) ||
              (this.periodicity == 8 &&
                (this.system == "Sorter" ||
                  this.system == "UPS" ||
                  this.system == "Inductions"))
            ) {
              if (this.system == "Inductions") {
                let tempGroups: any = [];
                for (const g of this.selectedGroups) {
                  for (const s of g.value) {
                    tempGroups.push({
                      id: s,
                      remarks: g.remarks,
                      value: s,
                      actions: g.actions,
                      hall: g.hall
                    });
                  }
                }
                this.selectedGroups = tempGroups;
              }
              if (
                this.system == "Conveyors" ||
                this.system == "Desks" ||
                this.system == "Chutes"
              ) {
                let tempCompos: any = [];
                for (const c of this.components) {
                  for (const s of c.id) {
                    console.log(s);
                    tempCompos.push({
                      id: s,
                      remarks: c.remarks,
                      value: s,
                      actions: c.actions,
                      hall: c.hall
                    });
                  }
                }
                this.components = tempCompos;
              }
              await ppm
                .postRemarks(
                  this.$store.getters.getToken,
                  this.system == "Inductions" || this.system == "Sorter"
                    ? this.selectedGroups
                    : this.components,
                  this.$store.getters.getHost,
                  this.periodicity,
                  this.system == "Fire Panels"
                    ? "Electrical Panels"
                    : this.system
                )
                .then(r => {
                  if (r.status == 400) {
                    this.$message.error(r.error);
                  } else {
                    this.$message.success("PPM was edited successfully");
                    this.$store.dispatch("updateCurrentObject", {});
                    this.$store.dispatch("updateCurrentComponent", "PPMList");
                    this.$router.replace({
                      name: "load"
                    });
                  }
                });
            } else {
              this.$store.dispatch("updateCurrentObject", {});
              this.$store.dispatch("updateCurrentComponent", "PPMList");
              this.$router.replace({
                name: "load"
              });
            }
          });
      } else {
        console.log(this.system);
        console.log(this.selectedHall);
        await ppm
          .addPPM(
            this.$store.getters.getToken,
            {
              multiremarks:
                this.jsonRemarks.length != 0 ? this.jsonRemarks : [],
              hall:
                (this.system == "IT and Automation" && this.periodicity == 7) ||
                this.system == "Detectors" ||
                this.system == "Fire Panels" ||
                (this.system == "Electrical Panels" &&
                  (this.periodicity == 4 || this.periodicity == 8)) ||
                ((this.system == "Sorter" ||
                  this.system == "UPS" ||
                  this.system == "Inductions") &&
                  this.periodicity == 8) ||
                (this.periodicity == 4 &&
                  (this.system == "Fire Shutter and Ret" ||
                    this.system == "Reclaims"))
                  ? this.selectedHall
                  : undefined,
              belt:
                (this.periodicity == 4 && this.system == "Conveyors") ||
                (this.periodicity == 8 && this.system == "Electrical Panels") ||
                (this.periodicity != 8 && this.system == "IT and Automation") ||
                (this.periodicity == 8 &&
                  (this.system == "Sorter" ||
                    this.system == "UPS" ||
                    this.system == "Inductions"))
                  ? this.belt
                  : undefined,
              periodicity: this.periodicity,
              personnel: this.techs,
              status: this.status,
              system:
                this.system == "Fire Panels"
                  ? "Electrical Panels"
                  : this.system,
              completion:
                this.periodicity == 4 && this.system == "Pull Rope Circuits"
                  ? JSON.stringify({
                      week1: {
                        parts: this.W1Parts,
                        completion: this.W1Completion
                      },
                      week2: {
                        parts: this.W2Parts,
                        completion: this.W2Completion
                      },
                      week3: {
                        parts: this.W3Parts,
                        completion: this.W3Completion
                      },
                      week4: {
                        parts: this.W4Parts,
                        completion: this.W4Completion
                      }
                    })
                  : JSON.stringify({
                      north: {
                        parts: this.Nparts,
                        completion: this.Ncompletion
                      },
                      south: {
                        parts: this.Sparts,
                        completion: this.Scompletion
                      }
                    }),
              workOrders: this.workOrders,
              senTech: this.senTech,
              foreman: this.foreman,
              component:
                this.system == "Conveyors" &&
                this.periodicity == 4 &&
                (this.belt == "EA" || this.belt == "EK")
                  ? this.beltLine
                  : (this.periodicity == 8 ||
                      (this.periodicity == 6 &&
                        this.system == "IT and Automation")) &&
                    this.system != "Sorter" &&
                    this.system != "UPS"
                  ? this.yearlyComponent
                  : undefined,
              remarks:
                this.system == "Fire Panels" ||
                ((this.periodicity == 8 ||
                  (this.periodicity == 6 &&
                    this.system == "IT and Automation")) &&
                  this.system != "Sorter")
                  ? this.yearlyRemarks
                  : undefined,
              activity:
                this.system == "Fire Panels" ||
                ((this.periodicity == 8 ||
                  (this.periodicity == 6 &&
                    this.system == "IT and Automation")) &&
                  this.system != "Sorter")
                  ? this.yearlyActions
                  : undefined
            },
            this.$store.getters.getHost
          )
          .then(async r => {
            if (
              (this.periodicity != 8 &&
                (this.periodicity != 7 &&
                  this.system != "IT and Automation")) ||
              (this.periodicity == 8 &&
                (this.system == "Sorter" ||
                  this.system == "UPS" ||
                  this.system == "Inductions"))
            ) {
              if (this.system == "Inductions") {
                let tempGroups: any = [];
                for (const g of this.selectedGroups) {
                  for (const s of g.value) {
                    tempGroups.push({
                      id: s,
                      remarks: g.remarks,
                      value: s,
                      actions: g.actions,
                      hall: g.hall
                    });
                  }
                }
                this.selectedGroups = tempGroups;
              }
              if (
                this.system == "Conveyors" ||
                this.system == "Desks" ||
                this.system == "Chutes"
              ) {
                console.log(this.components);
                let tempCompos: any = [];
                for (const c of this.components) {
                  for (const s of c.id) {
                    console.log(s);
                    tempCompos.push({
                      id: s,
                      remarks: c.remarks,
                      value: s,
                      actions: c.actions,
                      hall: c.hall
                    });
                  }
                }
                console.log(tempCompos);
                this.components = tempCompos;
              }
              await ppm
                .postRemarks(
                  this.$store.getters.getToken,
                  this.system == "Sorter" || this.system == "Inductions"
                    ? this.selectedGroups
                    : this.components,
                  this.$store.getters.getHost,
                  this.periodicity,
                  this.system == "Fire Panels"
                    ? "Electrical Panels"
                    : this.system
                )
                .then(r => {
                  if (r.status == 400) {
                    this.$message.error(r.error);
                  } else {
                    this.$message.success("PPM was edited successfully");
                    this.$store.dispatch("updateCurrentObject", {});
                    this.$store.dispatch("updateCurrentComponent", "PPMList");
                    this.$router.replace({
                      name: "load"
                    });
                  }
                });
            } else {
              this.$store.dispatch("updateCurrentObject", {});
              this.$store.dispatch("updateCurrentComponent", "PPMList");
              this.$router.replace({
                name: "load"
              });
            }
          });
      }
    }
  }

  public async created() {
    // await this.getSystemPeriodicities();
    // await this.getPeriodicities();
    // await this.getActivities();
    // await this.getWorkOrderList();
    // await this.getSenTechList();
    // await this.getPersonnelList();
    // await this.getComponentList();
    // await this.getZones();
    // await this.getWeeks();
    // await this.getLines();
  }

  public async getLines() {
    await ppm
      .getConveyorLines(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.lines = JSON.parse(JSON.stringify(resp));
          this.conveyorBelts = this.conveyorBelts.filter(
            r =>
              !["CA", "CB", "CC", "CD", "CE", "CL", "CM", "CK", "AA"].includes(
                r
              )
          );
          for (const l of this.lines) {
            if (l.literal.indexOf("Line") == -1) {
              if (!this.conveyorBelts.includes(l.literal)) {
                this.conveyorBelts.push(l.literal);
              }
            } else {
              if (!this.conveyorNumbers.includes(l.literal)) {
                this.conveyorNumbers.push(l.literal);
              }
            }
          }
          console.log(this.conveyorBelts);
        });
      });
  }

  public async getZones() {
    for (const z of await (await ppm.getZones(
      this.$store.getters.getHost,
      this.$store.getters.getToken
    )).json()) {
      if (z.hall == "South") {
        this.southZones.push(z);
      } else {
        this.northZones.push(z);
      }
    }
  }

  public async getWeeks() {
    console.log("weeks");
    await component
      .getComponentWeek(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then((resp: any) => {
          let i = 0;
          while (i <= resp.length - 1) {
            let compo = this.componentList.find(
              r => r.id == resp[i].component_id
            );
            compo.week = resp[i].week;
            i++;
          }
        });
      });
    this.checkEdit();
  }
  public constructDate(): boolean {
    let valid = true;
    for (const t of this.techs) {
      try {
        let starthour = t.start.split(":")[0];
        let startminute = t.start.split(":")[1];
        let endhour = t.end.split(":")[0];
        let endminute = t.end.split(":")[1];
        if (starthour < 0 || starthour > 23) {
          valid = false;
          this.$message.error("Invalid Start Hours");
        }
        if (valid && (endhour < 0 || endhour > 23)) {
          valid = false;
          this.$message.error("Invalid Start Hours");
        }
        if (valid && (startminute < 0 || startminute > 59)) {
          valid = false;
          this.$message.error("Invalid Start Minutes");
        }
        if (valid && (endminute < 0 || endminute > 59)) {
          valid = false;
          this.$message.error("Invalid End Minutes");
        }
        if (valid) {
          t.date_started = Moment(t.date)
            .hours(starthour)
            .minutes(startminute)
            .toDate();
          t.date_ended = Moment(t.date)
            .hours(endhour)
            .minutes(endminute)
            .toDate();
        } else {
          this.$message.error("Invalid Dates");
          return false;
        }
      } catch (e) {
        this.$message.error("Invalid Dates");
        return false;
      }
    }
    return true;
  }
}
</script>
<style scoped>
.tableCell {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 220px;
  position: relative;
  height: 30px;
}
.longTableCell {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 400px;
  position: relative;
  height: 30px;
}
.superLongTableCell {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 100%;
  position: relative;
  height: 30px;
}
.superLongTableCellFixed {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 550px;
  position: relative;
  height: 30px;
}
.shortTableCell {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 100px;
  position: relative;
  height: 30px;
}
.midTableCell {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 120px;
  position: relative;
  height: 30px;
}
.tableTag {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 100px;
  height: 30px;
}
.yellowTableTag {
  background-color: rgb(253, 255, 136);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 100px;
  height: 30px;
}
.dateTableCell {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 150px;
  position: relative;
  height: 30px;
}
.tagText {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.notATextbox {
  width: 100%;
  padding-left: 5px;
  background-color: rgb(226, 226, 226);
  border: 1px solid rgb(0, 0, 0);
  font-size: 9pt !important;
  overflow-wrap: break-word;
}
.white {
  color: white;
}
</style>