<template>
  <div>
    <!-- {{filters}} -->
     <el-row class="container">
      <NewProjectFilters v-model="filters"></NewProjectFilters>
      <br>
      <el-button @click="search" type="success" v-if="!loading">
        <strong class="white">Search</strong>
      </el-button>
    </el-row>
    <br>
    <!-- :data="PPMList" -->
    <el-row class="container">   
      <el-table
        :data="NewProjectList"        
        :row-class-name="PPMStatus"
        v-loading="loading"
        @sort-change="sortDates"
        border
        :header-cell-style="{background:'rgb(170, 238, 238)',color:'black' ,'text-align':'center'}"
      >
        
        <el-table-column prop="sector" align="center" label="Sector"></el-table-column>
        <el-table-column prop="place" align="center" label="Place"></el-table-column>
        <el-table-column align="center" label="Project Title" prop="project_title"></el-table-column>
        <el-table-column align="center" label="Project Manager" prop="specialty" ></el-table-column>
        <el-table-column prop="status" label="Status" align="center"></el-table-column>
         
      
        
        <el-table-column fixed="right" label="Actions" width="120">
          <template slot-scope="scope">
            <el-button
              circle
              type="primary"
              icon="el-icon-edit"
              @click="handleClick(scope.$index, scope.row)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="deleteNewProject(scope.$index, scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>
<script lang="ts">
import Moment from "moment";
import { Component, Vue, Prop } from "vue-property-decorator";
import { component } from "../../api/component/component";
import { personnel } from "../../api/personnel/personnel";
import { workorder } from "../../api/workorder/workorder";
import NewProjectFilters from "../Filters/NewProjectFilters.vue";
import { ppm } from "../../api/ppm/ppm";
import { status } from "../../api/status/status";
import { new_project } from "../../api/new_project/new_project";
@Component({
  name: "NewProjectList",
  components: {
    NewProjectFilters
  }
})
export default class NewProjectList extends Vue {
  public NewProjectList: any[] = [];

  public PPMList: any[] = [];
  public componentList: any[] = [];
  public componentLiterals: any[] = [];
  public personnelList: any[] = [];
  public workOrderList: any[] = [];
  public statusList: any[] = [];
  public periodicityList: any[] = [];
  public loading: boolean = false;
  public filters: any = {};
  public newprojectpersonnelList: any[] = [];
  public test2:any  = ""

  public componentIDFormatter(row, column, cellValue, index){
    return Array.from(row.component_table, (x:any) => x['id_component']).join(', ');
  }

 public async created() {
    await this.getNewProjectList();
    this.loading = true;
    await this.getPersonnelList();
    this.fillTable();
    console.log('calling getPPMList2')
    //this.getPPMList2();
    

  }
 public async getFilters2() {
    // let a = [];
    // {
    //   for (const s of this.statusList) {
    //     a.push({ value: s.id, text: s.literal });
    //   }
    // }
    // return a;
  }
 // deletePPM
    public async deleteNewProject(rowIndex: number, row: any) {
      console.log('test12')
       console.log(row.id)
    await new_project
      .deleteNewProject(
        this.$store.getters.getHost,
        this.$store.getters.getToken,  
        row.id       
      )
      .then(async () => {
          this.$message.success("Project Deleted");
          this.$router.replace({
            name: "load"
          });
      });
  }
  public async getNewProjectList() {
     
    await new_project
      .getAllNewProject(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(async resp => {
          console.log(JSON.stringify(resp))
          console.log('resp')
          this.newprojectpersonnelList = JSON.parse(JSON.stringify(resp));
           console.log(JSON.stringify(this.newprojectpersonnelList))
           console.log('this.newprojectpersonnelList')
          await this.getStatusList();
        });
      });
  // console.log(this.newprojectpersonnelList)
  // console.log('this.newprojectpersonnelList')
  }

  public getHall(row: any, column: any) {
    // console.log(row);
    // let component =  this.newprojectpersonnelList.find(
    //   (obj: any) => obj.id === row.components[0]
    // );

    // if (component != undefined) {
    //   return component.group1;
    // }
// let test1= "1"
// let test2= "2"
// test2+=" "+test1
//              for (const x of this.NewProjectList){
//             let temtable= [];
//             let cc=0
//             let test = ""
//             for(const y of x.new_project_personnel_table){             
//                temtable[cc]=y.personnel_id
//               // console.log('testforyy')
//               // console.log(temtable)
//               // console.log('testforzz')
//               console.log(temtable[cc])
//               test+=" "+temtable[cc]
//                //return temtable[cc]
//                 return test
//               console.log(test);
//               console.log('testforzz123')
//                cc++
//             }
            
//            console.log(test)
//            console.log('testfortttttt')
          
//           }
// for(const y of row){
// console.log('abcd')
  // // for(const x of y.new_project_personnel_table){
  // //   console.log(x)
  // //   console.log('abcd')
//return x.new_project_specialties_id
  // // }
// }
this.test2 =" "
for(const x of row.new_project_personnel_table){
console.log(x.personnel_id)
console.log('abcd')
this.test2+= ' '+x.personnel_id
console.log(this.test2)
console.log('test2')
}
// console.log(this.test2)
// console.log('test22222222222')
return this.test2
 

  }







  // na to valw gia na fenonte otan vazo to pontiki apo pano select subpanel !!!!!
  //  <el-table-column
  //     property="address"
  //     label="Address"
  //     show-overflow-tooltip>
  
////?

// public getPPMList2(){

// // let r = await ppm.getALLPpmsList2(this.$store.getters.getToken, this.$store.getters.getHost)
    
// //     if (r.status == 400) {
// //       this.$message.error(r.error);
// //     } else {
// //       console.log("THIS IS THE POST RESPONSE")
// //       console.log(JSON.stringify(r))

// //       let ppm_id = r.ppm_id; 

// // }

// }
public Completiondate(row: any) {
    // if (row.status.enddate === undefined) {
    //   return "";
    // }
    console.log(JSON.stringify(row))
    
   return Moment(row.date_performed).format("DD/MM/YYYY");
  }



   public async getPPMList2() {

    // var filters = {
    //   fromDate: JSON.stringify(new Date(0)),
    //   toDate: JSON.stringify(new Date(86400000000000)),
    //   system: 3,
    //   status: 2,
    //   maintenance_period: "2019-2020",
    //   component: 146,
    // }
    
    //   .getSomePpmsList2(this.$store.getters.getToken, filters, this.$store.getters.getHost)

    await ppm
      .getALLPpmsList2(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        
        response.json().then(async resp => {

          this.PPMList = JSON.parse(JSON.stringify(resp));

          console.log('ppmlist1')
          console.log(JSON.stringify(this.PPMList))
          console.log('ppmlist2')
          
          // await this.getComponentList();
        });
        

      });
  }

  public getDate(row: any, column: any) {
    let date_started = new Date(8640000000000000);
    let date_ended = new Date(0);
    for (const p of row.personnel) {
      if (new Date(p.date_started) < new Date(date_started)) {
        date_started = p.date_started;
      }
      if (new Date(p.date_ended) > new Date(date_ended)) {
        date_ended = p.date_ended;
      }
    }
    return (
      Moment(date_started).format("DD/MM/YYYY HH:mm") +
      " - " +
      Moment(date_ended).format("HH:mm")
    );
  }
  public sortDates() {
    return this.PPMList.sort(function(a, b) {
      let aDate_started = new Date(8640000000000000);
      let bDate_started = new Date(8640000000000000);
      for (const p of a.personnel) {
        if (
          new Date(p.date_started).getTime() < new Date(aDate_started).getTime()
        ) {
          aDate_started = p.date_started;
        }
      }
      for (const p of b.personnel) {
        if (
          new Date(p.date_started).getTime() < new Date(bDate_started).getTime()
        ) {
          bDate_started = p.date_started;
        }
      }
      console.log(a);
      console.log(b);
      if (
        new Date(aDate_started).getTime() < new Date(bDate_started).getTime()
      ) {
        return 1;
      }
      return -1;
    });
  }
  public filterHandler(value: any, row: any, column: any) {
    const property = column["property"];
    return row[property] === value;
  }
  public getFilters() {
    let a: any = [];
    
    for (const s of this.statusList) {
      a.push({ value: s.id, text: s.literal });
    }
    
    return a;
  }

  public getPeriodicityFilters() {
    let a: any = [];
    let commonPeriodicities: any = [];
    let commonIds = [1, 2, 4, 6, 7, 8];
    let rarePeriodicities: any = [];
    for (const s of this.periodicityList) {
      if (commonIds.indexOf(s.id) != -1) {
        commonPeriodicities.push({ value: s.id, text: s.literal });
      } else {
        rarePeriodicities.push({ value: s.id, text: s.literal });
      }
    }
    for (const s of commonPeriodicities) {
      a.push(s);
    }
    for (const s of rarePeriodicities) {
      a.push(s);
    }
    return a;
  }
  public getComponentName(row: any, column: any): String {
    let content = "";
    for (const k of row.components) {
      if (content != "") {
        content = content + ", ";
      }
      let component = this.componentList.find((obj: any) => obj.id === k.id);
      if (component != undefined) {
        content = content + component.literal;
      }
    }
    return content;
  }

  public getSystem(row: any, column: any): String {
    return row.system;
  }
  public async getStatusList() {
    await status
      .getAllStatuses(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then((resp: any) => {
          this.statusList = JSON.parse(JSON.stringify(resp));
          this.getComponentList();
        });
      });
  }
  public async getPeriodicities() {
    await ppm
      .getMaintenancePeriodicities(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then((resp: any) => {
          this.periodicityList = JSON.parse(JSON.stringify(resp));
          this.loading = false;
        });
      });
  }
  public filterDateTime(date: any) {
    console.log(date);
    if (date != undefined) {
      return Moment(date).format("DD/MM/YYYY HH:mm");
    }
    return "";
  }
  public getStatus(row: any, column: any): String {
    let status = this.statusList.find((obj: any) => obj.id === row.status);
    if (status != undefined) {
      return status.literal;
    }
    return "";
  }
  public getPeriodicity(row: any, column: any): String {
    let periodicity = this.periodicityList.find(
      (obj: any) => obj.id === row.periodicity
    );
    if (periodicity != undefined) {
      return periodicity.literal;
    }
    return "";
  }
  public PPMStatus({
    row,
    rowIndex
  }: {
    row: number;
    rowIndex: number;
  }): String {
    console.log(row)
    console.log('testrow')
    return this.getStatus(row, 1);
  }


  public async search() {
    this.loading = true;
    console.log('JSON.stringify(this.filters) BEFORE DELETES1');
    console.log(JSON.stringify(this.filters));

    var fromDateOld = null
    if (this.filters.fromDate == null) {
      delete this.filters.fromDate;
    }
    if(this.filters.fromDate){
      fromDateOld = this.filters.fromDate
      this.filters.fromDate = JSON.stringify(this.filters.fromDate)
    }

    var toDateOld = null
    if (this.filters.toDate == null) {
      delete this.filters.toDate;
    }
    if(this.filters.toDate){
      toDateOld = this.filters.toDate
      this.filters.toDate = JSON.stringify(this.filters.toDate)
    }
    if(this.filters.place == ''){
      delete this.filters.place;
    }

    if(this.filters.project_title == ''){
      delete this.filters.project_title;
    }

    if(this.filters.status == ''){
      delete this.filters.status;
    }
    if(this.filters.sector == ''){
      delete this.filters.sector;
    }
  

    
    console.log('JSON.stringify(this.filters) AFTER DELETES1');
    console.log(JSON.stringify(this.filters));
    
  
    await new_project
      .getSomeNewProject(this.$store.getters.getToken, this.filters, this.$store.getters.getHost)
      .then(response => {
        
        response.json().then(async resp => {

          this.NewProjectList = JSON.parse(JSON.stringify(resp));
          //this.PPMList = JSON.parse(JSON.stringify(resp));
           
  //         for (const x of this.NewProjectList){
  //           let temtable= [];
  //           let cc=0
  //           x 
  //           for(const y of x.new_project_personnel_table){             
  //              temtable[cc]=y.personnel_id
  //             // console.log('testforyy')
  //             // console.log(temtable)
  //             // console.log('testforzz')
  //             console.log(temtable[cc])
  //             console.log('testforzz')
  //              cc++
  //           }

  //          console.log(temtable)
  //          console.log('testfortt')
  //         }
  //  //sum = sum + t.total_days_req
  //   //    sum0 = sum0 + (moment(t.leave_on).format("DD/MM/YYYY") + '-' + moment(t.return_on).format("DD/MM/YYYY") + ' ')

  //       let response = [];
  //       let temp_table4=[]        
  //       let cc4=0
  //       for(let i of this.NewProjectList){
  //        let row2 ={ "id": "11",   "sector": "Airport",   "place": "test11",    "project_title": "aaa",    "date_started": "2020-09-01T00:00:00.000Z",    "date_ended": "2020-09-02T00:00:00.000Z",    "status": "Pending",    "new_project_personnel_table": []}
  //       let row2 ={id:"", new_project_id:"" , personnel_id:"" , new_project_specialties_id:"" }
  //       row2.id=i.id
  //       row2.new_project_id=i.new_project_id
  //       let x
  //       let y
  //       x = personnelList.find(obj=>obj.id==i.personnel_id).lastname
  //       y = personnelList.find(obj=>obj.id==i.personnel_id).firstname
  //       console.log(x)
  //       console.log('xxxxxxxxxxxxx')
  //       row2.personnel_id= x+" "+y
  //       for (let x of specialtiesList){        
  //         if(i.new_project_specialties_id== x.id){
  //           row2.new_project_specialties_id=x.literal                 
  //         }
  //       }                   
        
  //       console.log(JSON.stringify(row2))
  //       console.log('new_project12')
  //         temp_table4.push(row2)  
  //         row.new_project_personnel_table.push(temp_table4[cc4])
  //         cc4++
    
  //     }
  //     response.push(row)

for(let x of this.NewProjectList){
  

 let specialty = x.new_project_personnel_table.find(y => y.new_project_specialties_id == "Project Manager");
      //  x= {new_project_specialties_id: specialty }
      // x.push(row)
      x.specialty = specialty.personnel_id
console.log(specialty.new_project_specialties_id)
console.log('specialty1234')
console.log(x)


}

          
          console.log('NewProjectList1')
          console.log(JSON.stringify(this.NewProjectList))
          console.log('NewProjectList2')
        });
      });
    
      if(fromDateOld){
        this.filters.fromDate = fromDateOld
      }
    
      if(toDateOld){
        this.filters.toDate = toDateOld
      }

    this.loading = false;
  }
  public fillTable() {
    for (const p of this.PPMList) {
      //p.personnel = JSON.parse(p.personnel);
    }
    this.loading = false;
  }
 
  public filterDate(date: any) {
    if (date != undefined) {
      return Moment(date).format("DD-MM-YYYY");
    }
    return "";
  }
  public handleClick(rowIndex: number, row: any) {
    console.log("ROW");
    console.log(row);
    console.log("ROW")
    this.$store.dispatch("updateCurrentObject", row);
    this.$store.dispatch("updateCurrentComponent", "AddNewProject");
    this.$router.replace({ name: "load" });
  }
 

  public async getComponentList() {
    await component
      .getAllComponents(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(async resp => {
          this.componentList = JSON.parse(JSON.stringify(resp));
          await this.getPeriodicities();
        });
      });
  }
  public async getPersonnelList() {
    await personnel
      .getAllPersonnel(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(async resp => {
          this.personnelList = JSON.parse(JSON.stringify(resp));
          await this.getStatusList();
        });
      });
  }
}
</script>
<style>
.el-table .Pending {
  background: rgb(236, 255, 149);
}

.el-table .Completed {
  background: #ffffff;
}

.el-table .Cancelled {
  background: rgb(255, 186, 186);
}
</style>
<style scoped>
.container {
  border: 2px solid rgb(170, 238, 238);
  border-radius: 10px;
  background-color: rgb(248, 247, 247);
  padding: 5px 10px 5px 10px;
}
.personnel {
  background-color: rgb(230, 242, 255);
  font-weight: normal !important;
  padding-left: 10px;
  padding-right: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}
.personnelHead {
  background-color: rgb(255, 242, 240);
  font-weight: normal !important;
  padding-left: 10px;
  padding-right: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}
</style>