<template>
  <div>
    <el-collapse accordion v-model="activeName">
      <el-collapse-item title="PPM Filters" name="1">
        <div class="container">
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>From Date:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-date-picker
                v-model="$props.filters.dateFrom"
                type="date"
                placeholder="From Date"
                format="dd/MM/yyyy"
                size="mini"
                :style="{width:'200px'}"
                clearable
              ></el-date-picker>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>To Date:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-date-picker
                v-model="$props.filters.dateTo"
                type="date"
                placeholder="To Date"
                format="dd/MM/yyyy"
                size="mini"
                :style="{width:'200px'}"
                clearable
              ></el-date-picker>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>System:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-select
                v-model="$props.filters.system"
                placeholder="Select System"
                size="mini"
                :style="{width:'200px'}"
                clearable
                v-loading="loading"
              >
                <el-option
                  v-for="item in systems"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>PPM Periodicity:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-select
                v-model="$props.filters.periodicity"
                placeholder="PPM Periodicity"
                :items="periodicities"
                size="mini"
                clearable
                :style="{width:'200px'}"
              >
                <el-option-group
                  v-for="group in periodicities"
                  :key="group.label"
                  :label="group.label"
                >
                  <el-option
                    v-for="item in group.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-option-group>
              </el-select>
            </el-col>
          </el-row>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script lang="ts">
import ComponentSelector from "../Selectors/ComponentSelector.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { ppm } from "../../api/ppm/ppm";
import { group } from "../../api/group/group";

@Component({
  name: "PPMFilters",
  props: {
    filters: Object
    //  {
    //   idFrom: "",
    //   idTo: "",
    //   dateFrom: "",
    //   dateTo: "",
    //   search: "",
    //   system: "",
    //   periodicity: ""
    // }
  },
  model: {
    prop: "filters",
    event: "change"
  }
})
export default class WorkOrderFilters extends Vue {
  public loading: boolean = false;
  public activeName: any = "1";
  public periodicities: any[] = [];
  public systems: any[] = [];

  public async mounted() {
    this.loading = true;
    await this.getGroupList();
    await this.getPeriodicities();
    this.loading = false;
  }
  public async getPeriodicities() {
    await ppm
      .getMaintenancePeriodicities(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then((resp: any) => {
          let i = 0;
          let commonPeriodicities: any = [];
          let commonIds = [1, 2, 4, 8];
          let rarePeriodicities: any = [];
          while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
            if (
              commonIds.indexOf(JSON.parse(JSON.stringify(resp))[i].id) != -1
            ) {
              commonPeriodicities.push({
                value: JSON.parse(JSON.stringify(resp))[i].id,
                label: JSON.parse(JSON.stringify(resp))[i].literal
              });
            } else {
              rarePeriodicities.push({
                value: JSON.parse(JSON.stringify(resp))[i].id,
                label: JSON.parse(JSON.stringify(resp))[i].literal
              });
            }
            i++;
          }
          this.periodicities = [
            { options: commonPeriodicities },
            { options: rarePeriodicities }
          ];
        });
      });
  }
  public async getGroupList() {
    await group
      .getAllGroups(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then(resp => {
          let groupList = JSON.parse(JSON.stringify(resp));
          for (const g of groupList) {
            switch (g.group_number) {
              case 2:
                this.systems.push({ value: g.id, label: g.literal });
                break;
            }
          }
        });
      });
  }
  // public remoteMethod(query: string) {
  //   console.log(this.workOrderList);
  //   if (query !== "") {
  //     this.loading = true;
  //     setTimeout(() => {
  //       this.loading = false;
  //       this.visibleWorkOrders = this.workOrderList.filter(
  //         item => item.aia_id == query
  //       );
  //     }, 200);
  //   } else {
  //     this.visibleWorkOrders = [];
  //   }
  // }
}
</script>
<style>
.tag {
  background-color: rgb(201, 228, 238);
  color: black;
  width: 130px;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  padding: 2px 10px 2px 10px;
  font-size: 9pt;
  line-height: 20px;
}
strong {
  color: black;
}
.container {
  border: 5px solid rgb(152, 226, 226);
  border-radius: 10px;
  background-color: rgb(221, 218, 218);
  padding: 5px 5px 5px 5px;
}
.vdp-datepicker input {
  width: 75px;
  height: 20px;
}
</style>