class SystemInformation {
  public async getAllMessages(token: string, host: any): Promise<any> {
    return await fetch(host + "/api/systemInformation/getAll?token=" + token);
  }
  public async getSomeMessages(
    token: string,
    filters: any,
    host: any
  ): Promise<any> {
    return await fetch(
      host +
        "/api/systemInformation/getSome?token=" +
        token +
        "&dateFrom=" +
        filters.dateFrom +
        "&dateTo=" +
        filters.dateTo +
        "&user=" +
        filters.user
    );
  }
  public async sendMessage(
    token: string,
    message: any,
    host: any
  ): Promise<any> {
    let result: Response;
    message.token = token;
    if (message.subject != undefined) {
      message.subject = message.subject.replace("&", "and");
    }
    if (message.content != undefined) {
      message.content = message.content.replace("&", "and");
    }
    result = (await fetch(host + "/api/systemInformation/postMessage", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body:
        "token=" +
        token +
        "&subject=" +
        message.subject +
        "&content=" +
        message.content +
        "&recycled=false"
    })) as any;

    return await result.json();
  }
  public async editMessage(
    token: string,
    message: any,
    host: any
  ): Promise<any> {
    let result: Response;
    message.token = token;
    if (message.subject != undefined) {
      message.subject = message.subject.replace("&", "and");
    }
    if (message.content != undefined) {
      message.content = message.content.replace("&", "and");
    }
    result = (await fetch(host + "/api/systemInformation/postMessage", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body:
        "token=" +
        token +
        "&subject=" +
        message.subject +
        "&content=" +
        message.content +
        "&recycled=false" +
        "&id=" +
        message.id
    })) as any;

    return await result.json();
  }

  public async messageSeen(token: string, attrs: any, host: any): Promise<any> {
    let result: Response;
    attrs.token = token;
    result = (await fetch(host + "/api/systemInformation/messageSeen", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: "token=" + token + "&msg_id=" + attrs.message
    })) as any;

    return await result.json();
  }

  public async getSomeRecycledMessages(
    token: string,
    filters: any,
    host: any
  ): Promise<any> {
    return await fetch(
      host +
        "/api/systemInformation/getSomeRecycled?token=" +
        token +
        "&dateFrom=" +
        filters.dateFrom +
        "&dateTo=" +
        filters.dateTo +
        "&user=" +
        filters.user
    );
  }

  public async recycleInfo(token: string, id: any, host: any) {
    return await fetch(
      host + "/api/systemInformation/recycle?token=" + token + "&id=" + id
    );
  }
}
export const systemInformation = new SystemInformation();
