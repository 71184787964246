<template>
  <div>
    <el-row class="container">
      <ActivityFilters v-model="filters"></ActivityFilters>
      <br />
      <el-button @click="search" type="success">
        <strong class="white">Search</strong>
      </el-button>
    </el-row>
    <br />
    <el-row class="container">
      <el-table
        :data="activityList"
        v-loading="loading"
        border
        :header-cell-style="{background:'rgb(170, 238, 238)',color:'black' ,'text-align':'center'}"
        :default-sort="{prop:'date',order:'descending'}"
      >
        <el-table-column prop="username" label="User Name" sortable></el-table-column>
        <el-table-column prop="type" label="Activity Type" sortable></el-table-column>
        <el-table-column prop="item" label="Item" sortable></el-table-column>
        <el-table-column prop="date" label="Date Executed" sortable :formatter="filterDateTime"></el-table-column>
      </el-table>
    </el-row>
    <br />
  </div>
</template>
<script lang="ts">
import ActivityFilters from "../Filters/ActivityFilters.vue";
import { activities } from "../../api/activity/activities";
import { Component, Vue, Prop } from "vue-property-decorator";
import Moment from "moment";
@Component({
  name: "ActivityList",
  components: {
    ActivityFilters
  }
})
export default class ActivityList extends Vue {
  public loading: boolean = false;
  public listEnd: boolean = false;
  public filters: any = {};
  public activityList: any[] = [];
  public userList: any[] = [];
  public tableData: any[] = [];
  public index: number = 0;
  public filterDateTime(row: any) {
    if (row.date === undefined) {
      return "";
    }
    return Moment(row.date).format("DD/MM/YYYY HH:mm");
  }
  public more() {
    let i = 1;
    while (i < 10) {
      this.tableData.push(
        this.activityList[this.activityList.length - 1 - this.index - i]
      );
      this.index++;
      i++;
      if (
        this.activityList[this.activityList.length - 1 - this.index - i] ==
        undefined
      ) {
        this.index--;
        this.listEnd = true;
        return;
      }
    }
  }
  public async search() {
    this.loading = true;
    this.listEnd = false;
    if (this.filters.dateFrom === null) {
      delete this.filters.dateFrom;
    }
    if (this.filters.dateTo === null) {
      delete this.filters.dateTo;
    }
    await activities
      .getSomeActivities(
        this.$store.getters.getToken,
        this.filters,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.activityList = JSON.parse(JSON.stringify(resp));
          this.activityList.sort(function(a, b) {
            var aDate = new Date(a.date);
            var bDate = new Date(b.date);

            return aDate < bDate ? -1 : 1;
          });
          this.tableData = this.activityList.slice(
            this.activityList.length - 10,
            this.activityList.length
          );
          this.index = 10;
          console.log(JSON.parse(JSON.stringify(resp)));
          this.loading = false;
        });
      });
  }
}
</script>
<style scoped>
.container {
  border: 2px solid rgb(170, 238, 238);
  border-radius: 10px;
  background-color: rgb(248, 247, 247);
  padding: 5px 10px 5px 10px;
}
</style>