<template>
<div>
 <el-row class="container">
      <el-row>
        <h2>PPM Details</h2>
      </el-row>
      <!-- {{this.system}} -->
          <el-row>
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Select System:</strong>
          </div>
          <!--  @change="receiveRemarks()" -->
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            v-model="system"
            placeholder="Select System"
            :items="availableSystems"
            @change="componentMethod('');clear();clearBelowHall();"
            size="mini"
            :style="{width:'250px'}"
            :disabled="existing"
             clearable
          >
            <el-option v-for="item in availableSystems" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-col>
      </el-row>

<el-row v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''&&system!='Scales'&&system!='IT and Automation'">
<el-col :span="4" align="left">
<div class="tag">
  <strong>Maintenance period:</strong>
</div>
</el-col>

<el-col :span='20' align='left'>
  <el-select v-model="maintenancePeriod" size='mini' :style="{width:'250px'}" @change="maintenancePeriodMethod"  filterable placeholder="Add Year">
    <el-option
      v-for="item in maintenancePeriodOptions"
      :key="item"
      :label="item"
      :value="item">
    </el-option>
  </el-select>
</el-col>

  
</el-row>
<el-row v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''&&(system=='Scales'||system=='IT and Automation')">
<el-col :span="4" align="left">
<div class="tag">
  <strong>Maintenance period:</strong>
</div>
</el-col>

<el-col :span='20' align='left'>
  <el-select v-model="maintenancePeriod" size='mini' :style="{width:'250px'}" @change="maintenancePeriodMethod('');"  filterable placeholder="Add Year">
    <el-option
      v-for="item in maintenancePeriodOptions2"
      :key="item"
      :label="item"
      :value="item">
    </el-option>
  </el-select>
</el-col>

  
</el-row>



      <!-- {{selectedHall}} -->
      <el-row
        v-if="((periodicity==8&&(system=='Inductions'||system=='Sorter'||system=='UPS'||system=='IT and Automation'||system=='Fire Shutter'||system=='Retainer'||system=='Air-Condition'||system=='Electrical Panels'))||(periodicity==4&&system=='Electrical Panels')||(periodicity==4&&(system=='Fire Shutter and Ret'||system=='Reclaims')||(periodicity==7&&system!=''&&system!=undefined)))"
      >
      
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Select Hall:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            v-model="selectedHall"
            :style="{width:'250px'}"
            placeholder="Hall"
            :items="halls"
            clearable
            size="mini"
            @change="componentMethod(''); clearBelowHall();"
          >
            <el-option v-for="item in halls" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <!-- {{this.belt}} -->
      <el-row v-if="periodicity==8&&system=='Electrical Panels'">       
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Select Electrical Panel:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            filterable
            v-model="belt"
            placeholder="Select Electrical Panel"
            :items="southPanels"
            size="mini"
            :style="{width:'250px'}"
            @change="componentMethod(''); clearBelowElectricalPanels();filterHallstest();"
            v-if="selectedHall=='South'"
            clearable
          >
            <el-option v-for="item in southPanels" :key="item" :label="item" :value="item"></el-option>
          </el-select>
          <el-select
            filterable
            v-model="belt"
            placeholder="Select Electrical Panel"
            :items="northPanels"
            size="mini"
            :style="{width:'250px'}"
            @change="componentMethod(''); clearBelowElectricalPanels();"
            v-if="selectedHall=='North'"
            clearable
          >
            <el-option v-for="item in northPanels" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <!-- /////////////////////////////////////////////////////////////////////////////////////test for inductions-->
        <!-- <el-row v-if="periodicity==8&&system=='Electrical Panels'">       
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Select Electrical Panel/ Inductions:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            filterable
            v-model="belt"
            placeholder="Select Electrical Panel"
            :items="southPanels"
            size="mini"
            :style="{width:'250px'}"
            @change="componentMethod(''); clearBelowElectricalPanels();filterHallstest();"
            v-if="selectedHall=='South'"
            clearable
          >
            <el-option v-for="item in southPanels" :key="item" :label="item" :value="item"></el-option>
          </el-select>
          <el-select
            filterable
            v-model="belt"
            placeholder="Select Electrical Panel"
            :items="northPanels"
            size="mini"
            :style="{width:'250px'}"
            @change="componentMethod(''); clearBelowElectricalPanels();"
            v-if="selectedHall=='North'"
            clearable
          >
            <el-option v-for="item in northPanels" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-col>
      </el-row> -->

      <!-- /////////////////////////////////////////////////////////////////////////////////////-->
      <el-row v-if="system=='IT and Automation'&&periodicity!=7">
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Select Group:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            v-model="belt"
            :style="{width:'250px'}"
            placeholder="Group"
            :items="itGroups"
            clearable
            size="mini"
            @change="componentMethod('')"
          >
            <el-option v-for="item in itGroups" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row v-if="system=='IT and Automation'&&periodicity==7">
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Select Group:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            v-model="belt"
            :style="{width:'250px'}"
            placeholder="Group"
            :items="atrGroups"
            clearable
            size="mini"
            @change="componentMethod('')"
          >
            <el-option v-for="item in atrGroups" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-col>
      </el-row>
       <!-- {{this.belt}} -->
      <el-row
        v-if="(system=='Inductions'||system=='Sorter'||system=='UPS')&&periodicity==8"
      >
      <!-- &&selectedHall!=''&&selectedHall!=undefined -->
        <el-col :span="4">
          <div class="tag">
            <strong v-if="system!='UPS'&&system!='Inductions'">Select Group:</strong>
            <strong v-if="system=='UPS'">Select Equipment:</strong>
            <strong v-if="system=='Inductions'">Select Station:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <div v-if="system=='Sorter'">
            <div v-if="selectedHall=='North'">
              <el-select :style="{width:'250px'}" size="mini" v-model="belt" @change="componentMethod('')">
                <el-option v-for="item in northSorter" :key="item.group4" :label="item.group4" :value="item.group4"></el-option>
              </el-select>
            </div>
            <div v-if="selectedHall=='South'">
              <el-select :style="{width:'250px'}" size="mini" v-model="belt" @change="componentMethod('')" >
                <el-option v-for="item in southSorter" :key="item.group4" :label="item.group4" :value="item.group4"></el-option>
              </el-select>
            </div>
          </div>
          <div v-if="system=='Inductions'">
            <div v-if="selectedHall=='North'">
              <el-select :style="{width:'250px'}" size="mini" v-model="belt" @change="componentMethod('')" >
                <el-option v-for="item in northInductions" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
            <div v-if="selectedHall=='South'">
              <el-select :style="{width:'250px'}" size="mini" v-model="belt" @change="componentMethod('')" >
                <el-option v-for="item in southInductions" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
          </div>
          <div v-if="system=='UPS'">
            <div v-if="selectedHall=='North'">
              <el-select :style="{width:'250px'}" size="mini" v-model="belt">
                <el-option v-for="item in northUps" :key="item.id" :label="item.literal" :value="item.id"></el-option>
              </el-select>
            </div>
            <div v-if="selectedHall=='South'">
              
              <el-select :style="{width:'250px'}" size="mini" v-model="belt">
                <el-option v-for="item in southUps" :key="item.id" :label="item.literal" :value="item.id"></el-option>
              </el-select>
            </div>
          </div>
        </el-col>
      </el-row>

<el-row v-if="periodicity !=undefined && periodicity!=''&& system!=undefined&&system!=''&&(periodicity==8&&system=='Sorter')">
  <el-col :span="4" align="left">
    <div class="tag">
      <strong>Name:</strong>
    </div>
</el-col>
<!-- {{availableComponents}} -->
<!-- {{selectedSubpanels}} -->
<el-col :span="20" align="left"> 
  <el-select
  v-model="selectedSubpanels" 
  :style="{width:'800px'}"
  size="mini"
  multiple placeholder="Select" 
  >
  <!-- :key="item.value"
      :label="item.label"
      :value="item.value" -->
      
    <el-option
      v-for="item in availableComponents"
      :key="item.id "
      :label="item.literal "
      :value="item.id">
    </el-option>
  </el-select>
  </el-col>
  <el-col :span="4" align="left"  > 
  <el-button size="mini" @click="SelectAllso" round>Select All</el-button>
</el-col>
</el-row>

      <el-row
        v-if="(periodicity==8||periodicity==6)&&system!='Scales'&&system!=''&&system!=undefined&&system!='Electrical Panels'&&system!='Sorter'&&system!='UPS'&&system!='Inductions'&&system!='Fire Shutter'&&system!='Retainer'"
      >
      <!-- {{yearlyComponent}} -->
        <el-col :span="4">
          <div class="tag">
            <strong>Select Component:</strong>
          </div>
        </el-col>         
        <el-col :span="20" align="left">
          <el-select
            v-model="yearlyComponent"
            filterable
            :style="{width:'250px'}"
            remote
            size="mini"
            clearable
            placeholder="Add Component"
            @change="clearSearch2()"
            :remote-method="componentMethod"
            :loading="loading"
          >
          
            <el-option
              v-for="item in availableComponents"
              :key="item.id"
              :label="item.literal"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row v-if="periodicity==4&&system=='Conveyors'">
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Select Conveyor Line:</strong>
          </div>
        </el-col>
        <el-col :span="7" align="left">
          <el-select
            filterable
            v-model="belt"
            placeholder="Select Conveyor Belt"
            :items="conveyorBelts"
            @change="componentMethod('')"
            size="mini"
            :style="{width:'250px'}"
          >
            <el-option v-for="item in conveyorBelts" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-col>
        <el-col :span="11" align="left">
          <el-select
            v-if="belt=='EA'||belt=='EK'"
            size="mini"
            v-model="beltLine"
            multiple
          >
            <el-option v-for="item in conveyorBelts" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''">
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>AIA Sen. Tech:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            v-model="senTech"
            filterable
            :style="{width:'250px'}"
            remote
            size="mini"
            @change="clearSearch()"
            clearable
            placeholder="AIA Sen. Tech"
            :remote-method="senTechMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in availableSenTechs"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''">
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Foreman:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            v-model="foreman"
            filterable
            :style="{width:'250px'}"
            remote
            size="mini"
            clearable
            placeholder="Add Foreman"
            :remote-method="foremanMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in availableForemen"
              :key="item.value"
              :label="item.firstname + ' ' + item.lastname"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
<!-- Select Subpanel -->
<!-- na valw gia subpanel antistixi apothikeusi opos to komponent den xriazete extra pinakas  -->
<!-- Basic multiple select -->
<el-row v-if="periodicity !=undefined && periodicity!=''&& system!=undefined&&system!=''&&(periodicity==8&&(system=='Electrical Panels'||system=='Inductions'||system=='Fire Shutter'||system=='Retainer'))">
  <el-col :span="4" align="left">
    <div class="tag">
      <!-- <strong>Select Subpanel:</strong> -->
      <strong v-if="system=='Electrical Panels'">Select Subpanel:</strong>
      <strong v-if="system=='Inductions'">Indaction Part:</strong>
      <strong v-if="system=='Fire Shutter'||system=='Retainer'">Select Component:</strong>
    </div>
</el-col>
<!-- {{availableComponents}} -->
<!-- {{selectedSubpanels}} -->
<el-col :span="20" align="left"> 
  <el-select
  v-model="selectedSubpanels" sortable
  :style="{width:'800px'}"
  size="mini"
  :items="availableComponents"
  multiple placeholder="Select" 
  >
  <!-- :key="item.value"
      :label="item.label"
      :value="item.value" -->
      
    <el-option
      v-for="item in availableComponents"
      :key="item.id "
      :label="item.literal "
      :value="item.id">
    </el-option>
  </el-select>
  </el-col>
  <el-col :span="4" align="left"> 
     <el-button size="mini" round @click="SelectAllso" >
        <strong >Select All</strong>
      </el-button>

  <!-- <el-button size="mini" round>Select All</el-button> -->
</el-col>
</el-row>

<!-- <el-row v-if="periodicity !=undefined && periodicity!=''&& system!=undefined&&system!=''&&(periodicity==8&&system=='Scales')">
  <el-col :span="4" align="left">
    <div class="tag">
      <strong>Select Scales</strong>
    </div>
</el-col>

{{selectedSubpanels}}
<el-col :span="20" align="left"> 
  <el-select
  v-model="selectedSubpanels" 
  :style="{width:'250px'}"
  size="mini"
  multiple placeholder="Select" 
   @change="clearSearch2()"
            :remote-method="componentMethod"
  >

      
    <el-option
      v-for="item in availableComponents"
      :key="item.id "
      :label="item.literal "
      :value="item.id">
    </el-option>
  </el-select>
  </el-col>
  <el-col :span="4" align="left"> 
  <el-button size="mini" round>Select All</el-button>
</el-col>
</el-row> -->
<el-row
        v-if="(periodicity==8||periodicity==6) && (system == 'Scales') && (system!=undefined)"
      >
      <!-- {{selectedSubpanels}} -->
      <!-- placeholder="Add Component"  :loading="loading" filterable remote clearable-->
        <el-col :span="4">
          <div class="tag">
            <strong>Select Scales:</strong>
          </div>
        </el-col>         
        <el-col :span="100" align="left">
          <el-select       
            v-model="selectedSubpanels"            
            :style="{width:'800px'}"
            remote
            size="mini"
            filterable             
            :items="availableComponents"
            multiple placeholder="Select"              
            @change="clearSearch2()"
            :remote-method="componentMethod"
           :loading="loading"
          >
          
            <el-option
              v-for="item in availableComponents"
              :key="item.id"
              :label="item.literal"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
        <el-row>
          <el-col :span="100" align="left">
        <el-button size="mini" round @click="SelectAllso" >
        <strong >Select All</strong>
      </el-button>
      </el-col>
      </el-row>
      </el-row>

    </el-row>
<br>
<!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
    <!-- nai -->  <!-- v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''" -->
    <el-row
      class="container"
     v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''"
    >

      <!-- {{techs}} -->
      <!-- {{ techs[index].start_date}}  -->
      <el-row>
        <h2>Personnel (Working on PPM)</h2>
      </el-row>
      <table>
        <tr>
          <th>
            <div class="tableCell">
              <strong class="tagText">Tech Name</strong>
            </div>
          </th>
          <th>
            <div class="dateTableCell">
              <strong class="tagText">Start Date</strong>
            </div>
          </th>
          <th>
            <div class="dateTableCell">
              <strong class="tagText">Start Time</strong>
            </div>
          </th>
          <th>
            <div class="dateTableCell">
              <strong class="tagText">End Date</strong>
            </div>
          </th>
          <th>
            <div class="dateTableCell">
              <strong class="tagText">End Time</strong>
            </div>
          </th>
          <th>
            <!-- &&system!='Electrical Panels' -->            
            <div
              class="shortTableCell"
              v-if="!(periodicity==4||periodicity==6||(periodicity==7&&system=='IT and Automation')||(periodicity==8)||system=='Detectors'||system=='Fire Panels')"
            >
              <strong class="tagText">Hall</strong>
            </div>
          </th>
          <th v-if="false">
            <div class="tableCell">
              <strong class="tagText">Executed Parts</strong>
            </div>
          </th>
        </tr>
       
        <tr v-for="(p,index) in techs" v-if="techs[index].id!=''">
          <th>
            <div>
              <el-select
                v-model="techs[index].id"
                filterable
                :style="{width:'220px'}"
                remote
                size="mini"
                @change="techUpdate(techs[index].id,index)"
                clearable
                placeholder="Add Technician"
                :remote-method="foremanMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in availableForemen"
                  :key="item.value"
                  :label="item.firstname + ' ' + item.lastname"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </th>
          <th>
            <div>
              <el-date-picker
                v-model="techs[index].start_date"
                
                type="date"
                placeholder="Start Date"
                size="mini"
                format="dd/MM/yyyy"
                :style="{width:'150px'}"
              ></el-date-picker>
            </div>
          </th>
          <th>
            <div>
              <!-- :formater="Completiondate" -->
                <!-- v-on:input="startTimeChange(index)" -->
              <el-input
                placeholder="00:00"
                v-on:input="startTimeChange(index)"
                v-model="techs[index].start"
                :style="{width:'150px'}"
                size="mini"
                maxlength="5"
                
              ></el-input>
            </div>
          </th>
          <th>
            <div>
            <el-date-picker
            v-model="techs[index].end_date"
            
            type="date"
            placeholder="End Date"
            size="mini"
            format="dd/MM/yyyy"
            :style="{width:'150px'}"
            >
            </el-date-picker>
            </div>
            </th>
          <th>
            <div>
              <el-input
                placeholder="00:00"
                v-on:input="endTimeChange(index)"
                v-model="techs[index].end"
                :style="{width:'150px'}"
                size="mini"
                maxlength="5"
                
              ></el-input>
            </div>
          </th>
          <th>
            <!-- &&system!='Electrical Panels' -->
            <div
              v-if="!(periodicity==4||periodicity==6||(periodicity==7&&system=='IT and Automation')||(periodicity==8)||system=='Detectors'||system=='Fire Panels')"
            >
              <el-select
                v-model="techs[index].hall"
                :style="{width:'100px'}"
                placeholder="Hall"
                :items="halls"
                clearable
                size="mini"
              >
                <el-option v-for="item in halls" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
          </th>
          <th v-if="false">
            <div>
              <el-input
                placeholder="Parts"
                type="textarea"
                autosize
                v-model="techs[index].parts"
                size="mini"
              ></el-input>
            </div>
          </th>
        </tr>
        <tr>
          <th>
            <div>
              <el-select
                v-model="selectedTech"
                filterable
                @change="pushPersonnel"
                :style="{width:'220px'}"
                remote
                size="mini"
                clearable
                placeholder="Add Technician"
                :remote-method="foremanMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in availableForemen"
                  :key="item.value"
                  :label="item.firstname + ' ' + item.lastname"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </th>
          <th>
            <div></div>
          </th>
          <th>
            <div></div>
          </th>
          <th>
            <div></div>
          </th>
          <th v-if="false">
            <div></div>
          </th>
        </tr>
      </table>
    </el-row>
    <br>


<!-- ===================== -->

<!--v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''"-->

<!-- woold -->

      <!-- <el-row
      class="container"
      v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''"
    >
      <el-row>
        <h2>Work Orders</h2>
      </el-row>



      <table>
        <tr>
          <th>
            <div class="tableCell">
              <strong class="tagText">Work Order ID</strong>
            </div>
          </th>
          <th v-if="workOrders.length>0">
            <div class="superLongTableCell">
              <strong class="tagText">Description</strong>
            </div>
          </th>
        </tr>
        <tr v-for="(p,index) in workOrders" v-if="workOrders[index].id!=''>0">
          <th>
            <div>
              <el-select
                v-model="workOrders[index].id"
                filterable
                :style="{width:'220px'}"
                remote
                size="mini"
                @change="WOUpdate(workOrders[index].id,index)"
                clearable
                placeholder="Add Work Order"
                :remote-method="workOrderMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in availableWorkOrders"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </th>
          <th class="notATextbox">
            <p style="text-align:left">{{p.description}}</p>
            <p style="text-align:left"></p>
          </th>
        </tr>
        <tr>
          <th>
            <div>
              <el-select
                v-model="selectedWorkOrder"
                filterable
                @change="pushWorkOrder"
                :style="{width:'220px'}"
                remote
                size="mini"
                clearable
                placeholder="Add Work Order"
                :remote-method="workOrderMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in availableWorkOrders"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </th>
          <th>
            <div></div>
          </th>
        </tr>
      </table>
    </el-row> -->
<!-- /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// new work orders -->
     <el-row
      class="container"
      v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''"
    >
      <el-row>
        <h2>Work Orders</h2>
      </el-row>
<!-- work Orders !!!!-->

  <!-- :remote-method="workOrderMethod" -->
  <!-- {{this.selectedWorkOrder}} -->
      <table>
        <tr>
          <th>
            <div class="tableCell">
              <strong class="tagText">Work Order ID</strong>
            </div>
          </th>
          <th v-if="workOrders.length>0">
            <div class="superLongTableCell">
              <strong class="tagText">Description</strong>
            </div>
          </th>
        </tr>
        <tr v-for="(p,index) in workOrders" v-if="workOrders[index].id!=''">
          <th>
            <div>
              <el-select
                v-model="workOrders[index].id"
                filterable
                :style="{width:'220px'}"
                remote
                size="mini"
                @change="WOUpdate(workOrders[index].id,index)"
                clearable
                placeholder="Add Work Order"              
                :loading="loading"
              >
                <el-option
                  v-for="item in availableWorkOrders"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </th>
          <th class="notATextbox">
            <p style="text-align:left">{{p.description}}</p>
            <p style="text-align:left"></p>
          </th>
        </tr>
        <tr>
          <!--  -->
            <!-- @change="pushWorkOrder" -->
          <el-input
           
            size='mini'
            placeholder="Add Work Order New"
             v-model="selectedWorkOrder"
            
            
            clearable>
          </el-input>
          <!--  -->
          <th>
            <!-- <div>
              <el-select
                v-model="selectedWorkOrder"
                filterable
                @change="pushWorkOrder"
                :style="{width:'220px'}"
                remote
                size="mini"
                clearable
                placeholder="Add Work Order"
                :remote-method="workOrderMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in availableWorkOrders"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div> -->
          </th>
          <th>
            <div></div>
          </th>
        </tr>
         <el-col :span="4" align="left"> 
      <el-button size="mini" round  @click="SelectWO">
      <!-- <el-button size='mini' type="success" icon="el-icon-check" circle  @click="SelectWO"></el-button> -->
      <strong >Check</strong>
      </el-button>
      </el-col>
      </table>
    </el-row>
<!-- /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// new work orders end -->




<!--v-if="((periodicity==8||periodicity==6)&&system!='Scales'&&system!=''&&system!=undefined&&system!='Sorter'&&system!='Electrical Panels'&&system!='Inductions')||system=='Fire Panels'"-->
<!-- &&system!='Electrical Panels' !! &&system!='Sorter'&&system!='Inductions'-->
    <el-row
      
      class="container"
v-if="((periodicity==8||periodicity==6)&&system!=''&&system!=undefined)||system=='Fire Panels'"

    >
        <el-row>
        <h2 v-if="system!='Scales'">Maintenance Remarks During PPM</h2>
        <h2 v-if="system=='Scales'">Remarks</h2>
      </el-row>
      <el-row>
        <table id="compos">
          <tr>
            <th>
              <div class="superLongTableCell">
                <strong class="tagText">Remarks</strong>
              </div>
            </th>
            <th>
              <div class="midTableCell">
                <strong class="tagText">Proposed Actions</strong>
              </div>
            </th>
          </tr>
            <!-- 2 -->
           <tr v-for="(p,index) in jsonRemarks" v-if="jsonRemarks[index]!=null&&jsonRemarks[index].remarks!=''">
            <th>
              <div>
                <el-input
                  placeholder="Remarks"
                  type="textarea"
                  autosize
                  v-model="jsonRemarks[index].remarks"
                  size="mini"
                  :style="{width:'800px'}"
                ></el-input>
              </div>
            </th>
            <th>
              <div>
                <el-select
                  v-model="jsonRemarks[index].actions"
                  placeholder="Activity"
                  :items="activities"
                  size="mini"
                  :style="{width:'120px'}"
                >
                  <el-option
                    v-for="item in activities"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </th>
          </tr>
          <!-- first -->
          <tr>
            <th>
              <div>
                <el-input
                  placeholder="Remarks"
                  type="textarea"
                  autosize
                  v-model="yearlyRemarks"
                  size="mini"
                  :style="{width:'800px'}"
                ></el-input>
              </div>
            </th>
            <th>
              <div>
                <el-select
                  v-model="yearlyActions"
                  placeholder="Activity"
                  :items="activities"
                  size="mini"
                  :style="{width:'120px'}"
                  @change="pushRemark"
                >
                  <el-option
                    v-for="item in activities"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </th>
          </tr>
        </table>
      </el-row>
    </el-row>



    
<!--v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''"-->

     <el-row
      class="container"
      v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''"
    >
      <StatusSelector v-model="status"></StatusSelector>
    </el-row>
    <br>
    






<!-- v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''"   addToDBVacationDecision       addToDBPpm2 -->

<el-row 
v-if="periodicity!=undefined&&periodicity!=''&&system!=undefined&&system!=''" 
>

      <el-button style="margin-top: 12px;" @click="addToDBPpm2" type="success">
        <strong class="white">Submit</strong>
      </el-button>

       <!-- <el-button style="margin-top: 12px;" @click="Edit()" type="success">
        <strong class="white">Edit</strong>
      </el-button> -->



    </el-row>


<!-- endcom -->
<!-- @click="Edit(scope.$index, scope.row)"  -->

 <!-- <el-button

             v-if="userlevel<2"
               type="primary"
               @click="Edit(scope.$index, scope.row)"
               icon="el-icon-edit"
               circle
             >
              <strong class="white">Edit</strong>
             </el-button> -->



</div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { ppm } from "../../api/ppm/ppm";
import { personnel } from "../../api/personnel/personnel";
import { aiaPersonnel } from "../../api/personnel/aiaPersonnel";
import { component } from "../../api/component/component";
import { workorder } from "../../api/workorder/workorder";
import StatusSelector from "../Selectors/StatusSelector.vue";
import { postgrest } from "../../api/postgrest";
import Moment from "moment";

@Component({
  name: "Addppmyearly",
  components: {
    StatusSelector
  },
  props: {
    obj: {}
  }
})
export default class AddPPMyearly extends Vue {
public status: any = {};
public periodicity: any = 8;
public availableSystems: any[] = [];
public systemPeriodicities: any[] = [];
public existing: boolean = false;
public system: string = "";
public currentYear: any = "";
public currentMonth: any = "";
public maintenancePeriodOptions: any[] = [];
public maintenancePeriodOptions2: any[] = [];
public maintenancePeriod: any = '';
public techs: any[] = [];
public personnelList: any[] = [];
public availableForemen: any[] = [];
public loading: boolean = false;
public selectedTech: any = "";
public availableSenTechs: any[] = [];
public workOrders: any[] = [];
public workOrderList: any[] = [];
public availableWorkOrders: any[] = [];
public yearlyComponent: any=""; 
public availableComponents: any [] = [];
public availableComponents2: any [] = [];
public componentList: any[] = [];
public senTechs: any[] = [];
public senTechList: any[] = [];
public atrGroups: any[] = [];
public southUps: any[] = [];
public northUps: any[] = [];
public upsGroups: any[] = [];
public northSorter: any[] = [];
public southSorter: any[] = [];
public itGroups: any[] = [];
public atrComponents: any[] = [];
public northInductions: any[] = [];
public southInductions: any[] = [];
public halls: any[] = [];
public systems: any[] = [];
public groups: any[] = [];
public codes: any[] = [];
public descriptions: any[] = [];
public conveyorBelts: any[] = [];
public southPanels: any[] = [];
public northPanels: any[] = [];
public selectedHall = "";
public belt: any = "";
public components: any[] = [];
public selectedWeek: any = "";
public lines: any[] = [];
public beltLine: any[] = [];
public workOrdersTable: any[] = [];
public selectedWorkOrder: any = "";
public selectedWorkOrderNew: any = "";
public selectedComponent = "";
public jsonRemarks: any[] = [];
public yearlyRemarks: any = "";
public yearlyActions: any = "";
public activities: any[] = [];
public foreman: any = "";
public senTech: any = "";
public row: any = "";
public selectedSubpanels: any[] = [];
public selectedScales: any[] = [];
public selectedSubsortername: any[] = [];
public test1: any[] = []
public test2: any[] = []
public test3: any[] = []
public test4: any[] = []
public PpmsList2subpanel: any[] = [];
public Select: boolean = false;
public select_all: boolean = false;
public oneWO: any = "";

async  created(){
   this.maintenancePeriodYearOptions();
  await this.getSystemPeriodicities();
  this.periodicityChange();
  await this.getPersonnelList();
//await this.getWorkOrderList();
  await this.getSenTechList();
  await this.getActivities();
  this.checkEdit();
  this.fgetPpmsList2subpanel();
//console.log("this.status")
//console.log(JSON.stringify(this.status))
//console.log("this.status")
//na dw an xriazete await
  }

  public async SelectWO(query: string){  
    let woID: any = {} 
    woID = this.selectedWorkOrder
    console.log('oneWO112')
    console.log(this.selectedWorkOrder)
    console.log(woID)
    console.log('oneWO11')
  //  let r= await ppm.getoneWO(this.$store.getters.getToken, woID, this.$store.getters.getHost)
  //          .then(response => {        
  //         response.json().then(async resp => {
  //             this.oneWO = JSON.parse(JSON.stringify(resp));
  //             console.log('oneWO1')
  //             console.log(JSON.stringify(this.oneWO))
  //             console.log('oneWO2')
  //             // this.workOrderList.push(this.oneWO) 
  //             // console.log(JSON.stringify(this.workOrderList))
  //             console.log('this.workOrderList.')
  //       });
  //     });
  let response = await ppm.getoneWO(this.$store.getters.getToken, woID, this.$store.getters.getHost);            
  let resp = await response.json();
  this.oneWO = JSON.parse(JSON.stringify(resp));
               try {
          console.log('Postgrest call')
          let res = await postgrest.get('status', this.$store.getters.getToken);
          let res2 = await postgrest.get('ppm_personnel2?select=*,personnel(*)id=eq.2', this.$store.getters.getToken);
          // let res3 = await postgrest.get('ppm_personnel?select=*,ppm_remarks2(*)id=eq.2', this.$store.getters.getToken);
          // let res4 = await postgrest.get('ppm_personnel?select=*,personnel(*)id=eq.2', this.$store.getters.getToken);
          let res4 = await postgrest.get('ppm_2?select=*,personnel(*)id=eq.2,ppm_remarks2(*)id=eq.2', this.$store.getters.getToken);
          let res5 = await postgrest.get('companies', this.$store.getters.getToken)
          
         
          console.log('testres2front1')
          console.log(res5)
          console.log(this.$store.getters.getToken)
          console.log('testres2front2')
          
         
          console.log(JSON.stringify(res4))
          
          console.log('Postgrest result 1test:' + JSON.stringify(res));
        } catch (ex) {
          console.log('Postgrest call exception:' + ex);
        }
        // if (query != "" && query != undefined) {
        //     this.availableWorkOrders.push(this.oneWO)
        //     console.log('this.availableWorkOrders')
        //     console.log(this.availableWorkOrders)
        // } else {
        //     this.availableWorkOrders = [];
        // }
        await this.pushWorkOrder();
  }
  public pushWorkOrder() {  
    console.log('this.selectedWorkOrder123456 ');
    console.log(this.selectedWorkOrder );
    console.log(JSON.stringify(this.oneWO.woremarks ));
    console.log('this.oneWO');
    if (this.selectedWorkOrder != undefined || this.selectedWorkOrder != "") {        
      this.workOrders.push({
        id: this.selectedWorkOrder,
        value: this.selectedWorkOrder,
        description:this.oneWO.woremarks       
      });
    //   console.log(JSON.stringify(this.oneWO))
    //   console.log('this.oneWO12345')
    //   // this.workOrders.push({
    //   //   id: this.selectedWorkOrder,
    //   //   value: this.selectedWorkOrder,
    //   //   description: this.workOrderList.find(
    //   //     r => r.value == this.selectedWorkOrder
    //   //   ).description
    //   // });
   
      console.log('this.workOrders')
      console.log(JSON.stringify(this.workOrders))
      this.selectedWorkOrder = "";
      this.availableWorkOrders = [];
    }
  }
  public WOUpdate(id: any, index: number) {
    if (id == "") {
      this.workOrders.splice(index, 1);
    }
  }

  public filterHallstest() {
    
   
    
      this.availableComponents.sort(function(a, b): number {
        if (a >= b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }

        return 0;
      });
    
  }

// public async SelectAllScales(){
  
// if (this.Select == false){
// for (let i of this.availableComponents){
//   this.selectedScales.push({literal: i.literal , id: i.id })
//   console.log( this.selectedScales)
//   console.log('yyyyyyy1234')
//   this.Select =true
  
// }
// }else{
//   this.selectedScales=[]
//   this.Select =false
//   console.log('yyyyy2')
// }
// }

public async SelectAllso(){
  
if (this.Select == false && this.select_all == false){
for (let i of this.availableComponents){
  this.selectedSubpanels.push(i.id)
  this.Select =true
  this.select_all =true
}
console.log(JSON.stringify(this.selectedSubpanels))
console.log('yyyyyyy1')
}else{
  this.selectedSubpanels=[]
  this.Select =false
  this.select_all =false
  console.log('yyyyy2')
}
}
public async maintenancePeriodMethod(){

  console.log(this.maintenancePeriod)
  console.log('this.maintenancePeriod')
  
}

   public async fgetPpmsList2subpanel() { 
    await ppm
      .getPpmsList2subpanel(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        
        response.json().then(async resp => {

          this.PpmsList2subpanel = JSON.parse(JSON.stringify(resp));

          console.log('PpmsList2subpanel1')
          console.log(JSON.stringify(this.PpmsList2subpanel))
          console.log('PpmsList2subpanel2')
          
          // await this.getComponentList();
        });
        

      });


  }




public async clear(){
  this.techs = [];
  this.yearlyComponent = "";
  this.yearlyActions = "";
  this.selectedHall = "";
  this.belt = "";
  this.beltLine = [];
  this.senTech = "";
  this.foreman = "";
  this.maintenancePeriod=""
  this.selectedTech=""
  this.workOrders = []
  this.selectedWorkOrder =""
  this.selectedWorkOrderNew = ''
  this.jsonRemarks=[]
  this.yearlyRemarks="" 
  this.status={ enddate: "", selectedStatus: "" } 
  this.selectedSubpanels = [];
  console.log("clear")
  await this.getComponentList();
}

public async clearBelowHall(){
  this.belt = "";
  this.selectedSubpanels = [];
}

public async clearBelowElectricalPanels(){
  this.selectedSubpanels = [];
}

//  public Edit(rowIndex: number, row: any) {
//     //row.vacation = JSON.stringify(row.vacation);
//     console.log("row.id for ppm"+row)
//     console.log("row.id for ppm-------->")
//     this.$store.dispatch("updateCurrentObject", row);
//     this.$store.dispatch("updateCurrentComponent", "AddPPMyearly");
//     this.$router.replace({ name: "load" });
//   }

  



 public async checkEdit() {
    await this.getComponentList()
    this.row = this.$store.getters.getCurrentObject; 
     if(this.row){
    console.log("this.row.id")  
    console.log(this.row.id)  
    console.log("this.row")
    console.log(JSON.stringify(this.row)) 
    console.log("this.row") 
    this.system = this.row.system
    console.log(typeof this.row.id_component)
    console.log('typeof this.row.id_component')
    this.yearlyComponent = this.row.component_table[0].id_component 
    // if(this.periodicity == 8 && this.system =='Conveyors'){
    // // this.yearlyComponent = this.row.id_component
    // }else if(this.periodicity == 8 && this.system =="Electrical Panels"){
    //   this.selectedSubpanels[0]=this.row.id_component
      
    // }
    // this.yearlyComponent = this.row.id_component 
    this.selectedHall=this.row.hall
    this.belt=this.row.electrical_panel_edit
    for(let i of this.row.component_table){
     
    this.selectedSubpanels.push(i.id_component )
    console.log('this.selectedSubpanels1234')
     console.log(i )
    }
   
    
    this.senTech = this.row.aia_sentech
    this.foreman = this.row.foreman
    this.maintenancePeriod = this.row.maintenance_per
    this.select_all = this.row.select_all
    for(let x of this.row.personnel_table){
      // x.id = x.TechName
      // x.value = x.TechName
      // x.start_date = x.StartDate
      // x.start = x.StartTime
      // x.end_date = x.EndDate
      // x.end = x.EndTime
      this.techs.push({id2: x.id, id :x.TechName, value:x.TechName ,start_date:x.StartDate , start :x.StartTime, end_date: x.EndDate ,end:x.EndTime})

    }    
    
    // this.techs = this.row.personnel_table
   
    for(let i of this.row.wo_table){
      // i.id = i.ppmwo
      // i.value = i.ppmwo      
      // i.description= i.name
      this.workOrders.push({id: i.ppmwo , value: i.ppmwo , description: i.name})
    }       
    // this.workOrders = this.row.wo_table
    for(let i of this.row.maintenance_remarks_table){
      // i.id = i.remark
      // i.remarks = i.remark
       
      // i.actions= i.action
      this.jsonRemarks.push({id:i.remark , remarks: i.remark , actions: i.action})
    }    
    // this.jsonRemarks = this.row.maintenance_remarks_table

    // this.status=this.row.status
    this.status={enddate:this.row.date_performed , selectedStatus:this.row.status}

    //this.status.selectedStatus=this.row.status
    //this.status.enddate = this.row.date_performed

// this.=this.row.date_performed
    // --

//     if (this.row.id != undefined) {
//       let met = this.row.idpersonel;
//       let xxx: any = {
//         key: this.row.idpersonel,
//         label:
//           this.personnelList.find(function(element) {
//             return met == element.id;
//           }).firstname +
//           " " +
//           this.personnelList.find(function(element) {
//             return met == element.id;
//           }).lastname,
//         value: this.row.idpersonel,
//         id: this.row.idpersonel
//       };
//       this.name = {};
//       Vue.set(this.name, "key", xxx.key);
//       Vue.set(this.name, "value", xxx.value);
//       Vue.set(this.name, "label", xxx.label);
//       Vue.set(this.name, "id", xxx.id);
     
      
//       this.year_vacation = this.row.year_vacation;
//       this.total_days_req = this.row.total_days_req;            
//       // this.leaveOn = new Date(this.row.leave_on);
//       // this.leaveOn = new Date(this.row.leave_on_end[0].leave_on)      
//       // this.leaveEnd = new Date(this.row.leave_end)
//       //new

//       let x=JSON.parse(this.row.json_leaveon_leaveend)    
//     this.leave_on_end=x

//     // let temp=""
//     // let temp2=""
//     // for(let c in x){
//     //  temp+=Moment( x[c].leave_on  ).format("DD/MM/YYYY")+ "\n"
//     //  this.leaveOn = new Date(this.row.leave_on);
//     //  temp2+=Moment( x[c].leve_end).format("DD/MM/YYYY")+ "\n"
//     //  this.leaveEnd= new Date(this.row.leave_end);
     
//     // }
   
//       this.ReturnOn = new Date(this.row.return_on);
//       this.remarks = this.row.remarks;
//       this.DateOfRequest = new Date(this.row.date_of_request);

//       let met2 = this.row.type_of_vacation;
//       let yyy: any = null;
//       //console.log("ROOOOOW:" + JSON.stringify(this.row));
//       try {
//         //console.log("typeList ====> " + JSON.stringify(this.typeList));
//         let result: any = this.typeList.find(function(element) {
//           return met2 == element.id;
//         });
//         //console.log("result => " + JSON.stringify(result));
//         result = result.type_of_vacation;
//         yyy = {
//           key: this.row.type_of_vacation,
//           label: result,
//           value: this.row.type_of_vacation,
//           id: this.row.type_of_vacation
//         };
//       } catch (ex) {
//         // console.log("Exception:" + ex);
//       }

//       this.type = {};
//       Vue.set(this.type, "key", yyy.key);
//       Vue.set(this.type, "label", yyy.label);
//       Vue.set(this.type, "value", yyy.value);
//       Vue.set(this.type, "id", yyy.id);
//     }
//     this.$store.dispatch("updateCurrentObject", {});
//   }



  //   public addToDBVacationDecision() {
  //   if (this.row.id == undefined) {
  //     this.addToDBPpm2();
  //   } else {
  //     this.addToDBPmp3();
  //   }
     }
  }



public receiveRemarks(){

// this.system =[]
// this.selectedHall=""
// this.belt=""



}
// auto mln gia to edit
// public async addToDBPmp3(){

// let system_id = this.systemPeriodicities.find(x => x.system == this.system).system_id

//   let ppmItem: any[] = [
//       {
//         id: id,
//         type: this.periodicity,
//         system_groups_id: system_id,
//         sentech_aia: this.senTech,
//         foreman: this.foreman,
//         maintenance_period: this.maintenancePeriod,
//         status: this.status.selectedStatus,
//         date_completed: this.status.enddate.toLocaleDateString()
//       }
//     ];


// }


// public Completiondate(row: any) {
//     if (row.status.enddate === undefined) {
//       return "";
//     }
//     console.log("123456")
//     return Moment(row.status.enddate).format("DD/MM/YYYY HH:mm");
//   }


public async addToDBPpm2(){

  // vres system_id vasei onomatos tou system
  let system_id = this.systemPeriodicities.find(x => x.system == this.system).system_id
    console.log("typeof this.maintenancePeriod1")
  
  console.log(typeof this.periodicity)
  console.log(typeof system_id)
  console.log(typeof this.senTech)
  console.log(typeof this.foreman)
  console.log(typeof this.maintenancePeriod)
  console.log(typeof this.status.selectedStatus)
  // console.log(typeof this.status.enddate.toLocaleDateString())
  console.log("typeof this.maintenancePeriod2")
  // object pou prepei na mpei ston pinaka ppm_2
  console.log("this.periodicity---------->"+this.periodicity)
   console.log(this.status.enddate)
    console.log(typeof this.status.enddate)
    console.log(Moment(new Date(this.status.enddate)).format("DD-MM-YYYY"))
    console.log("typeof this.status.enddate 12345")
  
  let ppmItem: any[] = [
      {
        ///////?
        id: this.row.id,
        type: this.periodicity,
        system_groups_id: system_id,
        sentech_aia: isNaN(this.senTech)
          ? this.senTechList.find(
              r =>
                r.firstname == this.senTech.split(" ")[0] &&
                r.lastname == this.senTech.split(" ")[1]
            ).id
          : this.senTech,
        foreman:  isNaN(this.foreman)
          ? this.personnelList.find(
              r =>
                r.firstname == this.foreman.split(" ")[0] &&
                r.lastname == this.foreman.split(" ")[1]
            ).id
          : this.foreman,
        maintenance_period: this.maintenancePeriod,
        status: isNaN(this.status.selectedStatus) 
        ? this.row.status_id
        :this.status.selectedStatus
        ,
        
         date_completed: this.status.enddate,
         select_all: this.select_all
         //Moment(new Date(this.status.enddate)).format("DD-MM-YYYY")
         //this.status.enddate.toLocaleDateString()
        //  Moment(new Date(this.status.enddate)).format("DD-MM-YYYY")
        //  isNaN(this.status.selectedStatus) 
        //  ?  (JSON.stringify(this.status.enddate.toLocaleDateString()).replace('"', "").replace('"', "")) 
        //  :this.status.enddate.toLocaleDateString( )
         //not a function .toLocaleDateString()
      }
    ];

    //???
    console.log(this.status.enddate)
    console.log(typeof this.status.enddate)
    console.log(typeof this.status.enddate)
    console.log("typeof this.status.enddate 12345")

    console.log("Thisis what iwant to push to ppm_2");
    console.log(JSON.stringify(ppmItem));

    let r = await ppm.addPPM2(this.$store.getters.getToken, ppmItem, this.$store.getters.getHost)
    
    if (r.status == 400) {
      this.$message.error(r.error);
    } else {
      console.log("THIS IS THE POST RESPONSE")
      console.log(JSON.stringify(r))
      
      let ppm_id = null;
      if(r.ppm_id){
        // gia add
        console.log('NEW PPM2 ADDED') 
        ppm_id = r.ppm_id;
        
      }else{
        console.log('PPM2 UPDATED')
        // gia update
        ppm_id = this.row.id
        // to response etsi kai alios tha epistrepsi ppm_id giati tha to xrisimopoihsw sto add twn parakatw pinakwn
        // meta sta if xrisimopio mono r.ppm_id oxi ppm_id
      } 


     // mia for gia polla item 
    
  for( const i in this.techs ) {
    let ppmPersonnelItem: any[] = [
        {
          id: undefined,
          personnel_id: isNaN(this.techs[i].value)
            ? this.personnelList.find(
                r =>
                  r.firstname == this.techs[i].value.split(" ")[0] &&
                  r.lastname == this.techs[i].value.split(" ")[1]
              ).id
            : this.techs[i].value ,
          ppm_id: ppm_id,
          date_started:  this.techs[i].start_date,
          // isNaN(this.status.selectedStatus) 
          // ? this.techs[i].start_date
          // :this.techs[i].start_date.toLocaleDateString() ,
          
          //not a function .toLocaleDateString()         
          date_ended: this.techs[i].end_date,
          // isNaN(this.status.selectedStatus) 
          // ? this.techs[i].end_date
          // :this.techs[i].end_date.toLocaleDateString() ,
          //not a function .toLocaleDateString() 
          time_start: this.techs[i].start ,
          time_end: this.techs[i].end      

        }
    ];
    // }
    // else {
    //   // gia update
    //     ppmPersonnelItem = [
    //     {
    //       id: this.techs[i].id2,
    //       personnel_id: isNaN(this.techs[i].value)
    //         ? this.personnelList.find(
    //             r =>
    //               r.firstname == this.techs[i].value.split(" ")[0] &&
    //               r.lastname == this.techs[i].value.split(" ")[1]
    //           ).id
    //         : this.techs[i].value ,
    //       ppm_id: ppm_id,
    //       date_started: isNaN(this.status.selectedStatus) 
    //       ? this.techs[i].start_date
    //       :this.techs[i].start_date.toLocaleDateString() ,
    //       //not a function .toLocaleDateString()         
    //       date_ended: isNaN(this.status.selectedStatus) 
    //       ? this.techs[i].end_date
    //       :this.techs[i].end_date.toLocaleDateString() ,
    //       //not a function .toLocaleDateString() 
    //       time_start: this.techs[i].start ,
    //       time_end: this.techs[i].end      

    //     }
    //   ];
    // }
    console.log("Thisis what iwant to push to ppm_personnel2");
    console.log(JSON.stringify(ppmPersonnelItem));

    let re = await ppm.addPPMPersonnel2(this.$store.getters.getToken, ppmPersonnelItem, this.$store.getters.getHost)
    
    if (re.status == 400) {
      this.$message.error(r.error);
    } else {
      console.log("THIS IS THE POST RESPONSE")
      console.log(JSON.stringify(re))
    }
  }

  //I want for loop for multiple entries  
  for( const i in this.workOrders ){
    let ppmWOlItem: any[] = [
      {
        work_order_id: this.workOrders[i].id,
        ppm_id: ppm_id
                    
      }
    ];
    console.log("Thisis what iwant to push to ppm_wo");
    console.log(JSON.stringify(ppmWOlItem));

    let res = await ppm.addPPMWO2(this.$store.getters.getToken, ppmWOlItem, this.$store.getters.getHost)
    
    if (res.status == 400) {
      this.$message.error(res.error);
    } else {
      console.log("THIS IS THE POST RESPONSE")
      console.log(JSON.stringify(res))
    }

}
      //I want loop for multiple entries
      for( const i in this.jsonRemarks ){

      let ppmRemItem: any[] = [
        {
          remark:this.jsonRemarks[i].remarks,
          action: isNaN(this.jsonRemarks[i].actions)
          ? this.jsonRemarks[i].action_id
           : this.jsonRemarks[i].actions,
          ppm_id:ppm_id

                  
        }
        ];
          console.log("Thisis what iwant to push to ppm_remarks");
          console.log(JSON.stringify(ppmRemItem));

         let resp = await ppm.addPPMRem2(this.$store.getters.getToken, ppmRemItem, this.$store.getters.getHost)
    
          if (resp.status == 400) {
            this.$message.error(resp.error);
          } else {
            console.log("THIS IS THE POST RESPONSE")
            console.log(JSON.stringify(resp))
          }
      }

      let ppmCompItem: any[] = []

              //I want loop for multiple entries
      if(this.periodicity == 8 && (this.system =='Conveyors' || this.system == 'Air-Condition' || this.system == 'IT and Automation' )){
        // console.log(JSON.stringify(this.row.component_table[0].id_component))
         console.log('this.row.component_id_edit123')
        console.log(this.yearlyComponent)
         ppmCompItem= [
        {

          ppm_id: ppm_id,
          component_id: isNaN(this.yearlyComponent) 
          ? this.row.component_table[0].component_id_edit
          : this.yearlyComponent
                                         
        }
        ];

        console.log('this.yearlyComponent----->'+this.yearlyComponent)

        console.log("Thisis what iwant to push to ppm_remarks");
          console.log(JSON.stringify(ppmCompItem));

         let respo = await ppm.addPPMComp2(this.$store.getters.getToken, ppmCompItem, this.$store.getters.getHost)
    
          if (respo.status == 400) {
            this.$message.error(respo.error);
          } else {
            console.log("THIS IS THE POST RESPONSE")
            console.log(JSON.stringify(respo))
          }

      }else if(this.periodicity == 8 && this.system =="Electrical Panels"|| this.system == 'Sorter' || this.system =="Scales" ||this.system =="Inductions" || this.system =='Fire Shutter' || this.system == 'Retainer'){
        
         for( const i in this.selectedSubpanels ){
           console.log(this.selectedSubpanels[i])
           console.log("this.selectedSubpanels[i]1234")
        ppmCompItem= [
        {

          ppm_id: ppm_id,
          // component_id: isNaN(this.yearlyComponent) test test test'
          component_id: isNaN(this.selectedSubpanels[i]) 
          ? this.row.component_table[i].component_id_edit
          : this.selectedSubpanels[i]
          // component_id: this.selectedSubpanels[i]
          // component_id: isNaN(this.selectedSubpanels[i])
          // ? this.row.component_id_edit
          // //  : this.yearlyComponent test test test  na ftiaksw to this.row.component_id_edit
          // : this.selectedSubpanels[i]
                               
        }
        ];
        console.log("ppmCompItem"+JSON.stringify(ppmCompItem))

        console.log("Thisis what iwant to push to ppm_remarks");
          console.log(JSON.stringify(ppmCompItem));

         let respo = await ppm.addPPMComp2(this.$store.getters.getToken, ppmCompItem, this.$store.getters.getHost)
    
          if (respo.status == 400) {
            this.$message.error(respo.error);
          } else {
            console.log("THIS IS THE POST RESPONSE")
            console.log(JSON.stringify(respo))
          }

        

         }

      }else if(this.periodicity == 8 && (this.system =="UPS" )){
   console.log('this.belt21321')
        console.log(this.belt)
         ppmCompItem= [
        {

          ppm_id: ppm_id,
          component_id: isNaN(this.belt) 
          ? this.row.component_table[0].component_id_edit
          : this.belt
                                         
        }
        ];

        console.log('this.yearlyComponent----->'+this.yearlyComponent)

        console.log("Thisis what iwant to push to ppm_remarks");
          console.log(JSON.stringify(ppmCompItem));

         let respo = await ppm.addPPMComp2(this.$store.getters.getToken, ppmCompItem, this.$store.getters.getHost)
    
          if (respo.status == 400) {
            this.$message.error(respo.error);
          } else {
            console.log("THIS IS THE POST RESPONSE")
            console.log(JSON.stringify(respo))
          }


      }
      // else if(this.periodicity == 8 && this.system =="Scales"){
        
      //    for( const i in this.selectedScales ){
      //      console.log(this.selectedScales[i])
      //      console.log("this.selectedScales[i]1234")
      //   ppmCompItem= [
      //   {

      //     ppm_id: ppm_id,
      //     component_id: isNaN(this.selectedScales[i]) 
      //     ? this.row.component_table[i].component_id_edit
      //     : this.selectedScales[i]
      //     }
      //   ];
      //   console.log("ppmCompItem"+JSON.stringify(ppmCompItem))

      //   console.log("Thisis what iwant to push to ppm_remarks");
      //     console.log(JSON.stringify(ppmCompItem));

      //    let respo = await ppm.addPPMComp2(this.$store.getters.getToken, ppmCompItem, this.$store.getters.getHost)
    
      //     if (respo.status == 400) {
      //       this.$message.error(respo.error);
      //     } else {
      //       console.log("THIS IS THE POST RESPONSE")
      //       console.log(JSON.stringify(respo))
      //     }

        

      //    }

      // }     

     
        

          



        //current_time:,
      // console.log("=============")
      // console.log(this.jsonRemarks[0].remarks)
      // console.log(this.jsonRemarks[0].actions)
      // console.log("===============")

        // this.yearlyRemarks
        // this.jsonRemarks



      /* this.$store.dispatch("updateCurrentObject", {});
        this.$store.dispatch("updateCurrentComponent", "ShiftList"); */
        if(r.ppm_id){
          this.$message.success("PPM Added");
        }
        else{
          this.$message.success("PPM Updated");
          this.$store.dispatch("updateCurrentObject", {});
        }
        this.$router.replace({
          name: "load"
        });
      //this.$store.dispatch("updateCurrentObject", {});
    }



}

public destroyed(){
  this.$store.dispatch("updateCurrentObject", {});
}
public endTimeChange(index: number) {
    if (this.techs[index].end.length === 2) {
      this.techs[index].end = this.techs[index].end + ":";
      console.log("This is a example for end time change" )
    }
  }
  public startTimeChange(index: number) {
    if (this.techs[index].start.length === 2) {
      this.techs[index].start = this.techs[index].start + ":";
      console.log( this.techs[index].start )
      console.log("This is a example for start time change")
    }
  }

 public async getActivities() {
    await ppm
      .getMaintenanceActivities(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then((resp: any) => {
          console.log(JSON.stringify(resp))
          console.log('testforyearlyppm1')
          let i = 0;
          while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
            this.activities.push({
              value: JSON.parse(JSON.stringify(resp))[i].id,
              label: JSON.parse(JSON.stringify(resp))[i].literal
            });
            i++;
          }
        });
      });
      console.log('testforyearlyppm')
  }

 public pushRemark() {
    this.jsonRemarks.push({
      remarks: this.yearlyRemarks,
      actions: this.yearlyActions
    });
    console.log("this.jsonRemarks")
    console.log(this.jsonRemarks)
    console.log("this.jsonRemarks")
    this.yearlyRemarks = "";
    this.yearlyActions = "";
  }
 

   

  // public pushWorkOrder() { old
  //   console.log('this.selectedWorkOrder ');
  //   console.log(this.selectedWorkOrder );
  //   if (this.selectedWorkOrder != undefined || this.selectedWorkOrder != "") {
        

  //     this.workOrders.push({
  //       id: this.selectedWorkOrder,
  //       value: this.selectedWorkOrder,
  //       description: this.workOrderList.find(
  //         r => r.value == this.selectedWorkOrder
  //       ).description
  //     });
   
  //     console.log('this.workOrders')
  //     console.log(JSON.stringify(this.workOrders))
  //     this.selectedWorkOrder = "";
  //     this.availableWorkOrders = [];
  //   }
  // }
  // public pushWorkOrder() {         OLD
  //   console.log('this.selectedWorkOrder ');
  //   console.log(this.selectedWorkOrder );
  //   if (this.selectedWorkOrder != undefined || this.selectedWorkOrder != "") {
        

  //     this.workOrders.push({
  //       id: this.selectedWorkOrder,
  //       value: this.selectedWorkOrder,
  //       description: this.workOrderList.find(
  //         r => r.value == this.selectedWorkOrder
  //       ).description
  //     });
   
  //     console.log('this.workOrders')
  //     console.log(JSON.stringify(this.workOrders))
  //     this.selectedWorkOrder = "";
  //     this.availableWorkOrders = [];
  //   }
  // }

 public componentMethod(query: string) {
    console.log("query1")
    console.log(query)
    console.log("query1")
    console.log('this.periodicity--->'+this.periodicity) 
    console.log('this.system--->'+this.system);

    console.log(this.selectedHall);
    console.log(this.belt);
    console.log("test for components1")
    console.log(JSON.stringify(this.availableComponents))
    console.log("test for components2")
    //if (query != "" && query != undefined) {
    this.loading = true;
    this.loading = false;
   
    // if(this.system){

    // }
    // this.test1 = this.componentList.filter(r=>r.group1 == this.selectedHall)
    //       this.test2 = this.test1.filter(r=>r.group4== this.belt)



    if (this.system == "Chutes" || this.system == "Desks") {
      this.availableComponents = this.componentList.filter(item => {
        return (
          (item.literal.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
            query == "" ||
            query == undefined) &&
          this.components.find(r => r.id == item.literal) == undefined &&
          item.group2 == this.system
        );
      });
    } else {
      if (this.system == "IT and Automation" && this.periodicity == 7) {
        this.availableComponents = this.componentList.filter(item => {
          return (
            (item.literal.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
              query == "" ||
              query == undefined) &&
            this.components.find(r => r.id == item.literal) == undefined &&
            item.group2 == "ATR" &&
            item.group1 == this.selectedHall &&
            item.group4 == this.belt
          );
        });
      } else {
        if (this.system == "Scales") {
          this.availableComponents = this.componentList.filter(item => {
            return (
              (item.literal.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
                query == "" ||
                query == undefined) &&
              this.components.find(r => r.id == item.literal) == undefined &&
              item.group2 == this.system
              
            );
          });
          console.log(JSON.stringify(this.availableComponents))
          console.log('123ewawgf')
        } else {
          if (this.system == "IT and Automation" && this.periodicity == 6) {
            this.availableComponents = this.componentList.filter(item => {
              return (
                (item.literal.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
                  query == "" ||
                  query == undefined) &&
                this.components.find(r => r.id == item.literal) == undefined &&
                item.group2 == this.system &&
                item.group3 == this.belt
              );
            });
          } else {
            if (this.system == "Reclaims" && this.periodicity == 4) {
              this.availableComponents = this.componentList.filter(item => {
                return (
                  (item.literal.toLowerCase().indexOf(query.toLowerCase()) >
                    -1 ||
                    query == "" ||
                    query == undefined) &&
                  this.components.find(r => r.id == item.literal) ==
                    undefined &&
                  item.group2 == this.system &&
                  item.group1 == this.selectedHall
                );
              });
            } else {
              if (
                this.system == "Fire Shutter and Ret" &&
                this.periodicity == 4
              ) {
                this.availableComponents = this.componentList.filter(item => {
                  return (
                    (item.literal.toLowerCase().indexOf(query.toLowerCase()) >
                      -1 ||
                      query == "" ||
                      query == undefined) &&
                    this.components.find(r => r.id == item.literal) ==
                      undefined &&
                    (item.group2 == "Retainer" ||
                      item.group2 == "Fire Shutters") &&
                    item.group1 == this.selectedHall
                  );
                });
              } else {
                if (
                  this.system == "Electrical Panels" &&
                  this.periodicity == 4
                ) {
                  this.availableComponents = this.componentList.filter(item => {
                    return (
                      (item.literal.toLowerCase().indexOf(query.toLowerCase()) >
                        -1 ||
                        query == "" ||
                        query == undefined) &&
                      this.components.find(r => r.id == item.literal) ==
                        undefined &&
                      item.group2 == this.system &&
                      item.group1 == this.selectedHall
                    );
                  });
                } else {
                  if (this.system == "Fire Panels") {
                    this.availableComponents = this.componentList.filter(
                      item => {
                        return (
                          (item.literal
                            .toLowerCase()
                            .indexOf(query.toLowerCase()) > -1 ||
                            query == "" ||
                            query == undefined) &&
                          item.group2 == "Electrical Panels" &&
                          item.group1 == this.selectedHall &&
                          item.group3 == "Fire Panel"
                        );
                      }
                    );
                  } else {
                    if (
                      this.system == "IT and Automation" &&
                      this.periodicity != 6
                    ) {
                      this.availableComponents = this.componentList.filter(
                        item => {
                          return (
                            (item.literal
                              .toLowerCase()
                              .indexOf(query.toLowerCase()) > -1 ||
                              query == "" ||
                              query == undefined) &&
                            this.components.find(r => r.id == item.literal) ==
                              undefined &&
                            item.group2 == this.system &&
                            item.group1 == this.selectedHall &&
                            item.group3 == this.belt
                          );
                        }
                      );
                    } else {
                      if (
                        this.periodicity == 8 &&
                        (this.system == "Sorter" ||
                          this.system == "Inductions" ||
                          this.system == "UPS" 
                          // ||
                          // this.system == "Fire Shutter" ||
                          // this.system == "Air-Condition" ||
                          // this.system == "Retainer"
                          )
                      ) {
                        console.log("QUERY:" + query);
                        this.availableComponents = this.componentList.filter(
                          item => {
                            return (
                              (item.literal
                                .toLowerCase()
                                .indexOf(query.toLowerCase()) > -1 ||
                                query == "" ||
                                query == undefined) &&
                              item.group2 == this.system &&
                              item.group1 == this.selectedHall &&
                               item.group4 == this.belt
                                    
                                    
                              //this.belt test
                            );
                          }
                        );
                        console.log('xxxxxxxxxxx')
                        for( var i = 0; i < this.availableComponents.length; i++)
                        { if ( this.availableComponents[i].literal =='IS07 CB'||this.availableComponents[i].literal =='IS07 BB'||this.availableComponents[i].literal =='IS08 CB'||this.availableComponents[i].literal =='IS08 BB'||this.availableComponents[i].literal =='IS09 CB'||this.availableComponents[i].literal =='IS09 BB')
                         { this.availableComponents.splice(i, 1); i--; }}

                       console.log('xxxx123')
                       console.log(JSON.stringify(this.availableComponents))
                       console.log('xxxx')
                       console.log(this.system )
                       console.log('this.system')
                       console.log(this.selectedHall)
                        console.log('this.selectedHall')
                       console.log(this.belt)
                      console.log('this.belt')

                      }else if(this.system == "Fire Shutter" || this.system == "Air-Condition"|| this.system == "Retainer" ){
                          console.log("QUERY:" + query);
                        this.availableComponents = this.componentList.filter(
                          item => {
                            return (
                              (item.literal
                                .toLowerCase()
                                .indexOf(query.toLowerCase()) > -1 ||
                                query == "" ||
                                query == undefined) &&
                              item.group2 == this.system &&
                              item.group1 == this.selectedHall 
                              //  item.group4 == this.belt
                                    
                                    
                              //this.belt test
                            );
                          }
                        );



                      } else {
                        if (
                          this.periodicity == 4 &&
                          this.system == "Pull Rope Circuits"
                        ) {
                          this.availableComponents = this.componentList.filter(
                            item => {
                              return (
                                (item.literal
                                  .toLowerCase()
                                  .indexOf(query.toLowerCase()) > -1 ||
                                  query == "" ||
                                  query == undefined) &&
                                item.group2 == "Conveyors" &&
                                item.week == this.selectedWeek
                              );
                            }
                          );
                        } else {
                          if (
                            this.periodicity == 4 &&
                            this.system == "Conveyors"
                          ) {
                            this.availableComponents = this.componentList.filter(
                              item => {
                                if (
                                  item.group4 != "EK" &&
                                  item.group4 != "EA"
                                ) {
                                  return (
                                    (item.literal
                                      .toLowerCase()
                                      .indexOf(query.toLowerCase()) > -1 ||
                                      query == "" ||
                                      query == undefined) &&
                                    item.group2 == this.system &&
                                    (item.group4 == this.belt ||
                                      (this.lines.find(
                                        r => r.component == item.id
                                      ) != undefined
                                        ? this.lines.find(
                                            r => r.component == item.id
                                          ).literal == this.belt
                                        : false))
                                  );
                                } else {
                                  console.log(item.id);
                                  return (
                                    (item.literal
                                      .toLowerCase()
                                      .indexOf(query.toLowerCase()) > -1 ||
                                      query == "" ||
                                      query == undefined) &&
                                    item.group2 == this.system &&
                                    item.group4 == this.belt &&
                                    (this.lines.find(
                                      r => r.component == item.id
                                    ) != undefined &&
                                      this.beltLine.includes(
                                        this.lines.find(
                                          r => r.component == item.id
                                        ).literal
                                      ))
                                  );
                                }
                              }
                            );
                          } else {
                            if (
                              this.periodicity == 8 &&
                              this.system == "Electrical Panels"
                            ) {
                              //========================================================
                              console.log("xxxxxxxtest_for_electrical")
                              console.log(this.componentList)

                              this.availableComponents = this.componentList.filter(
                                item => {
                                  return (
                                    (item.literal
                                      .toLowerCase()
                                      .indexOf(query.toLowerCase()) > -1 ||
                                      query == "" ||
                                      query == undefined) &&
                                    item.group1 == this.selectedHall &&
                                    item.group4 == this.belt
                                  );
                                }
                              );
                              //test

                                  //  this.availableComponents.sort(function(a, b) {
                                  //           if (a >= b) {
                                  //             return 1;
                                  //           }
                                  //           if (a < b) {
                                  //             return -1;
                                  //           }
                                  //         });
                                
                                // this.availableComponents.sort(function(a, b){

                                //           var keyA = a.literal,
                                //               keyB = b.literal;
                                //           // Compare the 2 dates
                                //           console.log(keyA)
                                //           console.log("keyAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
                                //           console.log(keyB)
                                //           console.log("keyB")
                                //           if(keyA < keyB) return -1;
                                //           if(keyA > keyB) return 1;
                                //           return keyA-keyB;
                                //         });



                                        var a = this.availableComponents;
                                        this.availableComponents=[]
console.log("BEGIN WITH: " + a);
var numericPart;
var letterPart;
var max_chars = 4;
var pad_char = 0;
var b = [];
var i=0;
 for(var x of a){

 
// for (var i=0; i<a.length; i++) {
    numericPart = x.literal.match(/\d+/);
    
    numericPart = (new Array(max_chars + 1).join(pad_char + '') + numericPart).slice(-max_chars); 
    letterPart = x.literal.match(/[A-z ]+/);
    this.availableComponents[i] = x
    this.availableComponents[i].literal = letterPart+numericPart;
   
    i++
}
for (var i=0; i<this.availableComponents.length; i++) {
 console.log(JSON.stringify(this.availableComponents[i]))
    console.log('b[i]')
}
// b.sort(function(a,b){
//     if (a > b)
//         return 1;
//     if (a < b)
//         return -1;
//     // a must be equal to b
//     return 0;
// });

       this.availableComponents.sort(function(a, b){

                                          var keyA = a.literal,
                                              keyB = b.literal;
                                          // Compare the 2 dates
                                          console.log(keyA)
                                          console.log("keyAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
                                          console.log(keyB)
                                          console.log("keyB")
                                          if(keyA < keyB) return -1;
                                          if(keyA > keyB) return 1;
                                          return keyA-keyB;
                                        });
                                  
console.log("INTERMEDIATE - TAKE THIS OUTPUT IF OK - : " + this.availableComponents);
for (var i=0; i<this.availableComponents.length; i++) {
    this.availableComponents[i].literal = this.availableComponents[i].literal.replace(/0+(?=[1-9])/, '');
    this.availableComponents[i].literal =this.availableComponents[i].literal.replace(/null/,'')
}
//       for (var i=0; i<this.availableComponents.length; i++) {
//  console.log(JSON.stringify(this.availableComponents[i].literal.replace(/null/,"")))
//     console.log('b[i]')
// }
console.log("END WITH: " +JSON.stringify( this.availableComponents));
                                          console.log(JSON.stringify(this.availableComponents))
                                          console.log('AAAAAAAAAAAAAAAAAAAAAAAAx')
                              //test
                              for(let i of this.PpmsList2subpanel){
                                
                                if(this.maintenancePeriod == i.maintenance_per){
                                 
                                  if(this.system== i.system){
                                    if(this.selectedHall == i.hall){
                                      if(this.belt == i.electrical_panel_edit){
                                      
                                        for(let x of i.component_table){
                                         
                                        for(let y in this.availableComponents){  
                                             
                                              //  x.id_component.replace('"',' ')
                                              // x.id_component.slice(0,x.id_component.length - 1)
                                        // console.log(JSON.stringify(y.id))
                                       
                                          if(x.id_component.includes(this.availableComponents[i].literal)){
                                             console.log('yyyyyyyyyyy123')
                                             this.availableComponents.splice(i,1)
                                          //  y.splice()
                                          // console.log(y)
                                          // this.availableComponents2.push(x)
                                         
                                          }




                                        }



                                        }




                                      }




                                    }




                                  }





                                }


                              }

                                console.log('yyyyyyyyyyy123')

                               console.log(JSON.stringify(this.availableComponents))
                               console.log('this.availableComponents123456')
                              this.availableComponents2=this.PpmsList2subpanel
                             // .filter( item => { item.system == this.system &&item.hall == this.selectedHall && item.electrical_panel_edit == this.belt  })
                            console.log(JSON.stringify(this.availableComponents2))
                            console.log('this.availableComponents2')
                              // for (let x of this.PpmsList2subpanel){
                              // if(x.maintenance_per == this.maintenancePeriod){
                              //           this.availableComponents.filter( item => {(item.group1 == this.selectedHall && item.group4 == this.belt) && })
                              // }
                              // }
                            //   for (let a of this.availableComponents){
                            //   for (let x of this.PpmsList2subpanel){
                            //       if(x.maintenance_per == this.maintenancePeriod){
                            //         if(x.hall == this.selectedHall){
                            //           if( x.electrical_panel_edit==this.belt){
                            //             for(let y of x.component_table){
                            //              if (y != a.literal){
                            //                console.log(a)
                            //                console.log('a123')
                            //                 this.availableComponents2 =a

                            //              }                                                                          
                            //             }                                 
                            //           }                                  
                            //         }                                  
                            //       }
                            //   }
                            //  }
                             // this.availableComponents= this.availableComponents.filter(literal !=)
                              console.log("test for components1")
                              console.log(JSON.stringify(this.availableComponents))
                              console.log("test for components2")
                            } else {
                              if (
                                this.periodicity == 8 ||
                                this.periodicity == 4
                              ) {
                                this.availableComponents = this.componentList.filter(
                                  item => {
                                    return (
                                      (item.literal
                                        .toLowerCase()
                                        .indexOf(query.toLowerCase()) > -1 ||
                                        query == "" ||
                                        query == undefined) &&
                                      item.group2 == this.system
                                    );
                                  }
                                );
                                console.log(this.availableComponents)
                                console.log("test for components12")
                              } else {
                                
                                this.availableComponents = this.componentList.filter(
                                  item => {
                                    return (
                                      (item.literal
                                        .toLowerCase()
                                        .indexOf(query.toLowerCase()) > -1 ||
                                        query == "" ||
                                        query == undefined) &&
                                      item.group2 == this.system &&
                                      item.group1 == this.selectedHall
                                    );
                                  }
                                );
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  } 

//test
public async getlistelectricalPanels(){
this.componentList
console.log(this.componentList)
console.log(this.componentList)
}

  public async getComponentList() {
    await component
      .getAllComponents(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
         console.log("getComponentList")
          //kathisteri
          
          // this.componentList = JSON.parse(JSON.stringify(resp)).filter(r=>r.group2==this.system);           
          // this.test1 = this.componentList.filter(r=>r.group1 == this.selectedHall)
          // this.test2 = this.test1.filter(r=>r.group4== this.belt)

          this.componentList = JSON.parse(JSON.stringify(resp)).filter(r=>r.group2==this.system);  
          console.log(this.componentList) 
          console.log("this.componentList12345")        
          


          // var componentList2 = componentList.filter(r=>r.group4== this.southPanels)
          console.log("this.componentList1")
          console.log(JSON.stringify(this.componentList))
          console.log("this.componentList2")
          for (const i of this.componentList) {
            if (i.group2 == "ATR") {
              /////
              if (!this.atrGroups.includes(i.group4)) {
                this.atrGroups.push(i.group4);
              }
            }
            if (i.group2 == "UPS") {
              //////
              if (i.group1 == "South") {
                if (!this.southUps.includes(i.group4)) {
                  this.southUps.push({literal :i.group4 , id: i.id });
                  
                }
              } else {
                if (!this.northUps.includes(i.group4)) {
                  this.northUps.push({literal :i.group4 , id: i.id });
                }
              }
              if (!this.upsGroups.includes(i.group4)) {
                this.upsGroups.push({literal :i.group4 , id: i.id });
              }
            }
            if (i.group2 == "Sorter") {
              //////
              console.log('=================')
              console.log(i)
              console.log('i.group2123123123sorter')
              if (i.group1 == "North") {
               if (this.northSorter.find(r=>r.group4==i.group4)==undefined) {
                  this.northSorter.push({group4 :i.group4 , id: i.id });   
                   console.log('=================')
                   console.log(i)
                   console.log('i.group4north1231')     
                                
                }
              } else {
                if (this.southSorter.find(r=>r.group4==i.group4)==undefined) {
                  this.southSorter.push({group4 :i.group4 , id: i.id });
                  
                   console.log('=================')
                   console.log(this.southSorter)
                   console.log('i.group4123123123sorter') 
                }
              }
            }
            if (i.group2 == "IT and Automation") {
              //////
              if (!this.itGroups.includes(i.group3)) {
                this.itGroups.push(i.group3);
              }
            }
            if (i.group4 == "ATR" || i.group2 == "ATR") {
              //////
              this.atrComponents.push(i);
            }
            if (i.group2 == "Inductions") {
              //////
              if (i.group1 == "North") {
                if (!this.northInductions.includes(i.group4)) {
                  this.northInductions.push(i.group4);
                  //!!! this.northInductions.push({group4 :i.group4 , id: i.id });
                  console.log('northInductions1')
                  console.log(JSON.stringify(this.northInductions))
                  console.log('northInductions')
                   this.northInductions.sort(function(a, b): number {
                                            if (a >= b) {
                                              return 1;
                                            }
                                            if (a < b) {
                                              return -1;
                                            }
                                            return 0;
                                          });
                }
              } else {
                if (!this.southInductions.includes(i.group4)) {
                  this.southInductions.push(i.group4);
                   this.southInductions.sort(function(a, b): number {
                                            if (a >= b) {
                                              return 1;
                                            }
                                            if (a < b) {
                                              return -1;
                                            }
                                             return 0;
                                          });
                }
              }
            }
            if (!this.halls.includes(i.group1)) {
              ////----
              this.halls.push(i.group1);
              console.log("this.halls1")
              console.log(this.halls)
              console.log("this.halls2")
            }
            if (!this.systems.includes(i.group2)) {
              ////----
              this.systems.push(i.group2);
            }
            if (!this.groups.includes(i.group3)) {
              ////----
              this.groups.push(i.group3);
            }
            if (!this.codes.includes(i.group4)) {
              ////----
              this.codes.push(i.group4);
            }
            if (!this.descriptions.includes(i.group5)) {
              ////----
              this.descriptions.push(i.group5);
            }
            if (i.group2 == "Conveyors") {
              /////-----
              if (!this.conveyorBelts.includes(i.group4)) {
                this.conveyorBelts.push(i.group4);
              }
            }
            if (i.group2 == "Electrical Panels") {
              //////
              if (i.group1 == "South") {
                if (!this.southPanels.includes(i.group4)) {
                  this.southPanels.push(i.group4);
                  //  this.southPanels.sort(function(a, b) {
                  //                           if (a >= b) {
                  //                             return 1;
                  //                           }
                  //                           if (a < b) {
                  //                             return -1;
                  //                           }
                  //                         });
                                          console.log(JSON.stringify(this.southPanels))
                                          console.log('aaaaa')
                  console.log(JSON.stringify(this.southPanels))
                  console.log("xxxxx123456")
                }
              } else {
                if (!this.northPanels.includes(i.group4)) {
                  this.northPanels.push(i.group4);
                  console.log("xxxxx1")
                }
              }
            }
            //  console.log(JSON.stringify(this.southUps))
            //       console.log('this.southUps21321')
            //         console.log(JSON.stringify(this.northPanels))
            //         console.log("xxxxx123456b")
            console.log('=================')
            console.log(JSON.stringify(this.northSorter))
            console.log('i.group4north123123123sorter') 
          }
          console.log(this.atrComponents);
        });
      });
  }



 public async getSenTechList() {
   console.log(new Date().toLocaleTimeString()+'1')
    await aiaPersonnel
      .getAllPersonnel(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        console.log(new Date().toLocaleTimeString()+'2')
        response.json().then(resp => {
          console.log(new Date().toLocaleTimeString()+'3')
          this.senTechList = JSON.parse(JSON.stringify(resp));
          for (const p of this.senTechList) {
            this.senTechs.push({
              label: p.firstname + " " + p.lastname,
              value: p.id,
              team: p.team
            });
          }
          console.log(new Date().toLocaleTimeString()+'4')
          console.log(this.senTechs);
         
        });
      });
  }


 public clearSearch() {
    this.availableSenTechs = [];
  }
public clearSearch2() {
this.availableComponents = [];
}
 

    public senTechMethod(query: string) {
    if (query != "" && query != undefined) {
      this.loading = true;
      this.loading = false;
      this.availableSenTechs = this.senTechs.filter(item => {
        return (
          item.label.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          item.team == query
        );
      });
    } else {
      this.availableSenTechs = [];
    }
  }




  public workOrderMethod(query: string) {
    if (query != "" && query != undefined) {
      this.availableWorkOrders = this.workOrderList.filter(item => {
        return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
      });
      console.log('this.availableWorkOrders')
      console.log(this.availableWorkOrders)
    } else {
      this.availableWorkOrders = [];
    }
  }



 

  public pushPersonnel() {
    //auto gemizi tin proti timi kai meta i for ta emfanizi
    if(this.techs.length<1){
    this.techs.push({
      id:
        this.personnelList.find(r => r.id == this.selectedTech).firstname +
        " " +
        this.personnelList.find(r => r.id == this.selectedTech).lastname,
      value: this.selectedTech,
      start: "",
      end: "",
      start_date: "",
      end_date: ""
    });
    
    }else{
     this.techs.push({
      id:
        this.personnelList.find(r => r.id == this.selectedTech).firstname +
        " " +
        this.personnelList.find(r => r.id == this.selectedTech).lastname,
      value: this.selectedTech,
      start: this.techs[0].start,
      end: this.techs[0].end,
      start_date: this.techs[0].start_date,
      end_date: this.techs[0].end_date
    });
    }
    this.selectedTech = "";
    this.availableForemen = [];
    console.log("xxxxxxxxx")
    console.log(JSON.stringify(this.techs));
    console.log("xxxxxxxxx")
  }

  public techUpdate(id: any, index: number) {
    if (id == "") {
      this.techs.splice(index, 1);
    }
    console.log("this.techs1")
    console.log(this.techs);
    console.log("this.techs2")
  }

  public foremanMethod(query: string) {
    if (query != "" && query != undefined) {
      this.loading = true;
      this.loading = false;
      this.availableForemen = this.personnelList.filter(item => {
        return (
          (item.firstname.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
            item.team == query ||
            item.lastname.toLowerCase().indexOf(query.toLowerCase()) > -1) &&
          this.techs.find(r => r.id == item.firstname + " " + item.lastname) ==
            undefined && item.active != false
        );
      });
      console.log("this.availableForemen1")
      console.log(this.availableForemen);
      console.log("this.availableForemen2")
    } else {
      this.availableForemen = [];
    }
  }



  public async getPersonnelList() {
    await personnel
      .getAllPersonnel(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.personnelList = JSON.parse(JSON.stringify(resp));
        });
      });
  }



  public async maintenancePeriodYearOptions(){
    // ftiaxnw epiloges gia to maintenance period pedio
    this.currentYear = Moment(new Date()).format("YYYY");
    var year = parseInt(this.currentYear);
    this.currentMonth = Moment(new Date()).format("MM")
    //  this.currentMonth = Moment(new Date(2019,9,2)).format("MM")
    var month = parseInt(this.currentMonth)
    if(month <= 9){

      year=year-1

    }

    do {
      // year= year +"-"+year+1
      console.log(typeof year)
      console.log("typeofyear1")
      console.log(year+1)
      console.log("typeofyear2")
      var yeartest2=year+1
      let yeartest= year+"-"+yeartest2
      this.maintenancePeriodOptions.push(yeartest);
      this.maintenancePeriodOptions2.push(yeartest2);
      year -= 1;
    } while (2019 <= year);
  }

 public async getSystemPeriodicities() {
  //  let res2 = await postgrest.get('groups,system_periodicity',this.$store.getters.getToken);
   let res3 = await postgrest.get('groups?select=*,sytem_periodicity(*)system=eq.id',this.$store.getters.getToken)
   console.log('testforgetSystemperiodicities111111')
   console.log(JSON.stringify(res3))
  console.log('testforgetSystemperiodicities2222222')
  //  console.log(res2)
  //  console.log('testforgetSystemperiodicities')

  //  let res4 = await postgrest.get('ppm_2?select=*,personnel(*)id=eq.2,ppm_remarks2(*)id=eq.2', this.$store.getters.getToken);
    let response: any  = await ppm
      .getSystemPeriodicities(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      );
    
    let jsonResult = await response.json();
    console.log('jsonResult')
    console.log(JSON.stringify(jsonResult));

    for (let i = 0; i <= jsonResult.length - 1; ++i) {
      this.systemPeriodicities.push({
        system: jsonResult[i].system,
        system_id: jsonResult[i].system_id,
        periodicity: jsonResult[i].periodicity
      });
    }
  }


 public periodicityChange() {
    console.log(this.systemPeriodicities);
    this.availableSystems = [];
    let availablePeriodicities = this.systemPeriodicities.filter(
      r => r.periodicity == 8
    );
    console.log(this.periodicity);
    console.log(availablePeriodicities);
    //edw exw ta lektika apo ta components kai periodicity 8 pou einai to yearly
    
    for (const p of availablePeriodicities) {
           this.availableSystems.push(p.system);
    }
    // if (this.periodicity == 7) {
    //   this.availableSystems.push("Fire Panels");
    // }
    // if (this.periodicity == 4) {
    //   this.availableSystems.push("Fire Shutter and Ret");
    //   this.availableSystems = this.availableSystems.filter(
    //     r => r != "Fire Shutters" && r != "Retainer"
    //   );
    // }
    // this.receiveRemarks();
  }



//o ipolipos kodikas st note
}

</script>

<style scoped>
.tableCell {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 220px;
  position: relative;
  height: 30px;
}
.longTableCell {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 400px;
  position: relative;
  height: 30px;
}
.superLongTableCell {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 100%;
  position: relative;
  height: 30px;
}
.superLongTableCellFixed {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 550px;
  position: relative;
  height: 30px;
}
.shortTableCell {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 100px;
  position: relative;
  height: 30px;
}
.midTableCell {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 120px;
  position: relative;
  height: 30px;
}
.tableTag {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 100px;
  height: 30px;
}
.yellowTableTag {
  background-color: rgb(253, 255, 136);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 100px;
  height: 30px;
}
.dateTableCell {
  background-color: rgb(201, 228, 238);
  font-weight: normal !important;
  font-size: 9pt !important;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  width: 150px;
  position: relative;
  height: 30px;
}
.tagText {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.notATextbox {
  width: 100%;
  padding-left: 5px;
  background-color: rgb(226, 226, 226);
  border: 1px solid rgb(0, 0, 0);
  font-size: 9pt !important;
  overflow-wrap: break-word;
}
.white {
  color: white;
}
</style>