<template>
  <div>Downloading Reports PPM</div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { ppm } from "../../api/ppm/ppm";
import moment from "moment";
@Component({ name: "DownloadPPMReports" })
export default class DownloadPPMReports extends Vue {
  public async mounted() {
    let object = this.$store.getters.getCurrentObject;
    let token = this.$store.getters.getToken;
    let fromDate1 = object.fromDate
    let toDate1 = object.toDate
    let periodicity1 = object.periodicity
    let reports1 = object.reports

    console.log("testfordownloadvacation2all")
    console.log(object)
    console.log(token)
    console.log(fromDate1)
    console.log(toDate1)
    console.log(periodicity1)
    console.log(reports1)

    console.log("testfordownloadvacation2")

    //let year=this.$store.getters.getCurrentObject;
    //setCurrentId -1 gia epistrofi
    this.$store.dispatch("setCurrentId",-1 );
    //AddNewVacation arxiki othoni ekei pou thelw na giriso
    this.$store.dispatch("updateCurrentComponent", "PPMReports");
    this.$router.replace({
      name: "load"
    });
    //let response = await vacation.downloadVacation2(
    let response = await ppm.downloadPPMReports(
      token,
      fromDate1,
      this.$store.getters.getHost,
      toDate1,
      periodicity1,
      reports1
    );
    console.log(response)
    console.log('response12334')
    response.blob().then((blob: any) => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      //a.download = object.id.id + ".xlsx";
      if(reports1==1){
        console.log("test_for_e")
        var CurrentDate = moment().format("DD/MM/YYYY")
        a.download = "Client_PPM_Report_"+CurrentDate+".xlsx";
      }else if(reports1==2){
       console.log("test_for_e")
        var CurrentDate = moment().format("DD/MM/YYYY")
        a.download = "General"+CurrentDate+".xlsx";
      }else if(reports1==4){
       console.log("test_for_e")
        var CurrentDate = moment().format("DD/MM/YYYY")
        a.download = "Overview_Report"+CurrentDate+".xlsx";
      }


      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    });
  }
}
</script>
<style>
</style>