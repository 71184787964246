<template>
  <div>
    <el-col :span="12">
      <h3>Work Order</h3>
      <el-date-picker v-model="WODate" @change="refreshWO" type="month" placeholder="Pick a month"></el-date-picker>
      <div id="WOChart" v-loading="WOloading"></div>
    </el-col>
    <el-col :span="12">
      <h3>PPM</h3>
      <el-date-picker
        v-model="PPMDate"
        @change="refreshPPM"
        type="month"
        placeholder="Pick a month"
      ></el-date-picker>
      <div id="PPMChart" v-loading="PPMloading"></div>
    </el-col>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { workorder } from "../../api/workorder/workorder";
import { personnel } from "../../api/personnel/personnel";
import { ppm } from "../../api/ppm/ppm";
@Component({
  name: "employeeOfTheMonth"
})
export default class employeeOfTheMonth extends Vue {
  public WOList: any[] = [];
  public WODate: any = "";
  public PPMDate: any = "";
  public personnelList: any[] = [];
  public PPMList: any[] = [];
  public WOloading: boolean = true;
  public PPMloading: boolean = true;
  public async getAllWorkOrders() {
    await workorder
      .getSomeWorkOrders(
        this.$store.getters.getToken,
        {
          idFrom: undefined,
          idTo: undefined,
          dateFrom: undefined,
          dateTo: undefined,
          search: undefined,
          keyword: undefined
        },
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then((resp: any) => {
          this.WOList = JSON.parse(JSON.stringify(resp));
          this.getPersonnelList();
        });
      });
  }
  public async getSomeWorkOrders() {
    let tempDate = new Date(this.WODate);
    await workorder
      .getSomeWorkOrders(
        this.$store.getters.getToken,
        {
          idFrom: undefined,
          idTo: undefined,
          dateFrom: new Date(tempDate),
          dateTo: new Date(tempDate.setMonth(tempDate.getMonth() + 1)),
          search: undefined,
          keyword: undefined
        },
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then((resp: any) => {
          this.WOList = JSON.parse(JSON.stringify(resp));
          this.getPersonnelList();
        });
      });
  }
  public async refreshWO() {
    this.WOloading = true;
    if (this.WODate != undefined) {
      await this.getSomeWorkOrders();
    } else {
      await this.getAllWorkOrders();
    }
    this.embedWO(this.parsePersonnel(this.WOList));
    this.WOloading = false;
  }
  public async refreshPPM() {
    this.PPMloading = true;
    if (this.PPMDate != undefined) {
      await this.getSomePPM();
    } else {
      await this.getPPMList();
    }
    this.embedPPM(this.parsePersonnel(this.PPMList));
    this.PPMloading = false;
  }
  public async getPPMList() {
    await ppm
      .getSomePPMs(
        this.$store.getters.getToken,
        { dateFrom: undefined, dateTo: undefined, keyword: undefined },
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then((resp: any) => {
          this.PPMList = JSON.parse(JSON.stringify(resp));
        });
      });
  }
  public async getSomePPM() {
    let tempDate = new Date(this.PPMDate);
    await ppm
      .getSomePPMs(
        this.$store.getters.getToken,
        {
          dateFrom: new Date(tempDate),
          dateTo: new Date(tempDate.setMonth(tempDate.getMonth() + 1)),
          keyword: undefined
        },
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then((resp: any) => {
          this.PPMList = JSON.parse(JSON.stringify(resp));
        });
      });
  }
  public async mounted() {
    this.WOloading = true;
    this.PPMloading = true;
    await this.getPersonnelList();
    await this.getAllWorkOrders();
    await this.getPPMList();
    this.embedWO(await this.parsePersonnel(this.WOList));
    this.WOloading = false;
    this.embedPPM(await this.parsePersonnel(this.PPMList));
    this.PPMloading = false;
  }
  public async getPersonnelList() {
    personnel
      .getAllPersonnel(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.personnelList = JSON.parse(JSON.stringify(resp));
        });
      });
  }

  public parsePersonnel(list: any) {
    let personnel: Array<any> = [];
    for (const w of list) {
      if (!w.recycled) {
        for (const p of JSON.parse(w.personnel)) {
          let person: any = personnel.find(r => r.id == p.id);
          if (person === undefined) {
            personnel.push({
              id: p.id,
              time:
                (new Date(p.end_date).getTime() -
                  new Date(p.start_date).getTime()) /
                (1000 * 60 * 60),
              name:
                this.personnelList.find(r => r.id == p.id).firstname +
                " " +
                this.personnelList.find(r => r.id == p.id).lastname
            });
          } else {
            person.time =
              person.time +
              (new Date(p.end_date).getTime() -
                new Date(p.start_date).getTime()) /
                (1000 * 60 * 60);
          }
        }
      }
    }
    console.log(personnel);
    return personnel;
  }
  public embedWO(personnel: any) {
    // vegaEmbed("#WOChart", this.chartSpec).then(res =>
    //   res.view.insert("myData", personnel).run()
    // );
  }
  public embedPPM(personnel: any) {
    // vegaEmbed("#PPMChart", this.chartSpec).then(res =>
    //   res.view.insert("myData", personnel).run()
    // );
  }
  public chartSpec = {
    $schema: "https://vega.github.io/schema/vega-lite/v3.json",
    data: {
      name: "myData"
    },
    mark: "bar",
    encoding: {
      y: {
        field: "name",
        type: "ordinal",
        axis: {
          title: "Name"
        },
        sort: {
          op: "sum",
          field: "b",
          order: "descending"
        }
      },
      x: {
        field: "time",
        type: "quantitative",
        axis: {
          title: "Total Hours"
        }
      }
    }
  };
}
</script>
<style>
.vega-actions {
  display: none;
}
</style>