import { RESTCalls } from "../helpers/restcalls";
import { Company } from "../personnel/company";
export namespace personnel {
  export async function getAllPersonnel(token: string, host: any) {
    return fetch(host + "/api/personnel/getAll?token=" + token);
  }
  export class PersonnelItemResponse {
    public id: number;
    public lastname: string;
    public firstname: string;
    public mobile: number;
    public landline: number;
    public email: string;
    public company_id: number;
    public specialty_id: number;
    public active: boolean;
    public company: {
      id: number;
      name: string;
    };
    constructor() {
      this.id = -1;
      this.lastname = "";
      this.firstname = "";
      this.mobile = -1;
      this.landline = -1;
      this.email = "";
      this.company_id = -1;
      this.specialty_id = -1;
      this.active = false;
      this.company = JSON.parse(
        JSON.stringify(new Company.CompanyItemResponse())
      );
    }
  }
  export async function addNewStaff(
    token: string,
    personnelItem: any,
    host: any
  ) {
    let result: Response;
    result = (await fetch(host + "/api/personnel/Add", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body:
        "token=" +
        token +
        "&firstname=" +
        personnelItem.firstname +
        "&lastname=" +
        personnelItem.lastname +
        //"&active=1" +
        "&active="+
        personnelItem.active +
        "&mobile=" +
        personnelItem.mobile +
        "&landline=" +
        personnelItem.landline +
        "&company_id=" +
        personnelItem.company_id +
        "&specialty_id=" +
        personnelItem.specialty_id +
        "&email=" +
        personnelItem.email +
        "&wo_specialty=" +
        personnelItem.wo_specialty
    })) as any;

    return await result.json();
  }
  export async function editStaff(
    token: string,
    personnelItem: any,
    host: any
  ) {
    let result: Response;
    result = (await fetch(host + "/api/personnel/Add", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body:
        "token=" +
        token +
        "&firstname=" +
        personnelItem.firstname +
        "&lastname=" +
        personnelItem.lastname +
        //"&active=1" +
        "&active=" +
        personnelItem.active+
        "&mobile=" +
        personnelItem.mobile +
        "&landline=" +
        personnelItem.landline +
        "&company_id=" +
        personnelItem.company_id +
        "&specialty_id=" +
        personnelItem.specialty_id +
        "&email=" +
        personnelItem.email +
        "&id=" +
        personnelItem.id +
        "&wo_specialty=" +
        personnelItem.wo_specialty
    })) as any;

    return await result.json();
  }
}
