<template>
<div>
    <el-row>
    <el-steps :active="active" finish-status="success">
  <el-step title="Project Details"></el-step>
  <el-step title="Project Personnel"></el-step>
  <el-step title="Summary"></el-step>
</el-steps>
</el-row>
  <el-row>
     <component v-bind:is="componentname" @on-loaded="loading=false" @new-details="getDetails" @new-personnel="getPersonnel" :details="obj" v-if="active<2"></component>
     <div v-else>obj:{{projectObject}}</div>
  </el-row>
  <el-row>
<el-button style="margin-top: 12px;" @click="next">Next</el-button>
</el-row>
</div>
</template>
<script lang ="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import ProjectDetails from "../Details/ProjectDetails.vue";
import PersonnelSelector from "../Selectors/PersonnelSelector.vue";
import {project} from "../../api/project/project";
// @Component({
//   name: "AddProject",
//   components: {
//     ProjectDetails,
//     PersonnelSelector
//   },
  
// })

const APProps = Vue.extend({
props: {
    obj: {
      default: undefined
    }
  },
})

@Component
export default class AddProject extends APProps {

  active: number = 0;
  componentname: string = "ProjectDetails";
  loading: boolean = true;
  project: any[] = []
  details: any = [];

  get projectObject() {
    return this.project;
  }
  set projectObject(val) {
    this.project.push(val);
  }
  public next() {
    if (this.active++ > 1) {
      this.active = 0;
      if (this.details.id === undefined) {
        project.addproject(this.$store.getters.getToken, this.project,this.$store.getters.getHost);
      } else {
        project.editproject(this.$store.getters.getToken, this.project,this.$store.getters.getHost);
      }
    }
    this.drawPage();
  }
  public drawPage() {
    console.log(this.$data.project);
    this.$data.loading = true;
    switch (this.active) {
      case 0:
        this.componentname = "projectDetails";
        break;
      case 1:
        this.componentname = "PersonnelSelector";
        break;
    }
  }
  public async getDetails(details) {
    this.projectObject = details;
  }
  public async getPersonnel(personnel) {
    this.projectObject = personnel;
  }
}
</script>