<template>
  <div>
    <el-row class="container">
      <WorkOrderDetails v-model="details"></WorkOrderDetails>
    </el-row>
    <br>
    <el-row class="container">
      <ComponentSelector v-model="selectedComponents"></ComponentSelector>
    </el-row>
    <br>
    <el-row class="container">
      <PersonnelSelector v-model="personnel"></PersonnelSelector>
    </el-row>
    <br>
    <el-row class="container">
      <StatusSelector v-model="status"></StatusSelector>
    </el-row>
    <br>
    <el-row>
      <el-button style="margin-top: 12px;" @click="validate" type="success">
        <strong>Submit</strong>
      </el-button>
    </el-row>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import ComponentSelector from "../Selectors/ComponentSelector.vue";
import StatusSelector from "../Selectors/StatusSelector.vue";
import { workorder } from "../../api/workorder/workorder";
import WorkOrderDetails from "../Details/WorkOrderDetails.vue";
import PersonnelSelector from "../Selectors/PersonnelSelector.vue";

const AWOProps = Vue.extend({
  props: {
    obj: {}
  }
})

@Component({
  name: "AddWorkOrder",
  components: {
    WorkOrderDetails,
    ComponentSelector,
    PersonnelSelector,
    StatusSelector
  },
})

export default class AddWorkOrder extends AWOProps {

  public active: number = 0;
  public componentname: string = "WorkOrderDetails";
  public loading: boolean = true;
  public workOrder: any[] = [];
  public details: any = {};
  public selectedComponents: any[] = [];
  public personnel: any[] = [];
  public status: any = [];

  get workOrderObject() {
     return this.workOrder;
  }

  set workOrderObject(val) {
    this.workOrder.push(val);
  }

  public next() {
    if (this.$props.obj.id === undefined) {
      workorder
        .addWorkOrder(
          this.$store.getters.getToken,
          this.workOrderObject,
          this.$store.getters.getHost
        )
        .then(r => {
          if (r.status == 400) {
            this.$message.error(r.error);
          } else {
            this.$message.success("Work Order Added");
            this.workOrder = [];
            this.$store.dispatch("updateCurrentObject", {});
            this.$store.dispatch("updateCurrentComponent", "WorkOrderList");
            this.$router.replace({
              name: "load"
            });
          }
        });
    } else {
      workorder
        .editWorkOrder(
          this.$store.getters.getToken,
          this.workOrderObject,
          this.$store.getters.getHost
        )
        .then(r => {
          if (r.status == 400) {
            this.$message.error(r.error);
          } else {
            this.$message.success("Work Order Edited");
            this.workOrder = [];
            this.$store.dispatch("updateCurrentObject", {});
            this.$store.dispatch("updateCurrentComponent", "WorkOrderList");
            this.$router.replace({
              name: "load"
            });
          }
        });
    }
  }
  public async mounted() {
    if (this.$props.obj.id === undefined) {
      console.log("add");
    } else {
      console.log("edit");
      this.details = {
        aia: this.$props.obj.aia_id,
        issuedate: this.$props.obj.date_issued,
        name: this.$props.obj.name,
        type: this.$props.obj.type,
        comments: this.$props.obj.comments,
        id: this.$props.obj.id
      };
      this.status = {
        enddate: (this.$props.obj as  any).date_closed,
        selectedStatus: this.$props.obj.status
      };
      this.selectedComponents = this.$props.obj.componentIds;
      let addedPersons = await JSON.parse(
        JSON.parse(this.$props.obj.personnel)
      );
      for (const p of addedPersons) {
        let obj = this.personnel.find(obj => obj.id === p.id);
        if (obj != undefined) {
          console.log("upd");
          obj.dates.push({
            date_started: p.start_date,
            date_ended: p.end_date
          });
        } else {
          console.log("new");
          this.personnel.push({
            id: p.id,
            dates: [{ date_started: p.start_date, date_ended: p.end_date }]
          });
        }
      }
    }
  }
  public validateStatus(): boolean {
    if (this.status.selectedStatus != undefined) {
      if (this.status.selectedStatus != 2) {
        this.status.enddate = new Date(0);
        this.workOrderObject = this.status;
        return true;
      } else {
        if (this.status.enddate === undefined) {
          this.$message.error("Please add Completion Date");
          return false;
        } else {
          this.workOrderObject = this.status;
          return true;
        }
      }
    } else {
      this.$message.error("Please add Status");
      return false;
    }
  }
  public validateDetails(): boolean {
    if (this.details.name != undefined) {
      if (this.details.issuedate != undefined) {
        if (this.details.aia != undefined) {
          if (this.details.type != undefined) {
            this.workOrderObject = this.details;
            return true;
          } else {
            this.$message.error("Please add Work Order Type");
            return false;
          }
        } else {
          this.$message.error("Work Order Id");
          return false;
        }
      } else {
        this.$message.error("Please add Issue Date");
        return false;
      }
    } else {
      this.$message.error("Please add Description");
      return false;
    }
  }
  public validateComponents(): boolean {
    if (this.selectedComponents.length === 0) {
      this.$message.error("No components selected");
      return false;
    } else {
      if (this.selectedComponents.length > 1) {
        this.$message.error("Select Only One Component");
        return false;
      } else {
        this.workOrderObject = this.selectedComponents;
        return true;
      }
    }
  }
  public validate() {
    if (this.validateDetails()) {
      if (this.validateComponents()) {
        if (this.validateStatus()) {
          if (this.validatePersonnel()) {
            console.log(this.workOrderObject);
            this.next();
          }
        }
      }
    }
    this.workOrder = [];
  }
  public beforeDestroy() {
    console.log("DESTROY");
    this.workOrder = [];
  }
  public validatePersonnel(): boolean {
    for (const p of this.personnel) {
      for (const d of p.dates) {
        if (d.date_started === undefined) {
          this.$message.error(
            "Please add Start Date on " + p.firstname + " " + p.lastname
          );
          return false;
        }
        if (d.date_ended === undefined) {
          this.$message.error(
            "Please add End Date on " + p.firstname + " " + p.lastname
          );
          return false;
        }
      }
    }
    this.workOrderObject = this.personnel;
    return true;
  }
}
</script>
<style scoped>
.container {
  border: 5px solid rgb(152, 226, 226);
  border-radius: 10px;
  background-color: rgb(221, 218, 218);
  padding: 5px 5px 5px 5px;
}
strong {
  color: white;
}
</style>
