export namespace users {
  export async function getAllUsers(token: string, host: any) {
    return fetch(host + "/api/users/getAll?token=" + token);
  }
  export async function changePassword(
    token: string,
    newpass: string,
    host: any
  ) {
    return await fetch(host + "/api/users/changePassword", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: "token=" + token + "&password=" + newpass
    });
  }
}
