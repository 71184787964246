import { render, staticRenderFns } from "./ShiftFilters.vue?vue&type=template&id=07cf8966&scoped=true"
import script from "./ShiftFilters.vue?vue&type=script&lang=ts"
export * from "./ShiftFilters.vue?vue&type=script&lang=ts"
import style0 from "./ShiftFilters.vue?vue&type=style&index=0&id=07cf8966&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07cf8966",
  null
  
)

export default component.exports