<template>
  <div @keydown.enter="authenticate" class="Login">
    <el-row type="flex" align="middle" justify="center">
      <el-col :span="6">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span class="title">CMMS Login</span>
            <div><i>version: {{ $store.getters.getVersion }}</i></div>
          </div>
          <div class="input-container">
            <label for="username">Username</label>
            <el-input id="username" placeholder="Enter your username" v-model="username"></el-input>
          </div>
          <div class="input-container">
            <label for="password">Password</label>
            <el-input id="password" type="password" placeholder="Enter your password" v-model="password"></el-input>
          </div>
          <div class="button-container">
            <el-button type="primary" @click.native="authenticate">Login</el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import { login } from "../api/login";
import { Component, Vue, Prop } from "vue-property-decorator";
import { postgrest } from '../api/postgrest';

@Component({})
export default class Login extends Vue {
  public userList: string[] = [];
  public username: string = "";
  public password: string = "";

  public async authenticate(): Promise<void> {
    if (!this.$store.state.loggedIn) {
      try {
        const loginchunk = await login.getUser(
          this.username,
          this.password,
          this.$store.getters.getHost,
          this.$store.getters.getConnectionDuration
        );
        let resp = await loginchunk.json();
        this.$store.dispatch("AddUserName", resp.username);
        this.$store.dispatch("AddToken", resp.token);
        this.$store.dispatch("login");
        this.$store.dispatch("updateUserLevel", resp.level);
        this.$store.dispatch("updateCurrentComponent", "Welcome");
        this.$store.dispatch("setCurrentId", -1);
        this.$store.dispatch("resetTime");
        // kanei riset sti mia wra
        this.$router.replace({ name: "load" });

        try {
          console.log('Postgrest call')
          console.log('token --->',this.$store.getters.getToken);
          let res = await postgrest.get('companies', this.$store.getters.getToken);
          console.log('Postgrest result:' + JSON.stringify(res));
        } catch (ex) {
          console.log('Postgrest call exception:' + ex);
        }

        console.log("Userlevel:" + this.$store.state.userlevel);
      } catch (ex) {
        console.log("Failed logging in");
        return Promise.resolve();
      }
    } else {
      console.log("already logged");
    }
  }

  public async mounted() {
    await this.$store.dispatch("updateConnectionSettings");
    console.log("Login mounted()");
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.title {
  line-height: 36px;
  color: #409EFF; /* Blue color */
}

.input-container {
  margin-bottom: 30px;
}

.input-container label {
  display: block;
  margin-bottom: 5px;
  color: #606266; /* Dark gray color */
}

.el-input__inner {
  border-color: #c0c4cc; /* Light gray border color */
}

.el-button--primary {
  background-color: #67C23A; /* Green color */
}

.el-button--primary:hover {
  background-color: #5CAD32; /* Darker green color on hover */
}

.button-container {
  text-align: center;
  margin-top: 20px;
}

</style>
