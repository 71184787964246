<template>
  <div>
    <el-row class="container">
      <PPMFilters v-model="filters"></PPMFilters>
      <br>
      <el-button @click="search" type="success" v-if="!loading">
        <strong class="white">Search</strong>
      </el-button>
    </el-row>
    <br>
    <el-row class="container">
      <el-table
        :data="PPMList"
        :row-class-name="PPMStatus"
        v-loading="loading"
        border
        :header-cell-style="{background:'rgb(170, 238, 238)',color:'black' ,'text-align':'center'}"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="personnel">
              <p>
                <strong>Involved Personnel:</strong>
              </p>
            </div>
            <table style="font-weight:normal;">
              <tr>
                <th class="personnelHead">
                  <strong>Last Name</strong>
                </th>
                <th class="personnelHead">
                  <strong>First Name</strong>
                </th>
                <th class="personnelHead">
                  <strong>Date Started</strong>
                </th>
                <th class="personnelHead">
                  <strong>Date Ended</strong>
                </th>
                <th class="personnelHead">
                  <strong>Hall</strong>
                </th>
              </tr>
              <tr v-for="p in props.row.personnel">
                <th class="personnel">{{personnelList.find(obj=>obj.id==p.id).lastname}}</th>
                <th class="personnel">{{personnelList.find(obj=>obj.id==p.id).firstname}}</th>
                <th class="personnel">{{filterDateTime(p.date_started)}}</th>
                <th class="personnel">{{filterDateTime(p.date_ended)}}</th>
                <th class="personnel">{{p.hall.literal}}</th>
              </tr>
            </table>
          </template>
        </el-table-column>
        <el-table-column
          prop="periodicity"
          align="center"
          label="Periodicity"
          :filters="getPeriodicityFilters()"
          :filter-method="filterHandler"
          :formatter="getPeriodicity"
        ></el-table-column>
        <el-table-column
          prop="status"
          :formatter="getStatus"
          label="Status"
          :filters="getFilters()"
          :filter-method="filterHandler"
          align="center"
        ></el-table-column>
        <el-table-column label="System" prop="system"></el-table-column>
        <el-table-column
          label="Date Performed"
          prop="date_started"
          :formatter="getDate"
          align="center"
          width="250"
        ></el-table-column>
        <el-table-column fixed="right" label="Actions" width="120">
          <template slot-scope="scope">
            <el-button
              circle
              type="primary"
              icon="el-icon-edit"
              @click="handleClick(scope.$index, scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>
<script lang="ts">
import Moment from "moment";
import { Component, Vue, Prop } from "vue-property-decorator";
import { component } from "../../api/component/component";
import { personnel } from "../../api/personnel/personnel";
import { workorder } from "../../api/workorder/workorder";
import PPMFilters from "../Filters/PPMFilters.vue";
import { ppm } from "../../api/ppm/ppm";
import { status } from "../../api/status/status";
@Component({
  name: "PPMList",
  components: {
    PPMFilters
  }
})
export default class PPMList extends Vue {
  public PPMList: any[] = [];
  public componentList: any[] = [];
  public componentLiterals: any[] = [];
  public personnelList: any[] = [];
  public workOrderList: any[] = [];
  public statusList: any[] = [];
  public periodicityList: any[] = [];
  public loading: boolean = false;
  public filters: any = {};

  public getDate(row: any, column: any) {
    let date_started = new Date(8640000000000000);
    let date_ended = new Date(0);
    for (const p of row.personnel) {
      if (new Date(p.date_started) < new Date(date_started)) {
        date_started = p.date_started;
      }
      if (new Date(p.date_ended) > new Date(date_ended)) {
        date_ended = p.date_ended;
      }
    }
    return (
      Moment(date_started).format("DD/MM/YYYY HH:mm") +
      " - " +
      Moment(date_ended).format("HH:mm")
    );
  }
  public filterHandler(value: any, row: any, column: any) {
    const property = column["property"];
    return row[property] === value;
  }
  public getFilters() {
    let a: any = [];
    {
      for (const s of this.statusList) {
        a.push({ value: s.id, text: s.literal });
      }
    }
    return a;
  }

  public getPeriodicityFilters() {
    let a: any = [];
    let commonPeriodicities: any = [];
    let commonIds = [1, 2, 4, 6, 7, 8];
    let rarePeriodicities: any = [];
    for (const s of this.periodicityList) {
      if (commonIds.indexOf(s.id) != -1) {
        commonPeriodicities.push({ value: s.id, text: s.literal });
      } else {
        rarePeriodicities.push({ value: s.id, text: s.literal });
      }
    }
    for (const s of commonPeriodicities) {
      a.push(s);
    }
    for (const s of rarePeriodicities) {
      a.push(s);
    }
    return a;
  }
  public getComponentName(row: any, column: any): String {
    let content = "";
    for (const k of row.components) {
      if (content != "") {
        content = content + ", ";
      }
      let component = this.componentList.find((obj: any) => obj.id === k.id);
      if (component != undefined) {
        content = content + component.literal;
      }
    }
    return content;
  }
  public getHall(row: any, column: any): String {
    console.log(row);
    let component = this.componentList.find(
      (obj: any) => obj.id === row.components[0]
    );
    if (component != undefined) {
      return component.group1;
    }
    return "";
  }
  public getSystem(row: any, column: any): String {
    return row.system;
  }
  public async getStatusList() {
    await status
      .getAllStatuses(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then((resp: any) => {
          this.statusList = JSON.parse(JSON.stringify(resp));
          this.getComponentList();
        });
      });
  }
  public async getPeriodicities() {
    await ppm
      .getMaintenancePeriodicities(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then((resp: any) => {
          this.periodicityList = JSON.parse(JSON.stringify(resp));
          this.loading = false;
        });
      });
  }
  public filterDateTime(date: any) {
    console.log(date);
    if (date != undefined) {
      return Moment(date).format("DD/MM/YYYY HH:mm");
    }
    return "";
  }
  public getStatus(row: any, column: any): String {
    let status = this.statusList.find((obj: any) => obj.id === row.status);
    if (status != undefined) {
      return status.literal;
    }
    return "";
  }
  public getPeriodicity(row: any, column: any): String {
    let periodicity = this.periodicityList.find(
      (obj: any) => obj.id === row.periodicity
    );
    if (periodicity != undefined) {
      return periodicity.literal;
    }
    return "";
  }
  public PPMStatus({
    row,
    rowIndex
  }: {
    row: number;
    rowIndex: number;
  }): String {
    return this.getStatus(row, 1);
  }
  public async deletePPM(rowIndex: number, row: any) {
    await ppm
      .deletePPM(
        this.$store.getters.getToken,
        row.id,
        this.$store.getters.getHost
      )
      .then(async r => this.search());
  }
  public async search() {
    this.loading = true;
    console.log(this.filters);
    if (this.filters.dateFrom === null) {
      delete this.filters.dateFrom;
    }
    if (this.filters.dateTo === null) {
      delete this.filters.dateTo;
    }
    await ppm
      .getSomeRecycledPPMs(
        this.$store.getters.getToken,
        this.filters,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then((resp: any) => {
          this.PPMList = JSON.parse(JSON.stringify(resp));
          console.log(JSON.parse(JSON.stringify(resp)));
          this.loading = false;
        });
      });
  }
  public fillTable() {
    for (const p of this.PPMList) {
      //p.personnel = JSON.parse(p.personnel);
    }
    this.loading = false;
  }
  public async created() {
    this.loading = true;
    await this.getPersonnelList();
    this.fillTable();
  }
  public filterDate(date: any) {
    if (date != undefined) {
      return Moment(date).format("DD-MM-YYYY");
    }
    return "";
  }
  public handleClick(rowIndex: number, row: any) {
    console.log("ROW");
    console.log(row);
    this.$store.dispatch("updateCurrentObject", row);
    this.$store.dispatch("updateCurrentComponent", "AddPPMnew");
    this.$router.replace({ name: "load" });
  }
  public async getPPMList() {
    await ppm
      .getAllPPMs(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then(async resp => {
          this.PPMList = JSON.parse(JSON.stringify(resp));
          console.log(resp);
          await this.getComponentList();
        });
      });
  }

  public async getComponentList() {
    await component
      .getAllComponents(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(async resp => {
          this.componentList = JSON.parse(JSON.stringify(resp));
          await this.getPeriodicities();
        });
      });
  }
  public async getPersonnelList() {
    await personnel
      .getAllPersonnel(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(async resp => {
          this.personnelList = JSON.parse(JSON.stringify(resp));
          await this.getStatusList();
        });
      });
  }
}
</script>
<style>
.el-table .Pending {
  background: rgb(236, 255, 149);
}

.el-table .Completed {
  background: #ffffff;
}

.el-table .Cancelled {
  background: rgb(255, 186, 186);
}
</style>
<style scoped>
.container {
  border: 2px solid rgb(170, 238, 238);
  border-radius: 10px;
  background-color: rgb(248, 247, 247);
  padding: 5px 10px 5px 10px;
}
.personnel {
  background-color: rgb(230, 242, 255);
  font-weight: normal !important;
  padding-left: 10px;
  padding-right: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}
.personnelHead {
  background-color: rgb(255, 242, 240);
  font-weight: normal !important;
  padding-left: 10px;
  padding-right: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}
</style>