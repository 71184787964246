
class Postgrest {
  // public postgrestAddress: string = "https://www.testbed.gr/postgrest";  
  public postgrestAddress: string = "https://postgrest.cmms-lass.com";  

  //this.$store.getters.getConnectionSettings
  //"https://www.testbed.gr/postgrest/";
  //https://server_ip/postgrest/
  //"http://localhost:3000";
  //https
  ///postgrest
  //testbed.gr
//54.171.62.112
  // TODO: Error handling
  public Postgrest() {
    console.log('Test print');
  }
  public async get(request: string, token: string): Promise<any> {
    let url: string = `${this.postgrestAddress}/${request}`;
    let res: any = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
         Authorization: "Bearer " + token,
      },
    });

    return await res.json();
  }

  public async post(request: string, token: string, body: any): Promise<any> {
    let url: string = `${this.postgrestAddress}/${request}`;
    let res: any = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: body,
    });

    return await res.json();
  }
}

export const postgrest = new Postgrest();
