export namespace activities {
  export async function getSomeActivities(
    token: string,
    filters: any,
    host: any
  ) {
    return fetch(
      host +
        "/api/activity/getSome?token=" +
        token +
        "&dateFrom=" +
        filters.dateFrom +
        "&dateTo=" +
        filters.dateTo +
        "&search=" +
        filters.search
    );
  }
  export async function cleanActivities(token: string, host: any) {
    return fetch(host + "/api/activity/clean?token=" + token);
  }
}
