<template>
  <div>
    <h2>Work Order Details</h2>
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Work Order ID:</strong>
        </div>
      </el-col>
      <el-col :span="6" align="left">
        <el-input
          placeholder="id"
          :style="{width:'200px'}"
          v-model="$props.details.aia"
          size="mini"
        ></el-input>
      </el-col>
    </el-row>
    <br>
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Work Order Description:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="Work Order Description"
          v-model="$props.details.name"
        ></el-input>
      </el-col>
    </el-row>
    <br>
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Work Order Comments:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="Work Order Comments"
          v-model="$props.details.comments"
        ></el-input>
      </el-col>
    </el-row>
    <br>
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Work Order Type:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-select
          v-model="$props.details.type"
          :style="{width:'200px'}"
          placeholder="Work Order Type"
          :items="types"
          size="mini"
        >
          <el-option
            v-for="item in types"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <br>
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Date Issued:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-date-picker
          v-model="$props.details.issuedate"
          type="date"
          :style="{width:'200px'}"
          placeholder="Issue Date"
          format="dd/MM/yyyy"
          value-format="yyyy-MM-dd"
          size="mini"
        ></el-date-picker>
      </el-col>
    </el-row>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Emit from "vue-property-decorator";
import { StringHelpers } from "../../api/helpers/StringHelpers";
import { status } from "../../api/status/status";
import { workorder } from "../../api/workorder/workorder";
@Component({
  name: "WorkOrderDetails",
  props: {
    details: Object
    // {
    //   name: "",
    //   issuedate: "",
    //   type: "",
    //   id: "",
    //   aia: "",
    //   comments: ""
    // }
  },
  model: {
    prop: "details",
    event: "change"
  }
})
export default class WorkOrderDetails extends Vue {
  public types: any[] = [];
  public row: any;
  public async fillboxes() {
    await this.getWOTypeList();
  }
  public mounted() {
    this.row = this.$store.getters.getCurrentId;
    this.fillboxes();
    this.$emit("on-loaded");
  }
  public async getWOTypeList() {
    await workorder
      .getAllTypes(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then((resp: any) => {
          let i = 0;
          while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
            this.types.push({
              value: JSON.parse(JSON.stringify(resp))[i].id,
              label: JSON.parse(JSON.stringify(resp))[i].literal
            });
            i++;
          }
        });
      });
  }
}
</script>
<style scoped>
strong {
  color: black;
}
.tag {
  background-color: rgb(201, 228, 238);
  color: black;
  width: 140px;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  padding: 2px 10px 2px 10px;
  font-size: 9pt;
  line-height: 20px;
}
</style>