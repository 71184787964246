<template>
  <div>Downloading Reports</div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { vacation } from "../../api/vacation";
import moment from "moment";

@Component({ name: "DownloadVacation" })
export default class DownloadVacation extends Vue {
  public test1: any = {};
  public object: any = {};
  public id: any = "";

  public async Vacation1() {
    if (this.object.id != undefined) {
      let response = await vacation.Vacation1(
        this.$store.getters.getToken,
        this.object.id,
        this.$store.getters.getHost
      );
      let jsonObj = await response.json();
      this.test1 = jsonObj;
    } else {
      this.id = -1;
      let response = await vacation.Vacation1(this.$store.getters.getToken, this.id, this.$store.getters.getHost);
      let jsonObj = await response.json();
      this.test1 = jsonObj;
    }
  }

  public async mounted() {
    this.id = this.$store.getters.getCurrentId;
    let token = this.$store.getters.getToken;
    this.object = this.$store.getters.getCurrentObject;
    await this.Vacation1();
    //setCurrentId -1 gia epistrofi
    this.$store.dispatch("setCurrentId", -1);
    //AddNewVacation arxiki othoni ekei pou thelw na giriso

    // this.$store.dispatch("updateCurrentComponent","ViewVacationSubmitted");
    this.$router.replace({
      name: "load",
    });

    if (this.object.id == undefined) {
      this.$store.dispatch("updateCurrentComponent", "AddNewVacation");
      let response = await vacation.downloadVacation(token, this.id, this.$store.getters.getHost);
      response.blob().then((blob: any) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;

        a.download =
          this.test1.lastnamegr +
          "_" +
          this.test1.total_days_req +
          "_" +
          moment(this.test1.leave_on).format("DD/MM/YYYY") +
          ".xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
    } else {
      if (this.object.decisionofvacation == 2) {
        this.$store.dispatch("updateCurrentComponent", "ViewVacationSubmitted");
        let response = await vacation.downloadVacation(token, this.object.id, this.$store.getters.getHost);
        response.blob().then((blob: any) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;

          a.download =
            this.test1.lastnamegr +
            "_" +
            this.test1.total_days_req +
            "_" +
            moment(this.test1.leave_on).format("DD/MM/YYYY") +
            ".xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
      } else {
        this.$store.dispatch("updateCurrentComponent", "Decisionofvacation");
        let response = await vacation.downloadVacation(token, this.object.id, this.$store.getters.getHost);
        response.blob().then((blob: any) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;

          a.download =
            this.test1.lastnamegr +
            "_" +
            this.test1.total_days_req +
            "_" +
            moment(this.test1.leave_on).format("DD/MM/YYYY") +
            ".xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
      }
    }
  }
}
</script>
<style>
</style>
