<template>
  <div>Downloading Reports</div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { workorder } from "../../api/workorder/workorder";
@Component({ name: "DownloadWorkOrderDetails" })
export default class DownloadWorkOrderDetails extends Vue {
  public async mounted() {
    let id = this.$store.getters.getCurrentId;
    let token = this.$store.getters.getToken;
    this.$store.dispatch("updateCurrentObject", {});
    this.$store.dispatch("updateCurrentComponent", "WorkOrderList");
    this.$router.replace({
      name: "load"
    });
    let response = await workorder.downloadDetails(
      token,
      id,
      this.$store.getters.getHost
    );
    response.blob().then((blob: any) => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = id + ".xlsx";
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    });
  }
}
</script>
<style>
</style>