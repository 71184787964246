import { StringHelpers } from "../helpers/StringHelpers";

export namespace ppm {
  export async function recyclePPM(
    token: string,
    id: number,
    host: any
  ): Promise<any> {
    return await fetch(host + "/api/ppm/recycle?token=" + token + "&id=" + id);
  }
  export async function deletePPM2(
    token: string,
    id: number,
    host: any
  ): Promise<any> {
    return await fetch(host + "/api/ppm/delete2?token=" + token + "&id=" + id);
  }
  export async function deletePPM(
    token: string,
    id: number,
    host: any
  ): Promise<any> {
    return await fetch(host + "/api/ppm/delete?token=" + token + "&id=" + id);
  }
  export async function addPPM(token: string, ppmItem: any, host: any) {
    console.log(ppmItem);
    let result: Response;
    ppmItem.token = token;
    result = (await fetch(host + "/api/ppm/Add", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body:
        "token=" +
        token +
        "&periodicity=" +
        ppmItem.periodicity +
        "&personnelList=" +
        JSON.stringify(ppmItem.personnel) +
        "&recycled=false" +
        "&status=" +
        ppmItem.status.selectedStatus +
        "&date_completed=" +
        ppmItem.status.enddate +
        "&system=" +
        ppmItem.system +
        "&completion=" +
        ppmItem.completion +
        "&senTech=" +
        ppmItem.senTech +
        "&workOrders=" +
        JSON.stringify(ppmItem.workOrders) +
        ((ppmItem.periodicity == 4 && ppmItem.system == "Conveyors") ||
          (ppmItem.periodicity == 8 && ppmItem.system == "Electrical Panels") ||
          (ppmItem.periodicity != 8 && ppmItem.system == "IT and Automation") ||
          (ppmItem.periodicity == 8 &&
            (ppmItem.system == "Sorter" ||
              ppmItem.system == "UPS" ||
              ppmItem.system == "Inductions"))
          ? "&belt=" + ppmItem.belt
          : "") +
        "&foreMan=" +
        ppmItem.foreman +
        ((ppmItem.periodicity == 8 ||
          ppmItem.periodicity == 6 ||
          (ppmItem.system == "Conveyors" && ppmItem.periodicity == 4) ||
          (ppmItem.system == "Electrical Panels" &&
            ppmItem.periodicity == 7)) &&
          (ppmItem.system != "Scales" &&
            (ppmItem.system != "IT and Automation" && ppmItem.periodicity != 7))
          ? (ppmItem.system != "UPS" &&
            ppmItem.system != "Sorter" &&
            ppmItem.system != "Electrical Panels" &&
            ppmItem.system != "Sorter" &&
            ppmItem.system != "Inductions"
            ? "&component=" + ppmItem.component
            : "") +
          "&remarks=" +
          (ppmItem.remarks != undefined ? ppmItem.remarks : "") +
          "&actions=" +
          (ppmItem.activity != undefined ? ppmItem.activity : 4)
          : "") +
        "&hall=" +
        (((ppmItem.system == "Electrical Panels" ||
          ppmItem.system == "IT and Automation") &&
          ppmItem.periodicity == 7) ||
          ppmItem.system == "Detectors" ||
          (ppmItem.system == "Electrical Panels" &&
            (ppmItem.periodicity == 4 || ppmItem.periodicity == 8)) ||
          (ppmItem.periodicity == 8 &&
            (ppmItem.system == "Sorter" ||
              ppmItem.system == "Inductions" ||
              ppmItem.system == "UPS")) ||
          (ppmItem.periodicity == 4 &&
            (ppmItem.system == "Fire Shutter and Ret" ||
              ppmItem.system == "Reclaims"))
          ? ppmItem.hall
          : "") +
        (ppmItem.multiremarks.length != 0
          ? "&multiremarks=" + JSON.stringify(ppmItem.multiremarks)
          : "")
    })) as any;

    return await result.json();
  }
  export async function getoneWO(token: string, woID: any, host: any){
  console.log(token)
  console.log(woID)
  console.log(host)
  console.log("example for one WO frontend1"+ woID)
  var url = host + "/api/ppm/getoneWO?token=" + token
  url += '&woID=' + woID
  console.log('url1')
  console.log(url)
  console.log('url2')
  return fetch(url);



  // console.log(woID)
  // console.log("example for one WO frontend1"+ woID)
  // let result: Response;

  // let body=
  // "token=" +
  // token +
  // "&woID="+
  //  woID;
  //  console.log(body)
  //  console.log("example for oneWO body frontend2")
  
  //  result = (await fetch(host + "/api/ppm/getoneWO/", {
  //   method: "POST",
  //   headers: {
  //     "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
  //   },
  //   body: body

  // })) as any;
  
  // let response = await result.json();
  
  // console.log("result of api ppm1")
  // console.log(response)
  // console.log("result of api ppm2 addPPM2")
  // return response;


  }

 
    //,
    // wocomments1: string,    
    //+ "&wocomments1=" + wocomments1  
  // ): Promise<any> {
  //   return await fetch(
  //     host + "/api/ppm/getoneWO?token=" + token + "&woID=" + woID 
  //   );

  // }
  export async function addPPM2(token: string, ppmItem: any, host: any) {
    console.log(JSON.stringify(ppmItem));
    console.log("example for ppm api frontend")
    let result: Response;

    let body =
      "token=" +
      token +
      "&id=" +
      ppmItem[0].id +
      "&type=" +
      ppmItem[0].type +
      "&system_groups_id=" +
      ppmItem[0].system_groups_id +
      "&sentech_aia=" +
      ppmItem[0].sentech_aia +
      "&foreman=" +
      ppmItem[0].foreman +
      "&maintenance_period=" +
      ppmItem[0].maintenance_period +
      "&status=" +
      ppmItem[0].status +
      "&date_completed=" +
      ppmItem[0].date_completed;

    console.log("body")
    console.log(body)

    ppmItem.token = token;
    result = (await fetch(host + "/api/ppm/Add2", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: body

    })) as any;

    let response = await result.json();
    console.log("result of api ppm1")
    console.log(response)
    console.log("result of api ppm2 addPPM2")
    return response;
  }


  //new for update

  export async function addPPM2update( token: string, ppmItem: any, host: any){

    console.log(ppmItem)
    let result: Response;
    let body =
    "token=" +
    token +
    "id="+
    ppmItem[0].id+
    "&type=" +
    ppmItem[0].type +
    "&system_groups_id=" +
    ppmItem[0].system_groups_id +
    "&sentech_aia=" +
    ppmItem[0].sentech_aia +
    "&foreman=" +
    ppmItem[0].foreman +
    "&maintenance_period=" +
    ppmItem[0].maintenance_period +
    "&status=" +
    ppmItem[0].status +
    "&date_completed=" +
    ppmItem[0].date_completed;


    ppmItem.token = token;
    result = (await fetch(host + "/api/ppm/Add2", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: body

    })) as any;

    let response = await result.json();
    console.log("result of api ppm2 update")
    console.log(response)
    console.log("result of api ppm2 update")
    return response;



  }

  export async function addPPMPersonnel2(token: string, ppmPersonnelItem: any, host: any) {
    console.log(JSON.stringify(ppmPersonnelItem));
    console.log("example for ppmpersonnel api frontend")
    let result: Response;

    let body =
      "token=" +
      token +
      "&id=" +
      ppmPersonnelItem[0].id +
      "&personnel_id=" +
      ppmPersonnelItem[0].personnel_id +
      "&ppm_id=" +
      ppmPersonnelItem[0].ppm_id +
      "&date_started=" +
      ppmPersonnelItem[0].date_started +
      "&date_ended=" +
      ppmPersonnelItem[0].date_ended +
      "&time_start=" +
      ppmPersonnelItem[0].time_start +
      "&time_end=" +
      ppmPersonnelItem[0].time_end;


    console.log("body")
    console.log(body)

    ppmPersonnelItem.token = token;
    result = (await fetch(host + "/api/ppm/Add3", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: body

    })) as any;

    let response = await result.json();
    console.log("result of api ppm1")
    console.log(response)
    console.log("result of api ppm2 addPPMPersonnel2")
    return response;
  }

  export async function addPPMWO2(token: string, ppmWOlItem: any, host: any) {
    console.log(JSON.stringify(ppmWOlItem));
    console.log("example for ppmpersonnel api frontend")
    let result: Response;

    let body =
      "token=" +
      token +
      "&work_order_id=" +
      ppmWOlItem[0].work_order_id +
      "&ppm_id=" +
      ppmWOlItem[0].ppm_id;


    console.log("body")
    console.log(body)

    ppmWOlItem.token = token;
    result = (await fetch(host + "/api/ppm/Add4", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: body

    })) as any;

    let response = await result.json();
    console.log("result of api ppm1")
    console.log(response)
    console.log("result of api ppm2 addPPMWO2")
    return response;
  }

  export async function addPPMRem2(token: string, ppmWOlItem: any, host: any) {
    console.log(JSON.stringify(ppmWOlItem));
    console.log("example for ppmpersonnel api frontend")
    let result: Response;

    let body =
      "token=" +
      token +
      "&remark=" +
      ppmWOlItem[0].remark +
      "&action=" +
      ppmWOlItem[0].action+
      "&ppm_id=" +
      ppmWOlItem[0].ppm_id;


    console.log("body")
    console.log(body)

    ppmWOlItem.token = token;
    result = (await fetch(host + "/api/ppm/Add5", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: body

    })) as any;

    let response = await result.json();
    console.log("result of api ppm1")
    console.log(response)
    console.log("result of api ppm2 addPPMRem2")
    return response;
  }


  export async function addPPMComp2(token: string, ppmCompItem: any, host: any) {
    console.log(JSON.stringify(ppmCompItem));
    console.log("example for ppmpersonnel api frontend")
    let result: Response;

    let body =
      "token=" +
      token +
      "&ppm_id=" +
      ppmCompItem[0].ppm_id +
      "&component_id=" +
      ppmCompItem[0].component_id;
      

    console.log("body")
    console.log(body)

    ppmCompItem.token = token;
    result = (await fetch(host + "/api/ppm/Add6", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: body

    })) as any;

    let response = await result.json();
    console.log("result of api ppm1")
    console.log(response)
    console.log("result of api ppm2 addPPMComp2")
    return response;
  }

  export async function editPPM(token: string, ppmItem: any, host: any) {
    console.log(ppmItem);
    let result: Response;
    result = (await fetch(host + "/api/ppm/Add", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body:
        "id=" +
        ppmItem.id +
        "&token=" +
        token +
        "&periodicity=" +
        ppmItem.periodicity +
        "&personnelList=" +
        JSON.stringify(ppmItem.personnel) +
        "&recycled=false" +
        "&status=" +
        ppmItem.status.selectedStatus +
        "&date_completed=" +
        ppmItem.status.enddate +
        "&system=" +
        ppmItem.system +
        "&completion=" +
        ppmItem.completion +
        "&senTech=" +
        ppmItem.senTech +
        "&workOrders=" +
        JSON.stringify(ppmItem.workOrders) +
        ((ppmItem.periodicity == 4 && ppmItem.system == "Conveyors") ||
          (ppmItem.periodicity == 8 &&
            ppmItem.system == "Electrical Panels") ||
          (ppmItem.periodicity != 8 &&
            ppmItem.system == "IT and Automation") ||
          (ppmItem.periodicity == 8 &&
            (ppmItem.system == "Sorter" ||
              ppmItem.system == "UPS" ||
              ppmItem.system == "Inductions"))
          ? "&belt=" + ppmItem.belt
          : "") +
        "&foreMan=" +
        ppmItem.foreman +
        ((ppmItem.periodicity == 8 ||
          ppmItem.periodicity == 6 ||
          (ppmItem.system == "Conveyors" && ppmItem.periodicity == 4) ||
          (ppmItem.system == "Electrical Panels" &&
            ppmItem.periodicity == 7)) &&
          (ppmItem.system != "Scales" &&
            (ppmItem.system != "IT and Automation" &&
              ppmItem.periodicity != 7))
          ? (ppmItem.system != "UPS" &&
            ppmItem.system != "Sorter" &&
            ppmItem.system != "Electrical Panels" &&
            ppmItem.system != "Sorter" &&
            ppmItem.system != "Inductions"
            ? "&component=" + ppmItem.component
            : "") +
          "&remarks=" +
          (ppmItem.remarks != undefined ? ppmItem.remarks : "") +
          "&actions=" +
          (ppmItem.activity != undefined ? ppmItem.activity : 4)
          : "") +
        "&hall=" +
        ((((ppmItem.system == "Electrical Panels" ||
          ppmItem.system == "IT and Automation") &&
          ppmItem.periodicity == 7) ||
          ppmItem.system == "Detectors" ||
          (ppmItem.system == "Electrical Panels" &&
            (ppmItem.periodicity == 4 || ppmItem.periodicity == 8)) ||
          (ppmItem.periodicity == 8 &&
            (ppmItem.system == "Sorter" ||
              ppmItem.system == "Inductions" ||
              ppmItem.system == "UPS")) ||
          (ppmItem.periodicity == 4 &&
            (ppmItem.system == "Fire Shutter and Ret" ||
              ppmItem.system == "Reclaims"))
          ? ppmItem.hall
          : "") +
          (ppmItem.multiremarks.length != 0
            ? "&multiremarks=" + JSON.stringify(ppmItem.multiremarks)
            : ""))
    })) as any;

    return await result.json();
  }
  export async function postRemarks(
    token: string,
    remarks: any,
    host: any,
    periodicity: number,
    system: number
  ) {
    let result: Response;
    result = (await fetch(host + "/api/ppm/updateRemarks", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body:
        "&token=" +
        token +
        "&periodicity=" +
        periodicity +
        "&system=" +
        system +
        "&recycled=false" +
        "&remarks=" +
        JSON.stringify(remarks)
    })) as any;

    return await result.json();
  }
 
  export async function getPpmsList2subpanel(token: string, host: any) {
    return fetch(host + "/api/ppm/getPpmsList2subpanel?token=" + token);
  }

  export async function getALLPpmsList2(token: string, host: any) {
    return fetch(host + "/api/project/getallPPMlList2?token=" + token);
  }

  export async function getSomePpmsList2(token: string, filters: any, host: any) {
    var url = host + "/api/project/getallPPMlList2?token=" + token

    if(filters.fromDate){
      url += '&fromDate=' + filters.fromDate;
    }
    if(filters.toDate){
      url += '&toDate=' + filters.toDate
    }
    if(filters.status){
      url += '&status=' + filters.status
    }
    if(filters.system){
      url += '&system=' + filters.system
    }
    if(filters.maintenance_period){
      url += '&maintenance_period='+ filters.maintenance_period
    }
    if(filters.component){
      url += '&component=' + filters.component
    }

    return fetch(url);
  }
  
  export async function getSomePpmsListExport(token: string, filters: any, host: any) {
    var url = host + "/api/project/getALLPpmexport?token=" + token

    if(filters.fromDate){
      url += '&fromDate=' + filters.fromDate;
    }
    if(filters.toDate){
      url += '&toDate=' + filters.toDate
    }    
    if(filters.system){
      url += '&system=' + filters.system
    }
    if(filters.periodicity){
      url += '&periodicity='+ filters.periodicity
    }
    if(filters.name){
      url += '&name=' + filters.name
    }

    return fetch(url);
  }

  export async function getAllPPMs(token: string, host: any) {
    return fetch(host + "/api/ppm/getAll?token=" + token);
  }

  export async function getAllRemarks(token: string, host: any) {
    return fetch(host + "/api/ppm/getAllRemarks?token=" + token);
  }

  export async function getMaintenancePeriodicities(token: string, host: any) {
    return fetch(host + "/api/ppm/getMaintenancePeriodicities?token=" + token);
  }  
  export async function getPPMReports(token: string, host: any) {
    return fetch(host + "/api/ppm/getPPMReports?token=" + token);
  }
  export async function getConveyorLines(token: string, host: any) {
    return fetch(host + "/api/components/getConveyorLines?token=" + token);
  }
  export async function getSystemPeriodicities(token: string, host: any) {
    return fetch(host + "/api/ppm/getSystemPeriodicities?token=" + token);
  }
  export async function getMaintenanceActivities(token: string, host: any) {
    return fetch(host + "/api/ppm/getMaintenanceActivities?token=" + token);
  }

  export async function downloadPPMReports(
    token: string,
    fromDate1: any,
    host: any,
    toDate1: any,
    periodicity1: number,
    reports1: number,  
  ): Promise<any> {
    return await fetch(
      host + "/api/ppm/downloadPPMReports?token=" + token + "&fromDate1=" + fromDate1 + "&toDate1=" + toDate1 + "&periodicity1=" + periodicity1 + "&reports1=" + reports1
    );

  }
 
  export async function DownloadPPMExport(
    token: string,
    fromDate1: any,
    host: any,
    toDate1: any,
    periodicity1: number,
    system1: number,  
    name1: number,  
  ): Promise<any> {
    return await fetch(
      host + "/api/ppm/DownloadPPMExport?token=" + token + "&fromDate1=" + fromDate1 + "&toDate1=" + toDate1 + "&periodicity1=" + periodicity1 + "&system1=" + system1 + "&name1=" + name1
    );

  }

  export async function getSomePPMs(
    token: string,
    filters: any,
    host: any
  ): Promise<any> {
    return await fetch(
      host +
      "/api/ppm/getSome?token=" +
      token +
      "&dateFrom=" +
      filters.dateFrom +
      "&dateTo=" +
      filters.dateTo +
      "&keyword=" +
      filters.keyword +
      "&system=" +
      filters.system +
      "&periodicity=" +
      filters.periodicity
    );
  }
  // TEST1
  // export async function getoneWO(token: string, ppmWOlItem: any, host: any) {
  //   console.log(JSON.stringify(ppmWOlItem));
  //   console.log("example for ppmpersonnel api frontend")
  //   let result: Response;

  //   let body =
  //     "token=" +
  //     token +
  //     "&work_order_id=" +
  //     ppmWOlItem[0].work_order_id +
  //     "&ppm_id=" +
  //     ppmWOlItem[0].ppm_id;


  //   console.log("body")
  //   console.log(body)

  //   ppmWOlItem.token = token;
  //   result = (await fetch(host + "/api/ppm/Add4", {
  //     method: "POST",
  //     headers: {
  //       "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
  //     },
  //     body: body

  //   })) as any;

  //   let response = await result.json();
  //   console.log("result of api ppm1")
  //   console.log(response)
  //   console.log("result of api ppm2 addPPMWO2")
  //   return response;
  // }
  // test2
  export async function getSomeRecycledPPMs(
    token: string,
    filters: any,
    host: any
  ): Promise<any> {
    return await fetch(
      host +
      "/api/ppm/getSomeRecycled?token=" +
      token +
      "&dateFrom=" +
      filters.dateFrom +
      "&dateTo=" +
      filters.dateTo +
      "&keyword=" +
      filters.keyword +
      "&system=" +
      filters.system +
      "&periodicity=" +
      filters.periodicity
    );
  }
  export async function getSomeRemarks(
    token: string,
    filters: any,
    host: any
  ): Promise<any> {
    console.log(filters);
    return await fetch(
      host +
      "/api/ppm/getRemarks?token=" +
      token +
      "&system=" +
      filters.system +
      "&periodicity=" +
      filters.periodicity
    );
  }
  export async function getZones(host: any, token: string) {
    return await fetch(host + "/api/components/getZones?token=" + token);
  }
  export async function getConveyors(host: any, token: string) {
    return await fetch(
      host + "/api/components/getConveyorLines?token=" + token
    );
  }
}
