import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

class VueState {
  public version: string = "1.0.3a";
  public loggedIn: boolean = false;
  public token: string = "";
  public AlarmList: string[] = [];
  public PersonnelList: string[] = [];
  public CompanyList: string[] = [];
  public SpecialtyList: string[] = [];
  public StatusList: string[] = [];
  public username: string = "";
  public WorkOrderList: string[] = [];
  public WorkOrderTypeList: string[] = [];
  public currentId: number = -1;
  public currentComponent: string = "";
  public ComponentList: string[] = [];
  public GroupList: string[] = [];
  public ComponentLiterals: string[] = [];
  public GroupLiterals: string[] = [];
  public TempAttributes: string[] = [];
  public WOSpecialtyList: string[] = [];
  public MaintenancePeriodicities: string[] = [];
  public currentObject: any = {};
  public PPMList: string[] = [];
  public connectionSettings: any = {};
  public userlevel: number = -1;
  public remainingTime: number = 1000 * 60 * 60;
  public dialogShowed: boolean = false;
}
export default new Vuex.Store({
  state: new VueState(),
  
  mutations: {
    setVersion(state, version) {
      state.version = version;
    },
    dialogSeen(state) {
      state.dialogShowed = true;
    },
    timerTick(state) {
      state.remainingTime = state.remainingTime - 1000;
    },
    resetTime(state) {
      state.remainingTime = state.connectionSettings.duration;
    },
    updateUserLevel(state, newLevel) {
      state.userlevel = newLevel;
    },
    updateMaintenancePeriodicities(state, newList) {
      state.MaintenancePeriodicities = newList;
    },
    updateCurrentObject(state, newObj) {
      state.currentObject = newObj;
    },
    updateTempAttributes(state, newList) {
      state.TempAttributes = newList;
    },
    updateComponentList(state, newList) {
      state.ComponentList = newList;
    },
    updateComponentLiterals(state, newList) {
      state.ComponentLiterals = newList;
    },
    updateGroupList(state, newList) {
      // newList.sort((o1: any, o2: any) => {
      //   return o1.id - o2.id;
      // });
      state.GroupList = newList;
    },
    updateGroupLiterals(state, newList) {
      state.GroupLiterals = newList;
    },
    changeLoginState(state) {
      state.loggedIn = !state.loggedIn;
    },
    setToken(state, NewToken) {
      state.token = NewToken;
    },
    setUserName(state, NewName) {
      state.username = NewName;
    },
    setCurrentId(state, NewId) {
      state.currentId = NewId;
      console.log(state.currentId);
    },
    updateAlarmList(state, newList) {
      state.AlarmList = newList;
    },
    updatePPMList(state, newList) {
      state.PPMList = newList;
    },
    updateWorkOrderList(state, newList) {
      state.WorkOrderList = newList;
    },
    updatePersonnelList(state, newList) {
      state.PersonnelList = newList;
    },
    updateCompanyList(state, newList) {
      state.CompanyList = newList;
    },
    updateSpecialtyList(state, newList) {
      state.SpecialtyList = newList;
    },
    updateWOSpecialtyList(state, newList) {
      state.WOSpecialtyList = newList;
    },
    updateStatusList(state, newList) {
      state.StatusList = newList;
    },
    updateWorkOrderTypeList(state, newList) {
      state.WorkOrderTypeList = newList;
    },
    reset(state) {
      state.token = "";
      state.loggedIn = !state.loggedIn;
      state.username = "";
      state.dialogShowed = false;
    },
    updateCurrentComponent(state, newComponent) {
      state.currentComponent = newComponent;
    },
    updateConnectionSettings(state, newSettings) {
      state.connectionSettings = newSettings;
    }
  },
  actions: {
    setVersion(context, version) {
      context.commit("setVersion", version);
    },
    dialogSeen(context) {
      context.commit("dialogSeen");
    },
    startTime(context) {
      context.commit("startTime");
    },
    resetTime(context) {
      context.commit("resetTime");
    },
    timerTick(context) {
      context.commit("timerTick");
    },
    updateUserLevel(context, newLevel) {
      context.commit("updateUserLevel", newLevel);
    },
    updateMaintenancePeriodicities(context, newList) {
      context.commit("updateMaintenancePeriodicities", newList);
    },
    updateCurrentObject(context, newObj) {
      context.commit("updateCurrentObject", newObj);
    },
    updateTempAttributes(context, newList) {
      context.commit("updateTempAttributes", newList);
    },
    login(context) {
      context.commit("changeLoginState");
    },
    AddToken(context, token) {
      context.commit("setToken", token);
    },
    setCurrentId(context, id) {
      context.commit("setCurrentId", id);
    },
    AddUserName(context, name) {
      context.commit("setUserName", name);
    },
    updateAlarmList(context, newList) {
      context.commit("updateAlarmList", newList);
    },
    updatePPMList(context, newList) {
      context.commit("updatePPMList", newList);
    },
    updateWorkOrderList(context, newList) {
      context.commit("updateWorkOrderList", newList);
    },
    updateStatusList(context, newList) {
      context.commit("updateStatusList", newList);
    },
    updateComponentList(context, newList) {
      context.commit("updateComponentList", newList);
    },
    updateComponentLiterals(context, newList) {
      context.commit("updateComponentLiterals", newList);
    },
    updateGroupList(context, newList) {
      context.commit("updateGroupList", newList);
    },
    updateGroupLiterals(context, newList) {
      context.commit("updateGroupLiterals", newList);
    },
    updateWorkOrderTypeList(context, newList) {
      context.commit("updateWorkOrderTypeList", newList);
    },
    updatePersonnelList(context, newList) {
      context.commit("updatePersonnelList", newList);
    },
    updateCompanyList(context, newList) {
      context.commit("updateCompanyList", newList);
    },
    updateSpecialtyList(context, newList) {
      context.commit("updateSpecialtyList", newList);
    },
    updateWOSpecialtyList(context, newList) {
      context.commit("updateWOSpecialtyList", newList);
    },
    reset(context) {
      context.commit("reset");
    },
    updateCurrentComponent(context, newComponent) {
      context.commit("updateCurrentComponent", newComponent);
    },
    async updateConnectionSettings(context) {
      var xmlhttp = new XMLHttpRequest();
      xmlhttp.onreadystatechange = await function() {
        if (xmlhttp.status === 200 && xmlhttp.readyState === 4) {
          let txt = JSON.parse(xmlhttp.responseText);
          context.commit("updateConnectionSettings", {
            host: txt.host,
            port: txt.port,
            duration: txt.duration
          });
        }
      };
      try {
        xmlhttp.open("GET", "@/../ConnectionSettings.json", true);
      } catch (ex) {
        console.log("what the:" + ex);
      }

      await xmlhttp.send();
    }
  },
  getters: {
    getVersion(state) {
      return state.version;
    },
    getDialogSeen(state) {
      return state.dialogShowed;
    },
    getRemainingTime(state) {
      return state.remainingTime;
    },
    getUserLevel(state) {
      return state.userlevel;
    },
    getConnectionSettings(state: VueState) {
      return state.connectionSettings;
    },
    getHost(state: VueState) {
      let c = state.connectionSettings;
      if (c.port == undefined) {
        return c.host;
      }
      return c.host + ":" + c.port;
    },
    getConnectionDuration(state) {
      return state.connectionSettings.duration;
    },
    getMaintenancePeriodicities(state) {
      return state.MaintenancePeriodicities;
    },
    getCurrentObject(state) {
      return state.currentObject;
    },
    getTempAttributes(state) {
      return state.TempAttributes;
    },
    getLoginState(state) {
      return state.loggedIn;
    },
    getToken(state) {
      return state.token;
    },
    getAlarmList(state) {
      return state.AlarmList;
    },
    getPPMList(state) {
      console.log(state.PPMList);
      return state.PPMList;
    },
    getWorkOrderList(state) {
      return state.WorkOrderList;
    },
    getStatusList(state) {
      return state.StatusList;
    },
    getCurrentId(state) {
      return state.currentId;
    },
    getWorkOrderTypeList(state) {
      return state.WorkOrderTypeList;
    },
    getPersonnelList(state) {
      return state.PersonnelList;
    },
    getComponentList(state) {
      return state.ComponentList;
    },
    getComponentLiterals(state) {
      return state.ComponentLiterals;
    },
    getSpecialtyList(state) {
      return state.SpecialtyList;
    },
    getWOSpecialtyList(state) {
      return state.WOSpecialtyList;
    },
    getCompanyList(state) {
      return state.CompanyList;
    },
    getUserName(state) {
      return state.username;
    },
    getCurrentComponent(state) {
      return state.currentComponent;
    },
    getGroupList(state) {
      return state.GroupList;
    },
    getGroupLiterals(state) {
      return state.GroupLiterals;
    }
  }
});
