<template>
<div>
    <el-row class="container">
        <el-row>
            <h2>Μenu for project reports export</h2>
        </el-row>
        <el-row>
            <el-col :span="17" align="left">
                <el-row>
                    <el-col :span="4" align="left">
                        <div class="tag">
                            <strong>Sector:</strong>
                        </div>
                    </el-col>
                    <!-- {{sectorselectrd}} -->
                    <el-col :span="4" align="left">
                        <el-select v-model="sectorselectrd" placeholder="Activity" size="mini" :style="{width:'250px'}">
                            <el-option v-for="item in sectorsList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-col>
                </el-row>
                <br>
                <br>
                <el-row>
                    <el-col :span="4" align="left">
                        <div class="tag">
                            <strong>Project Title:</strong>
                        </div>
                    </el-col>
                    <!-- {{titleselectrd}} -->
                    <el-col :span="4" align="left">
                        <el-select v-model="titleselectrd" placeholder="Project Titles" size="mini" :style="{width:'250px'}">
                            <el-option v-for="item in titlesList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-col>
                </el-row>
                <br>
                <br>
                <el-row>
                    <div>
                        <el-col :span="4" align="left">
                            <div class="tag">
                                <strong>Employee's Name:</strong>
                            </div>
                        </el-col>
                        <!-- {{selectedTech}} -->
                        <el-col :span="4" align="left">
                            <el-select v-model="selectedTech" filterable :style="{width:'250px'}" remote size="mini" clearable placeholder="Add Name" :remote-method="foremanMethod" :loading="loading">
                                <el-option v-for="item in availableForemen" :key="item.value" :label="item.firstname + ' ' + item.lastname" :value="item.id"></el-option>
                            </el-select>
                        </el-col>
                    </div>

                </el-row>
                <br>

            </el-col>
            <el-col :span="6" align="left">
                <table width="100%" class="tableTag2" border="1">
                    <col style="width:16%">
                    <col style="width:16%">
                    <col style="width:16%">
                    <col style="width:16%">
                    <col style="width:16%">
                    <col style="width:16%">
                    <tr align="center" color='red'>
                        <td rowspan="2"></td>
                        <td width='40px'>Report 1</td>
                        <td>Report 2</td>
                        <td>Report 3</td>
                        <td>Report 4</td>
                        <td>Report 5</td>
                    </tr>
                    <tr align="center">
                        <td width='40px'>All projects</td>
                        <td>Specific Project</td>
                        <td>Employees / project</td>
                        <td>Employee / project</td>
                        <td>All Projects / Employee</td>
                    </tr>
                    <tr align="center">
                        <td align="right"> <strong> Sector: </strong></td>
                        <td width='40px'>Chose</td>
                        <td>Chose</td>
                        <td>Chose</td>
                        <td>Chose</td>
                        <td>Chose</td>
                    </tr>
                    <tr align="center">
                        <td align="right"><strong>Project Title:</strong> </td>
                        <td width='40px'>All</td>
                        <td>Chose</td>
                        <td>Chose</td>
                        <td>Chose</td>
                        <td>All</td>
                    </tr>
                    <tr align="center">
                        <td align="right"><strong>Employees Name:</strong></td>
                        <td width='40px'>None</td>
                        <td>None</td>
                        <td>All</td>
                        <td>Chose</td>
                        <td>Chose</td>
                    </tr>
                </table>
                <br>
                <br>
            </el-col>
        </el-row>
    </el-row>
    <br>
    <el-row>
        <!-- <el-button style="margin-top: 12px;" @click="addToDBPNewProject" type="success"> -->
        <el-button @click="exportReports()" type="success" v-if="!loading">
            <strong class="white">Export</strong>
        </el-button>
    </el-row>
</div>
</template>

<script lang="ts">
import {
    Component,
    Vue,
    Prop
} from "vue-property-decorator";
import {
    new_project
} from "../../api/new_project/new_project"
import {
    ppm
} from "../../api/ppm/ppm";
import {
    personnel
} from "../../api/personnel/personnel";
import {
    aiaPersonnel
} from "../../api/personnel/aiaPersonnel";
import {
    component
} from "../../api/component/component";
import {
    workorder
} from "../../api/workorder/workorder";
import StatusSelector from "../Selectors/StatusSelector.vue";
import {
    postgrest
} from "../../api/postgrest";
import Moment from "moment";
import {
    status
} from "../../api/status/status";

@Component({
    name: "newProjectReports",
    components: {
        StatusSelector
    },
    props: {
        obj: {}
    }
})
export default class newProjectReports extends Vue {
    public sectorsList: any[] = [];
    public sectorselectrd: any = "";
    public placesList: any[] = [];
    public placeselectrd: any = "";
    public titlesList: any[] = [];
    public titleselectrd: any = "";
    public jsonPersonSpecialties: any[] = [];
    public SpecialtieSelected: any[] = [];
    public yearlyActions2: any = "";
    public selectedTech: any = "";
    public techs: any[] = [];
    public availableForemen: any[] = [];
    public statuses: any[] = [];
    public StartDate: any = "";
    public EndDate: any = "";
    public selectedStatus: any = "";
    public SpecialtiesList: any[] = [];
    public newprojecttitleadded: any = "";
    public selectedPlace: any = "";

    public status: any = {};
    public periodicity: any = 8;
    public availableSystems: any[] = [];
    public systemPeriodicities: any[] = [];
    public existing: boolean = false;
    public system: any[] = [];
    public currentYear: any = "";
    public currentMonth: any = "";
    public maintenancePeriodOptions: any[] = [];
    public maintenancePeriodOptions2: any[] = [];
    public maintenancePeriod: any = '';
    //public techs: any[] = [];
    public personnelList: any[] = [];
    //public availableForemen: any[] = [];
    public loading: boolean = false;
    //public selectedTech: any = "";
    public availableSenTechs: any[] = [];
    public workOrders: any[] = [];
    public workOrderList: any[] = [];
    public availableWorkOrders: any[] = [];
    public yearlyComponent: any = "";
    public availableComponents: any[] = [];
    public availableComponents2: any[] = [];
    public componentList: any[] = [];
    public senTechs: any[] = [];
    public senTechList: any[] = [];
    public atrGroups: any[] = [];
    public southUps: any[] = [];
    public northUps: any[] = [];
    public upsGroups: any[] = [];
    public northSorter: any[] = [];
    public southSorter: any[] = [];
    public itGroups: any[] = [];
    public atrComponents: any[] = [];
    public northInductions: any[] = [];
    public southInductions: any[] = [];
    public halls: any[] = [];
    public systems: any[] = [];
    public groups: any[] = [];
    public codes: any[] = [];
    public descriptions: any[] = [];
    public conveyorBelts: any[] = [];
    public southPanels: any[] = [];
    public northPanels: any[] = [];
    public selectedHall = "";
    public belt: any = "";
    public components: any[] = [];
    public selectedWeek: any = "";
    public lines: any[] = [];
    public beltLine: any[] = [];
    public workOrdersTable: any[] = [];
    public selectedWorkOrder: any = "";
    public selectedWorkOrderNew: any = "";
    public selectedComponent = "";
    public jsonRemarks: any[] = [];
    public yearlyRemarks: any = "";
    public yearlyActions: any = "";
    public activities: any[] = [];
    public foreman: any = "";
    public senTech: any = "";
    public row: any = "";
    public selectedSubpanels: any[] = [];
    public selectedScales: any[] = [];
    public selectedSubsortername: any[] = [];
    public test1: any[] = []
    public test2: any[] = []
    public test3: any[] = []
    public test4: any[] = []
    public PpmsList2subpanel: any[] = [];
    public Select: boolean = false;
    public select_all: boolean = false;
    public oneWO: any = "";

    async created() {
        await this.getPersonnelList();
        await this.getSectorsList();
        await this.getProjectPlacesList();
        await this.getProjectTitlesList();
        await this.getProjectSpecialties();
        await this.getStatusList();

        this.checkEdit();

    }

    public exportReports() {
        console.log('testforreports')
        console.log(this.sectorselectrd)
        console.log(this.titleselectrd)
        console.log(this.selectedTech)
        console.log('test2')

        this.$store.dispatch("updateCurrentObject", {
            sector: this.sectorselectrd,
            project_title: this.titleselectrd,
            personnel_id: this.selectedTech
        });
        this.$store.dispatch("updateCurrentComponent", "downloadNPReports");
        this.$router.replace({
            name: "load"
        });
    }

    public async getSectorsList() {
        await new_project
            .getSectors(
                this.$store.getters.getToken,
                this.$store.getters.getHost
            )
            .then(response => {
                response.json().then(resp => {
                    let i = 0;
                    while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
                        this.sectorsList.push({
                            value: JSON.parse(JSON.stringify(resp))[i].id,
                            label: JSON.parse(JSON.stringify(resp))[i].literal
                        });
                        i++;
                    }
                    console.log('testforresponse--------->' + resp)
                });
            });
    }

    public async getProjectPlacesList() {
        await new_project
            .getPlaces(
                this.$store.getters.getToken,
                this.$store.getters.getHost
            )
            .then(response => {
                response.json().then(resp => {
                    let i = 0;
                    while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
                        this.placesList.push({
                            value: JSON.parse(JSON.stringify(resp))[i].id,
                            label: JSON.parse(JSON.stringify(resp))[i].literal
                        });
                        i++;
                    }
                    console.log('testforprojectplaces-------->' + JSON.stringify(resp))
                });
            });
    }

    public async getProjectTitlesList() {
        await new_project
            .getProjectTitles(
                this.$store.getters.getToken,
                this.$store.getters.getHost
            )
            .then(response => {
                response.json().then(resp => {
                    let i = 0;
                    while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
                        this.titlesList.push({
                            value: JSON.parse(JSON.stringify(resp))[i].id,
                            label: JSON.parse(JSON.stringify(resp))[i].literal
                        });
                        i++;
                    }
                    this.titlesList.push({
                        label: "All",
                        value: -2
                    });
                    this.titlesList.push({
                        label: "None",
                        value: -3
                    });
                    console.log('testforprojectplaces-------->' + JSON.stringify(resp))
                });
            });
    }

    public async getProjectSpecialties() {
        await new_project
            .getProjectSpecialties(
                this.$store.getters.getToken,
                this.$store.getters.getHost
            )
            .then(response => {
                response.json().then(resp => {
                    let i = 0;
                    while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
                        this.SpecialtiesList.push({
                            value: JSON.parse(JSON.stringify(resp))[i].id,
                            label: JSON.parse(JSON.stringify(resp))[i].literal
                        });
                        i++;
                    }
                    console.log('testforprojectplaces-------->' + JSON.stringify(resp))
                });
            });
    }

    public pushPersonSpecialties() {
        this.jsonRemarks.push({
            remarks: this.yearlyRemarks,
            specialtie: this.SpecialtieSelected
        });
        console.log("this.jsonRemarks")
        console.log(this.jsonRemarks)
        console.log("this.jsonRemarks")
        this.yearlyRemarks = "";
        this.yearlyActions = "";
    }

    public techUpdate(id: any, index: number) {
        if (id == "") {
            this.techs.splice(index, 1);
        }
        console.log("this.techs1")
        console.log(this.techs);
        console.log("this.techs2")
    }

    public foremanMethod(query: string) {
        if (query != "" && query != undefined) {
            this.loading = true;
            this.loading = false;
            this.availableForemen = this.personnelList.filter(item => {
                return (
                    (item.firstname.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
                        item.team == query ||
                        item.lastname.toLowerCase().indexOf(query.toLowerCase()) > -1) &&
                    this.techs.find(r => r.id == item.firstname + " " + item.lastname) ==
                    undefined
                );
            });
            console.log("this.availableForemen1")
            console.log(this.availableForemen);
            console.log("this.availableForemen2")
        } else {
            this.availableForemen = [];
        }
    }

    public pushPersonnel() {
        //auto gemizi tin proti timi kai meta i for ta emfanizi
        if (this.techs.length < 1) {
            this.techs.push({
                id: this.personnelList.find(r => r.id == this.selectedTech).firstname +
                    " " +
                    this.personnelList.find(r => r.id == this.selectedTech).lastname,
                value: this.selectedTech,
                specialtie: "",
            });

        } else {
            this.techs.push({
                id: this.personnelList.find(r => r.id == this.selectedTech).firstname +
                    " " +
                    this.personnelList.find(r => r.id == this.selectedTech).lastname,
                value: this.selectedTech,
                specialtie: this.techs[0].specialtie
            });
        }
        this.selectedTech = "";
        this.availableForemen = [];
        console.log("xxxxxxxxx")
        console.log(JSON.stringify(this.techs));
        console.log("xxxxxxxxx")
    }

    public async addToDBPNewProject() {

        let projectItem: any[] = [{
            id: this.row.id,
            sector: this.sectorselectrd,
            place: this.placeselectrd,
            project_title: this.titleselectrd,
            date_started: this.StartDate,
            date_ended: this.EndDate,
            status: this.selectedStatus

        }]
        console.log(JSON.stringify(projectItem))
        console.log("testforprojectfront")
        let r = await new_project.addNewProject(this.$store.getters.getToken, projectItem, this.$store.getters.getHost)

        console.log(r)
        console.log("testforprojectfront2")

        if (r.status == 400) {
            this.$message.error(r.error);
        } else {
            console.log("THIS IS THE POST RESPONSE")
            console.log(JSON.stringify(r))

            let newproject_id = null;
            if (r.newproject_id) {
                // gia add
                console.log('NEW PROJECT2 ADDED')
                newproject_id = r.newproject_id;

            } else {
                // console.log('PPM2 UPDATED')
                // // gia update
                // ppm_id = this.row.id
                // // to response etsi kai alios tha epistrepsi ppm_id giati tha to xrisimopoihsw sto add twn parakatw pinakwn
                // // meta sta if xrisimopio mono r.ppm_id oxi ppm_id
            }

            console.log(JSON.stringify(this.techs))
            console.log('this.techsexample')

            for (const i in this.techs) {
                // console.log(JSON.stringify(i))
                console.log('this.techsexample2')
                let projectPersonnelItem: any[] = [{
                    id: undefined,
                    new_project_id: newproject_id,
                    personnel_id: isNaN(this.techs[i].value) ?
                        this.personnelList.find(
                            r =>
                            r.firstname == this.techs[i].value.split(" ")[0] &&
                            r.lastname == this.techs[i].value.split(" ")[1]
                        ).id :
                        this.techs[i].value,
                    new_project_specialties_id: this.techs[i].specialtie

                }];
                console.log("Thisis what iwant to push to new_project_personnel");
                console.log(JSON.stringify(projectPersonnelItem));

                let re = await new_project.addProjectPersonnel(this.$store.getters.getToken, projectPersonnelItem, this.$store.getters.getHost)

                if (re.status == 400) {
                    this.$message.error(re.error);
                } else {
                    console.log("THIS IS THE POST RESPONSE")
                    console.log(JSON.stringify(re))
                }
            }
        }

    }

    public async addToDBPNewProjectTitle() {
        console.log(this.newprojecttitleadded)
        console.log('testforfrontend')
        let placeItem: any[] = [{
            id: undefined,
            literal: this.newprojecttitleadded

        }];
        console.log("Thisis what iwant to push to new_project_place");
        console.log(JSON.stringify(placeItem));

        let res = await new_project.addnewProjectTitle(this.$store.getters.getToken, placeItem, this.$store.getters.getHost)

        if (res.status == 400) {
            this.$message.error(res.error);
        } else {
            console.log("THIS IS THE POST RESPONSE")
            console.log(JSON.stringify(res))
        }

    }

    public async addToDBPNewProjectPlace() {

        let placeItem: any[] = [{
            id: undefined,
            literal: this.selectedPlace

        }];
        console.log("Thisis what iwant to push to new_project_place");
        console.log(JSON.stringify(placeItem));

        let res = await new_project.addnewProjectPlace(this.$store.getters.getToken, placeItem, this.$store.getters.getHost)

        if (res.status == 400) {
            this.$message.error(res.error);
        } else {
            console.log("THIS IS THE POST RESPONSE")
            console.log(JSON.stringify(res))
        }

    }

    public async getStatusList() {
        await status
            .getAllStatuses(this.$store.getters.getToken, this.$store.getters.getHost)
            .then(response => {
                response.json().then(resp => {
                    let i = 0;
                    while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
                        this.statuses.push({
                            value: JSON.parse(JSON.stringify(resp))[i].id,
                            label: JSON.parse(JSON.stringify(resp))[i].literal
                        });
                        i++;
                    }
                    console.log(JSON.stringify(resp))
                    console.log('responseforstatuses')
                });
            });
    }

    public async clear2() {

    }
    public async clear() {
        this.techs = [];
        this.yearlyComponent = "";
        this.yearlyActions = "";
        this.selectedHall = "";
        this.belt = "";
        this.beltLine = [];
        this.senTech = "";
        this.foreman = "";
        this.maintenancePeriod = ""
        this.selectedTech = ""
        this.workOrders = []
        this.selectedWorkOrder = ""
        this.selectedWorkOrderNew = ''
        this.jsonRemarks = []
        this.yearlyRemarks = ""
        this.status = {
            enddate: "",
            selectedStatus: ""
        }
        this.selectedSubpanels = [];
        console.log("clear")
    }
    public async getPersonnelList() {
        await personnel
            .getAllPersonnel(
                this.$store.getters.getToken,
                this.$store.getters.getHost
            )
            .then(response => {
                response.json().then(resp => {
                    this.personnelList = JSON.parse(JSON.stringify(resp));
                    console.log(JSON.stringify(this.personnelList))
                    console.log('this.personnelList1234')
                    this.personnelList.push({
                        id: -2,
                        lastname: "None",
                        firstname: '',
                        lastnamegr: "None",
                        firstnamegr: ''
                    });
                    this.personnelList.push({
                        id: -3,
                        lastname: "All",
                        firstname: '',
                        lastnamegr: "All",
                        firstnamegr: ''
                    });
                });
            });
    }

    ///?? 17/3/
    public async checkEdit() {

        this.row = this.$store.getters.getCurrentObject;
        console.log(JSON.stringify(this.row))
        console.log('test12')
        if (this.row) {
            console.log("this.row.id")
            console.log(this.row.id)
            console.log("this.rownewproject")
            console.log(JSON.stringify(this.row))
            console.log("this.row")
            this.sectorselectrd = this.row.sector_id
            this.placeselectrd = this.row.place_id
            this.titleselectrd = this.row.project_title_id
            this.StartDate = this.row.date_started
            this.EndDate = this.row.date_ended
            this.selectedStatus = this.row.status_id
            console.log('test test test')
            for (let x of this.row.new_project_personnel_table) {
                this.techs.push({
                    id2: x.id,
                    id: x.personnel_id,
                    value: x.personnel_id,
                    specialtie: x.new_project_specialties_id
                })
            }

        }
    }

}
</script>

<style scoped>
.tableCell {
    background-color: rgb(201, 228, 238);
    font-weight: normal !important;
    font-size: 9pt !important;
    border: 1px solid rgb(53, 167, 167);
    border-radius: 6px;
    width: 220px;
    position: relative;
    height: 30px;
}

.longTableCell {
    background-color: rgb(201, 228, 238);
    font-weight: normal !important;
    font-size: 9pt !important;
    border: 1px solid rgb(53, 167, 167);
    border-radius: 6px;
    width: 400px;
    position: relative;
    height: 30px;
}

.superLongTableCell {
    background-color: rgb(201, 228, 238);
    font-weight: normal !important;
    font-size: 9pt !important;
    border: 1px solid rgb(53, 167, 167);
    border-radius: 6px;
    width: 100%;
    position: relative;
    height: 30px;
}

.superLongTableCellFixed {
    background-color: rgb(201, 228, 238);
    font-weight: normal !important;
    font-size: 9pt !important;
    border: 1px solid rgb(53, 167, 167);
    border-radius: 6px;
    width: 550px;
    position: relative;
    height: 30px;
}

.shortTableCell {
    background-color: rgb(201, 228, 238);
    font-weight: normal !important;
    font-size: 9pt !important;
    border: 1px solid rgb(53, 167, 167);
    border-radius: 6px;
    width: 100px;
    position: relative;
    height: 30px;
}

.midTableCell {
    background-color: rgb(201, 228, 238);
    font-weight: normal !important;
    font-size: 9pt !important;
    border: 1px solid rgb(53, 167, 167);
    border-radius: 6px;
    width: 120px;
    position: relative;
    height: 30px;
}

.tableTag2 tr:nth-of-type(1) td {
    /* 1st row */
    color: red;
    font-weight: bold;
}

.tableTag2 tr:nth-of-type(2) td {
    /* 2st row */
    color: rgb(30, 123, 209);
    font-weight: bold;
}

.tableTag2 {

    border-collapse: collapse;
    background-color: rgb(201, 228, 238);
    font-weight: normal !important;
    font-size: 9pt !important;
    border: 1px solid rgb(53, 167, 167);
    border-radius: 6px;
    width: 380px;
    height: 180px;
}

.tableTag {
    background-color: rgb(201, 228, 238);
    font-weight: normal !important;
    font-size: 9pt !important;
    border: 1px solid rgb(53, 167, 167);
    border-radius: 6px;
    width: 100px;
    height: 30px;
}

.yellowTableTag {
    background-color: rgb(253, 255, 136);
    font-weight: normal !important;
    font-size: 9pt !important;
    border: 1px solid rgb(53, 167, 167);
    border-radius: 6px;
    width: 100px;
    height: 30px;
}

.dateTableCell {
    background-color: rgb(201, 228, 238);
    font-weight: normal !important;
    font-size: 9pt !important;
    border: 1px solid rgb(53, 167, 167);
    border-radius: 6px;
    width: 150px;
    position: relative;
    height: 30px;
}

.tagText {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.notATextbox {
    width: 100%;
    padding-left: 5px;
    background-color: rgb(226, 226, 226);
    border: 1px solid rgb(0, 0, 0);
    font-size: 9pt !important;
    overflow-wrap: break-word;
}

.white {
    color: white;
}
</style>
