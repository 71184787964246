<template>
  <div>
    <el-row class="container">
      <InternalInformationFilters v-model="filters"></InternalInformationFilters>
      <br />
      <el-button @click="search" type="success">
        <strong class="white">Search</strong>
      </el-button>
    </el-row>
    <br />
    <el-row class="container">
      <el-table
        :data="InternalInformationList"
        v-loading="loading"
        :default-sort="{prop: 'seen', order: 'descending'}"
        :row-class-name="seenStatus"
        border
        :header-cell-style="{background:'rgb(170, 238, 238)',color:'black' ,'text-align':'center'}"
      >
        <el-table-column
          prop="date_posted"
          label="Date Recorded"
          sortable
          :formatter="filterDateTime"
        ></el-table-column>
        <el-table-column prop="poster.name" label="Written By" sortable></el-table-column>
        <el-table-column label="Subject" sortable>
          <template slot-scope="scope">
            <el-button type="text" @click="formatDialog(scope.row)">{{scope.row.subject}}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="Seen By" :formatter="getSeen" sortable sort-by="seen"></el-table-column>
        <el-table-column fixed="right" label="Actions" width="120">
          <template slot-scope="scope">
            <el-button
              v-if="userLevel<2||userName==scope.row.poster.name"
              circle
              type="primary"
              icon="el-icon-edit"
              @click="handleClick(scope.$index, scope.row)"
            ></el-button>
            <el-button
              v-if="userLevel<2||userName==scope.row.poster.name"
              type="danger"
              icon="el-icon-delete"
              circle
              @click="recycleInternalInformation(scope.$index, scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-dialog :visible.sync="dialogVisible" width="30%" class="dial">{{viewedContent}}</el-dialog>
    <br />
  </div>
</template>
<script lang="ts">
import InternalInformationFilters from "../Filters/InternalInformationFilters.vue";
import { internalInformation } from "../../api/internalinformation/internalinformation";
import { Component, Vue, Prop } from "vue-property-decorator";
import Moment from "moment";
@Component({
  name: "InternalInformationList",
  components: {
    InternalInformationFilters
  }
})
export default class InternalInformationList extends Vue {
  public loading: boolean = false;
  public filters: any = {};
  public InternalInformationList: any[] = [];
  public dialogVisible: boolean = false;
  public viewedContent: string = "";
  public userLevel: number = 1;
  public userName: string = "";
  public tableData: any[] = [];
  public index: number = 0;
  public listEnd: boolean = false;
  public created() {
    this.userName = this.$store.getters.getUserName;
    this.userLevel = this.$store.getters.getUserLevel;
  }
  public more() {
    let i = 1;
    while (i < 10) {
      this.tableData.push(
        this.InternalInformationList[
          this.InternalInformationList.length - 1 - this.index
        ]
      );
      this.index++;
      i++;
      if (
        this.InternalInformationList[
          this.InternalInformationList.length - 1 - this.index
        ] == undefined
      ) {
        this.index--;
        this.listEnd = true;
        return;
      }
    }
  }
  public async recycleInternalInformation(index: number, row: any) {
    await internalInformation.recycleInfo(
      this.$store.getters.getToken,
      row.id,
      this.$store.getters.getHost
    );
    this.search();
  }
  public seenStatus({ row, rowIndex }: { row: any; rowIndex: number }): string {
    if (!row.seen) {
      console.log(row.seen);
      return "pending";
    } else {
      if (row.seen) {
        console.log(row.seen);
        return "seen";
      } else {
        return "";
      }
    }
  }
  public async formatDialog(row: any) {
    await this.messageViewed(row);
    this.dialogVisible = true;
    this.viewedContent = row.content;
  }
  public handleClick(rowIndex: number, row: any) {
    console.log("ROW");
    console.log(row);
    this.$store.dispatch("updateCurrentObject", row);
    this.$store.dispatch("setCurrentId", row.id);
    this.$store.dispatch("updateCurrentComponent", "AddInternalInformation");
    this.$router.replace({ name: "load" });
  }
  public filterDateTime(row: any) {
    if (row.date_posted === undefined) {
      return "";
    }
    return Moment(row.date_posted).format("DD/MM/YYYY HH:mm");
  }
  public getSeen(row: any) {
    let result: string = "";
    for (const s of row.internal_information_seen) {
      result =
        result +
        (s.users.name.split(" ")[0][0] + s.users.name.split(" ")[1][0]) +
        " ";
    }
    return result;
  }
  public async search() {
    this.listEnd = false;
    this.loading = true;
    this.listEnd = false;
    if (this.filters.dateFrom === null) {
      delete this.filters.dateFrom;
    }
    if (this.filters.dateTo === null) {
      delete this.filters.dateTo;
    }
    if (this.filters.user === null) {
      delete this.filters.user;
    }
    await internalInformation
      .getSomeMessages(
        this.$store.getters.getToken,
        this.filters,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.InternalInformationList = JSON.parse(JSON.stringify(resp));

          console.log(JSON.parse(JSON.stringify(resp)));
          for (const r of this.InternalInformationList) {
            r.seen = false;
            for (const s of r.internal_information_seen) {
              if (s.users.name == this.$store.getters.getUserName) {
                r.seen = true;
              }
            }
          }

          this.InternalInformationList.sort(function(a, b) {
            var aSeen = a.seen;
            var bSeen = b.seen;
            var aDate = new Date(a.date_posted);
            var bDate = new Date(b.date_posted);

            if (aSeen == bSeen) {
              return aDate.getTime() < bDate.getTime()
                ? -1
                : aDate.getTime() > bDate.getTime()
                ? 1
                : 0;
            } else {
              return aSeen < bSeen ? -1 : 1;
            }
          });
          this.tableData = this.InternalInformationList.slice(
            this.InternalInformationList.length - 10,
            this.InternalInformationList.length
          );
          this.index = 10;
          this.loading = false;
        });
      });
  }
  public async messageViewed(row: any) {
    console.log(row);
    internalInformation.messageSeen(
      this.$store.getters.getToken,
      { message: row.id },
      this.$store.getters.getHost
    );
  }
}
</script>
<style>
.el-table .pending {
  background: rgb(255, 200, 200);
}
.el-table .seen {
  background: #f0f9eb;
}
</style>
<style scoped>
.container {
  border: 2px solid rgb(170, 238, 238);
  border-radius: 10px;
  background-color: rgb(248, 247, 247);
  padding: 5px 10px 5px 10px;
}
.dial {
  text-align: left;
}
</style>
