<template>
  <iframe
    src="http://testbed.gr:3000/public/dashboard/2ccc5d1e-6f9e-49c6-8a70-741be671262e"
    frameborder="0"
    width="800"
    height="600"
    allowtransparency
  ></iframe>
</template>
<script lang = "ts">
import { Component, Vue } from "vue-property-decorator";
@Component({
    name: "dashBoard"
})
export default class dashBoard extends Vue {}
</script>
<style>
</style>