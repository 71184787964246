
export namespace new_project {

export async function getSectors(token: string, host: any) {
    return fetch(host + "/api/new_project/getSectors?token=" + token);
  }

  export async function getPlaces(token: string, host: any) {
    return fetch(host + "/api/new_project/getPlaces?token=" + token);
  }


  export async function getProjectTitles(token: string, host: any) {
    return fetch(host + "/api/new_project/getProjectTitles?token=" + token);
  }

  export async function getProjectSpecialties(token: string, host: any) {
    return fetch(host + "/api/new_project/getProjectSpecialties?token=" + token);
  }


  export async function addNewProject(token: string, projectItem: any, host: any) {
    console.log(JSON.stringify(projectItem));
    console.log("example for projectItem api frontend")
    let result: Response;
    
    let body =
      "token=" +
      token +
      "&id=" +
      projectItem[0].id +
      "&sector=" +
      projectItem[0].sector +
      "&place=" +
      projectItem[0].place +
      "&project_title=" +
      projectItem[0].project_title +
      "&date_started=" +
      projectItem[0].date_started +
      "&date_ended=" +
      projectItem[0].date_ended +
      "&status=" +
      projectItem[0].status;
     

    console.log("body")
    console.log(body)

    projectItem.token = token;
    result = (await fetch(host + "/api/new_project/addNewProject", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: body

    })) as any;

    let response = await result.json();
    console.log("result of api projectItem")
    console.log(response)
    console.log("result of api projectItem2")
    return response;
  }
 
  
  export async function addProjectPersonnel(token: string, projectPersonnelItem: any, host: any) {
    console.log(JSON.stringify(projectPersonnelItem));
    console.log("example for projectPersonnel api frontend")
    let result: Response;

    let body =
      "token=" +
      token +
      "&id=" +
      projectPersonnelItem[0].id +
      "&new_project_id=" +
      projectPersonnelItem[0].new_project_id+
      "&personnel_id=" +
      projectPersonnelItem[0].personnel_id +    
      "&new_project_specialties_id=" +
      projectPersonnelItem[0].new_project_specialties_id;
 
     console.log("body")
     console.log(body)

     projectPersonnelItem.token = token;
    result = (await fetch(host + "/api/new_project/addProjectPersonnel", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: body

    })) as any;

    let response = await result.json();
    console.log("result of api ppm1")
    console.log(response)
    console.log("result of api ppm2 addPPMPersonnel2")
    return response;
  }
  

  export async function addnewProjectTitle(token: string, placeItem: any, host: any) {
    console.log(JSON.stringify(placeItem));
    console.log("example for projectPersonnel api frontend")
    let result: Response;

    let body =
      "token=" +
      token +
      "&id=" +
      placeItem[0].id +
      "&literal=" +
      placeItem[0].literal;
     
 
     console.log("body")
     console.log(body)

     placeItem.token = token;
    result = (await fetch(host + "/api/new_project/addnewProjectTitle", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: body

    })) as any;

    let response = await result.json();
    console.log("result of api new_project1")
    console.log(JSON.stringify(response))
    console.log("result of api new_project2")
    return response;
  }

  export async function addnewProjectPlace(token: string, placeItem: any, host: any) {
    console.log(JSON.stringify(placeItem));
    console.log("example for projectPlace api frontend")
    let result: Response;

    let body =
      "token=" +
      token +
      "&id=" +
      placeItem[0].id +
      "&literal=" +
      placeItem[0].literal;
     
 
     console.log("body")
     console.log(body)

     placeItem.token = token;
    result = (await fetch(host + "/api/new_project/addnewProjectPlace", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: body

    })) as any;

    let response = await result.json();
    console.log("result of api new_project11")
    console.log(JSON.stringify(response))
    console.log("result of api new_project22")
    return response;
  }

  export async function addnewProjectPersonnel2(token: string, projectdurationItem: any, host: any) {
    console.log(JSON.stringify(projectdurationItem));
    console.log("example for ppmpersonnel api frontend")
    let result: Response;
    console.log(projectdurationItem[0].mech_hours)
    console.log(typeof (projectdurationItem[0].mech_hours))
    console.log('projectdurationItem[0].mech_hours')
   // == ""?0:projectdurationItem[0].mech_hours
    let body =
      "token=" +
      token +
      "&id=" +
      projectdurationItem[0].id +
      "&project_id=" +
      projectdurationItem[0].project_id +
      "&personnel_id=" +
      projectdurationItem[0].personnel_id +
      "&start_date=" +
      projectdurationItem[0].start_date +
      "&end_date=" +
      projectdurationItem[0].end_date +
      "&unique_id=" +
      projectdurationItem[0].unique_id 

      if(projectdurationItem[0].mech_hours == ""){
        body += '&mech_hours='+ 0
      }  else{
        body += '&mech_hours='+ projectdurationItem[0].mech_hours
      }
      if(projectdurationItem[0].elec_hours == ""){
        body += '&elec_hours='+ 0
      }  else{
        body += '&elec_hours='+ projectdurationItem[0].elec_hours
      }
      if(projectdurationItem[0].general_hours == ""){
        body += '&general_hours='+ 0
      }  else{
        body += '&general_hours='+ projectdurationItem[0].general_hours
      }
      if(projectdurationItem[0].control_hours == ""){
        body += '&control_hours='+ 0
      }  else{
        body += '&control_hours='+ projectdurationItem[0].control_hours
      }
      if(projectdurationItem[0].it_hours == ""){
        body += '&it_hours='+ 0
      }  else{
        body += '&it_hours='+ projectdurationItem[0].it_hours
      }
      if(projectdurationItem[0].pm_hours == ""){
        body += '&pm_hours='+ 0
      }  else{
        body += '&pm_hours='+ projectdurationItem[0].pm_hours
      }
      if(projectdurationItem[0].ps_hours == ""){
        body += '&ps_hours='+ 0
      }  else{
        body += '&ps_hours='+ projectdurationItem[0].ps_hours
      }
      if(projectdurationItem[0].admin_hours == ""){
        body += '&admin_hours='+ 0
      }  else{
        body += '&admin_hours='+ projectdurationItem[0].admin_hours
      }
      // "&mech_hours=" +
      // projectdurationItem[0].mech_hours  +
      // "&elec_hours=" +
      // projectdurationItem[0].elec_hours +
      // "&general_hours=" +
      // projectdurationItem[0].general_hours +
      // "&control_hours=" +
      // projectdurationItem[0].control_hours +
      // "&it_hours=" +
      // projectdurationItem[0].it_hours +
      // "&pm_hours=" +
      // projectdurationItem[0].pm_hours +
      // "&ps_hours=" +
      // projectdurationItem[0].ps_hours 
      // "&admin_hours=" +
      // projectdurationItem[0].admin_hours 


    console.log("body")
    console.log(body)

    projectdurationItem.token = token;
    result = (await fetch(host + "/api/new_project/addnewProjectPersonnel2", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: body

    })) as any;

    let response = await result.json();
    console.log("result of api ProjectPersonnel2")
    console.log(response)
    console.log("result of api ProjectPersonnel2")
    return response;
  }

  export async function getAllNewProject(token: string, host: any) {
    return fetch(host + "/api/new_project/getAllNewProject?token=" + token);
  }
  
  export async function getAllEmployeesDurations(token: string, host: any) {
    return fetch(host + "/api/new_project/getAllEmployeesDurations?token=" + token);
  }

  export async function getSomeEmployeesDurations(token: string, filters: any, host: any) {
    var url = host + "/api/new_project/getSomeEmployeesDurations?token=" + token   
      
    if(filters.project_title){
      url += '&project_title=' + filters.project_title
    }  
    if(filters.fromDate){
      url += '&fromDate=' + filters.fromDate;
    }
    if(filters.toDate){
      url += '&toDate=' + filters.toDate
    }         

    return fetch(url);
  }

  export async function getAllEmployeesDurations2(token: string, host: any) {
    return fetch(host + "/api/new_project/getAllEmployeesDurations2?token=" + token);
  }
  export async function getSomeNewProject(token: string, filters: any, host: any) {
    var url = host + "/api/new_project/getAllNewProject?token=" + token   
    if(filters.sector){
      url += '&sector='+ filters.sector
    }  
    if(filters.place){
      url += '&place=' + filters.place
    }
    if(filters.project_title){
      url += '&project_title=' + filters.project_title
    }  
    if(filters.fromDate){
      url += '&fromDate=' + filters.fromDate;
    }
    if(filters.toDate){
      url += '&toDate=' + filters.toDate
    }
    if(filters.status){
      url += '&status=' + filters.status
    }
     
    

    return fetch(url);
  }

  export async function deleteNewProject(
    host: any,
    token: string,
    id: number   
  ): Promise<any> {
    return await fetch(host + "/api/new_project/deleteNewProject?token=" + token + "&id=" + id);
  }

  
  export async function deleteNewProjectDurations(
    host: any,
    token: string,
    unique_id: number    
  ): Promise<any> {
    return await fetch(host + "/api/new_project/deleteNewProjectDurations?token=" + token + "&unique_id=" + unique_id);
  }
  
  export async function deletenpdurations(
    host: any,
    token: string,
    unique_id: number    
  ): Promise<any> {
    return await fetch(host + "/api/new_project/deletenpdurations?token=" + token + "&unique_id=" + unique_id);
  }
  

  export async function downloadNPReports(
    token: string,
    sector: any,
    host: any,
    project_title: any,
    personnel_id: number,
  ): Promise<any> {
    return await fetch(
      host + "/api/new_project/downloadNPReports?token=" + token + "&sector=" + sector + "&project_title=" + project_title + "&personnel_id=" + personnel_id 
    );
 
  }


}