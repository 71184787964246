<template>
  <div>Downloading Reports PPM</div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { new_project } from "../../api/new_project/new_project";
import * as moment from "moment";
@Component({ name: "DownloadNPReports" })
export default class DownloadNPReports extends Vue {
  public async mounted() {
    let object = this.$store.getters.getCurrentObject;
    let token = this.$store.getters.getToken;
    let sector = object.sector
    let project_title = object.project_title
    let personnel_id = object.personnel_id

   

    console.log("testforDownloadNPReportsall")
    console.log(object)
    console.log(token)
    console.log(sector)
    console.log(project_title)
    console.log(personnel_id)
    console.log("testforDownloadNPReports2")

    //let year=this.$store.getters.getCurrentObject;
    //setCurrentId -1 gia epistrofi
    this.$store.dispatch("setCurrentId",-1 );
    //AddNewVacation arxiki othoni ekei pou thelw na giriso
    this.$store.dispatch("updateCurrentComponent", "newProjectReports");
    this.$router.replace({
      name: "load"
    });
  
    let response = await new_project.downloadNPReports(
      token,
      sector,
      this.$store.getters.getHost,
      project_title,
      personnel_id    
    );
    console.log(response)
    console.log('response12334')
    response.blob().then((blob: any) => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      // a.download = object.id.id + ".xlsx";

       
      if (object.sector > 0 && object.project_title == -2 && object.personnel_id == -2){
        a.download ="Report-All.xlsx"
      }else if(object.sector > 0 && object.project_title > 0 && object.personnel_id == -2 ){
        a.download ="Report-Spesfic.xlsx"
      }else if(object.sector > 0 && object.project_title > 0 && object.personnel_id == -3){
        a.download = "Report-All_Employees.xlsx"
      }else if(object.sector > 0 && object.project_title == -2 && object.personnel_id > 0 ){
        a.download ="NPReport-AllProjectsperEmployee.xlsx"
        // console.log("NPReport-AllProjectsperEmployee.xlsx")
      } else {
        a.download = "Report-Per_Employee.xlsx"
        console.log('testfor_Report-Per_Employee.xlsx')
        
      }









      //a.download = object.id.id + ".xlsx";
    //   if(reports1==1){
    //     console.log("test_for_e")
    //     var CurrentDate = moment().format("DD/MM/YYYY")
    //     a.download = "Client_PPM_Report_"+CurrentDate+".xlsx";
    //   }else if(reports1==2){
    //    console.log("test_for_e")
    //     var CurrentDate = moment().format("DD/MM/YYYY")
    //     a.download = "General"+CurrentDate+".xlsx";
    //   }else if(reports1==4){
    //    console.log("test_for_e")
    //     var CurrentDate = moment().format("DD/MM/YYYY")
    //     a.download = "Overview_Report"+CurrentDate+".xlsx";
    //   }
      //   a.download = 'test1.xlsx'

      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    });
  }
}
</script>
<style>
</style>