export namespace Company {
  export async function getAllCompanies(token: string,host:any) {
    return fetch(host+'/api/personnel/getCompanies?token=' + token);
  }
  export class CompanyItemResponse {
    public id: number;
    public name: string;
    constructor() {
      this.id = -1;
      this.name = '';
    }
  }
}
