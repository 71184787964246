<template>
  <div>
    <br />
    <el-row class="container">
      <el-table
        :data="viewVacationSubmitted"
        :row-class-name="vacationStatus1"
        :default-sort="{prop: 'seen', order: 'descending'}"
        :header-cell-style="{background:'rgb(170, 238, 238)',color:'black', 'text-align':'center'}"
        border
      >

        <el-table-column label="Name" sortable :formatter="getName" width="170px" align="center" :sort-method="(a, b) => sortName(a, b)"></el-table-column>
        <el-table-column prop="total_days_req"   label="Total days requested" sortable width="90px" align="center" :sort-method="(a, b) => sorttotalDateRequestedDate(a, b)" ></el-table-column>
        <el-table-column :formatter="getleaveon" label="Leave on" sortable width="130px" align="center" :sort-method="(a, b) => sortDate(a, b)"></el-table-column>
        <el-table-column sortable :formatter="getleaveend" label="Leave end"  width="130px" align="center" :sort-method="(a, b) => sortDatesortDateLeaveEnd(a, b)"  ></el-table-column>
        <el-table-column :formatter="getreturnon" label="Return on" sortable width="130px" align="center" :sort-method="(a, b) => sortDatesortDateReturnOn(a, b)" ></el-table-column>
        <el-table-column :formatter="getTypeOfvacation" label="Type" sortable  width="90px" align="center" :sort-method="(a, b) => sortTypeOfvacation(a, b)"></el-table-column>
        <el-table-column label="Status" sortable :formatter="getStatus" width="90px" align="center"  :sort-method="(a, b) => sortStatus(a, b)"></el-table-column>

        <el-table-column label="Actions" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="userlevel<2"
              type="primary"
              @click="Edit(scope.$index, scope.row)"
              icon="el-icon-edit"
              circle
            ></el-button>
            <el-button
              v-if="userlevel<2"
              type="danger"
              icon="el-icon-delete"
              circle
              @click="open(scope.$index, scope.row)"
            ></el-button>
            <el-button
              v-if="userlevel<2"
              type="success"
              icon="el-icon-check"
              circle
              @click="addApproved(scope.$index, scope.row)"
            ></el-button>
            <el-button
              v-if="userlevel<2"
              type="info"
              icon="el-icon-error"
              circle
              @click="addRejected(scope.$index, scope.row)"
            ></el-button>
            <el-button
              v-if="userlevel<2"
              type="warning"
              icon="el-icon-printer"
              circle
              @click="exportVacation(scope.$index, scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { personnel } from "../../api/personnel/personnel";
import { vacation } from "../../api/vacation";
import Moment from "moment";
import Component from "vue-class-component";

@Component({
  name: "Decisionofvacation"
})
export default class ViewVacationSubmitted extends Vue {
  public viewVacationSubmitted: any[] = [];
  public viewVacationSubmitted1: any[] = [];
  public personnelList: any[] = [];
  public TypeofvacationList: any[] = [];
  public loading: boolean = true;
  public userlevel: number = -1;
  public statusofvacation: number = 1;
  public statusofvacationList: any[] = [];
  public id1: number = -1;
  public year1: any = "";
  public nameAsc:boolean = false;
  public dateAsc:boolean = false;
  

  public async created() {
    this.loading = true;
    let object = this.$store.getters.getCurrentObject;

    this.year1 = object.year;

    if (object.id != undefined) this.id1 = object.id.id;

    this.$store.dispatch("updateCurrentObject", {
      year: undefined,
      id: undefined
    });
    await this.getPersonnelList();
    await this.getvacationList();
    await this.getTypeofvacationList();
    await this.getStatusofvacationList();
  }
  public exportVacation(rowIndex: number, row: any) {
    let temp = { decisionofvacation: 1 };

    const row1 = Object.assign(temp, row);

    this.$store.dispatch("updateCurrentObject", row1);
    this.$store.dispatch("updateCurrentComponent", "DownloadVacation");
    this.$router.replace({
      name: "load"
    });
  }

  public vacationStatus1({
    row,
    rowIndex
  }: {
    row: number;
    rowIndex: number;
  }): String {
    return this.getStatus(row, 1);
  }
 public getleaveon(row: any) {
    let x=JSON.parse(row.json_leaveon_leaveend)    
    let temp=""
    for(let c in x){
     temp+=Moment( x[c].leave_on  ).format("DD/MM/YYYY")+ "\n"
    }
    // if (row.leave_on === undefined) {
    //   return "";
    // }
    // return Moment(row.leave_on).format("DD/MM/YYYY");
    return temp
  }
  public getleaveend(row: any) {
    let x=JSON.parse(row.json_leaveon_leaveend)    
    let temp=""
    for(let c in x){
     temp+=Moment(x[c].leave_end ).format("DD/MM/YYYY")+ "\n" 
    }
    // if (row.leave_end === undefined) {
    //   return "";
    // }
    // return Moment(row.leave_end).format("DD/MM/YYYY");
    return temp
  }
  // public getleaveon(row: any) {
  //   if (row.leave_on === undefined) {
  //     return "";
  //   }
  //   return Moment(row.leave_on).format("DD/MM/YYYY");
  // }
  // public getleaveend(row: any) {
  //   if (row.leave_end === undefined) {
  //     return "";
  //   }
  //   return Moment(row.leave_end).format("DD/MM/YYYY");
  // }

  public getreturnon(row: any) {
    if (row.return_on === undefined) {
      return "";
    }
    return Moment(row.return_on).format("DD/MM/YYYY");
  }

  public async getvacationList() {
    await vacation
      .getAllVacations(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.viewVacationSubmitted1 = JSON.parse(JSON.stringify(resp));
          console.log("VACAY");
          console.log(this.viewVacationSubmitted1);
          this.viewVacationSubmitted = [];
          for (const a of this.viewVacationSubmitted1) {
            var x = false;
            for (const b of this.personnelList) {
              if (a.idpersonel == b.id && b.firstname != "x") {
                console.log(a);
                console.log(b);
                console.log(b.firstname);
                console.log("xxxxxxxxxxxxxx");
                x = true;
                break;
              }
            }
            if (x == true) {
              var temp = {seen:true};
              if (a.decision == undefined) {
                a.decision = 1;
              }
              if (a.decision == 1) {
                temp = {seen:false};
                
              }

              //this.viewVacationSubmitted.push(a);
              var temp2 =a
              console.log(temp2)
              console.log("temp2")
              this.viewVacationSubmitted.push(Object.assign(temp2, temp))
              // this.viewVacationSubmitted.merge(temp)
              this.viewVacationSubmitted.sort(function(a, b) {
              var aSeen = a.seen;
              var bSeen = b.seen;
              var aDate = new Date(a.date_posted);
              var bDate = new Date(b.date_posted);

              if (aSeen == bSeen) {
                return aDate.getTime() < bDate.getTime()
                  ? -1
                  : aDate.getTime() > bDate.getTime()
                  ? 1
                  : 0;
              } else {
                return aSeen < bSeen ? -1 : 1;
              }
            });
              console.log("this.viewVacationSubmitted111111");
              console.log(this.viewVacationSubmitted);
              console.log("this.viewVacationSubmitted22222");
            }
          }
          console.log(this.viewVacationSubmitted);
          if (this.id1 != undefined && this.id1 != -1) {
            var start = new Date(this.year1, 1, 1);
            var end = new Date(this.year1, 12, 31);

            this.viewVacationSubmitted = this.viewVacationSubmitted.filter(
              word =>
                new Date(word.leave_on) > start &&
                new Date(word.leave_on) < end &&
                word.idpersonel == this.id1
            );
            console.log("yyyyyyyyyyyyyyyyyyyyy");

            // this.viewVacationSubmitted = this.viewVacationSubmitted.filter(word=>this.personnelList.id == word)
          }
        });
      });
    //this.fillTable();
    this.loading = false;
  }

  public async getPersonnelList() {
    await personnel
      .getAllPersonnel(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )

      //  .then(response => {
      //   response.json().then(resp => {
      //     this.personnelList = JSON.parse(JSON.stringify(resp));
      //     this.personnelList.filter(word => word.active != false);
      //     console.log(this.personnelList);
      //     console.log("this.personnelList");
      //   });
      // });
      .then(response => {
        response.json().then(resp => {
          this.personnelList = JSON.parse(JSON.stringify(resp));
          // for(var item in this.personnelList ){
          //   console.log(item.id)
          //   console.log("item")

          //           }

          this.personnelList = this.personnelList.filter(word =>
            word.active != false ? this.personnelList : (word.firstname = "x")
          );
          // console.log("this.personnelList1111111111");
          // console.log(this.personnelList);
          // console.log("this.personnelList222222222");
          //element != undefined ? temp == element.id : false;
        });
      });
  }

  public async addApproved(rowIndex: number, row: any) {
    await vacation.approvedVacation(
      this.$store.getters.getToken,
      row.id,
      this.$store.getters.getHost
    );
    this.getvacationList();
  }

  public async addRejected(rowIndex: number, row: any) {
    await vacation.rejectedVacation(
      this.$store.getters.getToken,
      row.id,
      this.$store.getters.getHost
    );
    this.getvacationList();
  }

  public async deleteVA(rowIndex: number, row: any) {
    await vacation.deleteVacation(
      this.$store.getters.getToken,
      row.id,
      this.$store.getters.getHost
    );
    this.getvacationList();
  }

  public async open(rowIndex: number, row: any) {
    this.$confirm("This will permanently deleted. Continue?", "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning"
    })
      .then(() => {
        this.$message({
          type: "success",
          message: "Delete completed"
        });
        this.deleteVA(rowIndex, row);
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "Delete canceled"
        });
      });
  }

  public async getTypeofvacationList() {
    await vacation
      .getAllType(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then(resp => {
          this.TypeofvacationList = JSON.parse(JSON.stringify(resp));
        });
      });
  }

  public async getStatusofvacationList() {
    await vacation
      .getAllStatusofvacation(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.statusofvacationList = JSON.parse(JSON.stringify(resp));
        });
      });
  }
//  public sortName(a:any,b:any){
//     console.log('aaaaaaaaaaaaaaaaaaaaaaaaa')
//     console.log(a);
//     this.nameAsc = !this.nameAsc
//   if (this.nameAsc){
    
//     return this.getName(a,null)>this.getName(b,null)?1:-1;
//   }
//     return this.getName(a,null)>this.getName(b,null)?1:-1;
//   }

  public sortName(a:any,b:any){
    console.log('aaaaaaaaaaaaaaaaaaaaaaaaa')
    console.log(a);
    this.nameAsc = !this.nameAsc
  if (this.nameAsc){
    
    return this.getName(a,null)>this.getName(b,null)?1:-1;
  }
    return this.getName(a,null)>this.getName(b,null)?1:-1;
  }
  
  public sortStatus(a:any,b:any){
    console.log('aaaaaaaaaaaaaaaaaaaaaaaaa')
    console.log(a);
    // this.nameAsc = !this.nameAsc
  // if (this.nameAsc){
    
    return this.getStatus(a,null)>this.getStatus(b,null)?1:-1;
  // }
  //   return this.getStatus(a,null)>this.getStatus(b,null)?1:-1;
  }

    public sortTypeOfvacation(a:any,b:any){
    console.log('aaaaaaaaaaaaaaaaaaaaaaaaa')
    console.log(a);
    this.nameAsc = !this.nameAsc
  if (this.nameAsc){
    
    return this.getTypeOfvacation(a,null)>this.getTypeOfvacation(b,null)?1:-1;
  }
    return this.getTypeOfvacation(a,null)>this.getTypeOfvacation(b,null)?1:-1;
  }


  public sortDatesortDateLeaveEnd(a:any,b:any){
    console.log("bbbbbbbbbbbbbbb")
    console.log(a.leave_end)
    console.log("bbbbbbbbbbbbbbb")
    // this.dateAsc = !this.dateAsc
    console.log(Moment(a.leave_end).format("YYYY/MM/DD"))
    console.log(Moment(b.leave_end).format("YYYY/MM/DD"))
    console.log("----------------")
    //0
  // if(this.dateAsc){
  //   return Moment(a.leave_on).format("YYYY/MM/DD")>Moment(b.leave_on).format("YYYY/MM/DD")?1:-1;
  // }
    return Moment(a.leave_end).format("YYYY/MM/DD")>Moment(b.leave_end).format("YYYY/MM/DD")?1:-1;

  }


  public sortDatesortDateReturnOn(a:any,b:any){
    console.log("bbbbbbbbbbbbbbb")
    console.log(a.return_on)
    console.log("bbbbbbbbbbbbbbb")
    // this.dateAsc = !this.dateAsc
    console.log(Moment(a.return_on).format("YYYY/MM/DD"))
    console.log(Moment(b.return_on).format("YYYY/MM/DD"))
    console.log("----------------")
    //0
  // if(this.dateAsc){
  //   return Moment(a.leave_on).format("YYYY/MM/DD")>Moment(b.leave_on).format("YYYY/MM/DD")?1:-1;
  // }
    return Moment(a.return_on).format("YYYY/MM/DD")>Moment(b.return_on).format("YYYY/MM/DD")?1:-1;

  }

   public sorttotalDateRequestedDate(a:any,b:any){
    console.log("bbbbbb")
    console.log(a.total_days_req)
    console.log("bbbbbb")
    // this.dateAsc = !this.dateAsc
    console.log(Moment(a.total_days_req).format("YYYY/MM/DD"))
    console.log(Moment(b.total_days_req).format("YYYY/MM/DD"))
    console.log("----------------")
    //0
  // if(this.dateAsc){
  //   return Moment(a.leave_on).format("YYYY/MM/DD")>Moment(b.leave_on).format("YYYY/MM/DD")?1:-1;
  // }
    return a.total_days_req>b.total_days_req?1:-1;

  }
//  public sortDate(a:any,b:any){
//     console.log("bbbbbbbbbbbbbbb")
//     this.dateAsc = !this.dateAsc

//   if(this.dateAsc){
//     return this.getleaveon(a,null)>this.getleaveon(b,null)?1:-1;
//   }
//     return this.getleaveon(a,null)>this.getleaveon(b,null)?1:-1;

//   }

  public sortDate(a:any,b:any){
    console.log("bbbbbbbbbbbbbbb")
    console.log(a.leave_on)
    console.log("bbbbbbbbbbbbbbb")
    // this.dateAsc = !this.dateAsc
    console.log(Moment(a.leave_on).format("YYYY/MM/DD"))
    console.log(Moment(b.leave_on).format("YYYY/MM/DD"))
    console.log("----------------")
    //0
  // if(this.dateAsc){
  //   return Moment(a.leave_on).format("YYYY/MM/DD")>Moment(b.leave_on).format("YYYY/MM/DD")?1:-1;
  // }
    return Moment(a.leave_on).format("YYYY/MM/DD")>Moment(b.leave_on).format("YYYY/MM/DD")?1:-1;

  }
  //to row exei olo to stixio
  //element olo to stixio tou row
  public getName(row: any, column: any): String {
    let temp = row.idpersonel;

    let tem2 = this.personnelList.find(function(element) {
      return temp == element.id;
      // return element.id != undefined ? temp == element.id : false;
    });

    if (tem2.firstname != "x") {
      return tem2.firstname + " " + tem2.lastname;
    } else {
      return "x";
    }
    // return tem2 ? tem2.firstname + " " + tem2.lastname : "x";
  }

  public getStatus(row: any, column: any): String {
    let temp5 = row.decision;
    let tem6 = this.statusofvacationList.find(function(element) {
      return temp5 == element.id;
    });
    if (tem6 === undefined) return "Pending";
    return tem6.literal;
  }

  public getTypeOfvacation(row: any, column: any): String {
    let temp3 = row.type_of_vacation;
    //console.log("searching for:" + temp3);
    let temp4 = this.TypeofvacationList.find(function(element) {
      return temp3 == element.id;
    });
    //console.log("picked:" + temp4);
    if (temp4 === undefined) return "na";
    return temp4.type_of_vacation;
  }

  public Edit(rowIndex: number, row: any) {
    this.$store.dispatch("updateCurrentObject", row);
    this.$store.dispatch("updateCurrentComponent", "AddNewVacation");
    this.$router.replace({ name: "load" });
  }
}
</script>

<style>
.el-table .Pending {
  background: rgb(236, 255, 149);
}

.el-table .Completed {
  background: #ffffff;
}

.el-table .Cancelled {
  background: rgb(255, 186, 186);
}
</style><style scoped>
.container {
  border: 2px solid rgb(170, 238, 238);
  border-radius: 10px;
  background-color: rgb(248, 247, 247);
  padding: 5px 10px 5px 10px;
}

.personnel {
  background-color: rgb(230, 242, 255);
  font-weight: normal !important;
  padding-left: 10px;
  padding-right: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}

.personnelHead {
  background-color: rgb(255, 242, 240);
  font-weight: normal !important;
  padding-left: 10px;
  padding-right: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}

u {
  text-decoration: underline;
}

.hours {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: rgb(230, 242, 255);
  padding-right: 10px;
}
</style>
