import Vue from "vue";
import Router from "vue-router";
import Login from "./views/Login.vue";
import MainMenu from "./views/MainMenu_v2.vue";
import Load from "./components/Load.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "login",
      component: Login
    },
    {
      path: "/menu",
      name: "menu",
      component: MainMenu
    },
    {
      path: "/load",
      name: "load",
      component: Load
    }
  ]
});
