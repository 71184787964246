import { RESTCalls } from '../helpers/restcalls';
export namespace Specialty {
    export async function getAllSpecialties(token: string,host:any) {
        return fetch(host+'/api/personnel/getSpecialties?token=' + token);
    }
    export class SpecialtyItemResponse {
        public id: number;
        public literal: string;
        constructor() {
            this.id = -1;
            this.literal = '';
        }
    }
}
