<template>
  <div>
    <!-- <el-collapse accordion v-model="activeName">
      <el-collapse-item title="Project Filters" name="2"> -->
        <div class="container">               
            <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>Project Title:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-select
                size="mini"
                :style="{width:'200px'}"
                v-model="$props.filters.project_title"
                placeholder="Project Title"
                clearable
              >
                <el-option
                  v-for="item in titlesList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>From Date:</strong>
              </div>
            </el-col>
             <!-- v-on:change="update" -->
              <!-- v-model="date" -->
            <el-col :span="5" align="left">
              <el-date-picker
                v-model="$props.filters.fromDate"
               
                type="date"
                placeholder="From Date"
                format="dd/MM/yyyy"
                size="mini"
                :style="{width:'200px'}"
                clearable
              ></el-date-picker>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>To Date:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-date-picker
                v-model="$props.filters.toDate"
                type="date"
                placeholder="To Date"
                format="dd/MM/yyyy"
                size="mini"
                :style="{width:'200px'}"
                clearable
              ></el-date-picker>
            </el-col>
          </el-row> 
        </div>
      <!-- </el-collapse-item>
    </el-collapse> -->
  </div>
</template>
<script lang="ts">
import ComponentSelector from "../Selectors/ComponentSelector.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { ppm } from "../../api/ppm/ppm";
import { group } from "../../api/group/group";
import { component } from "../../api/component/component";
import { status } from "../../api/status/status";
import { new_project } from "../../api/new_project/new_project"
import Moment from "moment";

@Component({
  name: "PPMFilters2",
  props: {
    filters: Object
    // { 
    //   project_title: "",
    //   fromDate: "",
    //   toDate: "",     
    // }
  },
  model: {
    prop: "filters",
    event: "change"
  }
  // ,
  //  watch: {
  //   date: function(val) {
  //     this.$props.filters.fromDate = val;
  //   }
  // }
})
export default class WorkOrderFilters extends Vue {
  public sectorsList: any[] = [];
  public placesList: any[] = [];
  public titlesList: any[] = [];

  public loading: boolean = false;
  public activeName: any = "1";
  public systems: any[] = [];
  public componentList: any[] = [];
  public visibleComponents: any[] = [];
  public statuses: any[] = [];
  public maintenancePeriodOptions: any[] = [];
  public maintenancePeriod: any = ''; 
  public date: Date = new Date();
  //  public update() {
  //   this.$forceUpdate();
  // }
  public async mounted() {
    this.loading = true;

   
    await this.getProjectTitlesList();
    // this.$props.filters.fromDate = new Date(
    //   new Date().setDate(new Date().getDate() - 10)
    // );
    //this.date = new Date(new Date().setDate(new Date().getDate() - 10));
    // this.loading = false;
  }
 
   
public async getProjectTitlesList(){
    await new_project
    .getProjectTitles(
    this.$store.getters.getToken,
    this.$store.getters.getHost
    )
    .then(response => {
    response.json().then(resp => {
    let i = 0;
    while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
      this.titlesList.push({
        value: JSON.parse(JSON.stringify(resp))[i].id,
        label: JSON.parse(JSON.stringify(resp))[i].literal
        });
         i++;
       }
       console.log('testforprojectplaces-------->'+JSON.stringify(resp))
      });
    });   
  }
 



}
</script>
<style>
.tag {
  background-color: rgb(201, 228, 238);
  color: black;
  width: 130px;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  padding: 2px 10px 2px 10px;
  font-size: 9pt;
  line-height: 20px;
}
strong {
  color: black;
}
.container {
  border: 5px solid rgb(152, 226, 226);
  border-radius: 10px;
  background-color: rgb(221, 218, 218);
  padding: 5px 5px 5px 5px;
}
.vdp-datepicker input {
  width: 75px;
  height: 20px;
}
</style>