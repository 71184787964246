<template>
<div>
    <!-- {{filters}} -->
    <el-row class="container">
        <NewProjectFiltersDuration v-model="filters"></NewProjectFiltersDuration>
        <br>
        <el-button @click="search" type="success" v-if="!loading">
            <strong class="white">Search</strong>
        </el-button>
    </el-row>
    <br>   
    <el-row class="container">
        <el-table :data="PPMList" :row-class-name="PPMStatus" v-loading="loading" sortable :default-sort="{prop: 'datestart', order: 'descending'}" border :header-cell-style="{background:'rgb(170, 238, 238)',color:'black' ,'text-align':'center'}">
            <el-table-column prop="project" align="center" label="Project" sortable :sort-method="(a, b) => sortproject(a, b)"></el-table-column>
            <el-table-column style="white-space: pre" prop="name" align="center" label="Name"></el-table-column>
            <el-table-column :formatter="getdatestart" prop="datestart2" align="center" label="Date Start" width="110" sortable :sort-method="(a, b) => sortdatestart(a, b)"></el-table-column>
            <el-table-column prop="timestart" align="center" label="Time Start" width="60"></el-table-column>
            <el-table-column :formatter="getdateend" prop="dateend2" align="center" label="Date End" width="110"></el-table-column>
            <el-table-column prop="timeend" align="center" label="Time End" width="60"></el-table-column>
            <el-table-column prop="mech_hours" align="center" label="Me" width="60"></el-table-column>
            <el-table-column prop="elec_hours" align="center" label="El" width="60"></el-table-column>
            <el-table-column prop="general_hours" align="center" label="Ge" width="60"></el-table-column>
            <el-table-column prop="control_hours" align="center" label="Co" width="60"></el-table-column>
            <el-table-column prop="it_hours" align="center" label="IT" width="60"></el-table-column>
            <el-table-column prop="pm_hours" align="center" label="PM" width="60"></el-table-column>
            <el-table-column prop="ps_hours" align="center" label="PS" width="60"></el-table-column>
            <el-table-column prop="admin_hours" align="center" label="Ad" width="60"></el-table-column>
            <el-table-column fixed="right" label="Actions" width="120">
                <template slot-scope="scope">
                    <el-button circle type="primary" icon="el-icon-edit" @click="handleClick(scope.$index, scope.row)"></el-button>
                    <el-button type="danger" icon="el-icon-delete" circle @click="deleteNewProjectDurations(scope.$index, scope.row)"></el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-row>
</div>
</template>

<script lang="ts">
import Moment from "moment";
import {
    Component,
    Vue,
    Prop
} from "vue-property-decorator";
import {
    component
} from "../../api/component/component";
import {
    personnel
} from "../../api/personnel/personnel";
import {
    workorder
} from "../../api/workorder/workorder";
import NewProjectFiltersDuration from "../Filters/NewProjectFiltersDuration.vue";
import {
    ppm
} from "../../api/ppm/ppm";
import {
    status
} from "../../api/status/status";
import {
    new_project
} from "../../api/new_project/new_project";
@Component({
    name: "EmployeesDurationsList",
    components: {
        NewProjectFiltersDuration
    }
})
export default class EmployeesDurationsList extends Vue {
    public EmployeesDurationsList: any[] = [];
    public newProjectEmployeesDurationsList: any[] = [];

    public PPMList: any[] = [];
    public componentList: any[] = [];
    public componentLiterals: any[] = [];
    public personnelList: any[] = [];
    public workOrderList: any[] = [];
    public statusList: any[] = [];
    public periodicityList: any[] = [];
    public loading: boolean = false;
    public filters: any = {};

    public sortproject(a: any, b: any) {

        return a.project > b.project ? 1 : -1;

    }

    public sortdatestart(a: any, b: any) {
  
        return Moment(a.datestart2).format("YYYY/MM/DD") > Moment(b.datestart2).format("YYYY/MM/DD")?1:-1;
    }
     public getdatestart(row: any) {
    if (row.datestart2 === undefined) {
      return "";
    }
    return Moment(row.datestart2).format("DD/MM/YYYY");
   
  }
    public getdateend(row: any) {
    if (row.dateend2 === undefined) {
      return "";
    }
    console.log(row.dateend2)
    return Moment(row.dateend2).format("DD/MM/YYYY");
   
  }
  

    public componentIDFormatter(row, column, cellValue, index) {
        return Array.from(row.component_table, (x: any) => x['id_component']).join(', ');
    }

    public async created() {
        this.loading = true;
        await this.getEmployeesDurationsList();
        // this.loading = true;
        await this.getPersonnelList();
        this.fillTable();
        console.log('calling getPPMList2')
        //this.getPPMList2();

    }

    public name(row: any) {

        console.log(row.name)
        console.log('xxxxx')
        let temp = ""
        //temp = row.name.replace(',' , \n )
        temp = row.name.split(',').join("\r\n")
        console.log(temp)
        console.log('yyyyyyyy')
        return temp
    }

    public async deleteNewProjectDurations(rowIndex: number, row: any) {
        await new_project
            .deleteNewProjectDurations(
                this.$store.getters.getHost,
                this.$store.getters.getToken,
                row.unique_id,

            )
            .then(async () => {
                this.$message.success("Project Deleted");
                this.$router.replace({
                    name: "load"
                });
            });

        console.log(row.unique_id)
        console.log('row.unique_id11111')

    }

    public async getEmployeesDurationsList() {
        //AllNewProject
        let response = await new_project
            .getSomeEmployeesDurations(
                this.$store.getters.getToken, this.filters,
                this.$store.getters.getHost
            )

        //.then(response => {
        response.json().then(async resp => {
            console.log(JSON.stringify(resp))
            console.log('resp1')
           
            this.EmployeesDurationsList = JSON.parse(JSON.stringify(resp));
            console.log(JSON.stringify(this.EmployeesDurationsList))
            console.log('this.newProjectEmployeesDurationsList')
            await this.getStatusList();

        });
      
    }
    
    public Completiondate(row: any) {
    
        console.log(JSON.stringify(row))

        return Moment(row.date_performed).format("DD/MM/YYYY");
    }
    
    public getDate(row: any, column: any) {
        let date_started = new Date(8640000000000000);
        let date_ended = new Date(0);
        for (const p of row.personnel) {
            if (new Date(p.date_started) < new Date(date_started)) {
                date_started = p.date_started;
            }
            if (new Date(p.date_ended) > new Date(date_ended)) {
                date_ended = p.date_ended;
            }
        }
        return (
            Moment(date_started).format("DD/MM/YYYY HH:mm") +
            " - " +
            Moment(date_ended).format("HH:mm")
        );
    }
    public sortDates() {
        return this.PPMList.sort(function (a, b) {
            let aDate_started = new Date(8640000000000000);
            let bDate_started = new Date(8640000000000000);
            for (const p of a.personnel) {
                if (
                    new Date(p.date_started).getTime() < new Date(aDate_started).getTime()
                ) {
                    aDate_started = p.date_started;
                }
            }
            for (const p of b.personnel) {
                if (
                    new Date(p.date_started).getTime() < new Date(bDate_started).getTime()
                ) {
                    bDate_started = p.date_started;
                }
            }
            console.log(a);
            console.log(b);
            if (
                new Date(aDate_started).getTime() < new Date(bDate_started).getTime()
            ) {
                return 1;
            }
            return -1;
        });
    }
    public filterHandler(value: any, row: any, column: any) {
        const property = column["property"];
        return row[property] === value;
    }
    public getFilters() {
        let a: any = []; {
            for (const s of this.statusList) {
                a.push({
                    value: s.id,
                    text: s.literal
                });
            }
        }
        return a;
    }

    public getPeriodicityFilters() {
        let a: any = [];
        let commonPeriodicities: any = [];
        let commonIds = [1, 2, 4, 6, 7, 8];
        let rarePeriodicities: any = [];
        for (const s of this.periodicityList) {
            if (commonIds.indexOf(s.id) != -1) {
                commonPeriodicities.push({
                    value: s.id,
                    text: s.literal
                });
            } else {
                rarePeriodicities.push({
                    value: s.id,
                    text: s.literal
                });
            }
        }
        for (const s of commonPeriodicities) {
            a.push(s);
        }
        for (const s of rarePeriodicities) {
            a.push(s);
        }
        return a;
    }
    public getComponentName(row: any, column: any): String {
        let content = "";
        for (const k of row.components) {
            if (content != "") {
                content = content + ", ";
            }
            let component = this.componentList.find((obj: any) => obj.id === k.id);
            if (component != undefined) {
                content = content + component.literal;
            }
        }
        return content;
    }
    public getHall(row: any, column: any): String {
        console.log(row);
        let component = this.componentList.find(
            (obj: any) => obj.id === row.components[0]
        );
        if (component != undefined) {
            return component.group1;
        }
        return "";
    }
    public getSystem(row: any, column: any): String {
        return row.system;
    }
    public async getStatusList() {
        await status
            .getAllStatuses(this.$store.getters.getToken, this.$store.getters.getHost)
            .then(response => {
                response.json().then((resp: any) => {
                    this.statusList = JSON.parse(JSON.stringify(resp));
                    this.getComponentList();
                });
            });
    }
    public async getPeriodicities() {
        await ppm
            .getMaintenancePeriodicities(
                this.$store.getters.getToken,
                this.$store.getters.getHost
            )
            .then(response => {
                response.json().then((resp: any) => {
                    this.periodicityList = JSON.parse(JSON.stringify(resp));
                    this.loading = false;
                });
            });
    }
    public filterDateTime(date: any) {
        console.log(date);
        if (date != undefined) {
            return Moment(date).format("DD/MM/YYYY HH:mm");
        }
        return "";
    }
    public getStatus(row: any, column: any): String {
        let status = this.statusList.find((obj: any) => obj.id === row.status);
        if (status != undefined) {
            return status.literal;
        }
        return "";
    }
    public getPeriodicity(row: any, column: any): String {
        let periodicity = this.periodicityList.find(
            (obj: any) => obj.id === row.periodicity
        );
        if (periodicity != undefined) {
            return periodicity.literal;
        }
        return "";
    }
    public PPMStatus({
        row,
        rowIndex
    }: {
        row: number;
        rowIndex: number;
    }): String {
        return this.getStatus(row, 1);
    }
    public async deletePPM(rowIndex: number, row: any) {
        await ppm
            .deletePPM2(
                this.$store.getters.getToken,
                row.id,
                this.$store.getters.getHost
            )
            .then(async () => {
                this.$message.success("PPM Deleted");
                this.$router.replace({
                    name: "load"
                });
            });
    }
    public async search() {
        this.loading = true;
        console.log('JSON.stringify(this.filters) BEFORE DELETES2');
        console.log(JSON.stringify(this.filters));

        var fromDateOld = null
        if (this.filters.fromDate == null) {
            delete this.filters.fromDate;
        }
        if (this.filters.fromDate) {
            fromDateOld = this.filters.fromDate
            this.filters.fromDate = JSON.stringify(this.filters.fromDate)
        }

        var toDateOld = null
        if (this.filters.toDate == null) {
            delete this.filters.toDate;
        }
        if (this.filters.toDate) {
            toDateOld = this.filters.toDate
            this.filters.toDate = JSON.stringify(this.filters.toDate)
        }

        if (this.filters.project_title == '') {
            delete this.filters.project_title;
        }

        console.log('JSON.stringify(this.filters) AFTER DELETES2');
        console.log(JSON.stringify(this.filters));

      

        await new_project
            .getSomeEmployeesDurations(this.$store.getters.getToken, this.filters, this.$store.getters.getHost)
            .then(response => {

                response.json().then(async resp => {

                    this.PPMList = JSON.parse(JSON.stringify(resp));

                    console.log('ppmlist1')
                    console.log(JSON.stringify(this.PPMList))
                    console.log('ppmlist2')
                    console.log(JSON.stringify(this.PPMList[0]))
                    console.log('ppmlist3')
                    // this.PPMList.sort(function (a, b) {
                    //     return parseFloat(Moment(b.datestart).format("YYYY/MM/DD")) - parseFloat(Moment(a.datestart).format("YYYY/MM/DD"));
                    // });
                    
                });
            });

        if (fromDateOld) {
            this.filters.fromDate = fromDateOld
        }

        if (toDateOld) {
            this.filters.toDate = toDateOld
        }

        this.loading = false;
    }
    public fillTable() {
        for (const p of this.PPMList) {
            //p.personnel = JSON.parse(p.personnel);
        }
        this.loading = false;
    }

    public filterDate(date: any) {
        if (date != undefined) {
            return Moment(date).format("DD-MM-YYYY");
        }
        return "";
    }
    public handleClick(rowIndex: number, row: any) {
        console.log("ROW");
        console.log(row);
        console.log("ROW")
        this.$store.dispatch("updateCurrentObject", row);
        this.$store.dispatch("updateCurrentComponent", "AddNewDuration");
        this.$router.replace({
            name: "load"
        });
    }

    public async getComponentList() {
        await component
            .getAllComponents(
                this.$store.getters.getToken,
                this.$store.getters.getHost
            )
            .then(response => {
                response.json().then(async resp => {
                    this.componentList = JSON.parse(JSON.stringify(resp));
                    await this.getPeriodicities();
                });
            });
    }
    public async getPersonnelList() {
        await personnel
            .getAllPersonnel(
                this.$store.getters.getToken,
                this.$store.getters.getHost
            )
            .then(response => {
                response.json().then(async resp => {
                    this.personnelList = JSON.parse(JSON.stringify(resp));
                    await this.getStatusList();
                });
            });
    }
}
</script>

<style>
.el-table .Pending {
    background: rgb(236, 255, 149);
}

.el-table .Completed {
    background: #ffffff;
}

.el-table .Cancelled {
    background: rgb(255, 186, 186);
}

.el-table--border td {
    border: 1px solid rgb(221, 231, 240);
}
</style><style scoped>
.container {
    border: 2px solid rgb(170, 238, 238);
    border-radius: 10px;
    background-color: rgb(248, 247, 247);
    padding: 5px 10px 5px 10px;
}

.personnel {
    background-color: rgb(230, 242, 255);
    font-weight: normal !important;
    padding-left: 10px;
    padding-right: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: gray;
}

.personnelHead {
    background-color: rgb(255, 242, 240);
    font-weight: normal !important;
    padding-left: 10px;
    padding-right: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: gray;
}
</style>
