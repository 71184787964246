<template>
  <div>
    <h2>Welcome</h2>
    <br>
    <h3>{{username}}</h3>
    <br>
    <h1
      style="background-color: yellow;"
      v-if="SystemInformationList.filter(r=>r.seen==false).length>0"
    >{{SystemInformationList.filter(r=>r.seen==false).length}} Unread System Information</h1>
    <br>
    <h1
      style="background-color: yellow "
      v-if="InternalInformationList.filter(r=>r.seen==false).length>0"
    >{{InternalInformationList.filter(r=>r.seen==false).length}} Unread Internal information</h1>
    <h1
      style="background-color: yellow;"
      v-if="sum!=0&&userlevel<2"
    >You have {{sum}} Vacation leaves for check – approval</h1>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { systemInformation } from "../api/systeminformation/systeminformation";
import { internalInformation } from "../api/internalinformation/internalinformation";
import { vacation } from "../api/vacation";
@Component({
  name: "Welcome"
})
export default class Welcome extends Vue {
  public username: string = "";
  public SystemInformationList: any[] = [];
  public InternalInformationList: any[] = [];
  public viewVacationSubmitted: any[] = [];
  public loading: boolean = true;
  public sum: any = " ";
  public userlevel: any = "";

  public async created() {
    this.loading = true;
    this.username = this.$store.getters.getUserName;
    await this.getvacationList();
    await this.getSysInfo();
    await this.getIntInfo();
    await this.test();
    this.userlevel = this.$store.getters.getUserLevel;
  }

  public async getvacationList() {
    await vacation
      .getAllVacations(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.viewVacationSubmitted = JSON.parse(JSON.stringify(resp));
          console.log(this.viewVacationSubmitted);
        });
      });
    this.loading = false;
  }

  test() {
    this.sum = 0;

    for (const p of this.viewVacationSubmitted) {
      if (p.decision != 3 && p.decision != 2) {
        this.sum = this.sum + 1;
        //console.log(p.decision);
      }
    }
    console.log(this.sum);
    //console.log("testforsum111111111111");
  }

  public async getSysInfo() {
    await systemInformation
      .getSomeMessages(
        this.$store.getters.getToken,
        {},
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.SystemInformationList = JSON.parse(JSON.stringify(resp));
          console.log(JSON.parse(JSON.stringify(resp)));
          for (const r of this.SystemInformationList) {
            r.seen = false;
            for (const s of r.system_information_seen) {
              if (s.users.name == this.$store.getters.getUserName) {
                r.seen = true;
              }
            }
            console.log(r.seen);
          }
        });
      });
  }
  public async getIntInfo() {
    await internalInformation
      .getSomeMessages(
        this.$store.getters.getToken,
        {},
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.InternalInformationList = JSON.parse(JSON.stringify(resp));
          console.log(JSON.parse(JSON.stringify(resp)));
          for (const r of this.InternalInformationList) {
            r.seen = false;
            for (const s of r.internal_information_seen) {
              if (s.users.name == this.$store.getters.getUserName) {
                r.seen = true;
              }
            }
          }
        });
      });
  }
}
</script>
