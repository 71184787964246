<template>
  <div class="container">
    <el-switch placeholder="Active" v-model="checked" active-text="Active" inactive-text="Inactive"></el-switch>
    <p/>
    <el-input placeholder="First Name" v-model="firstname" clearable></el-input>
    <el-input placeholder="Last Name" v-model="lastname" clearable></el-input>
    <el-input placeholder="Mobile Number" v-model="mobile" clearable></el-input>
    <el-input placeholder="Landline" v-model="landline" clearable></el-input>
    <el-input placeholder="E-mail" v-model="email" clearable></el-input>
    <el-select v-model="specialty" placeholder="Specialty" :items="specialtyList">
      <el-option
        v-for="item in specialtyList"
        :key="item.id"
        :label="item.literal"
        :value="item.id"
      ></el-option>
    </el-select>
    <el-select v-model="WoSpecialty" placeholder="WOSpecialty" :items="WOSpecialtyList">
      <el-option
        v-for="item in WOSpecialtyList"
        :key="item.id"
        :label="item.literal"
        :value="item.id"
      ></el-option>
    </el-select>
    <el-select v-model="company" placeholder="Company" :items="companyList">
      <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>
    <br>
    <el-button type="success" round @click.native="addToDB">Save</el-button>
    <el-button type="danger" round @click.native="back">Back</el-button>   
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Company } from "../../api/personnel/company";
import { Specialty } from "../../api/personnel/specialty";
import { personnel } from "../../api/personnel/personnel";
import { WOSpecialty } from "../../api/personnel/wospecialty";

@Component({
  name: "AddPersonnel"
})
export default class AddPersonnel extends Vue {
  public companyList: any[] = [];
  public specialtyList: any[] = [];
  public firstname: string = "";
  public lastname: string = "";
  public mobile: string = "";
  public landline: string = "";
  public email: string = "";
  public specialty: string = "";
  public company: string = "";
  public WoSpecialty: string = "";
  public WOSpecialtyList: any[] = [];
  public row: number = -1;
  public id: number = -1;
  public transporter: any = "";
  public mailOptions: any = "";
  public checked:boolean = true;
  public active:boolean = true;
  public CheckEdit(person: any) {
    console.log(this.specialtyList);
    console.log(this.companyList);
    console.log('person--------->'+JSON.stringify(person))
    if (person.id != undefined) {

      this.firstname = person.firstname;
      this.lastname = person.lastname;
      if (person.mobile != "null") {
        this.mobile = person.mobile;
      }
      if (person.landline != "null") {
        this.landline = person.landline;
      }
      if (person.email! != "null") {
        this.email = person.email;
      }
      this.specialty = person.specialty_id;
      this.company = person.company_id;
      this.id = person.id;
      this.active = person.active
      this.WoSpecialty = person.wo_specialty;
      this.checked=person.active
    }
  }

  public back() {
    this.$store.dispatch("updateCurrentObject", undefined);
    this.$store.dispatch("updateCurrentComponent", "PersonnelList");
    this.$store.dispatch("setCurrentId", -1);
    this.$router.replace({ name: "load" });
  }

  public async getSpecialtyList() {
    Specialty.getAllSpecialties(
      this.$store.getters.getToken,
      this.$store.getters.getHost
    ).then(response => {
      response.json().then(resp => {
        this.specialtyList = JSON.parse(JSON.stringify(resp));
        this.getCompanyList();
      });
    });
  }
  public async getCompanyList() {
    Company.getAllCompanies(
      this.$store.getters.getToken,
      this.$store.getters.getHost
    ).then(response => {
      response.json().then(resp => {
        this.companyList = JSON.parse(JSON.stringify(resp));
        this.getWOSpecialtyList();
      });
    });
  }
  public async getWOSpecialtyList() {
    WOSpecialty.getAllWOSpecialties(
      this.$store.getters.getToken,
      this.$store.getters.getHost
    ).then(response => {
      response.json().then(resp => {
        this.WOSpecialtyList = JSON.parse(JSON.stringify(resp));
        this.CheckEdit(this.row);
      });
    });
  }
  public mounted() {
    this.row = this.$store.getters.getCurrentObject;
    this.getSpecialtyList();
  }
    // data() {
    //   return {
    //     checked: true
    //   };
    // }
  public async addToDB() {
    if (this.id === -1) {
      let personnelItem = {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        mobile: this.mobile,
        landline: this.landline,
        company_id: this.company,
        specialty_id: this.specialty,
        active: this.checked,
        wo_specialty: this.WoSpecialty
      };
      await personnel.addNewStaff(
        this.$store.getters.getToken,
        personnelItem,
        this.$store.getters.getHost
      );
    } else {
      let personnelItem = {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        mobile: this.mobile,
        landline: this.landline,
        company_id: this.company,
        specialty_id: this.specialty,
        active: this.checked,
        id: this.id,
        wo_specialty: this.WoSpecialty
      };
      await personnel.editStaff(
        this.$store.getters.getToken,
        personnelItem,
        this.$store.getters.getHost
      );
    }
    this.$store.dispatch("updateCurrentObject", undefined);
    this.$store.dispatch("updateCurrentComponent", "Welcome");
    this.$store.dispatch("setCurrentId", -1);
    this.$router.replace({ name: "load" });
  }
}
</script>
<style scoped>
.container {
  border: 2px solid rgb(170, 238, 238);
  border-radius: 10px;
  background-color: rgb(248, 247, 247);
  padding: 5px 10px 5px 10px;
}
</style>