  <template>
  <div>
    <el-collapse accordion v-model="activeName">
      <el-collapse-item title="Alarm Filters" name="1">
        <div class="container">
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>From Date:</strong>
              </div>
            </el-col>
            <el-col :span="18" align="left">
              <el-date-picker
                v-model="date"
                :style="{width:'200px'}"
                v-on:change="update"
                type="date"
                placeholder="From Date"
                format="dd/MM/yyyy"
                size="mini"
                clearable
              ></el-date-picker>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>To Date:</strong>
              </div>
            </el-col>
            <el-col :span="18" align="left">
              <el-date-picker
                v-model="$props.filters.dateTo"
                :style="{width:'200px'}"
                type="date"
                placeholder="To Date"
                format="dd/MM/yyyy"
                size="mini"
                clearable
              ></el-date-picker>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>Component Name:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-select
                v-model="$props.filters.search"
                filterable
                remote
                clearable
                :style="{width:'200px'}"
                reserve-keyword
                placeholder="Component Name"
                :remote-method="remoteMethod"
                :loading="loading"
                size="mini"
              >
                <el-option
                  v-for="item in visibleComponents"
                  :key="item.id"
                  :label="item.literal"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>Hall:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-select
                v-model="$props.filters.hall"
                filterable
                remote
                clearable
                :style="{width:'200px'}"
                reserve-keyword
                placeholder="Hall"
                :remote-method="hallMethod"
                :loading="loading"
                size="mini"
              >
                <el-option v-for="item in visibleHalls" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>System:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-select
                v-model="$props.filters.system"
                filterable
                remote
                clearable
                :style="{width:'200px'}"
                reserve-keyword
                placeholder="System"
                :remote-method="systemMethod"
                :loading="loading"
                size="mini"
              >
                <el-option v-for="item in visibleSystems" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" align="left">
              <div class="tag">
                <strong>Group:</strong>
              </div>
            </el-col>
            <el-col :span="5" align="left">
              <el-select
                v-model="$props.filters.group"
                filterable
                remote
                clearable
                :style="{width:'200px'}"
                reserve-keyword
                placeholder="Group"
                :remote-method="groupMethod"
                :loading="loading"
                size="mini"
              >
                <el-option v-for="item in visibleGroups" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-col>
          </el-row>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { users } from "../../api/users/users";
import { component } from "../../api/component/component";
@Component({
  name: "AlarmFilters",
  props: {
    filters: Object
    // {
    //   dateFrom: "",
    //   dateTo: "",
    //   search: "",
    //   hall: "",
    //   system: ""
    // }
  },
  model: {
    prop: "filters",
    event: "change"
  },
  watch: {
    date: function(val) {
      this.$props.filters.dateFrom = val;
    }
  }
})
export default class AlarmFilters extends Vue {
  public loading: boolean = false;
  public activeName: string = "1";
  public componentList: any[] = [];
  public date: Date = new Date();
  public visibleComponents: any[] = [];
  public halls: any[] = [];
  public systems: any[] = [];
  public groups: any[] = [];
  public codes: any[] = [];
  public descriptions: any[] = [];
  public visibleHalls: any[] = [];
  public visibleSystems: any[] = [];
  public visibleGroups: any[] = [];

  public created() {
    this.$props.filters.dateFrom = new Date(
      new Date().setDate(new Date().getDate() - 10)
    );
  }
  public async mounted() {
    await this.getComponentList();
    this.$props.filters.dateFrom = new Date(
      new Date().setDate(new Date().getDate() - 10)
    );
    this.date = new Date(new Date().setDate(new Date().getDate() - 10));
  }
  public update() {
    this.$forceUpdate();
  }
  public async getComponentList() {
    await component
      .getAllComponents(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then((resp: any) => {
          this.componentList = JSON.parse(JSON.stringify(resp));
          for (const i of this.componentList) {
            if (!this.halls.includes(i.group1)) {
              this.halls.push(i.group1);
              console.log(this.halls);
            }
            if (!this.systems.includes(i.group2)) {
              this.systems.push(i.group2);
            }
            if (!this.groups.includes(i.group3)) {
              this.groups.push(i.group3);
            }
            if (!this.codes.includes(i.group4)) {
              this.codes.push(i.group4);
            }
            if (!this.descriptions.includes(i.group5)) {
              this.descriptions.push(i.group5);
            }
          }
        });
      });
  }
  public remoteMethod(query: string) {
    console.log(this.componentList);
    if (query !== "") {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.visibleComponents = this.componentList.filter(item =>
          item.literal.toLowerCase().includes(query.toLowerCase())
        );
      }, 200);
    } else {
      this.visibleComponents = [];
    }
  }
  public hallMethod(query: string) {
    console.log(this.halls);
    if (query !== "") {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.visibleHalls = this.halls.filter(item =>
          item.toLowerCase().includes(query.toLowerCase())
        );
      }, 200);
    } else {
      this.visibleHalls = [];
    }
  }
  public systemMethod(query: string) {
    console.log(this.systems);
    if (query !== "") {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.visibleSystems = this.systems.filter(item =>
          item.toLowerCase().includes(query.toLowerCase())
        );
      }, 200);
    } else {
      this.visibleSystems = [];
    }
  }
  public groupMethod(query: string) {
    console.log(this.groups);
    if (query !== "") {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.visibleGroups = this.groups.filter(item =>
          item.toLowerCase().includes(query.toLowerCase())
        );
      }, 200);
    } else {
      this.visibleGroups = [];
    }
  }
}
</script>
<style scoped>
.tag {
  background-color: rgb(201, 228, 238);
  color: black;
  width: 130px;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  padding: 2px 10px 2px 10px;
  font-size: 9pt;
  line-height: 20px;
}
strong {
  color: black;
}
.container {
  border: 5px solid rgb(152, 226, 226);
  border-radius: 10px;
  background-color: rgb(221, 218, 218);
  padding: 5px 5px 5px 5px;
}
</style>