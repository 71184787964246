class WorkOrder {
  public async addWorkOrder(
    token: string,
    WorkOrderItem: any,
    host: any
  ): Promise<any> {
    WorkOrderItem[0].name = WorkOrderItem[0].name.replace("&", "and");
    if (WorkOrderItem[0].comments != undefined) {
      WorkOrderItem[0].comments = WorkOrderItem[0].comments.replace("&", "and");
    }
    let result: Response;
    WorkOrderItem.token = token;
    result = (await fetch(host + "/api/workOrder/Add", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body:
        "token=" +
        token +
        "&name=" +
        WorkOrderItem[0].name +
        "&status=" +
        WorkOrderItem[2].selectedStatus +
        "&type=" +
        WorkOrderItem[0].type +
        "&date_issued=" +
        WorkOrderItem[0].issuedate +
        "&date_closed=" +
        WorkOrderItem[2].enddate +
        "&comments=" +
        WorkOrderItem[0].comments +
        "&recycled=false" +
        "&aia_id=" +
        WorkOrderItem[0].aia +
        "&component_ids=[" +
        WorkOrderItem[1] +
        "]" +
        "&personnelObj=" +
        JSON.stringify(WorkOrderItem[3])
    })) as any;

    return await result.json();
  }

  public async editWorkOrder(
    token: string,
    WorkOrderItem: any,
    host: any
  ): Promise<any> {
    let result: Response;
    WorkOrderItem[0].name = WorkOrderItem[0].name.replace("&", "and");
    if (WorkOrderItem[0].comments != undefined) {
      WorkOrderItem[0].comments = WorkOrderItem[0].comments.replace("&", "and");
    }
    console.log(WorkOrderItem[0].name);
    result = (await fetch(host + "/api/workOrder/Add", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body:
        "id=" +
        WorkOrderItem[0].id +
        "&token=" +
        token +
        "&name=" +
        WorkOrderItem[0].name +
        "&status=" +
        WorkOrderItem[2].selectedStatus +
        "&type=" +
        WorkOrderItem[0].type +
        "&recycled=false" +
        "&date_issued=" +
        WorkOrderItem[0].issuedate +
        "&date_closed=" +
        WorkOrderItem[2].enddate +
        "&comments=" +
        WorkOrderItem[0].comments +
        "&aia_id=" +
        WorkOrderItem[0].aia +
        "&component_ids=[" +
        WorkOrderItem[1] +
        "]" +
        "&personnelObj=" +
        JSON.stringify(WorkOrderItem[3])
    })) as any;

    return await result.json();
  }

  public async getAllTypes(token: string, host: any): Promise<any> {
    return await fetch(host + "/api/workOrder/getTypes?token=" + token);
  }

  public async getAllWorkOrders(token: string, host: any): Promise<any> {
    return await fetch(host + "/api/workOrder/getAll?token=" + token);
  }

  public async downloadAllWorkOrders(token: string, host: any): Promise<any> {
    return await fetch(host + "/api/workOrder/getAllReports?token=" + token);
  }

  public async downloadDetails(
    token: string,
    id: number,
    host: any
  ): Promise<any> {
    return await fetch(
      host + "/api/workOrder/getDetails?token=" + token + "&id=" + id
    );
  }

  public async deleteWorkOrder(
    token: string,
    id: number,
    host: any
  ): Promise<any> {
    return await fetch(
      host + "/api/workOrder/delete?token=" + token + "&id=" + id
    );
  }

  public async recycleWorkOrder(
    token: string,
    id: number,
    aia_id: number,
    host: any
  ): Promise<any> {
    return await fetch(
      host + "/api/workOrder/recycle?token=" + token + "&id=" + id + "&aia_id=" + aia_id
    );
  }

  public async getSomeWorkOrders(
    token: string,
    filters: any,
    host: any
  ): Promise<any> {
    return await fetch(
      host +
        "/api/workOrder/getSome?token=" +
        token +
        "&idFrom=" +
        filters.idFrom +
        "&idTo=" +
        filters.idTo +
        "&dateFrom=" +
        filters.dateFrom +
        "&dateTo=" +
        filters.dateTo +
        "&search=" +
        filters.search +
        "&keyword=" +
        filters.keyword +
        "&status=" +
        filters.status +
        "&type=" +
        filters.type
    );
  }

  public async getSomeRecycledWorkOrders(
    token: string,
    filters: any,
    host: any
  ): Promise<any> {
    return await fetch(
      host +
        "/api/workOrder/getSomeRecycled?token=" +
        token +
        "&idFrom=" +
        filters.idFrom +
        "&idTo=" +
        filters.idTo +
        "&dateFrom=" +
        filters.dateFrom +
        "&dateTo=" +
        filters.dateTo +
        "&search=" +
        filters.search +
        "&keyword=" +
        filters.keyword +
        "&status=" +
        filters.status +
        "&type=" +
        filters.type
    );
  }

  public async downloadSomeWorkOrders(
    token: string,
    filters: any,
    host: any
  ): Promise<any> {
    return await fetch(
      host +
        "/api/workOrder/getSomeReports?token=" +
        token +
        "&idFrom=" +
        filters.idFrom +
        "&idTo=" +
        filters.idTo +
        "&dateFrom=" +
        filters.dateFrom +
        "&dateTo=" +
        filters.dateTo +
        "&search=" +
        filters.search +
        "&keyword=" +
        filters.keyword +
        "&status=" +
        filters.status +
        "&type=" +
        filters.type
    );
  }
}

export const workorder = new WorkOrder();
