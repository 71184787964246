class Login {
  public async getUser(username: string, password: string, host: any, duration: number): Promise<any> {
    let result: Response;
    try {
      console.log("host -> " + host);
      result = (await fetch(host + "/api/authenticate/", {
        method: "POST",
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: "username=" + username + "&password=" + password + "&duration=" + duration,
      })) as any;
    } catch (ex) {
      console.log("error while authenticating:" + ex);
      return Promise.reject();
    }

    return result;
  }
}

export const login = new Login();
