<template>
  <div>
    <el-row class="container">
      <WorkOrderFilters v-model="filters"></WorkOrderFilters>
      <br>
      <el-button @click="search" type="success"><strong class="white">Search</strong></el-button>
    </el-row>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { workorder } from "../../api/workorder/workorder";
import WorkOrderFilters from "../Filters/WorkOrderFilters.vue";
@Component({
  name: "DownloadWorkOrders",
  components: {
    WorkOrderFilters
  }
})
export default class DownloadWorkOrders extends Vue {
  public filters: any[] = [];
  public async search() {
    if ((this.filters as any).dateFrom === null) {
      delete (this.filters as any).dateFrom;
    }
    if ((this.filters as any).dateTo === null) {
      delete (this.filters as any).dateTo;
    }
    let token = this.$store.getters.getToken;
    this.$store.dispatch("updateCurrentObject", {});
    this.$store.dispatch("updateCurrentComponent", "WorkOrderList");
    this.$router.replace({
      name: "load"
    });
    console.log(this.filters);
    let response = await workorder.downloadSomeWorkOrders(
      this.$store.getters.getToken,
      this.filters,
      this.$store.getters.getHost
    );
    response.blob().then((blob: any) => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = "out.xls";
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    });
  }
}
</script>
<style scoped>
.container {
  border: 2px solid rgb(170, 238, 238);
  border-radius: 10px;
  background-color: rgb(248, 247, 247);
  padding: 5px 10px 5px 10px;
}
</style>